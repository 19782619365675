import { CellObject } from '../features/overview-table/hooks/use-table';

type Props = {
  cell: CellObject;
};

const RenderPercent = ({ cell }: Props) => {
  let occColor = 'bg-blue-300 bg-opacity-50';

  if (Number(cell.value) > 0.85) {
    occColor = 'bg-red-300 bg-opacity-50';
  } else if (Number(cell.value) > 0.5) {
    occColor = 'bg-green-700 bg-opacity-25';
  }
  return (
    <div className='w-full h-5'>
      <div
        className={`${occColor} text-xs text-left p-1 h-5 leading-none`}
        style={{ width: cell.displayValue as string }}
      >
        {' '}
        {cell.displayValue}
      </div>
    </div>
  );
};

export default RenderPercent;
