import {
  Maybe,
  MeetingEmail,
  TeamMember,
  TeamMemberActive,
} from '../../../graphql/types';

import { BiEdit } from 'react-icons/bi';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { clsx } from 'clsx';
import { useState } from 'react';
import { useUpdateTeamMemberMutation } from '../gql/_gen_/hotel-team.gql';

type ExpandedProps = {
  expanded?: Maybe<TeamMember>;
  setExpanded: React.Dispatch<
    React.SetStateAction<Maybe<TeamMember> | undefined>
  >;
};

const ExpandedTeamItem = ({ expanded, setExpanded }: ExpandedProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [name, setName] = useState<TeamMember['name']>(expanded?.name);
  const [email, setEmail] = useState<TeamMember['email']>(expanded?.email);
  const [emailPref, setEmailPref] = useState<TeamMember['emailPref']>(
    expanded?.emailPref
  );
  const [phone, setPhone] = useState<TeamMember['phone']>(expanded?.phone);
  const [title, setTitle] = useState<TeamMember['title']>(expanded?.title);
  const [active, setActive] = useState<TeamMember['active']>(expanded?.active);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [updateTeamMember] = useUpdateTeamMemberMutation();

  const handleBack = () => {
    setExpanded(undefined);
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    setName(expanded?.name);
    setEmail(expanded?.email);
    setEmailPref(expanded?.emailPref);
    setPhone(expanded?.phone);
    setTitle(expanded?.title);
    setActive(expanded?.active);
    setNameError(false);
    setEmailError(false);
  };

  const handleSave = async () => {
    if (expanded && expanded.id) {
      await updateTeamMember({
        variables: {
          updateTeamMemberId: expanded.id,
          active,
          email,
          emailPref,
          name,
          phone,
          title,
        },
      });
      setEditMode(false);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    validateName(e);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    validateEmail(e);
  };

  const validateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  const validateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    const valid = email
      .toLowerCase()
      .match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    if (!valid) {
      setEmailError(true);
      return;
    }

    if (e.target.value.length > 0) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  return (
    <div className='p-4 my-2 flex flex-row'>
      <button onClick={() => handleBack()}>
        <MdOutlineArrowBackIosNew className='h-full' />
      </button>
      <div className='flex flex-col ml-2 px-2 w-full'>
        <div className={clsx('flex justify-between', !editMode && 'mb-1.5')}>
          {editMode ? (
            <div className='flex flex-col w-full my-0.5'>
              <div className='flex items-center'>
                <label htmlFor='new-name' className='text-sm text-gray-600'>
                  Name:{' '}
                </label>
                {nameError && (
                  <p className='text-xs text-red-600 ml-2'>
                    Name cannot be empty
                  </p>
                )}
              </div>
              <input
                id='new-name'
                type='text'
                value={name as string}
                onChange={(e) => handleNameChange(e)}
                className='my-0.5 border border-gray-300 rounded-md text-sm p-1.5 w-full'
              />
            </div>
          ) : (
            <h2 className='text-gray-800 font-semibold text-sm'>{name}</h2>
          )}
          {!editMode && (
            <button onClick={() => setEditMode(true)}>
              <BiEdit />
            </button>
          )}
        </div>
        {editMode ? (
          <div className='flex flex-col my-0.5'>
            <div className='flex items-center'>
              <label htmlFor='new-email' className='text-sm text-gray-600'>
                Email:{' '}
              </label>
              {emailError && (
                <p className='text-xs text-red-600 ml-2'>
                  Please enter a valid email
                </p>
              )}
            </div>
            <input
              id='new-email'
              className='my-0.5 border border-gray-300 rounded-md text-sm p-1.5'
              type='text'
              value={email as string}
              onChange={(e) => handleEmailChange(e)}
              onBlur={(e) => validateEmail(e)}
            />
          </div>
        ) : (
          <p className='text-sm text-gray-700 my-0.5'>
            Email: <span className='text-gray-800 font-medium'>{email}</span>
          </p>
        )}
        {editMode ? (
          <div className='flex flex-col my-0.5'>
            <div className='flex items-center'>
              <label htmlFor='new-email' className='text-sm text-gray-600'>
                Email Preference:{' '}
              </label>
            </div>
            <select
              id='new-email-pref'
              className='my-0.5 border border-gray-300 rounded-md text-sm p-1.5'
              value={emailPref as string}
              onChange={(e) => setEmailPref(e.target.value as MeetingEmail)}
            >
              <option value={MeetingEmail.All}>{MeetingEmail.All}</option>
              <option value={MeetingEmail.Daily}>{MeetingEmail.Daily}</option>
              <option value={MeetingEmail.Weekly}>{MeetingEmail.Weekly}</option>
              <option value={MeetingEmail.Adhoc}>{MeetingEmail.Adhoc}</option>
            </select>
          </div>
        ) : (
          <p className='text-sm text-gray-700 my-0.5'>
            Email Preference:{' '}
            <span className='text-gray-800 font-medium'>{emailPref}</span>
          </p>
        )}
        {editMode ? (
          <div className='flex flex-col my-0.5'>
            <label htmlFor='new-phone' className='text-sm text-gray-600'>
              Phone:{' '}
            </label>
            <input
              id='new-phone'
              className='my-0.5 border border-gray-300 rounded-md text-sm p-1.5'
              type='text'
              value={phone as string}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        ) : (
          !!phone?.length && (
            <p className='text-sm text-gray-700 my-0.5'>
              Phone: <span className='text-gray-800 font-medium'>{phone}</span>
            </p>
          )
        )}
        {editMode ? (
          <div className='flex flex-col my-0.5'>
            <label htmlFor='new-title' className='text-sm text-gray-600'>
              Title:{' '}
            </label>
            <input
              id='new-title'
              className='my-0.5 border border-gray-300 rounded-md text-sm p-1.5'
              type='text'
              value={title as string}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        ) : (
          !!title?.length && (
            <p className='text-sm text-gray-700 my-0.5'>
              Title: <span className='text-gray-800 font-medium'>{title}</span>
            </p>
          )
        )}
        {editMode ? (
          <div className='flex my-0.5'>
            <label htmlFor='new-active' className='text-sm text-gray-600'>
              Active:{' '}
            </label>
            <input
              id='new-active'
              className='my-0.5 border border-gray-300 rounded-md ml-2'
              type='checkbox'
              checked={active === TeamMemberActive.Active}
              onChange={(e) =>
                setActive(
                  e.target.checked === true
                    ? TeamMemberActive.Active
                    : TeamMemberActive.Inactive
                )
              }
            />
          </div>
        ) : (
          <p className='text-sm text-gray-700 my-0.5'>
            Active:{' '}
            <span className='text-gray-800 font-medium'>
              {active ? 'Yes' : 'No'}
            </span>
          </p>
        )}
        {editMode && (
          <div className='flex w-full justify-center'>
            <div className='flex w-[50%] justify-evenly'>
              <button
                onClick={() => handleSave()}
                className={clsx(
                  'text-blue-700 hover:text-blue-900 text-sm rounded-md m-2',
                  (nameError || emailError) && 'opacity-50'
                )}
                disabled={nameError || emailError}
              >
                Save
              </button>
              <button
                onClick={() => handleCancel()}
                className='text-red-500 hover:text-red-700 text-sm rounded-md m-2 p-1'
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ExpandedTeamItem };
