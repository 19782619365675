interface BouncingLoadingTextProps {
  text?: string;
}

export default function Component({
  text = 'RM Workspace - Page Loading',
}: BouncingLoadingTextProps) {
  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100'>
      <div className='text-3xl text-sky-800'>
        <span className='inline-block animate-pulse'>{text}</span>
      </div>
      <div className='mt-4 text-gray-600'>Please wait...</div>
    </div>
  );
}
