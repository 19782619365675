'use client';

import { FeatureFlag, User } from '../../../graphql/types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/ui/table';
import { useCallback, useState } from 'react';

import { Input } from '../../../components/ui/input';
import { Switch } from '../../../components/ui/switch';
import { useToast } from '../../../components/ui/use-toast';

interface UserFlagManagementProps {
  flag: FeatureFlag;
  allUsers: (User | null)[];
  onToggleUserFlag: (
    flagKey?: string | null,
    userId?: string | null,
    active?: boolean
  ) => Promise<any>;
  updating: boolean;
}

export function UserFlagManagement({
  flag,
  allUsers,
  onToggleUserFlag,
  updating = false,
}: UserFlagManagementProps) {
  const { toast } = useToast();
  const [searchTerm, setSearchTerm] = useState('');
  const [optimisticUpdates, setOptimisticUpdates] = useState<
    Record<string, boolean>
  >({});

  const filteredUsers = allUsers.filter(
    (user) =>
      user?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleToggleUser = useCallback(
    async (
      flagKey?: string | null,
      userId?: string | null,
      checked = false
    ) => {
      if (!userId) return;

      // Optimistically update the UI
      setOptimisticUpdates((prev) => ({ ...prev, [userId]: checked }));

      try {
        const result = await onToggleUserFlag(flagKey, userId, checked);

        if (result.success) {
          toast({
            title: result.title,
            description: result.description,
          });
        } else {
          throw new Error(result.description);
        }
      } catch (error) {
        // Revert the optimistic update on error
        setOptimisticUpdates((prev) => ({ ...prev, [userId]: !checked }));
        toast({
          title: 'Error',
          description:
            error instanceof Error
              ? error.message
              : 'An error occurred while updating the flag.',
          variant: 'destructive',
        });
      }
    },
    [onToggleUserFlag, toast]
  );

  const getCheckedState = useCallback(
    (user: User | null, flag: FeatureFlag) => {
      if (!user?.id) return false;
      if (user.id in optimisticUpdates) return optimisticUpdates[user.id];
      const userFlag = flag.users?.find((fu) => fu?.user_id === user.id);
      return userFlag?.active ?? flag.default_value ?? false;
    },
    [optimisticUpdates]
  );

  return (
    <div className='space-y-4 w-full'>
      <Input
        placeholder='Search users...'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className='h-[200px] overflow-auto'>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Email</TableHead>
              <TableHead>Flag Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user?.id}>
                <TableCell>
                  <Switch
                    checked={getCheckedState(user, flag)}
                    disabled={updating}
                    onCheckedChange={(checked) =>
                      handleToggleUser(flag.key, user?.id, checked)
                    }
                  />
                </TableCell>
                <TableCell>{user?.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
