import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { ChevronRight, ChevronsUpDown, Plus, X } from 'lucide-react';

import { ActionItemView } from '@/features/meetings/components/action-item-view';
import { AgendaView } from '@/features/meetings/components/agenda-view';
import { Button } from '@/components/ui/button';
import { MeetingForms } from '@/features/meetings/components/meeting-forms';
import MeetingNotes from '@/components/meeting-notes';
import { Notepad } from '@/features/notes/notepad';
import { TeamView } from '@/features/meetings/components/team-view';
import { useHotel } from '@/context/hotelContext';
import { useIsShared } from '@/features/reports/hooks/use-report-location';
import { useLocation } from 'react-router-dom';
import { useMeeting } from '@/features/meetings/context/meeting-context';
import { useMeetingTools } from '../meetings/hooks/use-meeting-tools';
import { useState } from 'react';
import { useUser } from '@/context/userContext';

type ExpandableMeetingToolsProps = {
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
};

export enum FormView {
  ACTION_ITEMS = 'actionItems',
  AGENDA = 'agenda',
  NOTES = 'notes',
  TEAM = 'team',
}

export function ExpandableMeetingTools({
  isExpanded,
  setIsExpanded,
}: ExpandableMeetingToolsProps) {
  const location = useLocation();
  const { isShared } = useIsShared();
  const { hotel } = useHotel();
  const { user } = useUser();
  const { meetingTools } = useMeetingTools(hotel?.brand_code);
  const { meeting, setMeeting } = useMeeting();
  const [showForm, setShowForm] = useState<FormView | null>(null);
  const [expandedSections, setExpandedSections] = useState<string[]>([]);

  const toggleExpand = () => setIsExpanded(!isExpanded);
  const showMeetingNotes = !location.pathname.includes('reports');

  const handleCloseForm = () => {
    setShowForm(null);
  };

  const toggleAllSections = (hide: boolean) => {
    const allSections = [
      'agenda',
      'team',
      'actionItems',
      'notepad',
      ...(showMeetingNotes ? ['notes'] : []),
    ];
    setExpandedSections(hide ? [] : allSections);
  };

  return (
    <div
      className={`fixed z-50 top-0 right-0 h-full bg-white shadow-lg transition-all duration-300 ${
        isExpanded ? 'w-[550px]' : 'w-1'
      } flex`}
    >
      {isExpanded && (
        <div className='p-4 w-full overflow-y-auto'>
          <Button
            variant='ghost'
            size='icon'
            className='absolute animate-pulse h-7 w-7 top-10 -left-4 transform -translate-y-1/2 bg-sky-200 border-2 border-sky-700 rounded-full shadow'
            onClick={toggleExpand}
          >
            <ChevronRight className='h-4 w-4' />
          </Button>
          <div className='flex justify-between items-center mb-4'>
            <h3 className='text-lg font-semibold'>Meeting Tools</h3>
            <Button
              variant='outline'
              size='sm'
              onClick={() => toggleAllSections(expandedSections.length > 0)}
            >
              <ChevronsUpDown className='h-4 w-4 mr-2' />
              {expandedSections.length === 0 ? 'Expand' : 'Collapse'} All
            </Button>
          </div>
          <Accordion
            type='multiple'
            value={expandedSections}
            onValueChange={(value) => setExpandedSections(value)}
            className='w-full'
          >
            {showMeetingNotes && (
              <AccordionItem value='notes'>
                <AccordionTrigger className='flex justify-between items-center'>
                  Meeting Notes
                </AccordionTrigger>
                <AccordionContent>
                  <MeetingNotes
                    brandCode={hotel?.brand_code}
                    meeting={meeting}
                    parentName='meeting-panels'
                    setMeeting={setMeeting}
                    userId={user?.id as string | undefined}
                  />
                </AccordionContent>
              </AccordionItem>
            )}
            <AccordionItem value='agenda'>
              <AccordionTrigger className='flex justify-between items-center'>
                Agenda
                {!isShared && expandedSections.includes('agenda') && (
                  <Button
                    variant='outline'
                    size='sm'
                    className='ml-2'
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowForm(
                        showForm === FormView.AGENDA ? null : FormView.AGENDA
                      );
                    }}
                  >
                    <Plus className='h-4 w-4 mr-2' />
                    {showForm === FormView.AGENDA ? 'Hide Form' : 'Add Item'}
                  </Button>
                )}
              </AccordionTrigger>
              <AccordionContent>
                {showForm === FormView.AGENDA ? (
                  <div className='relative mt-4 p-4 bg-gray-50 rounded-md'>
                    <div className='absolute top-2 right-2'>
                      <Button
                        variant='ghost'
                        size='sm'
                        className='text-gray-500 hover:text-gray-700'
                        onClick={handleCloseForm}
                      >
                        <X className='h-4 w-4' />
                        <span className='sr-only'>Close form</span>
                      </Button>
                    </div>
                    <h4 className='text-lg font-semibold mb-4'>
                      Add New Agenda
                    </h4>
                    <MeetingForms
                      brandCode={hotel?.brand_code}
                      close={handleCloseForm}
                      view={FormView.AGENDA}
                    />
                  </div>
                ) : (
                  <AgendaView
                    isTool={true}
                    meeting={meeting}
                    meetingTools={meetingTools}
                  />
                )}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value='team'>
              <AccordionTrigger className='flex justify-between items-center'>
                Team
                {!isShared && expandedSections.includes('team') && (
                  <Button
                    variant='outline'
                    size='sm'
                    className='ml-2'
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowForm(
                        showForm === FormView.TEAM ? null : FormView.TEAM
                      );
                    }}
                  >
                    <Plus className='h-4 w-4 mr-2' />
                    {showForm === FormView.TEAM ? 'Hide Form' : 'Add Item'}
                  </Button>
                )}
              </AccordionTrigger>
              <AccordionContent>
                {showForm === FormView.TEAM ? (
                  <div className='relative mt-4 p-4 bg-gray-50 rounded-md'>
                    <div className='absolute top-2 right-2'>
                      <Button
                        variant='ghost'
                        size='sm'
                        className='text-gray-500 hover:text-gray-700'
                        onClick={handleCloseForm}
                      >
                        <X className='h-4 w-4' />
                        <span className='sr-only'>Close form</span>
                      </Button>
                    </div>
                    <h4 className='text-lg font-semibold mb-4'>Add New Team</h4>
                    <MeetingForms
                      brandCode={hotel?.brand_code}
                      close={handleCloseForm}
                      view={FormView.TEAM}
                    />
                  </div>
                ) : (
                  <TeamView
                    isTool={true}
                    meeting={meeting}
                    meetingTools={meetingTools}
                  />
                )}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value='actionItems'>
              <AccordionTrigger className='flex justify-between items-center'>
                Action Items
                {!isShared && expandedSections.includes('actionItems') && (
                  <Button
                    variant='outline'
                    size='sm'
                    className='ml-2'
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowForm(
                        showForm === FormView.ACTION_ITEMS
                          ? null
                          : FormView.ACTION_ITEMS
                      );
                    }}
                  >
                    <Plus className='h-4 w-4 mr-2' />
                    {showForm === FormView.ACTION_ITEMS
                      ? 'Hide Form'
                      : 'Add Item'}
                  </Button>
                )}
              </AccordionTrigger>
              <AccordionContent>
                {showForm === FormView.ACTION_ITEMS ? (
                  <div className='relative mt-4 p-4 bg-gray-50 rounded-md'>
                    <div className='absolute top-2 right-2'>
                      <Button
                        variant='ghost'
                        size='sm'
                        className='text-gray-500 hover:text-gray-700'
                        onClick={handleCloseForm}
                      >
                        <X className='h-4 w-4' />
                        <span className='sr-only'>Close form</span>
                      </Button>
                    </div>
                    <h4 className='text-lg font-semibold mb-4'>
                      Add New Action Item
                    </h4>
                    <MeetingForms
                      brandCode={hotel?.brand_code}
                      close={handleCloseForm}
                      view={FormView.ACTION_ITEMS}
                    />
                  </div>
                ) : (
                  <ActionItemView meetingTools={meetingTools} />
                )}
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value='notepad'>
              <AccordionTrigger className='flex justify-between items-center'>
                Notepad
              </AccordionTrigger>
              <AccordionContent>
                <Notepad brandCode={hotel?.brand_code} userId={user?.id} />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      )}
    </div>
  );
}
