import {
  ColumnSettings,
  HotelSettings,
  TableAction,
} from '../../features/table-settings';

import { IconType } from 'react-icons/lib';
import { useDashboard } from '../../context/dashboardContext';

type BtnProps = {
  btnClass: string;
  btnIcon: IconType;
  btnIconClass?: string;
  btnTooltip: string;
  enabled?: boolean;
  btnKey: string;
  toolbar?: boolean;
};

export default function BtnToolbarToggle({
  btnClass,
  btnIcon: BtnIcon,
  btnIconClass,
  btnTooltip,
  enabled,
  btnKey,
  toolbar = false,
}: BtnProps) {
  const { brandCode, hotelSettings, options, setTableSettings } =
    useDashboard();
  const handleClick = (action: TableAction) => {
    setTableSettings(action);
  };

  const toggleBtnClass = `${btnClass} cursor-pointer ${
    toolbar ? '' : 'hidden'
  } ${
    enabled
      ? 'text-white bg-blue-500 border-black'
      : 'border-gray-300 bg-white text-gray-500'
  }`;

  const hKey = btnKey as keyof HotelSettings;
  const colSettings = hotelSettings?.[hKey] as ColumnSettings;
  const vis = colSettings?.visible || false;

  return (
    <span
      onClick={() =>
        handleClick({
          brandCode,
          type: 'TOGGLE',
          key: btnKey,
          payload: {
            visible: !vis,
            year: options.yearsCompare,
          },
        })
      }
      className={toggleBtnClass}
      data-tip={btnTooltip}
    >
      <BtnIcon className={btnIconClass} aria-hidden='true' />
    </span>
  );
}
