/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
export type AgendaItemFieldsFragment = { __typename?: 'AgendaItem', brandCode?: string | null, completed?: boolean | null, createdAt?: string | null, createdById?: string | null, id?: string | null, name?: string | null, notes?: string | null, updatedAt?: string | null, isDefault?: boolean | null, order?: number | null };

export const AgendaItemFieldsFragmentDoc = gql`
    fragment AgendaItemFields on AgendaItem {
  brandCode
  completed
  createdAt
  createdById
  id
  name
  notes
  updatedAt
  isDefault
  order
}
    `;
export const namedOperations = {
  Fragment: {
    AgendaItemFields: 'AgendaItemFields'
  }
}