import { isBefore, today } from '../../../helpers/dateHelpers';

import { CellObject } from '../../../features/overview-table/hooks/use-table';
import RenderEditable from '../../../renderers/RenderEditable';
import RenderEventCell from '../../../renderers/RenderEventCell';
import RenderGroupDetails from '../../../renderers/RenderGroupDetails';
import RenderPercent from '../../../renderers/RenderPercent';
import RenderRateLevelStatus from '../../../renderers/RenderRateLevelStatus';
import RenderRateShop from '../../../renderers/RenderRateShop';
import RenderRmRecStatus from '../../../renderers/RenderRmRecStatus';
import RenderRoomTypeDetails from '../../../renderers/RenderRoomTypeDetails';
import { useDashboard } from '../../../context/dashboardContext';
import { useFlags } from '@/context/feature-flags';

type Props = {
  cell: CellObject;
  columnIndex: number;
  isLocked: boolean;
  rowIndex: number;
  style: React.CSSProperties;
};

type RenderProps = {
  cell: CellObject;
  columnIndex: number;
  editEnabled: boolean;
  isActiveCell: boolean;
  rowIndex: number;
};

const Cell = ({ cell, columnIndex, isLocked, rowIndex, style }: Props) => {
  const { activeCell, editEnabled, setActiveCell } = useDashboard();
  const { flags } = useFlags();

  const flagShowSuperscript = flags?.['rate-change-superscript'];

  const isRateShop =
    cell.meta.renderer === 'prm.rate_shop' && flagShowSuperscript;

  const isActiveCell =
    activeCell.row === rowIndex && activeCell.col === columnIndex;
  const isActiveRowOrColumn =
    activeCell.row === rowIndex || activeCell.col === columnIndex;
  const baseClass = `flex flex-row ${
    isRateShop ? 'text-left pl-1' : 'justify-center'
  } text-xs pt-1 ${isActiveRowOrColumn ? 'bg-blue-50' : cell.cls}`;

  const lockCell = isLocked
    ? `sticky ${
        isActiveRowOrColumn
          ? null
          : cell.cls.includes('bg')
          ? cell.cls
          : 'bg-white'
      }`
    : '';

  const isEditable =
    cell.dataKey.includes('forecast') &&
    cell.dataKey.includes('_1') &&
    isBefore(cell.stayDate, today())
      ? false
      : editEnabled;

  return (
    <div
      className={`${baseClass} ${lockCell}`}
      style={style}
      onClick={() => setActiveCell({ row: rowIndex, col: columnIndex })}
    >
      <CellRender
        cell={cell}
        columnIndex={columnIndex}
        editEnabled={isEditable}
        isActiveCell={isActiveCell}
        rowIndex={rowIndex}
      />
    </div>
  );
};

const CellRender = ({
  cell,
  columnIndex,
  editEnabled,
  isActiveCell,
  rowIndex,
}: RenderProps) => {
  switch (cell.meta?.renderer) {
    case 'prm.event':
      return <RenderEventCell cell={cell} />;
    case 'prm.group_sold':
      return <RenderGroupDetails cell={cell} />;
    case 'prm.percent':
      return <RenderPercent cell={cell} />;
    case 'prm.rate_level_status':
      return <RenderRateLevelStatus cell={cell} />;
    case 'prm.rate_shop':
      return <RenderRateShop cell={cell} />;
    case 'prm.rm_rec_status':
      return <RenderRmRecStatus cell={cell} editEnabled={editEnabled} />;
    case 'prm.room_type_details':
      return <RenderRoomTypeDetails cell={cell} />;
    default:
      if (
        cell.meta?.readOnly === false &&
        cell.meta?.isSummary !== true &&
        cell.meta?.data?.status !== 'UPLOADED' &&
        editEnabled
      ) {
        return (
          <RenderEditable
            cell={cell}
            columnIndex={columnIndex}
            isActiveCell={isActiveCell}
            rowIndex={rowIndex}
          />
        );
      }
      return <span>{cell.displayValue}</span>;
  }
};

export default Cell;
