import { RateQueryParams } from '../graphql/types';
import { useOtaiLowestRatesQuery } from '../features/overview-table/gql/_gen_/rate-shop.gql';

export type UseHookArgs = {
  brandCode: string;
  params: RateQueryParams;
  callback: () => void;
};

export function useRateShops({ brandCode, params, callback }: UseHookArgs) {
  return useOtaiLowestRatesQuery({
    variables: {
      brandCode,
      params,
    },
    skip: !brandCode || !params.subscriptionId,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => callback(),
  });
}
