import { Actions } from '../../../types/DataTable';
import DataTable from '../../../components/DataTable';
import Paginate from '../../../components/Paginate';
import { startCase } from 'lodash';
import { today } from '../../../helpers/dateHelpers';
import { useAgendaData } from '../hooks/useAgendaData';
import { useHotel } from '../../../context/hotelContext';
import { useRemoveAgendaItemMutation } from '../../meetings/gql/_gen_/agenda.gql';

export const AgendasDataTable = () => {
  const headers = [
    'id',
    'name',
    'notes',
    'completed',
    'createdById',
    'isDefault',
    'order',
    'createdAt',
    'updatedAt',
  ];

  const formFields = headers.map((key) => {
    return {
      bulk: [''].includes(key),
      id: '',
      label: startCase(key),
      name: key,
      type: ['createdat', 'updatedat'].includes(key.toLowerCase())
        ? 'date'
        : ['completed', 'isdefault'].includes(key.toLowerCase())
        ? 'boolean'
        : 'text',
      value: ['createdat', 'updatedat'].includes(key.toLowerCase())
        ? today()
        : '',
    };
  });

  const { hotel } = useHotel();

  const [removeAgendaItem] = useRemoveAgendaItemMutation({
    refetchQueries: ['GetHotelAgenda'],
  });

  const handleRemoveAgendaItem = async (id: string | string[]) => {
    const removeAgendaItemId = Array.isArray(id) ? id[0] : id;
    await removeAgendaItem({
      variables: {
        removeAgendaItemId,
      },
    });
  };

  const actions: Actions = {
    bulkUpsert: () => Promise.resolve(console.log('bulkUpsert')),
    delete: handleRemoveAgendaItem,
    deleteStatus: false,
    deleteAll: () => Promise.resolve(console.log('deleteAll')),
    networkStatus: 7, // 7 is the code for "ready"
    update: () => Promise.resolve(console.log('update')),
    updateStatus: false,
  };

  const {
    data,
    goToPage,
    loading,
    options,
    page,
    pageDown,
    pageUp,
    setOptions,
    setTableFilters,
    tableFilters,
    totalFiltered,
    totalItems,
    totalPages,
  } = useAgendaData(headers, hotel?.brand_code);

  return (
    <div className='w-full mt-2'>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Paginate
            first={page || 0}
            goToPage={goToPage}
            last={totalPages}
            previous={pageDown}
            next={pageUp}
            total={totalItems}
            totalFiltered={totalFiltered}
          />
          <DataTable
            actions={actions}
            fields={formFields}
            options={options}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            setOptions={setOptions}
            handleCreate={() => Promise.resolve(console.log('handleCreate'))}
            name='Agendas'
            rows={data || []}
          />
        </div>
      )}
    </div>
  );
};
