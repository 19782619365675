/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataTableRmRecsQueryVariables = Types.Exact<{
  filters: Types.FindRmRecArgs;
}>;


export type DataTableRmRecsQuery = { __typename?: 'Query', hotelRmRecs?: Array<{ __typename?: 'RmRec', created_by_id?: string | null, hotel_id?: number | null, id?: string | null, notes?: string | null, rate?: number | null, status?: Types.RmRecStatus | null, stay_date?: any | null } | null> | null };

export type DtRmRecsRmDataQueryVariables = Types.Exact<{
  args: Types.RmDataArgs;
}>;


export type DtRmRecsRmDataQuery = { __typename?: 'Query', rmData?: Array<{ __typename?: 'RmData', adr?: number | null, sold?: number | null, stay_date?: any | null } | null> | null };

export type DtRmRecsCurrentRateQueryVariables = Types.Exact<{
  args: Types.CurrentRateTableArgs;
}>;


export type DtRmRecsCurrentRateQuery = { __typename?: 'Query', currentRateTable?: Array<{ __typename?: 'DailyRoomRate', current_rate?: number | null, stay_date?: any | null } | null> | null };


export const DataTableRmRecsDocument = gql`
    query DataTableRmRecs($filters: FindRmRecArgs!) {
  hotelRmRecs(filters: $filters) {
    created_by_id
    hotel_id
    id
    notes
    rate
    status
    stay_date
  }
}
    `;

/**
 * __useDataTableRmRecsQuery__
 *
 * To run a query within a React component, call `useDataTableRmRecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataTableRmRecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataTableRmRecsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDataTableRmRecsQuery(baseOptions: Apollo.QueryHookOptions<DataTableRmRecsQuery, DataTableRmRecsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataTableRmRecsQuery, DataTableRmRecsQueryVariables>(DataTableRmRecsDocument, options);
      }
export function useDataTableRmRecsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataTableRmRecsQuery, DataTableRmRecsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataTableRmRecsQuery, DataTableRmRecsQueryVariables>(DataTableRmRecsDocument, options);
        }
export type DataTableRmRecsQueryHookResult = ReturnType<typeof useDataTableRmRecsQuery>;
export type DataTableRmRecsLazyQueryHookResult = ReturnType<typeof useDataTableRmRecsLazyQuery>;
export type DataTableRmRecsQueryResult = Apollo.QueryResult<DataTableRmRecsQuery, DataTableRmRecsQueryVariables>;
export const DtRmRecsRmDataDocument = gql`
    query DTRmRecsRmData($args: RmDataArgs!) {
  rmData(args: $args) {
    adr
    sold
    stay_date
  }
}
    `;

/**
 * __useDtRmRecsRmDataQuery__
 *
 * To run a query within a React component, call `useDtRmRecsRmDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDtRmRecsRmDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDtRmRecsRmDataQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDtRmRecsRmDataQuery(baseOptions: Apollo.QueryHookOptions<DtRmRecsRmDataQuery, DtRmRecsRmDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DtRmRecsRmDataQuery, DtRmRecsRmDataQueryVariables>(DtRmRecsRmDataDocument, options);
      }
export function useDtRmRecsRmDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DtRmRecsRmDataQuery, DtRmRecsRmDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DtRmRecsRmDataQuery, DtRmRecsRmDataQueryVariables>(DtRmRecsRmDataDocument, options);
        }
export type DtRmRecsRmDataQueryHookResult = ReturnType<typeof useDtRmRecsRmDataQuery>;
export type DtRmRecsRmDataLazyQueryHookResult = ReturnType<typeof useDtRmRecsRmDataLazyQuery>;
export type DtRmRecsRmDataQueryResult = Apollo.QueryResult<DtRmRecsRmDataQuery, DtRmRecsRmDataQueryVariables>;
export const DtRmRecsCurrentRateDocument = gql`
    query DTRmRecsCurrentRate($args: CurrentRateTableArgs!) {
  currentRateTable(args: $args) {
    current_rate
    stay_date
  }
}
    `;

/**
 * __useDtRmRecsCurrentRateQuery__
 *
 * To run a query within a React component, call `useDtRmRecsCurrentRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDtRmRecsCurrentRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDtRmRecsCurrentRateQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDtRmRecsCurrentRateQuery(baseOptions: Apollo.QueryHookOptions<DtRmRecsCurrentRateQuery, DtRmRecsCurrentRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DtRmRecsCurrentRateQuery, DtRmRecsCurrentRateQueryVariables>(DtRmRecsCurrentRateDocument, options);
      }
export function useDtRmRecsCurrentRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DtRmRecsCurrentRateQuery, DtRmRecsCurrentRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DtRmRecsCurrentRateQuery, DtRmRecsCurrentRateQueryVariables>(DtRmRecsCurrentRateDocument, options);
        }
export type DtRmRecsCurrentRateQueryHookResult = ReturnType<typeof useDtRmRecsCurrentRateQuery>;
export type DtRmRecsCurrentRateLazyQueryHookResult = ReturnType<typeof useDtRmRecsCurrentRateLazyQuery>;
export type DtRmRecsCurrentRateQueryResult = Apollo.QueryResult<DtRmRecsCurrentRateQuery, DtRmRecsCurrentRateQueryVariables>;
export const namedOperations = {
  Query: {
    DataTableRmRecs: 'DataTableRmRecs',
    DTRmRecsRmData: 'DTRmRecsRmData',
    DTRmRecsCurrentRate: 'DTRmRecsCurrentRate'
  }
}