import { FixMeLater } from '../../../types/FixMeLaterType';
import { TableWidget } from '../../TableWidget';
import _ from 'lodash';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { getCellColor } from '../../../helpers/heatMapHelper';

type Props = {
  table: string;
  roomData: RoomData;
  scrollDates: Function;
  loading: boolean;
};

export type RoomType = {
  room_category: string;
  room_code: string;
  rt_details: string;
  rt_sold: number;
  rt_remaining: number;
  rt_room_allocation: number;
  rt_flex_rate: number;
  rt_status: string;
  occupancy: any;
  stay_date: string;
};

export type RoomColumn = {
  room_types: RoomType[];
  stay_date: string;
};

type RoomListHeader = {
  header: string;
  order: number;
  tooltip: string;
};

export type RoomData = {
  allRoomData: RoomColumn[];
  roomList: RoomListHeader[];
};

function RoomsTable({ table, roomData, scrollDates, loading }: Props) {
  const tableKey = getTableKey();
  const tableName = getTableName();

  function getTableKey() {
    switch (table) {
      case 'sold':
        return 'rt_sold';
      case 'remaining':
        return 'rt_remaining';
      case 'occupancy':
        return 'occupancy';
      case 'allocation':
        return 'rt_room_allocation';
      case 'flex':
        return 'rt_flex_rate';
      default:
        return 'rt_sold';
    }
  }

  function getTableName() {
    switch (table) {
      case 'sold':
        return 'Sold';
      case 'remaining':
        return 'Remaining';
      case 'occupancy':
        return 'Occupancy %';
      case 'allocation':
        return 'Allocation';
      case 'flex':
        return 'Flex Rate';
      default:
        return '';
    }
  }

  function getRow(roomType: string, roomData: RoomType[]) {
    const row = [roomType, ...roomData];
    return row;
  }

  function cellColor(cellData: RoomType) {
    const {
      rt_sold,
      rt_remaining,
      rt_room_allocation,
      rt_status,
      rt_flex_rate,
    } = cellData;

    switch (table) {
      case 'sold':
        return getCellColor(rt_sold, rt_status);
      case 'remaining':
        return getCellColor(rt_remaining, rt_status);
      case 'allocation':
        getMinMax();
        return getCellColor(rt_room_allocation, rt_status);
      case 'occupancy':
        return getCellColor(cellData.occupancy, rt_status);
      case 'flex':
        return getCellColor(rt_flex_rate, rt_status);
      default:
        return 'bg-white';
    }
  }

  function getMinMax() {
    const minArray: Array<number> = [];
    const maxArray: Array<number> = [];

    const getDateMin = (roomTypes: RoomType[]) => {
      const dateMin = _.minBy(roomTypes, (room: RoomType) => room[tableKey]);

      if (dateMin) {
        minArray.push(dateMin[tableKey]);
      }
    };

    const getDateMax = (roomTypes: RoomType[]) => {
      const dateMax = _.maxBy(roomTypes, (room: RoomType) => room[tableKey]);

      if (dateMax) {
        maxArray.push(dateMax[tableKey]);
      }
    };

    if (allRoomData) {
      allRoomData.forEach((date: RoomColumn) => {
        getDateMin(date.room_types);
        getDateMax(date.room_types);
      });
    }

    const minValue: number = _.min(minArray) || 0;
    const maxValue: number = _.max(maxArray) || 0;

    return [minValue, maxValue];
  }

  let allRoomData = _.cloneDeep(roomData)?.allRoomData.sort(
    (a: RoomColumn, b: RoomColumn) => {
      return dayjs(b.stay_date).isBefore(dayjs(a.stay_date)) ? 1 : -1;
    }
  );

  let roomList = roomData?.roomList;
  let roomRows = [];
  let roomTooltips;

  //Format Table Data
  if (allRoomData && roomList) {
    const roomTypeMap = new Map();
    roomList.forEach((room: RoomListHeader) =>
      roomTypeMap.set(room.header, [])
    );

    allRoomData.forEach((date: RoomColumn) => {
      date.room_types.forEach((type: RoomType) => {
        const mapValue = roomTypeMap.get(type.room_code);

        if (mapValue) {
          let updatedValue;

          if (tableKey === 'occupancy') {
            const roomTotal = type.rt_room_allocation;
            const sold = type.rt_sold;
            const occPercent =
              roomTotal === 0 ? 0 : Math.round((sold / roomTotal) * 100);
            updatedValue = [...mapValue, { ...type, occupancy: occPercent }];
          } else if (tableKey === 'rt_flex_rate') {
            updatedValue = [
              ...mapValue,
              {
                ...type,
                rt_flex_rate: Math.round(type.rt_flex_rate),
                rt_flex_rate_raw: type.rt_flex_rate,
              },
            ];
          } else {
            updatedValue = [...mapValue, type];
          }

          roomTypeMap.set(type.room_code, updatedValue);
        }
      });
    });

    for (const roomType of Array.from(roomTypeMap)) {
      const [room, data] = roomType;
      const row = getRow(room, data);
      roomRows.push(row);
    }
  }

  const scrubbedRoomRows = roomRows.map((row) => {
    return _.uniqBy(row, 'stay_date');
  });

  if (roomList) {
    const roomTooltipMap = new Map();

    roomList.forEach((room: FixMeLater) => {
      roomTooltipMap.set(room.header, room.tooltip);
    });

    roomTooltips = roomTooltipMap;
  }

  return (
    <div
      className={`flex flex-col overflow-auto ${clsx(
        table === 'flex' ? null : 'mb-5'
      )}`}
    >
      <TableWidget
        tableName={tableName}
        cellColor={cellColor}
        data={allRoomData}
        roomTooltips={roomTooltips}
        rowData={scrubbedRoomRows}
        scrollDates={scrollDates}
        tableKey={tableKey}
        loading={loading}
        key={tableName}
        page='Room Type'
      />
    </div>
  );
}

export default RoomsTable;
