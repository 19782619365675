export type TabItems = {
  key: string;
  title: string;
  to: string;
};

export const tabItems = [
  {
    key: 'tab-overview',
    to: '/dashboard/overview',
    title: 'Overview',
  },
  // {
  //   key: 'tab-compare',
  //   to: '/dashboard/compare',
  //   title: 'Compare',
  // },
  // {
  //   key: 'tab-competitors',
  //   to: '/dashboard/competitors',
  //   title: 'Competitors',
  // },
  // {
  //   key: 'tab-groups',
  //   to: '/dashboard/groups',
  //   title: 'Groups',
  // },
  // {
  //   key: 'tab-forecasts',
  //   to: '/dashboard/forecasts',
  //   title: 'Forecasts',
  // },
  {
    key: 'tab-rm-recs',
    to: '/dashboard/rm_recs',
    title: 'RM Recs',
  },
  {
    key: 'tab-rate-change-logs',
    to: '/dashboard/rate_changes',
    title: 'Rate Changes',
  },
  {
    key: 'tabe-rate-levels',
    to: '/dashboard/rate_levels',
    title: 'Rate Levels',
  },
  {
    key: 'tab-rooms',
    to: '/dashboard/rooms',
    title: 'Rooms',
  },
  {
    key: 'tab-events',
    to: '/dashboard/events',
    title: 'Events',
  },
  {
    key: 'tab-budget',
    to: '/dashboard/budget',
    title: 'Budget',
  },
  {
    key: 'tab-meetings',
    to: '/dashboard/meetings',
    title: 'Meetings',
  },
  // {
  //   key: 'tab-str',
  //   to: '/dashboard/str',
  //   title: 'STR',
  // },
  // {
  //   key: 'tab-weather',
  //   to: '/dashboard/weather',
  //   title: 'Weather',
  // },
  // {
  //   key: 'tab-alerts',
  //   to: '/dashboard/alerts',
  //   title: 'Alerts',
  // },
];
