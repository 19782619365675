import { CellObject } from '../features/overview-table/hooks/use-table';
import ReactDOMServer from 'react-dom/server';
import { displayDate } from '../helpers/dateHelpers';

type RendererProps = {
  cell: CellObject;
};

const EventCellTooltip = (cell: CellObject): JSX.Element => {
  const data = cell.meta?.data;
  if (data && data.events) {
    return (
      <table className='border border-collapse border-white text-center'>
        <caption>Events {displayDate(cell.stayDate)}</caption>
        <thead>
          <tr>
            <th className='border p-2 w-60'>Event</th>
            <th className='border p-2'>Start</th>
            <th className='border p-2'>End</th>
            <th className='border p-2'>Created By</th>
          </tr>
        </thead>
        <tbody>
          {data.events.map((event: any) => {
            return (
              <tr key={event.id}>
                <td className='break-normal border p-2'>{event.name}</td>
                <td className='border p-2'>
                  {displayDate(event.start_date as string)}
                </td>
                <td className='border p-2'>
                  {displayDate(event.end_date as string)}
                </td>
                <td className='border p-2'>{event.created_by.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    return <></>;
  }
};

const EventCell = (cell: CellObject) => {
  const { value } = cell;

  return (
    <>
      <span
        data-html={true}
        data-type='light'
        data-tip={ReactDOMServer.renderToString(<EventCellTooltip {...cell} />)}
      >
        {value === 0 ? null : value}
      </span>
    </>
  );
};

export default function RenderEventCell({ cell }: RendererProps) {
  return <EventCell {...cell} />;
}
