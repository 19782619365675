import { ActionItem, ActionItemStatus, Maybe } from '../../../graphql/types';

import { displayDate } from '../../../helpers/dateHelpers';
import { useIsShared } from '../../reports/hooks/use-report-location';

type ActionListItemProps = {
  data: Maybe<ActionItem>;
  setExpanded: React.Dispatch<
    React.SetStateAction<ActionItem | null | undefined>
  >;
};

const ActionListItem = ({ data, setExpanded }: ActionListItemProps) => {
  const { isShared } = useIsShared();
  return (
    <li key={data?.id} className='py-5'>
      <div className='relative'>
        <div className='flex justify-between'>
          <div className='flex'>
            <h3 className='text-sm font-semibold text-gray-800'>
              <button disabled={isShared} onClick={() => setExpanded(data)}>
                {data?.name}
              </button>
            </h3>
            {data?.status === ActionItemStatus.Completed ? (
              <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                Completed
              </span>
            ) : data?.status === ActionItemStatus.InProgress ? (
              <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800'>
                In Progress
              </span>
            ) : (
              <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                Not Started
              </span>
            )}
            <span className='ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium'>
              Due: {displayDate(data?.dueDate as string)}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export { ActionListItem };
