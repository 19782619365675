import dayjs, { Dayjs } from 'dayjs';

import { DAYS_OUT } from '../reducers/useDashboardOptions';
import _ from 'lodash';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

export * as DateHelpers from './dateHelpers';

export type RecurParams = {
  startDate: string;
  endDate: string;
  days: number[]; // array of days of the week, 0 = sunday, 6 = saturday
};

/**
 * Convert a nanosecond timestamp to a formatted date string
 * @param timestamp A string representing a nanosecond timestamp
 * @param format The desired output format (default: 'YYYY-MM-DD HH:mm:ss')
 * @returns A formatted date string
 */
export const formatNanosTimestamp = (
  timestamp: string,
  format: string = 'YYYY-MM-DD HH:mm:ss',
  tz: string = 'UTC'
): string => {
  try {
    // Convert nanoseconds to seconds using BigInt to handle very large numbers
    const seconds = Number(BigInt(timestamp) / BigInt(1_000_000_000));
    const nanoseconds = Number(BigInt(timestamp) % BigInt(1_000_000_000));

    // Create a UTC date
    let date: Dayjs = dayjs.unix(seconds).utc();

    // Adjust for nanoseconds
    date = date.add(nanoseconds / 1_000_000, 'milliseconds');

    // Handle negative timestamps
    if (seconds < 0) {
      date = dayjs.utc('1970-01-01').subtract(Math.abs(seconds), 'seconds');
      date = date.subtract(nanoseconds / 1_000_000, 'milliseconds');
    }

    // Format the date
    return date.format(format);
  } catch (error) {
    console.error('Error formatting timestamp:', error);
    return 'Invalid Date';
  }
};

// The format for most dates within data sources
export const dateFormatData = 'YYYY-MM-DD';

// The format for dates in the UI shown to the user
export const dateFormatDisplay = 'M/D/YYYY';

export const timeFormatDisplay = 'h:mm A';

export const dateTimeFormatDisplay = 'M/D/YYYY h:mm A';

export const handleGroupDates = (dateValue: string | number): string => {
  if (typeof dateValue === 'number') {
    return handleGroupDates(dayjs(dateValue).toISOString());
  } else if (typeof dateValue !== 'string') {
    return dateValue;
  }

  if (dateValue.includes('T')) {
    return dateValue.split('T')[0];
  } else if (dateValue.includes('-') && !dateValue.includes('T')) {
    return dateValue;
  } else {
    // Assuming it's a timestamp in milliseconds as a string
    return handleGroupDates(dayjs(parseInt(dateValue)).toISOString());
  }
};

export type DateSegment = 'year' | 'month' | 'day' | 'quarter' | 'week' | 'dow';

/**
 * Add number of days to a string date
 * @param date The date to add days to.
 * @param days The number of days to add. Can be negative.
 * @returns A string date in data format.
 */
export const addDays = (date: string, days: number) => {
  return dayjs(date, dateFormatData).add(days, 'day').format(dateFormatData);
};

/**
 * Is date within a range?
 * @param date The date to query for.
 * @param start The beginning of the date range to query for the date.
 * @param end The end of the date range to query for the date.
 * @returns A boolean indicating whether the date is within the date range.
 */
export const between = (date: string, start: string, end: string) => {
  return dayjs(date, dateFormatData).isBetween(start, end, 'day');
};

export const betweenOrEqual = (date: string, start: string, end: string) => {
  return dayjs(date, dateFormatData).isBetween(start, end, 'day', '[]');
};

/**
 * Format a date string for data sources
 * @param date A string date
 * @returns A string date in the format YYYY-MM-DD
 */
export const dataDate = (date?: string, inputFormat?: string): string => {
  if (inputFormat) {
    return dayjs(date, inputFormat).format(dateFormatData);
  }
  return dayjs(date).format(dateFormatData);
};

/**
 * Days difference between two dates
 * @param firstDate A string representing the first date. Format: YYYY-MM-DD
 * @param lastDate A string representing the last date. Format: YYYY-MM-DD
 * @returns Absolute number of days between firstDate and lastDate
 */
export const dayDiff = (firstDate: string, lastDate: string): number => {
  return Math.abs(Number(dayjs(lastDate).diff(dayjs(firstDate), 'day')));
};

/**
 * Generate a date range
 * @param firstDate A string representing the first date. Format: YYYY-MM-DD
 * @param lastDate A string representing the last date. Format: YYYY-MM-DD
 * @param dateSegment An optional string specifying the date segment to use for the range.
 * @returns An array of string dates between and including firstDate and lastDate
 */
export const dayRange = (
  firstDate: string,
  lastDate: string,
  dateSegment: DateSegment = 'day',
  includeSummary = true
): string[] => {
  const days: string[] = [];
  for (let i = 0; i <= dayDiff(firstDate, lastDate); i++) {
    const targetDate = dayjs(firstDate).add(i, 'day');
    switch (dateSegment) {
      case 'year':
        days.push(targetDate.format('YYYY'));
        break;
      case 'month':
        days.push(getYearMonth(targetDate.format(dateFormatData)));
        break;
      case 'quarter':
        days.push(getYearQuarter(targetDate.format(dateFormatData)));
        break;
      case 'week':
        days.push(getYearWeek(targetDate.format(dateFormatData)));
        break;
      case 'dow':
        days.push(targetDate.format('ddd'));
        break;
      default:
        days.push(targetDate.format(dateFormatData));
        if (
          targetDate.isSame(targetDate.endOf('month'), 'day') ||
          targetDate.isSame(dayjs(lastDate).endOf('month'), 'day')
        ) {
          if (includeSummary === true) {
            days.push(getYearMonth(targetDate.format(dateFormatData)));
          }
        }
    }
  }
  return _.uniq(days);
};

/**
 * Convert date string to display format
 * @param date A string representing a date. Format: YYYY-MM-DD
 * @returns A date string formatted as dateFormatDisplay
 */
export const displayDate = (date: string) => {
  return dayjs(date).format(dateFormatDisplay);
};

/**
 * Convert to day of week abbreviation
 * @param date A string representing a date. Format: YYYY-MM-DD. Defaults to today.
 * @returns The date formatted as a day of week. Eg. 'Mon'
 */
export const dow = (date: string = today()): string => {
  return dayjs(date).format('ddd');
};

export const dowIndex = (dow: string): number => {
  return dayjs()
    .localeData()
    .weekdaysShort()
    .map((d) => d.toLowerCase())
    .indexOf(dow);
};

interface EndOfMonthParams {
  date?: string | dayjs.Dayjs;
  daysOut?: number;
}

/**
 * End of the month for a given date and days offset
 * @param date A DayJS object representing the date to be formatted
 * @param daysOut Number of days out from date to get the end of the month
 * @returns A string for the end of the month
 */
export const endOfMonth = ({
  date = dayjs(),
  daysOut = 180,
}: EndOfMonthParams): string => {
  if (typeof date === 'string') {
    date = dayjs(date);
  }
  return date.add(daysOut, 'day').endOf('month').format(dateFormatData);
};

/**
 * End of the year for a given date
 * @param date A DayJS object representing the date to be formatted
 * @returns A string for the end of the year
 */
export const endOfYear = (date: dayjs.Dayjs | string = dayjs()): string => {
  if (typeof date === 'string') {
    date = dayjs(date);
  }
  return date.endOf('year').format(dateFormatData);
};

export const fromToday = (days = 0) => {
  return dayjs().add(days, 'day').format(dateFormatData);
};

interface MonthsOutParams {
  date?: string | dayjs.Dayjs;
  months?: number;
  firstOrLast?: 'first' | 'last';
}

/**
 * Get the first or last day of a month for a given date and number of months offset
 * @param param.date A DayJS object representing the date to be formatted
 * @param param.monthsOut Number of months out from date to get the end of the month
 * @param param.firstOrLast Whether to get the first or last day of the month
 * @returns A string for the date requested
 */
export const monthsOut = ({
  date = dayjs(),
  months = 2,
  firstOrLast = 'last',
}: MonthsOutParams): string => {
  if (typeof date === 'string') {
    date = dayjs(date);
  }
  if (firstOrLast === 'first') {
    return date.add(months, 'month').startOf('month').format(dateFormatData);
  }
  return date.add(months, 'month').endOf('month').format(dateFormatData);
};

interface FirstOfMonthParams {
  date?: string | dayjs.Dayjs;
  daysOut?: number;
}

/**
 * First day of the month
 * @param param.date A DayJS object or string date representing the date to be formatted
 * @param param.daysOut Number of days out from date to get the first day of the month
 * @returns A string for the first day of the month
 */
export const firstOfMonth = ({
  date = dayjs(),
  daysOut = 0,
}: FirstOfMonthParams): string => {
  if (typeof date === 'string') {
    date = dayjs(date);
  }
  return date.add(daysOut, 'day').startOf('month').format(dateFormatData);
};

/**
 * First day of the year
 * @param date A DayJS object
 * @returns A string for the first date of the year
 */
export const firstOfYear = (date: dayjs.Dayjs | string = dayjs()): string => {
  if (typeof date === 'string') {
    date = dayjs(date);
  }
  return date.startOf('year').format(dateFormatData);
};

export const formatDate = (
  date: string | null,
  format = dateFormatDisplay
): string => {
  return dayjs(date).format(format);
};

/**
 * Get the Year of a date string
 * @param date A string date, format: YYYY-MM-DD
 * @returns A string for the year, format: YYYY.
 */
export const getYear = (date: string = today()): string => {
  return formatDate(date, 'YYYY');
};

/**
 * Get the Year and Month of a date string
 * @param date A string date, format: YYYY-MM-DD
 * @returns A string for the month of the year, format: YYYY-MM.
 */
export const getYearMonth = (date: string): string => {
  return `${dayjs(date).format('YYYY-MM')}`;
};

export const getMonthYear = (date: string): string => {
  return `${dayjs(date).format('MMM YYYY')}`;
};

/**
 * Get the Year and Quarter of a date string
 * @param date A string date, format: YYYY-MM-DD
 * @returns A string for the quarter of the year, format: YYYY-Q
 */
export const getYearQuarter = (date: string): string => {
  return `${dayjs(date).format('YYYY-Q')}`;
};

/**
 * Get the Year and Week of a date string
 * @param date A string date, format: YYYY-MM-DD
 * @returns A string for the week of the year, format: YYYY-ww
 */
export const getYearWeek = (date: string): string => {
  return `${dayjs(date).format('YYYY-ww')}`;
};

export const initTableDates = (daysOut = DAYS_OUT) => {
  return {
    startDate: firstOfMonth({}),
    endDate: endOfMonth({ daysOut }),
  };
};

/**
 * Get recurring dates for a given date range and day of week selection
 * @param param.startDate A string representing the start date. Format: YYYY-MM-DD
 * @param param.endDate A string representing the end date. Format: YYYY-MM-DD
 * @param param.days An array of numbers representing the days of the week. 0 = Sunday, 1 = Monday, etc.
 * @returns An array of string dates
 */
export const recur = ({ startDate, endDate, days }: RecurParams) => {
  const range = dayRange(startDate, endDate, 'day', false);
  return range.filter((date) => days.includes(dayjs(date).day()));
};

/**
 * Takes existing start and end dates, along with a direction, and returns
 * the new start and end dates that would scroll you an equal amount
 * in the direction provided. Mainly used for page up and down on Overview Table.
 * @param param0 An object with the following properties:
 * @param param0.startDate A string date, format: YYYY-MM-DD
 * @param param0.endDate A string date, format: YYYY-MM-DD
 * @param param0.direction A string specifying the scroll direction.
 * @returns An object with properties: startDate and endDate
 */
export const scrollDates = ({
  startDate,
  endDate,
  direction,
  days = 60,
}: {
  startDate: string;
  endDate: string;
  direction: 'next' | 'prev';
  days?: number;
}) => {
  if (direction === 'next') {
    const newStartDate = addDays(endDate, 1);
    return {
      startDate: newStartDate,
      endDate: endOfMonth({
        date: dayjs(newStartDate),
        daysOut: days,
      }),
    };
  } else {
    const newEndDate = addDays(startDate, -1);
    return {
      startDate: firstOfMonth({ date: dayjs(newEndDate), daysOut: -DAYS_OUT }),
      endDate: newEndDate,
    };
  }
};

type Record = {
  stay_date: string;
};

export const sortByStayDate = (records: Record[]) => {
  return records?.sort((a, b) => {
    return dayjs(a.stay_date).isAfter(dayjs(b.stay_date)) ? 1 : -1;
  });
};

/**
 * Today's date
 * @returns A string for the current date
 */
export const today = (): string => {
  return dayjs().format(dateFormatData);
};

/**
 * Same date {years} ago, for "same time last year" date comparisons
 * @param date A string date for current/baseline date, format: YYYY-MM-DD
 * @param years Number of years in the past
 * @returns A string date for the specified number of years ago
 */
export const sameTimeYearsAgo = (
  date: string = today(),
  years = 1,
  format = dateFormatData
): string => {
  return dayjs(date).subtract(yearsToDays(years), 'days').format(format);
};

/**
 * Years to days, for "same time last year" date comparisons
 * @param years Number of years in the past
 * @returns Returns the number of days that is the same date {years} ago
 */
export const yearsToDays = (years = 1): number => {
  return years * 364;
};

/**
 * Yesterday's date
 * @returns A string for yesterday
 */
export const yesterday = (): string => {
  return dayjs().add(-1, 'days').format(dateFormatData);
};

export const isBefore = (date: string, compareDate: string): boolean => {
  return dayjs(date).isBefore(compareDate);
};

export const subtract = (
  date: string,
  dateSegment: dayjs.ManipulateType,
  amount: number
): string => {
  return dayjs(date).subtract(amount, dateSegment).format(dateFormatData);
};

const isFeb29 = (date: string) => {
  return dayjs(date).month() === 1 && dayjs(date).date() === 29;
};

const setDateForLeapYear = (date: string): string => {
  if (isFeb29(date)) {
    const newDate = subtract(date, 'day', 1);
    return setDateForLeapYear(newDate);
  }
  return date;
};

export type LastNightTableDates = {
  // Base Date is the reference date, i.e. today
  baseDate: string;
  // Base Date Last Year is the reference date, i.e. today, but last year 1 year ago
  // if baseDate is 10/1/2024, then baseDateLy is 10/1/2023
  baseDateLy: string;
  // Last Night is the day before the base date
  lastNight: string;
  // Last Night Last Year is the day before the base date last year
  lastNightLy: string;
  // MTD Start is the first day of the month that includes Last Night
  mtdStart: string;
  // MTD End is the final date of the MTD range, up to and including Last Night
  mtdEnd: string;
  // MTD Start Last Year is the first day of the month that includes Last Night LY
  mtdStartLy: string;
  // MTD End Last Year is the final date of the MTD range, up to and including Last Night LY
  mtdEndLy: string;
};

export const lastNightTableDates = (
  targetDate?: string
): LastNightTableDates => {
  // Last Night Table is based on calendar date comparisons and NOT same time last year.
  const date = targetDate || today();

  const baseDate = setDateForLeapYear(date);
  const baseDateLy = subtract(baseDate, 'year', 1);

  const lastNight = setDateForLeapYear(subtract(baseDate, 'days', 1));
  const lastNightLy = setDateForLeapYear(subtract(baseDateLy, 'days', 1));

  const mtdStart = firstOfMonth({ date: lastNight });
  const mtdEnd = lastNight;

  const mtdStartLy = firstOfMonth({ date: lastNightLy });
  const mtdEndLy = lastNightLy;

  const results = {
    baseDate,
    baseDateLy,
    lastNight,
    lastNightLy,
    mtdStart,
    mtdEnd,
    mtdStartLy,
    mtdEndLy,
  };

  return results;
};

export const getYearMonths = () => {
  const months = [];
  const currentYear = dayjs().year();
  const nextYear = currentYear + 1;

  for (let year = currentYear; year <= nextYear; year++) {
    for (let month = 1; month <= 12; month++) {
      months.push({
        display: dayjs(`${year}-${month}`).format('MMMM YYYY'),
        value: (year - currentYear) * 12 + month,
        year,
      });
    }
  }

  return months;
};
