'use client';

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { ChevronDown, ChevronUp, Flag } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { CreateFlagForm } from './components/create-flag-form';
import { ToggleFlagBoolean } from './components/toggle-flag-boolean';
import { UserFlagManagement } from './components/user-flag-management';
import { useManageFlags } from './hooks/use-manage-flags';
import { useState } from 'react';
import { useUsersQuery } from '@/features/user/gql/_gen_/user.gql';

const FLAGS_WITH_NO_USERS = ['admin-menu'];

export const OverviewSection = () => {
  const { flags, loading, toggleFlag, updating, updatingUser, toggleUserFlag } =
    useManageFlags();
  const { data: usersData } = useUsersQuery();
  const [expandedFlags, setExpandedFlags] = useState<string[]>([]);

  const toggleExpand = (flagId?: string | null) => {
    if (!flagId) return;

    setExpandedFlags((prev) =>
      prev.includes(flagId)
        ? prev.filter((id) => id !== flagId)
        : [...prev, flagId]
    );
  };

  return (
    <div className='container mx-auto py-10'>
      <h1 className='text-3xl font-bold mb-8'>Feature Flags Admin</h1>

      <div className='grid gap-6 md:grid-cols-2 lg:grid-cols-3'>
        {loading && <p>Loading...</p>}

        {flags.map((flag) => (
          <Card key={flag?.id}>
            <CardHeader>
              <CardTitle className='flex items-center'>
                <Flag className='mr-2 h-5 w-5' />
                {flag?.key}
              </CardTitle>
              <CardDescription>{flag?.description}</CardDescription>
            </CardHeader>
            <CardContent>
              <p className='text-sm text-muted-foreground'>
                Created: {flag?.created_at}
              </p>
              <p className='text-sm text-muted-foreground'>
                Last Updated: {flag?.updated_at}
              </p>
            </CardContent>
            {flag && (
              <CardFooter className='flex flex-col space-y-4'>
                <div className='flex justify-items-start space-x-4 w-full'>
                  <ToggleFlagBoolean
                    columnName='active'
                    flag={flag}
                    onToggle={toggleFlag}
                    updating={updating}
                  />
                  <ToggleFlagBoolean
                    columnName='defaultValue'
                    flag={flag}
                    onToggle={toggleFlag}
                    updating={updating}
                  />
                </div>
                {flag.key && FLAGS_WITH_NO_USERS.includes(flag.key) ? null : (
                  <>
                    <Button
                      variant='outline'
                      onClick={() => toggleExpand(flag.id)}
                      className='w-full'
                    >
                      Manage Users{' '}
                      {flag.id && expandedFlags.includes(flag.id) ? (
                        <ChevronUp className='ml-2 h-4 w-4' />
                      ) : (
                        <ChevronDown className='ml-2 h-4 w-4' />
                      )}
                    </Button>
                    {flag.id && expandedFlags.includes(flag.id) && (
                      <UserFlagManagement
                        flag={flag}
                        allUsers={usersData?.users || []}
                        onToggleUserFlag={toggleUserFlag}
                        updating={updatingUser}
                      />
                    )}
                  </>
                )}
              </CardFooter>
            )}
          </Card>
        ))}
      </div>

      <div className='mt-8'>
        <Card>
          <CardHeader>
            <CardTitle>Create New Feature Flag</CardTitle>
            <CardDescription>
              Add a new feature flag to the system
            </CardDescription>
          </CardHeader>
          <CardContent>
            <CreateFlagForm />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
