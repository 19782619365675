import { useEffect, useState } from 'react';

import { FaSpinner } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { HookFuncs } from '../../../features/overview-table/hooks/use-data-hooks';
import { QueryResult } from '@apollo/client';
import _ from 'lodash';
import { allLogos } from '../../../img/logos';
import { messages } from './loadingMessages';

type LoadingProps = {
  hooks: HookFuncs;
};

export default function Loading(props: LoadingProps) {
  const { hooks } = props;
  const [currentLogo, setLogo] = useState(
    allLogos[Math.floor(Math.random() * allLogos.length)]
  );
  const [message, setMessage] = useState(
    messages[Math.floor(Math.random() * messages.length)]
  );

  useEffect(() => {
    let interval = setInterval(() => {
      setMessage(messages[Math.floor(Math.random() * messages.length)]);
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let logoInterval = setInterval(() => {
      setLogo(allLogos[Math.floor(Math.random() * allLogos.length)]);
    }, 4000);
    return () => clearInterval(logoInterval);
  }, []);

  return (
    <div className='bg-white'>
      <div className='mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <p className='animate-pulse text-center text-base font-normal text-blue-600 tracking-wider'>
          Fetching your data...
        </p>
        <p className='animate-pulse text-center text-base font-semibold uppercase text-gray-600 tracking-wider'>
          {message}
        </p>
        <div className='flex justify-center'>
          <img
            className='col-span-1 rounded-lg h-32 mt-4'
            src={currentLogo}
            alt='Best Western'
          />
        </div>
        <div className='mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-8'>
          {Object.keys(hooks).map((key) => {
            if (key === 'rateUpload') {
              return null;
            }
            const hook = hooks[key as keyof HookFuncs];

            if (
              Object.keys(hook).includes('loading') &&
              (hook as QueryResult<any, any>).loading
            ) {
              return (
                <div
                  key={key}
                  className='col-span-1 flex justify-center py-4 px-4 bg-gray-50'
                >
                  <FaSpinner
                    className='flex animate-spin text-gray-400'
                    size={32}
                  />
                  <span className='flex ml-2 font-semibold'>
                    {_.startCase(key)}
                  </span>
                </div>
              );
            } else {
              return (
                <div
                  key={key}
                  className='col-span-1 flex justify-center py-4 px-4 bg-gray-50'
                >
                  <FiCheckCircle className='text-green-600' size={32} />
                  <span className='flex ml-2 font-semibold'>
                    {_.startCase(key)}
                  </span>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
