import { endOfMonth, firstOfMonth, today } from '../helpers/dateHelpers';

import { Hotel } from '../graphql/types';
import dayjs from 'dayjs';
import { useReducer } from 'react';

export type Action = {
  type: string;
  payload: {
    brandCode?: string;
    dateSegment?: string;
    endDate?: string;
    hotelId?: number;
    monthOffset?: number;
    snapshotDate?: string;
    startDate?: string;
    yearsCompare?: number;
  };
};

export type State = {
  brandCode: string;
  dateSegment: string;
  endDate: string;
  hotelId: number;
  monthOffset: number;
  snapshotDate: string;
  startDate: string;
  yearsCompare: number;
};

export const DAYS_OUT = 365;

const initOptions = (hotel?: Hotel): State => {
  return {
    brandCode: hotel?.brand_code || '',
    dateSegment: 'day',
    endDate: endOfMonth({ date: dayjs(), daysOut: DAYS_OUT }),
    hotelId: hotel?.hotel_id || hotel?.brand_code,
    monthOffset: 0,
    snapshotDate: today(),
    startDate: firstOfMonth({ date: dayjs().subtract(1, 'month') }),
    yearsCompare: 2,
  };
};

const dashboardOptionsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'UPDATE_OPTIONS':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export function useDashboardOptions(hotel?: Hotel) {
  return useReducer(dashboardOptionsReducer, initOptions(hotel));
}
