/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUseForecastMutationVariables = Types.Exact<{
  hotel: Types.UpdateHotelInput;
}>;


export type SetUseForecastMutation = { __typename?: 'Mutation', updateHotel?: { __typename?: 'Hotel', brand_code: any, name?: string | null, use_forecast?: boolean | null } | null };


export const SetUseForecastDocument = gql`
    mutation SetUseForecast($hotel: UpdateHotelInput!) {
  updateHotel(hotel: $hotel) {
    brand_code
    name
    use_forecast
  }
}
    `;
export type SetUseForecastMutationFn = Apollo.MutationFunction<SetUseForecastMutation, SetUseForecastMutationVariables>;

/**
 * __useSetUseForecastMutation__
 *
 * To run a mutation, you first call `useSetUseForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUseForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUseForecastMutation, { data, loading, error }] = useSetUseForecastMutation({
 *   variables: {
 *      hotel: // value for 'hotel'
 *   },
 * });
 */
export function useSetUseForecastMutation(baseOptions?: Apollo.MutationHookOptions<SetUseForecastMutation, SetUseForecastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUseForecastMutation, SetUseForecastMutationVariables>(SetUseForecastDocument, options);
      }
export type SetUseForecastMutationHookResult = ReturnType<typeof useSetUseForecastMutation>;
export type SetUseForecastMutationResult = Apollo.MutationResult<SetUseForecastMutation>;
export type SetUseForecastMutationOptions = Apollo.BaseMutationOptions<SetUseForecastMutation, SetUseForecastMutationVariables>;
export const namedOperations = {
  Mutation: {
    SetUseForecast: 'SetUseForecast'
  }
}