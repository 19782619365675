import { ActionItem, ActionItemStatus } from '../../../graphql/types';

import { ActionListItem } from './action-list-item';
import { ExpandedActionItem } from './expanded-action-item';
import { MeetingPanelsProps } from '../../reports/components/meeting-panels';
import { useState } from 'react';

interface IActionItemView {
  meetingTools?: MeetingPanelsProps['meetingTools'];
}

const ActionItemView = ({ meetingTools }: IActionItemView) => {
  const [expanded, setExpanded] = useState<ActionItem | null>();
  const [statusFilter, setStatusFilter] = useState([
    ActionItemStatus.NotStarted,
    ActionItemStatus.InProgress,
    ActionItemStatus.Completed,
  ]);

  const handleStatusFilter = (status: ActionItemStatus) => {
    if (statusFilter.includes(status)) {
      setStatusFilter(statusFilter.filter((s) => s !== status));
    } else {
      setStatusFilter([...statusFilter, status]);
    }
  };

  return (
    <section className='px-3'>
      <div className='mt-3'>
        {expanded ? (
          <ExpandedActionItem data={expanded} setExpanded={setExpanded} />
        ) : (
          <div className='flex flex-col'>
            <div className='flex w-full gap-4 px-6 pb-4 justify-between z-10 bg-white'>
              <div className='flex items-center'>
                <input
                  type='checkbox'
                  id='not-started'
                  onChange={() =>
                    handleStatusFilter(ActionItemStatus.NotStarted)
                  }
                  checked={statusFilter.includes(ActionItemStatus.NotStarted)}
                />
                <label
                  htmlFor='not-started'
                  className='text-xs font-medium ml-1.5'
                >
                  Not Started
                </label>
              </div>

              <div className='flex items-center'>
                <input
                  type='checkbox'
                  id='in-progress'
                  onChange={() =>
                    handleStatusFilter(ActionItemStatus.InProgress)
                  }
                  checked={statusFilter.includes(ActionItemStatus.InProgress)}
                />
                <label
                  htmlFor='in-progress'
                  className='text-xs font-medium ml-1.5'
                >
                  In Progress
                </label>
              </div>

              <div className='flex items-center'>
                <input
                  type='checkbox'
                  id='completed'
                  onChange={() =>
                    handleStatusFilter(ActionItemStatus.Completed)
                  }
                  checked={statusFilter.includes(ActionItemStatus.Completed)}
                />
                <label
                  htmlFor='completed'
                  className='text-xs font-medium ml-1.5'
                >
                  Completed
                </label>
              </div>
            </div>
            <ul className='divide-y divide-gray-200 mt-4 overflow-auto max-h-[500px]'>
              {meetingTools?.actionItems &&
                meetingTools.actionItems
                  .filter(
                    (item) => item?.status && statusFilter.includes(item.status)
                  )
                  .map((item) => (
                    <ActionListItem
                      data={item}
                      setExpanded={setExpanded}
                      key={item?.id}
                    />
                  ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export { ActionItemView };
