import { Event } from '../../../graphql/types';
import Table from '../../../components/Table';
import { tableData } from '../../../reports/dataEventsTable';
import { useMemo } from 'react';

export type EventTableProps = {
  data?: Event[];
};

const EventsTable = ({ data }: EventTableProps) => {
  const eventData = useMemo(() => {
    if (!data) {
      return;
    }
    return tableData(data);
  }, [data]);

  if (!eventData) {
    return null;
  }

  return <Table {...eventData} />;
};

export { EventsTable };
