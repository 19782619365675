import { ColumnBase, ColumnParams } from '../../../types/Columns';
import { CustomColumnSettings, HotelSettings } from '../../table-settings';
import { DateSegment, dayRange } from '../../../helpers/dateHelpers';
import _, { split } from 'lodash';
import { baseFormat, getRowClass } from '../../../renderers/baseFormat';

import { FixMeLater } from '../../../types/FixMeLaterType';
import dayjs from 'dayjs';
import { masterColumns } from '../../../renderers/masterColumns';
import { useMemo } from 'react';

type UseTableDataProps = {
  customHeaders: CustomColumnSettings[] | null;
  data: any;
  dateSegment: string;
  endDate: string;
  hotelSettings?: HotelSettings;
  startDate: string;
  detailCols?: Array<any>;
  includeSummary?: boolean;
};

export type CellObject = {
  cls: string;
  dataKey: string;
  displayValue: string | null;
  meta?: any;
  stayDate: string;
  value: string | number | null;
};

export type HeaderObject = {
  columnSize: number;
  dataKey: string;
  dataSource?: string; // TODO REMOVE THIS
  meta?: any;
  title: string;
  tooltip: string;
};

export const useTable = (props: UseTableDataProps) => {
  const {
    customHeaders,
    data,
    dateSegment,
    endDate,
    hotelSettings,
    startDate,
    detailCols,
    includeSummary = true,
  } = props;

  return useMemo(() => {
    const tableCols = hotelSettings?.tableCols || detailCols;

    let headers: HeaderObject[] = [];
    let rows: CellObject[][] = [];

    if (data && !_.isEmpty(data) && tableCols) {
      const { compSet, roomList } = data;
      headers = tableCols.map((column) => {
        const colDetails = getColDetails(
          column,
          customHeaders,
          compSet,
          roomList
        );
        return {
          columnSize: colDetails?.columnSize || 55,
          dataKey: column.key,
          meta: { ...column, ...colDetails },
          title: colDetails?.header,
          tooltip: colDetails?.tooltip as string,
        };
      });

      const stayDates = dayRange(
        startDate,
        endDate,
        dateSegment as DateSegment,
        includeSummary
      );
      rows = stayDates.map((stayDate, dateIdx) => {
        const isSummary = stayDate.length === 7 && dateSegment === 'day';

        return tableCols.map((column) => {
          const { key } = column;

          const colDetails = getColDetails(
            column,
            customHeaders,
            compSet,
            roomList
          );

          let rowCell: CellObject = {
            cls: getRowClass(
              stayDate,
              dateIdx,
              dateSegment,
              undefined,
              isSummary
            ),
            dataKey: key,
            displayValue: null,
            meta: { ...column, ...colDetails, isSummary },
            stayDate,
            value: null,
          };

          if (colDetails) {
            let targetData: FixMeLater;

            const { dataSource } = colDetails;
            const categoryData = data[dataSource];

            if (key === 'note_ty.content') {
              targetData = _.find(categoryData, { stay_date: stayDate });
              if (targetData) {
                const noteId = targetData.note_ty
                  ? targetData.note_ty.id
                  : null;
                const noteContent = targetData.note_ty
                  ? targetData.note_ty.content
                  : null;
                let noteRowCell: CellObject = {
                  cls: getRowClass(stayDate, dateIdx, dateSegment),
                  dataKey: key,
                  displayValue: noteContent,
                  meta: { ...column, ...colDetails, id: noteId },
                  stayDate,
                  value: noteContent,
                };
                return noteRowCell;
              }
            }

            if (key === 'note_ly.content') {
              targetData = _.find(categoryData, { stay_date: stayDate });
              if (targetData) {
                const noteId = targetData.note_ly
                  ? targetData.note_ly.id
                  : null;
                const noteContent = targetData.note_ly
                  ? targetData.note_ly.content
                  : null;
                let noteRowCell: CellObject = {
                  cls: getRowClass(stayDate, dateIdx, dateSegment),
                  dataKey: key,
                  displayValue: noteContent,
                  meta: { ...column, ...colDetails, id: noteId },
                  stayDate,
                  value: noteContent,
                };
                return noteRowCell;
              }
            }

            if (
              categoryData &&
              categoryData !== null &&
              categoryData.length > 0
            ) {
              if (dataSource === 'hotelWeather') {
                targetData = _.find(categoryData, function (o) {
                  const d = dayjs(o.startTime).format('YYYY-MM-DD');
                  return d === stayDate;
                });
              } else {
                if (dateSegment === 'day') {
                  if (isSummary) {
                    targetData = _.filter(categoryData, function (o) {
                      return o.date_ym === stayDate && o.stay_date !== null;
                    });
                  } else {
                    targetData = _.find(categoryData, { stay_date: stayDate });
                  }
                } else {
                  targetData = _.filter(categoryData, function (o) {
                    switch (dateSegment) {
                      case 'year':
                        return o.date_year === stayDate;
                      case 'month':
                        return o.date_ym === stayDate;
                      case 'quarter':
                        return o.date_yq === stayDate;
                      case 'week':
                        return o.date_yw === stayDate;
                      default:
                        return o.stay_date === stayDate;
                    }
                  });
                }
              }

              if (targetData) {
                let id, rateData, rateLevelDetails, value;
                if (dataSource === 'dailyRates') {
                  const rateDetails = colDetails as FixMeLater;
                  const rateTargetData = targetData as FixMeLater;
                  const compId = rateDetails.competitor_id;
                  const rates = rateTargetData.rates;
                  rateData = _.find(rates, { competitor_id: compId });
                  if (isSummary) {
                    if (key === 'stay_date') {
                      // To ensure the summary row sorts correct, adding a false date of month value to end.
                      // We end up with something like 2023-01-999 for January 2023 summary row.
                      value = stayDate + '-999';
                    } else {
                      value = rateData?.rate;
                    }
                  } else {
                    value = key === 'stay_date' ? stayDate : rateData?.rate;
                  }
                } else if (dataSource === 'allRoomData') {
                  if (dateSegment === 'day') {
                    const roomCategory = key.split('.')[1];
                    const roomTypes = targetData.room_types;
                    value = _.find(roomTypes, {
                      room_category: roomCategory,
                    })?.rt_details;
                  } else {
                    value = '';
                  }
                } else if (colDetails.renderer === 'prm.temperature') {
                  const findKey = `values.${key.split('.')[1]}`;
                  value = _.get(targetData, findKey);
                } else if (colDetails.renderer === 'prm.rate_level_status') {
                  if (dateSegment === 'day') {
                    rateLevelDetails = _.get(
                      targetData,
                      key.replace('status', 'details')
                    );
                    value =
                      _.get(targetData, key) ||
                      _.get(targetData, key.split('.')[1]);
                  } else {
                    value = '';
                  }
                } else {
                  if (dateSegment === 'day') {
                    if (isSummary) {
                      if (key === 'stay_date') {
                        // To ensure the summary row sorts correct, adding a false date of month value to end.
                        // We end up with something like 2023-01-999 for January 2023 summary row.
                        value = stayDate + '-999';
                      } else if (key.includes('dow')) {
                        value = null;
                      } else if (key.includes('policy_cd')) {
                        value = null;
                      } else if (key.includes('rate')) {
                        value = null;
                      } else if (
                        key.includes('status') ||
                        key.includes('note')
                      ) {
                        value = null;
                      } else if (key.includes('rate') || key.includes('occ')) {
                        value =
                          _.meanBy(targetData, key) ||
                          _.meanBy(targetData, key.split('.')[1]);
                      } else if (key.includes('adr')) {
                        if (key.includes('var_')) {
                          //Get ADR Month Avg. for the current year
                          const revValue = _.sumBy(targetData, 'revenue');
                          const soldValue = _.sumBy(targetData, 'sold');

                          const currentYearADR =
                            soldValue > 0 ? revValue / soldValue : null;

                          //Get ADR Month Avg. for the last year
                          const revLastYear = _.sumBy(
                            targetData,
                            key.replace('adr', 'revenue').replace('var_', '')
                          );

                          const soldLastYear = _.sumBy(
                            targetData,
                            key.replace('adr', 'sold').replace('var_', '')
                          );

                          const lastYearADR =
                            soldLastYear > 0
                              ? revLastYear / soldLastYear
                              : null;

                          //Value = Current Year ADR - Last Year ADR
                          value =
                            currentYearADR && lastYearADR
                              ? currentYearADR - lastYearADR
                              : null;
                        } else {
                          const revValue = _.sumBy(
                            targetData,
                            key.replace('adr', 'revenue')
                          );
                          const soldValue = _.sumBy(
                            targetData,
                            key.replace('adr', 'sold')
                          );
                          value = soldValue > 0 ? revValue / soldValue : null;
                        }
                      } else if (key.includes('revpar')) {
                        const revValue = _.sumBy(
                          targetData,
                          key.replace('revpar', 'revenue')
                        );
                        const capacity = _.sumBy(
                          targetData,
                          key.replace('revpar', 'capacity')
                        );
                        value = capacity > 0 ? revValue / capacity : null;
                      } else {
                        value =
                          _.sumBy(targetData, key) ||
                          _.sumBy(targetData, key.split('.')[1]);
                      }
                    } else {
                      id = _.get(targetData, 'id');
                      value =
                        _.get(targetData, key) ||
                        _.get(targetData, key.split('.')[1]);
                    }
                  } else {
                    if (key === 'stay_date') {
                      value = stayDate;
                    } else if (key.includes('dow')) {
                      value = null;
                    } else if (key.includes('policy_cd')) {
                      value = null;
                    } else if (key.includes('status') || key.includes('note')) {
                      value = null;
                    } else if (key.includes('rate') || key.includes('occ')) {
                      const scrubbedData = _.filter(
                        targetData,
                        (data) => data.brand_code
                      );
                      value =
                        _.meanBy(scrubbedData, key) ||
                        _.meanBy(scrubbedData, key.split('.')[1]);
                    } else if (key.includes('adr')) {
                      if (key.includes('var_')) {
                        value = _.meanBy(targetData, key);
                      } else {
                        const revValue = _.sumBy(
                          targetData,
                          key.replace('adr', 'revenue')
                        );
                        const soldValue = _.sumBy(
                          targetData,
                          key.replace('adr', 'sold')
                        );
                        value = soldValue > 0 ? revValue / soldValue : null;
                      }
                    } else if (key.includes('revpar')) {
                      const revValue = _.sumBy(
                        targetData,
                        key.replace('revpar', 'revenue')
                      );
                      const capacity = _.sumBy(
                        targetData,
                        key.replace('revpar', 'capacity')
                      );
                      value = capacity > 0 ? revValue / capacity : null;
                    } else {
                      value =
                        _.sumBy(targetData, key) ||
                        _.sumBy(targetData, key.split('.')[1]);
                    }
                  }
                }

                const clientRate = rateData?.clientRate;

                rowCell = {
                  cls: getRowClass(
                    stayDate,
                    dateIdx,
                    dateSegment,
                    value,
                    isSummary,
                    clientRate,
                    key
                  ),
                  meta: {
                    ...rowCell.meta,
                    ...column,
                    ...colDetails,
                    id,
                    isSummary,
                    data:
                      rateData || getExtraData(key, targetData, data, stayDate),
                  },
                  dataKey: key,
                  displayValue:
                    rateLevelDetails ||
                    baseFormat(
                      key,
                      value,
                      dateSegment as string,
                      colDetails,
                      isSummary
                    ),
                  stayDate,
                  value,
                };
              }
            }
          }

          return rowCell;
        });
      });
      _.remove(rows, (row) => _.compact(row).length === 0);
    }

    return { headers, rows };
  }, [
    customHeaders,
    data,
    dateSegment,
    detailCols,
    endDate,
    hotelSettings?.tableCols,
    includeSummary,
    startDate,
  ]);
};

const getColDetails = (
  column: ColumnBase,
  customHeaders: CustomColumnSettings[] | null,
  compSet?: FixMeLater[],
  roomList?: FixMeLater[]
): ColumnParams => {
  const { key, dataSource, header, tooltip } = column;
  const masterColumn = masterColumns[key];
  let customColumn = {} as CustomColumnSettings | undefined;
  if (customHeaders) {
    customColumn = customHeaders.find(
      (h) => h.key === key || split(h.key)[1] === key
    );
    if (customColumn) {
      customColumn = _.omitBy(customColumn, _.isNull) as CustomColumnSettings;
    }
  }

  const mergedMaster = customColumn
    ? { ...masterColumn, ...customColumn }
    : masterColumn;

  if (key.includes('rates.')) {
    return { ..._.find(compSet, { key }), ...mergedMaster };
  } else if (key.includes('room_types.')) {
    return { ..._.find(roomList, { key }), ...mergedMaster };
  } else {
    if (dataSource || header || tooltip) {
      return {
        ...mergedMaster,
        ...(dataSource && { dataSource }),
        ...(header && { header }),
        ...(tooltip && { tooltip }),
      } as ColumnParams;
    } else {
      return mergedMaster as ColumnParams;
    }
  }
};

const getExtraData = (
  key: string,
  targetData: FixMeLater,
  data: FixMeLater,
  stayDate: string
) => {
  if (key.includes('event')) {
    return _.get(targetData, key.split('.')[0]);
  } else if (key === 'group_sold' || key === 'group_blocked') {
    const categoryData = data.groupDaily;
    const groupDailyData = _.find(categoryData, { stay_date: stayDate });
    return groupDailyData;
  } else if (
    key === 'rm_recs.status' ||
    key === 'rm_recs.rate' ||
    key === 'rm_recs.notes'
  ) {
    const categoryData = data.hotelRmRecs;
    const rmRecData = _.find(categoryData, { stay_date: stayDate });
    return rmRecData;
  } else if (key.includes('forecast')) {
    const categoryData = data.combinedHotelForecast;
    const forecastData = _.find(categoryData, { stay_date: stayDate });
    return forecastData;
  }
};
