import { BsSortDown, BsSortDownAlt } from 'react-icons/bs';

import { FaSort } from 'react-icons/fa';
import { IconProps } from '../../types/DataTable';

const DTSortIcon = ({ name, sortDir, sortKey }: IconProps) => {
  if (sortKey === name) {
    return sortDir === 'asc' ? (
      <BsSortDownAlt className='h-3 w-3 text-blue-600' />
    ) : (
      <BsSortDown className='h-3 w-3 text-pink-600' />
    );
  } else {
    return <FaSort className='h-3 w-3 text-gray-500' />;
  }
};

export default DTSortIcon;
