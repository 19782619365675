import { ActionItemStatus } from '../../../graphql/types';
import { CloseFn } from '../../../components/BtnPopover';
import { clsx } from 'clsx';
import { fromToday } from '../../../helpers/dateHelpers';
import { toast } from 'react-toastify';
import { useMeeting } from '../context/meeting-context';
import { useState } from 'react';
import { useUser } from '../../../context/userContext';

type FormProps = {
  brandCode?: string;
  closeForm?: CloseFn;
};

const AddActionItemForm = ({ brandCode, closeForm }: FormProps) => {
  const { createActionItem, meetingTools } = useMeeting();
  const { user } = useUser();
  const [assignedToId, setAssignee] = useState<string>();
  const [dueDate, setDueDate] = useState<string>(fromToday(7));
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [status, setItemStatus] = useState(ActionItemStatus.NotStarted);

  const handleSuccess = () => {
    toast.success(`${name} Added to Action Items`, { position: 'top-left' });
    // closeForm && closeForm();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (brandCode && user?.id) {
      await createActionItem({
        variables: {
          brandCode,
          createdById: user.id,
          assignedToId,
          dueDate,
          name,
          status,
          notes,
        },
        onCompleted: handleSuccess,
      });
    }

    setAssignee(undefined);
    setDueDate(fromToday(7));
    setName('');
    setNotes('');
    setItemStatus(ActionItemStatus.NotStarted);
  };

  return (
    <form className='mt-2' onSubmit={handleSubmit}>
      <div className='flex flex-col'>
        <div className='my-1 flex flex-col'>
          <label id='item-name' className='text-sm font-semibold text-gray-600'>
            Name
          </label>
          <input
            id='item-name'
            type='text'
            value={name}
            className='border border-gray-300 rounded-lg p-1.5 mt-1 text-sm font-medium'
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className='my-1 flex flex-col'>
          <label
            id='assigned-to'
            className='text-sm font-semibold text-gray-600'
          >
            Assigned To
          </label>
          <select
            id='assigned-to'
            value={assignedToId}
            className='text-sm py-1.5 font-medium'
            onChange={(e) => setAssignee(e.target.value)}
          >
            <option value={undefined}></option>
            {meetingTools?.hotelTeams?.map((member) => (
              <option key={member?.id} value={String(member?.id)}>
                {member?.name}
              </option>
            ))}
          </select>
        </div>

        <div className='my-1 flex flex-col'>
          <label id='due-date' className='text-sm font-semibold text-gray-600'>
            Due Date
          </label>
          <input
            id='due-date'
            type='date'
            value={dueDate}
            className='text-sm py-1.5 font-medium'
            onChange={(e) => setDueDate(e.target.value)}
          />
        </div>

        <div className='my-1 flex flex-col'>
          <label id='status' className='text-sm font-semibold text-gray-600'>
            Status
          </label>
          <select
            id='status'
            value={status}
            className='text-sm py-1.5 font-medium'
            onChange={(e) => setItemStatus(e.target.value as ActionItemStatus)}
          >
            <option value={ActionItemStatus.NotStarted}>Not Started</option>
            <option value={ActionItemStatus.InProgress}>In Progress</option>
            <option value={ActionItemStatus.Completed}>Completed</option>
          </select>
        </div>

        <div className='my-1 flex flex-col'>
          <label id='notes' className='text-sm font-semibold text-gray-600'>
            Notes
          </label>
          <input
            id='notes'
            type='text'
            value={notes}
            className='border border-gray-300 rounded-lg p-1.5 mt-1 text-sm font-medium'
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>

        <div className='w-full flex justify-center mt-2'>
          <button
            type='submit'
            className={clsx(
              'bg-blue-800 py-1 px-2 rounded-md text-white text-sm mt-1.5',
              name === '' && 'opacity-50'
            )}
            disabled={name === ''}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export { AddActionItemForm };
