import { useEffect, useState } from 'react';
import {
  useGetUserOptionQuery,
  useUpdateUserOptionMutation,
} from '../../../features/user-options/gql/_gen_/user-options.gql';

import ReactQuill from 'react-quill';

interface UseNotepadProps {
  brandCode?: string;
  editorRef: React.RefObject<ReactQuill>;
  userId?: string | null;
}

const useNotepad = ({ brandCode, editorRef, userId }: UseNotepadProps) => {
  const [notepad, setNotepad] = useState('');
  const [savedValue, setSavedValue] = useState('');

  const skip =
    !brandCode ||
    brandCode === undefined ||
    brandCode === null ||
    brandCode === 'undefined' ||
    !userId ||
    userId === undefined ||
    userId === null ||
    userId === 'undefined';

  const { data, loading } = useGetUserOptionQuery({
    skip,
    variables: {
      brandCode: String(brandCode),
      userId: String(userId),
    },
  });

  const [updateUserOption, { loading: loadingSave }] =
    useUpdateUserOptionMutation({
      onCompleted: (data) => {
        if (data?.updateUserOption?.appNotes) {
          setNotepad(data?.updateUserOption?.appNotes);
          setSavedValue(data?.updateUserOption?.appNotes);
        }
      },
    });

  useEffect(() => {
    if (data) {
      if (data.getUserOption?.appNotes) {
        setNotepad(data.getUserOption?.appNotes);
        setSavedValue(data.getUserOption?.appNotes);
      } else {
        setNotepad('');
        setSavedValue('');
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (loadingSave === false && editorRef && editorRef.current) {
      editorRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSave]);

  const saveNotes = async () => {
    if (brandCode && userId) {
      await updateUserOption({
        variables: {
          brandCode,
          userId,
          appNotes: notepad,
        },
      });
    }
  };

  return {
    notepad,
    loadingSave,
    setNotepad,
    saveNotes,
    savedValue,
  };
};

export { useNotepad };
