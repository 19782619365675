import { CellObject } from '@/features/overview-table/hooks/use-table';
import { displayDate } from '@/helpers/dateHelpers';

type Change = {
  days: number;
  change: {
    roomName: string;
    value: number;
  };
};

type RateShop = {
  arrivalDate: string;
  best_flex: boolean;
  cancellable: boolean;
  cityTaxIncl: boolean;
  currency: string;
  extractDateTime: string;
  hotelId: number;
  hotelName: string;
  los: number;
  maxPersons: number;
  message: string;
  otherTaxesIncl: boolean;
  roomName: string;
  shopCurrency: string;
  value: number;
  vatIncl: boolean;
  changes?: Change[];
};

export const RateShopToolTip = (cell: CellObject) => {
  const rate = cell.meta?.data as RateShop;
  if (rate) {
    return (
      <ul>
        <li>
          <span className='font-bold'>Stay Date:</span>{' '}
          {displayDate(rate.arrivalDate)}
        </li>
        <li>
          <span className='font-bold'>Shop Date:</span>{' '}
          {displayDate(rate.extractDateTime)}
        </li>
        <li>
          <span className='font-bold'>Rate:</span> {cell.displayValue}
        </li>
        <li>
          <span className='font-bold'>Room Name:</span>{' '}
          {rate.roomName.slice(0, 20)}
        </li>
        <li>
          <span className='font-bold'>LOS:</span> {rate.los}
        </li>
        <li>
          <span className='font-bold'>Best Flex?:</span>{' '}
          {String(rate.best_flex)}
        </li>
        {rate.changes && rate.changes.length > 0 && (
          <li>
            <span className='font-bold'>Rate Changes</span>
            <ul>
              {rate.changes.map((change: Change) => (
                <li key={`rate-change-${change.days}`}>
                  {change.days} day ago: {change.change.value}
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    );
  } else {
    return <></>;
  }
};
