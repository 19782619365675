import { OtaiSubLookupType, OtaiSubscription } from '../graphql/types';
import { createContext, useContext, useEffect, useState } from 'react';
import { getValue, saveValue } from '../helpers/localStorageHelper';

import { Hotel } from '../graphql/types';
import { useGetByBrandCodeQuery } from '../features/meetings/gql/_gen_/hotel.gql';
import { useIsShared } from '../features/reports/hooks/use-report-location';
import { useListOtaiSubscriptionsQuery } from '../features/rate_shops/gql/_gen_/rate_shops.gql';

export type State = Hotel | undefined;
type Dispatch = (hotel: State) => void;
type DispatchBrandCode = (brandCode: string) => void;

export type OtaiSubs = (Partial<OtaiSubscription> | null)[];

type HotelContextProps = {
  hotel: State;
  loading: boolean;
  otaiSubs?: OtaiSubs;
  setBrandCode: DispatchBrandCode;
  setHotel: Dispatch;
};
type HotelProviderProps = { children: React.ReactNode };

const HotelContext = createContext<HotelContextProps | undefined>(undefined);

function HotelProvider({ children }: HotelProviderProps) {
  const keyStorage = 'rmwLastViewedHotel';
  const { isShared } = useIsShared();
  const lastViewedHotel = getValue(keyStorage);
  const [brandCode, saveBrandCode] = useState<string | null>(lastViewedHotel);
  const [hotel, setHotel] = useState<State>(undefined);
  const [otaiSubs, setOtaiSubId] = useState<OtaiSubs>();

  const { data, loading } = useGetByBrandCodeQuery({
    skip: !brandCode || isShared,
    variables: {
      brandCode,
    },
    onCompleted: (data) => {
      if (data && data.getByBrandCode) {
        setHotel(data.getByBrandCode);
      }
    },
  });

  useListOtaiSubscriptionsQuery({
    skip: !brandCode || isShared,
    variables: {
      lookup: {
        lookupColumn: OtaiSubLookupType.BrandCode,
        lookupValue: brandCode as string,
      },
    },
    onCompleted: (data) => {
      if (data && data.listOtaiSubscriptions)
        setOtaiSubId(data.listOtaiSubscriptions);
    },
  });

  useEffect(() => {
    if (data && data.getByBrandCode) {
      setHotel(data.getByBrandCode);
    }
  }, [data, otaiSubs]);

  const setBrandCode = (brandCode: string) => {
    saveBrandCode(brandCode);
    saveValue(keyStorage, brandCode);
  };

  const value = { hotel, loading, setBrandCode, setHotel, otaiSubs };

  return (
    <HotelContext.Provider value={value}>{children}</HotelContext.Provider>
  );
}

function useHotel() {
  const context = useContext(HotelContext);
  if (context === undefined) {
    throw new Error(`useHotel must be used within a HotelProvider`);
  }
  return context;
}

export { HotelProvider, useHotel };
