import { Forecast, Hotel, MonthlyBudget, RmData } from '../../../graphql/types';

import MonthlyPerformance from '../../../reports/MonthlyPerformance';
import ReactTooltip from 'react-tooltip';
import clsx from 'clsx';

interface MonthlyPerformanceTableProps {
  data?: RmData[];
  dataRolling?: RmData[];
  forecastData?: Forecast[];
  forecastRolling?: Forecast[];
  hotel?: Hotel;
  budgetData?: MonthlyBudget[];
  budgetRolling?: MonthlyBudget[];
  handleVersionChange: (version: string) => void;
  loading: boolean;
  reportVersion: string;
}

const MonthlyPerformanceTable = ({
  data,
  dataRolling,
  hotel,
  budgetData,
  budgetRolling,
  forecastData,
  forecastRolling,
  handleVersionChange,
  loading,
  reportVersion,
}: MonthlyPerformanceTableProps) => {
  return (
    <div className='overflow-y-scroll'>
      <nav className='mb-2'>
        <button
          className={clsx(
            'text-sm font-semibold px-2',
            reportVersion === 'current' ? 'text-blue-900' : 'text-gray-400'
          )}
          onClick={() => handleVersionChange('current')}
          disabled={reportVersion === 'current'}
        >
          Current Year
        </button>
        <button
          className={clsx(
            'text-sm font-semibold px-2',
            reportVersion === 'rolling' ? 'text-blue-900' : 'text-gray-400'
          )}
          onClick={() => handleVersionChange('rolling')}
          disabled={reportVersion === 'rolling'}
        >
          Rolling
        </button>
      </nav>
      {loading ? (
        'Loading...'
      ) : reportVersion === 'current' ? (
        <MonthlyPerformance
          data={data}
          hotel={hotel}
          forecast={forecastData}
          budget={budgetData}
        />
      ) : (
        <MonthlyPerformance
          data={dataRolling}
          hotel={hotel}
          forecast={forecastRolling}
          budget={budgetRolling}
        />
      )}
    </div>
  );
};

export const MonthlyTooltip = () => {
  return (
    <ReactTooltip id='monthly-data-disclaimer' aria-haspopup='true'>
      <div>
        <div>
          <h4>Data Timing</h4>
        </div>
        <div>
          <p>
            The workspace statistics are a snapshot from 2:00am AZ time and may
            vary
            <br /> between the PMS, MemberWeb, and reporting.
          </p>
          <p>
            This data is intended to be used for revenue management analysis and
            <br /> not as a financial accounting record.
          </p>
          <p>
            The time of night audit closeout and data transmission to BW
            servers,
            <br /> after audit check-ins, early check-outs, day-use rooms,
            no-shows,
            <br /> two-way interface issues, time zones, revenue adjustments,
            <br />
            and misc. room revenue postings are potential causes of data
            discrepancies.
          </p>
        </div>
      </div>
    </ReactTooltip>
  );
};

export { MonthlyPerformanceTable };
