/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyForecastsMutationVariables = Types.Exact<{
  hotelId: Types.Scalars['Int'];
  target: Types.Scalars['String'];
}>;


export type CopyForecastsMutation = { __typename?: 'Mutation', copyForecasts?: Array<{ __typename?: 'Forecast', id?: string | null, fcstType?: string | null, forecast_sold_1?: number | null, forecast_adr_1?: number | null, forecast_revenue_1?: number | null, forecast_sold_2?: number | null, forecast_adr_2?: number | null, forecast_revenue_2?: number | null, hotel_id?: number | null, stay_date?: any | null, date_year?: string | null, date_ym?: string | null, date_yq?: string | null, date_yw?: string | null, updated_at?: string | null } | null> | null };

export type ResetForecastsMutationVariables = Types.Exact<{
  hotelId: Types.Scalars['Int'];
  target: Types.Scalars['String'];
  metric: Types.Scalars['String'];
}>;


export type ResetForecastsMutation = { __typename?: 'Mutation', resetForecasts?: Array<{ __typename?: 'Forecast', id?: string | null, fcstType?: string | null, forecast_sold_1?: number | null, forecast_adr_1?: number | null, forecast_revenue_1?: number | null, forecast_sold_2?: number | null, forecast_adr_2?: number | null, forecast_revenue_2?: number | null, hotel_id?: number | null, stay_date?: any | null, date_year?: string | null, date_ym?: string | null, date_yq?: string | null, date_yw?: string | null, updated_at?: string | null } | null> | null };


export const CopyForecastsDocument = gql`
    mutation CopyForecasts($hotelId: Int!, $target: String!) {
  copyForecasts(hotelId: $hotelId, target: $target) {
    id
    fcstType
    forecast_sold_1
    forecast_adr_1
    forecast_revenue_1
    forecast_sold_2
    forecast_adr_2
    forecast_revenue_2
    hotel_id
    stay_date
    date_year
    date_ym
    date_yq
    date_yw
    updated_at
  }
}
    `;
export type CopyForecastsMutationFn = Apollo.MutationFunction<CopyForecastsMutation, CopyForecastsMutationVariables>;

/**
 * __useCopyForecastsMutation__
 *
 * To run a mutation, you first call `useCopyForecastsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyForecastsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyForecastsMutation, { data, loading, error }] = useCopyForecastsMutation({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useCopyForecastsMutation(baseOptions?: Apollo.MutationHookOptions<CopyForecastsMutation, CopyForecastsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyForecastsMutation, CopyForecastsMutationVariables>(CopyForecastsDocument, options);
      }
export type CopyForecastsMutationHookResult = ReturnType<typeof useCopyForecastsMutation>;
export type CopyForecastsMutationResult = Apollo.MutationResult<CopyForecastsMutation>;
export type CopyForecastsMutationOptions = Apollo.BaseMutationOptions<CopyForecastsMutation, CopyForecastsMutationVariables>;
export const ResetForecastsDocument = gql`
    mutation ResetForecasts($hotelId: Int!, $target: String!, $metric: String!) {
  resetForecasts(hotelId: $hotelId, target: $target, metric: $metric) {
    id
    fcstType
    forecast_sold_1
    forecast_adr_1
    forecast_revenue_1
    forecast_sold_2
    forecast_adr_2
    forecast_revenue_2
    hotel_id
    stay_date
    date_year
    date_ym
    date_yq
    date_yw
    updated_at
  }
}
    `;
export type ResetForecastsMutationFn = Apollo.MutationFunction<ResetForecastsMutation, ResetForecastsMutationVariables>;

/**
 * __useResetForecastsMutation__
 *
 * To run a mutation, you first call `useResetForecastsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetForecastsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetForecastsMutation, { data, loading, error }] = useResetForecastsMutation({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      target: // value for 'target'
 *      metric: // value for 'metric'
 *   },
 * });
 */
export function useResetForecastsMutation(baseOptions?: Apollo.MutationHookOptions<ResetForecastsMutation, ResetForecastsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetForecastsMutation, ResetForecastsMutationVariables>(ResetForecastsDocument, options);
      }
export type ResetForecastsMutationHookResult = ReturnType<typeof useResetForecastsMutation>;
export type ResetForecastsMutationResult = Apollo.MutationResult<ResetForecastsMutation>;
export type ResetForecastsMutationOptions = Apollo.BaseMutationOptions<ResetForecastsMutation, ResetForecastsMutationVariables>;
export const namedOperations = {
  Mutation: {
    CopyForecasts: 'CopyForecasts',
    ResetForecasts: 'ResetForecasts'
  }
}