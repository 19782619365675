import {
  DateHelpers,
  lastNightTableDates,
} from '../../../../helpers/dateHelpers';
import LastNightTable, {
  LastNightTooltip,
} from '../../../../reports/LastNightTable';
import { Meeting, RmData } from '../../../../graphql/types';

import { FaInfoCircle } from 'react-icons/fa';
import { ReportDataQuery } from '../../gql/_gen_/report.gql';
import { SectionContainer } from '../section-container';

type LastNightSummaryProps = {
  dataReport?: ReportDataQuery;
  loadingReport: boolean;
  meeting?: Meeting;
};

export const LastNightSummary = ({
  dataReport,
  loadingReport,
  meeting,
}: LastNightSummaryProps) => {
  const tableDates = lastNightTableDates(
    meeting?.meetingDate || DateHelpers.today()
  );

  return (
    <SectionContainer
      anchorId='last-night'
      title='Last Night Summary'
      toolbar={
        <div>
          <FaInfoCircle
            className='text-blue-500'
            data-tip
            data-for='last-night-summary'
          />
          <LastNightTooltip tableDates={tableDates} />
        </div>
      }
    >
      {loadingReport ? (
        <span>Loading...</span>
      ) : (
        <LastNightTable
          currentData={
            dataReport?.rmData?.filter((rm) =>
              DateHelpers.betweenOrEqual(
                rm?.stay_date,
                tableDates.mtdStart,
                tableDates.mtdEnd
              )
            ) as RmData[]
          }
          lastYearData={
            dataReport?.rmData?.filter((rm) =>
              DateHelpers.betweenOrEqual(
                rm?.stay_date_ly,
                tableDates.mtdStartLy,
                tableDates.mtdEndLy
              )
            ) as RmData[]
          }
          lastNight={DateHelpers.subtract(
            meeting?.meetingDate || DateHelpers.today(),
            'days',
            1
          )}
        />
      )}
    </SectionContainer>
  );
};
