import { DATE_SEGMENT_FIELDS } from './fragments';
import { gql } from '@apollo/client';

export const GET_BESTREV = gql`
  query BestrevData($brandCode: String!, $useCache: Boolean) {
    bestrevData(brandCode: $brandCode, useCache: $useCache) {
      stay_date
      ${DATE_SEGMENT_FIELDS}
      recommended_rate
      market_rate
      snapshot_date
      total_fcst
    }
  }
`;
