import { Menu, Transition } from '@headlessui/react';
import { useClearColumnRmRecs, useResetRmRecs } from '../../hooks/useRmRecs';

import { CgPlayListRemove } from 'react-icons/cg';
import { FindRmRecArgs } from '../../types/__generated__/globalTypes';
import { Fragment } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { clsx } from 'clsx';
import { omit } from 'lodash';
import { useLocation } from 'react-router-dom';

type BtnProps = {
  btnClass: string;
  btnIconClass?: string;
  filters: FindRmRecArgs;
};

export default function BtnResetRecs({
  btnClass,
  btnIconClass = 'h-5 w-5',
  filters,
}: BtnProps) {
  const location = useLocation();
  const isReport = location.pathname.includes('report');
  const refetchQueries = isReport ? ['ReportData'] : ['FindRmRecs'];
  const [resetRecs, { loading }] = useResetRmRecs(refetchQueries);
  const [clearColRecs, { loading: loadingClear }] = useClearColumnRmRecs([
    'FindRmRecs',
  ]);

  const items = [
    { name: 'Clear Rates', click: () => handleClear('rate') },
    { name: 'Clear Other Recs', click: () => handleClear('notes') },
  ];

  const handleClear = (column: string) => {
    clearColRecs({
      variables: {
        filters: {
          ...omit(filters, ['startDate', 'endDate']),
        },
        column,
      },
    });
  };

  const handleClick = () => {
    resetRecs({
      variables: {
        filters: {
          ...omit(filters, ['startDate', 'endDate']),
        },
      },
    });
  };

  const ResetAllBtn = () => {
    return (
      <span onClick={handleClick} className={btnClass} data-tip='Reset RM Recs'>
        <CgPlayListRemove
          aria-hidden='true'
          className={`${
            loading || loadingClear ? 'animate-spin' : ''
          } ${btnIconClass} cursor-pointer`}
        />
      </span>
    );
  };

  return (
    <div className='relative z-30 inline-flex shadow-sm rounded-md'>
      <ResetAllBtn />
      <Menu as='div' className='-ml-px relative block'>
        <Menu.Button className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'>
          <span className='sr-only'>Open options</span>
          <HiChevronDown className={btnIconClass} aria-hidden='true' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-right absolute right-0 mt-2 -mr-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1'>
              {items.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <button
                      onClick={item.click}
                      className={clsx(
                        active ? ' text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
