/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRateLevelStatusQueryVariables = Types.Exact<{
  args: Types.RmDataArgs;
}>;


export type GetRateLevelStatusQuery = { __typename?: 'Query', rateLevelStatus?: Array<{ __typename?: 'RmData', stay_date?: any | null, date_year?: string | null, date_ym?: string | null, date_yq?: string | null, date_yw?: string | null, lvl_a_status?: string | null, lvl_a_details?: string | null, lvl_a_sold?: number | null, lvl_a_remaining?: number | null, lvl_a_occ?: number | null, lvl_b_status?: string | null, lvl_b_sold?: number | null, lvl_b_details?: string | null, lvl_b_remaining?: number | null, lvl_b_occ?: number | null, lvl_c_status?: string | null, lvl_c_details?: string | null, lvl_c_sold?: number | null, lvl_c_remaining?: number | null, lvl_c_occ?: number | null, lvl_d_status?: string | null, lvl_d_details?: string | null, lvl_d_sold?: number | null, lvl_d_remaining?: number | null, lvl_d_occ?: number | null, lvl_e_status?: string | null, lvl_e_details?: string | null, lvl_e_sold?: number | null, lvl_e_remaining?: number | null, lvl_e_occ?: number | null, lvl_f_status?: string | null, lvl_f_details?: string | null, lvl_f_remaining?: number | null, lvl_f_occ?: number | null, lvl_f_sold?: number | null, lvl_g_status?: string | null, lvl_g_details?: string | null, lvl_g_sold?: number | null, lvl_g_remaining?: number | null, lvl_g_occ?: number | null, lvl_h_status?: string | null, lvl_h_details?: string | null, lvl_h_occ?: number | null, lvl_h_remaining?: number | null, lvl_h_sold?: number | null } | null> | null };


export const GetRateLevelStatusDocument = gql`
    query GetRateLevelStatus($args: RmDataArgs!) {
  rateLevelStatus: rmData(args: $args) {
    stay_date
    date_year
    date_ym
    date_yq
    date_yw
    lvl_a_status
    lvl_a_details
    lvl_a_sold
    lvl_a_remaining
    lvl_a_occ
    lvl_b_status
    lvl_b_sold
    lvl_b_details
    lvl_b_remaining
    lvl_b_occ
    lvl_c_status
    lvl_c_details
    lvl_c_sold
    lvl_c_remaining
    lvl_c_occ
    lvl_d_status
    lvl_d_details
    lvl_d_sold
    lvl_d_remaining
    lvl_d_occ
    lvl_e_status
    lvl_e_details
    lvl_e_sold
    lvl_e_remaining
    lvl_e_occ
    lvl_f_status
    lvl_f_details
    lvl_f_remaining
    lvl_f_occ
    lvl_f_sold
    lvl_g_status
    lvl_g_details
    lvl_g_sold
    lvl_g_remaining
    lvl_g_occ
    lvl_h_status
    lvl_h_details
    lvl_h_occ
    lvl_h_remaining
    lvl_h_sold
  }
}
    `;

/**
 * __useGetRateLevelStatusQuery__
 *
 * To run a query within a React component, call `useGetRateLevelStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateLevelStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateLevelStatusQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useGetRateLevelStatusQuery(baseOptions: Apollo.QueryHookOptions<GetRateLevelStatusQuery, GetRateLevelStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRateLevelStatusQuery, GetRateLevelStatusQueryVariables>(GetRateLevelStatusDocument, options);
      }
export function useGetRateLevelStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRateLevelStatusQuery, GetRateLevelStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRateLevelStatusQuery, GetRateLevelStatusQueryVariables>(GetRateLevelStatusDocument, options);
        }
export type GetRateLevelStatusQueryHookResult = ReturnType<typeof useGetRateLevelStatusQuery>;
export type GetRateLevelStatusLazyQueryHookResult = ReturnType<typeof useGetRateLevelStatusLazyQuery>;
export type GetRateLevelStatusQueryResult = Apollo.QueryResult<GetRateLevelStatusQuery, GetRateLevelStatusQueryVariables>;
export const namedOperations = {
  Query: {
    GetRateLevelStatus: 'GetRateLevelStatus'
  }
}