export const messages = [
  '1946 - Best Western Motels is founded by M.K. Guertin, a hotelier with 23 years of experience in the business. The chain begins as an informal link between properties with each hotel recommending other lodging establishments to travelers. The "referral system" consists of phone calls from one desk operator to another.',
  '1951 - In a guest editorial published in American Motel Magazine, Guertin speaks of the importance of advertising properties to the general traveling public. This is considered a very revolutionary approach in the lodging industry.',
  '1962 - Best Western has the only hospitality reservations service covering the entire United States.',
  '1962 - Best Western begins using the crown logo with a rope border to identify member properties.',
  '1963 - Best Western is the largest motel chain in the industry with 699 member properties and 35,201 rooms.',
  '1964 - The group of motels east of the Mississippi River is incorporated as Best Eastern Inc.',
  '1966 - The entire membership, Best Western and Best Eastern, unites under the Best Western name. A seven-person Board of Directors is elected by regional members to provide leadership and make policy decisions. Guertin retires.',
  '1972 - Properties are required to accept six major credit cards. Reservations that are charged are considered "guaranteed" and rooms have to be held for the entire night. Properties have the right to bill for "no-show" clients.',
  '1974 - Best Western decides to drop its referral organization image, eliminates the word "motel" from its name and begins directly competing directly with other full-service lodging chains.',
  '1975 - Best Western begins to expand overseas, entering Australia and New Zealand.',
  '1976 - Best Western Mexico launches, bringing more than 100 properties in Mexico and Central America into the fold.',
  '1977 - To meet the demands of rapid growth, a multi-million dollar Best Western International Headquarters complex is designed and built in northeast Phoenix.',
  '1978 - Best Western welcomes Great Britain and Ireland into the family.',
  '1979 - Best Western accommodates 15 million guests and generates $1 billion in room sales.',
  '1980 - Best Western membership rises to 2,654 hoteliers worldwide.',
  "1981 - In August, a satellite reservations center is established inside the Arizona Center for Women (ACW), a minimum-security correctional facility in Phoenix. This innovative venture, employing inmates as reservations sales agents, answers the chain's business need for a flexible workforce. The program brings numerous awards and worldwide attention to Best Western.",
  '1982 - Best Western welcomes Denmark and Italy into the family.',
  '1988 - The Gold Crown Club International Program for frequent travelers launches. Within a year, it acquires more than 200,000 members and sales of more than $40 million.',
  '1993 - A brand identity study recommends the adoption of a new Best Western logo and identity. On November 30, the members approve the adoption of a new logo and officially retire the Gold Crown logo.',
  '1995 - Best Western introduces its first listings on the Internet. Full information on 150 member properties, including photographs, becomes instantly available via personal home computer.',
  '2003 - Best Western introduces the Best Western Premier descriptor in Europe and Asia.',
  "2004 - Best Western launches the hotel industry's largest High-Speed Internet Access (HSIA) initiative with the fastest implementation. In just eight months, the company establishes free wireless or hard-wired HSIA in some portion of the public areas, and at least 15 percent of rooms, at all of its North American properties.",
  '2005 - Best Western begins averaging $1 million in bookings per day through bestwestern.com.',
  '2006 - Best Western celebrates its 60th anniversary with the 1946 Room Rate Promotion, where lucky guests are provided with a special one-night charge of $5.40 - the approximate value of overnight accommodations six decades ago, when the company got its start.',
  '2007 - Best Western begins using online surveys to keep track of customer satisfaction, one of the first steps in leading the industry in superior customer care.',
  '2008 - The Gold Crown Club® International Program celebrates its 20th anniversary and is renamed Best Western Rewards®.',
  '2010 - Best Western passes ballot approving three descriptors - Best Western®, Best Western Plus® and Best Western Premier®.',
  '2014 - Best Western announces Vīb®, an urban boutique concept focused on style, technology and engagement, and BW Premier Collection®, a soft brand of carefully selected, high-quality hotels in global primary markets.',
  '2015 - Best Western announces GLō®, a broad-midscale boutique concept designed for secondary, suburban and highway markets. GLō is a broad-midscale new construction brand that offers a hip, boutique-style experience for savvy travelers who expect the best in value, design and comfort.',
  '2016 - Best Western celebrates its 70th anniversary and introduces SureStay Hotel Group®.',
  "2017 - Best Western adds company's eleventh brand, BW Signature Collection® by Best Western.",
];
