import {
  Bold,
  Italic,
  List,
  ListOrdered,
  Underline as UnderlineIcon,
} from 'lucide-react';
import { EditorContent, generateJSON, useEditor } from '@tiptap/react';
import { useCallback, useEffect } from 'react';

import { Button } from '@/components/ui/button';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import { Status } from '@/features/notes/types/status';
import Underline from '@tiptap/extension-underline';
import { useDebouncedCallback } from 'use-debounce';
import { useNotepad } from '@/features/navbar/hooks/use-notepad-new';

interface NotepadProps {
  brandCode?: string;
  userId?: string | null;
}

const AUTOSAVE_DELAY = 10000;

export function Notepad({ brandCode, userId }: NotepadProps) {
  const extensions = [StarterKit, Underline, Image];

  const { notepad, loadingSave, saveNotes, savedValue, setEditorInstance } =
    useNotepad({
      brandCode,
      userId,
    });

  const parseContent = (content: string | object | null): object | null => {
    if (content === null || content === undefined) return null;

    if (typeof content === 'string') {
      try {
        return JSON.parse(content);
      } catch (error) {
        console.warn(
          'Failed to parse content as JSON, creating a text node:',
          error
        );
        return generateJSON(content, extensions);
      }
    }
    return content || null;
  };

  const handlePaste = (view: any, event: ClipboardEvent) => {
    const items = Array.from(event.clipboardData?.items || []);
    const image = items.find((item) => item.type.indexOf('image') === 0);

    if (image) {
      event.preventDefault();
      const blob = image.getAsFile();
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const dataUrl = readerEvent.target?.result as string;
        view.dispatch(
          view.state.tr.replaceSelectionWith(
            view.state.schema.nodes.image.create({ src: dataUrl, size: 100 })
          )
        );
      };
      if (blob) reader.readAsDataURL(blob);
      return true;
    }
    return false;
  };

  const editor = useEditor({
    extensions,
    content: parseContent(notepad),
    editable: !loadingSave,
    editorProps: {
      attributes: {
        class:
          'outline-none w-full min-h-[5px] p-2 leading-extra-tight text-sm',
      },
      handlePaste,
    },
  });

  useEffect(() => {
    if (editor) {
      setEditorInstance(editor);
    }
  }, [editor, setEditorInstance]);

  useEffect(() => {
    editor?.commands.setContent(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCode]);

  useEffect(() => {
    if (notepad && editor?.isEmpty) {
      editor.commands.setContent(parseContent(notepad) || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, notepad]);

  const debouncedSave = useDebouncedCallback(async () => {
    if (
      editor &&
      JSON.stringify(editor.getJSON()) !== JSON.stringify(savedValue)
    ) {
      await saveNotes();
    }
  }, AUTOSAVE_DELAY);

  useEffect(() => {
    if (editor) {
      editor.on('update', () => {
        debouncedSave();
      });
    }
  }, [editor, debouncedSave]);

  const handleManualSave = useCallback(async () => {
    if (editor) {
      await saveNotes();
    }
  }, [editor, saveNotes]);

  const savedStatus = loadingSave
    ? Status.SAVING
    : JSON.stringify(editor?.getJSON()) === JSON.stringify(savedValue)
    ? Status.SAVED
    : editor?.getHTML().length
    ? Status.UNSAVED
    : undefined;

  return (
    <div className='p-2 text-left border rounded-md border-dashed border-gray-400'>
      <div className='mb-4 flex gap-2'>
        <Button
          variant='outline'
          size='icon-sm'
          onClick={() => editor?.chain().focus().toggleBold().run()}
          className={editor?.isActive('bold') ? 'bg-muted' : ''}
        >
          <Bold className='h-3 w-3' />
        </Button>
        <Button
          variant='outline'
          size='icon-sm'
          onClick={() => editor?.chain().focus().toggleItalic().run()}
          className={editor?.isActive('italic') ? 'bg-muted' : ''}
        >
          <Italic className='h-3 w-3' />
        </Button>
        <Button
          variant='outline'
          size='icon-sm'
          onClick={() => editor?.chain().focus().toggleUnderline().run()}
          className={editor?.isActive('underline') ? 'bg-muted' : ''}
        >
          <UnderlineIcon className='h-3 w-3' />
        </Button>
        <Button
          variant='outline'
          size='icon-sm'
          onClick={() => editor?.chain().focus().toggleBulletList().run()}
          className={editor?.isActive('bulletList') ? 'bg-muted' : ''}
        >
          <List className='h-3 w-3' />
        </Button>
        <Button
          variant='outline'
          size='icon-sm'
          onClick={() => editor?.chain().focus().toggleOrderedList().run()}
          className={editor?.isActive('orderedList') ? 'bg-muted' : ''}
        >
          <ListOrdered className='h-3 w-3' />
        </Button>
      </div>
      <div className='w-full border rounded-sm mx-1'>
        <EditorContent
          editor={editor}
          className='prose prose-sm max-w-none w-full'
        />
      </div>
      <div className='mt-4 flex justify-between items-center'>
        <Button onClick={handleManualSave} disabled={loadingSave}>
          Save
        </Button>
        <span className='text-sm text-muted-foreground'>
          {savedStatus === Status.SAVING
            ? 'Saving...'
            : savedStatus === Status.SAVED
            ? 'All changes saved'
            : savedStatus === Status.UNSAVED
            ? 'Unsaved changes'
            : ''}
        </span>
      </div>
    </div>
  );
}
