import { gql } from '@apollo/client';

export const CORE_WEATHER_FIELDS = gql`
  fragment CoreWeatherFields on Timeline {
    endTime
    startTime
    timestep
    intervals {
      startTime
      values {
        temperature
      }
    }
  }
`;

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    email
    id
    name
    okta_uid
  }
`;

export const CORE_EVENT_FIELDS = gql`
  ${CORE_USER_FIELDS}
  fragment CoreEventFields on Event {
    id
    name
    start_date
    end_date
    details
    created_by {
      ...CoreUserFields
    }
  }
`;

export const DATE_SEGMENT_FIELDS = `
  date_year
  date_ym
  date_yq
  date_yw
`;

export const CORE_AGENDA_ITEM_FIELDS = gql`
  fragment CoreAgendaItemFields on AgendaItem {
    brandCode
    completed
    createdAt
    createdById
    id
    name
    notes
    updatedAt
  }
`;

export const CORE_TEAM_MEMBER_FIELDS = gql`
  fragment CoreTeamMemberFields on TeamMember {
    id
    brandCode
    name
    email
    title
    phone
    status
    datesAttended
    active
    inactiveDate
  }
`;

export const CORE_ACTION_ITEM_FIELDS = gql`
  fragment CoreActionItemFields on ActionItem {
    id
    brandCode
    name
    assignedTo
    createdBy
    dueDate
    status
    createdAt
    statusUpdated
  }
`;
