import {
  COMBINED_HOTEL_FORECAST,
  CREATE_FORECAST,
  UPDATE_FORECAST,
} from '../gql/gqlForecasts';
import { useMutation, useQuery } from '@apollo/client';

import { LIST_FORECASTS } from '../gql/gqlForecasts';

export type UseHookArgs = {
  hotelId: number;
  callback: () => void;
};

export type CombinedHotelForecast = {
  hotelId: number;
  startDate: string;
  endDate: string;
  snapshotDate: string;
};

export function useForecasts(a: UseHookArgs) {
  const { hotelId, callback } = a;
  return useQuery(LIST_FORECASTS, {
    variables: {
      lookup: {
        lookupColumn: 'HOTEL_ID',
        lookupValue: hotelId,
      },
    },
    skip: !hotelId,
    onCompleted: () => callback(),
  });
}

export function useCombinedForecasts({
  hotelId,
  startDate,
  endDate,
  snapshotDate,
}: CombinedHotelForecast) {
  return useQuery(COMBINED_HOTEL_FORECAST, {
    variables: {
      hotelId,
      startDate,
      endDate,
      snapshotDate,
    },
    skip: !hotelId,
  });
}

export function useCreateForecast() {
  return useMutation(CREATE_FORECAST, {
    refetchQueries: ['CombinedHotelForecast'],
  });
}

export function useUpdateForecast() {
  return useMutation(UPDATE_FORECAST);
}
