import { Link, useLocation } from 'react-router-dom';

import { tabItems } from './tab-items';

type TabItem = {
  key: string;
  to: string;
  title: string;
};

const renderTabItems = (tabItems: TabItem[], location: any) => {
  return tabItems.map((tabItem) => {
    let linkClass =
      'inline-block w-full md:w-1/2 lg:w-auto px-4 pb-2 text-gray-700 border-b-2 border-transparent hover:border-gray-900';
    linkClass =
      tabItem.to === location.pathname
        ? `${linkClass} border-gray-900`
        : linkClass;

    return (
      <Link {...tabItem} className={linkClass}>
        {tabItem.title}
      </Link>
    );
  });
};

export const Tabs = () => {
  const location = useLocation();

  return (
    <div className='flex flex-wrap text-sm text-center'>
      {renderTabItems(tabItems, location)}
    </div>
  );
};
