import { Actions, FormField } from '../../types/DataTable';
import {
  State as Checked,
  Action as CheckedActions,
} from '../../reducers/useBulkCheckboxes';
import {
  State as Options,
  Action as OptionsAction,
  TableFilters,
} from '../../reducers/useDataTableOptions';

import DTHeaderBulk from './DTHeaderBulk';
import DTHeaderCell from './DTHeaderCell';

type Props = {
  actions?: Actions;
  checked: Checked;
  dataRange?: string[];
  fields: FormField[];
  options: Options;
  setChecked: React.Dispatch<CheckedActions>;
  setOptions: React.Dispatch<OptionsAction>;
  setTableFilters: React.Dispatch<React.SetStateAction<TableFilters>>;
  tableFilters: TableFilters;
  tableName: string;
};

const DTHeaderRow = ({
  actions,
  checked,
  dataRange,
  fields,
  options,
  setChecked,
  setOptions,
  setTableFilters,
  tableFilters,
  tableName,
}: Props) => {
  const headerWidth = () => {
    switch (fields.length) {
      case 1:
        return 'w-full';
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        return `w-1/${fields.length}`;
      default:
        return 'w-1/12';
    }
  };

  return (
    <thead>
      <tr className='text-xs text-gray-500'>
        <th className='py-5 font-medium w-1/12'>
          <DTHeaderBulk
            actions={actions}
            checked={checked}
            dataRange={dataRange}
            setChecked={setChecked}
            setTableFilters={setTableFilters}
            tableFilters={tableFilters}
            tableName={tableName}
          />
        </th>
        {fields.map((field, idx) => {
          return (
            <DTHeaderCell
              actions={actions}
              checked={checked}
              field={field}
              key={`header-0-cell-${tableName}-${idx}`}
              options={options}
              setOptions={setOptions}
              setTableFilters={setTableFilters}
              tableFilters={tableFilters}
              width={headerWidth()}
            />
          );
        })}
      </tr>
    </thead>
  );
};

export default DTHeaderRow;
