import { ActionItem, ActionItemStatus, Maybe } from '../../../graphql/types';

import { BiEdit } from 'react-icons/bi';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { clsx } from 'clsx';
import { displayDate } from '../../../helpers/dateHelpers';
import { useMeeting } from '../context/meeting-context';
import { useState } from 'react';

type ExpandedProps = {
  data: Maybe<ActionItem>;
  setExpanded: React.Dispatch<
    React.SetStateAction<ActionItem | null | undefined>
  >;
};

const ExpandedActionItem = ({ data, setExpanded }: ExpandedProps) => {
  const { meetingTools, updateActionItem } = useMeeting();
  const [nameEditMode, setnameEditMode] = useState(false);
  const [selectEditMode, setSelectEditMode] = useState(false);
  const [assignedToId, setAssignedTo] = useState(data?.assignedToId as string);
  const [dueDate, setDueDate] = useState(data?.dueDate as string);
  const [name, setName] = useState(data?.name as string);
  const [status, setStatus] = useState(
    data?.status || ActionItemStatus.NotStarted
  );

  const handleSave = async () => {
    if (data && data.id) {
      await updateActionItem({
        variables: {
          updateActionItemId: data.id,
          assignedToId: assignedToId || '',
          dueDate,
          name,
          status,
        },
      });
      setnameEditMode(false);
      setSelectEditMode(false);
    }
  };

  const handleBack = () => {
    setnameEditMode(false);
    setSelectEditMode(false);
    setExpanded(undefined);
  };

  const handleCancel = () => {
    setnameEditMode(false);
    setSelectEditMode(false);
    setName(data?.name as string);
    setAssignedTo(data?.assignedToId as string);
    setDueDate(data?.dueDate as string);
    setStatus(data?.status || ActionItemStatus.NotStarted);
  };

  const handleAssignedToChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectEditMode(true);
    setAssignedTo(e.target.value);
  };

  const handleDueDateChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectEditMode(true);
    setDueDate(e.target.value);
  };

  const handleStatusChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectEditMode(true);
    setStatus(e.target.value as ActionItemStatus);
  };

  const anyEditMode = nameEditMode || selectEditMode;

  return (
    <div className='p-4 my-2 flex flex-row'>
      <button onClick={() => handleBack()}>
        <MdOutlineArrowBackIosNew className='h-full' />
      </button>
      <div className='flex flex-col ml-2 px-2 w-full'>
        <div className='flex justify-between my-1'>
          {nameEditMode ? (
            <input
              type='text'
              value={name}
              className='w-[90%] border border-blue-600 rounded-md text-gray-800 text-sm font-semibold p-1'
              onChange={(e) => setName(e.target.value)}
            />
          ) : (
            <h2 className='text-gray-800 font-semibold text-sm'>
              {data?.name}
            </h2>
          )}
          <button onClick={() => setnameEditMode(!nameEditMode)}>
            <BiEdit />
          </button>
        </div>

        <div className='flex my-1'>
          <label
            htmlFor='assigned-to'
            id='assigned-to'
            className='text-sm text-gray-600'
          >
            Assigned To:{''}
          </label>
          <select
            id='assigned-to'
            name='assigned-to'
            className='text-sm text-gray-800 font-medium'
            value={assignedToId || undefined}
            onChange={(e) => handleAssignedToChange(e)}
          >
            <option value=''>Unassigned</option>
            {meetingTools?.hotelTeams?.map((member) => (
              <option key={member?.id} value={member?.id as string}>
                {member?.name}
              </option>
            ))}
          </select>
        </div>

        <div className='my-1'>
          <label
            htmlFor='due-date'
            id='due-date'
            className='text-sm text-gray-600'
          >
            Due Date:{' '}
          </label>
          <input
            id='due-date'
            type='date'
            value={dueDate}
            className='text-sm font-medium text-gray-800'
            onChange={(e) => handleDueDateChange(e)}
          />
        </div>

        <div className='my-1'>
          <label htmlFor='status' id='status' className='text-sm text-gray-600'>
            Status:
          </label>
          <select
            id='status'
            name='status'
            value={status || undefined}
            className='text-sm text-gray-800 font-medium'
            onChange={(e) => handleStatusChange(e)}
          >
            <option value={ActionItemStatus.NotStarted}>Not Started</option>
            <option value={ActionItemStatus.InProgress}>In Progress</option>
            <option value={ActionItemStatus.Completed}>Completed</option>
          </select>
        </div>

        {data && data.createdById && !nameEditMode && (
          <p className='text-xs text-gray-500 mt-3'>
            Created by: {data.createdById} on{' '}
            {displayDate(data.createdAt as string)}
          </p>
        )}
        {anyEditMode ? (
          <div className='flex w-full justify-center'>
            <div className='flex w-[50%] justify-evenly'>
              <button
                onClick={() => handleSave()}
                className={clsx(
                  'text-blue-700 hover:text-blue-900 text-sm rounded-md m-2',
                  !name.length && 'opacity-50'
                )}
                disabled={!name.length}
              >
                Save
              </button>
              <button
                onClick={() => handleCancel()}
                className='text-red-500 hover:text-red-700 text-sm rounded-md m-2 p-1'
              >
                Cancel
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { ExpandedActionItem };
