import { DATE_SEGMENT_FIELDS } from './fragments';
import { gql } from '@apollo/client';

export const CREATE_RM_REC = gql`
  mutation CreateRmRec($rmRec: CreateRmRecInput!) {
    createRmRec(rmRec: $rmRec) {
      created_at
      hotel_id
      id
      notes
      rate
      status
      stay_date
      ${DATE_SEGMENT_FIELDS}
      updated_at
    }
  }
`;

export const UPSERT_RM_RECS = gql`
  mutation CreateRmRecs($rmRecs: [UpsertRmRecInput]!) {
    rmRecBulkUpsert(rmRecs: $rmRecs) {
      created_at
      hotel_id
      id
      notes
      rate
      status
      stay_date
      ${DATE_SEGMENT_FIELDS}
      updated_at
    }
  }
`;

export const FIND_RM_RECS = gql`
  query FindRmRecs($filters: FindRmRecArgs!) {
    hotelRmRecs(filters: $filters) {
      created_at
      created_by_id
      hidden
      hotel_id
      id
      notes
      rate
      status
      stay_date
      ${DATE_SEGMENT_FIELDS}
      updated_at
    }
  }
`;

export const DATA_TABLE_RM_RECS = gql`
  query DataTableRmRecs($filters: FindRmRecArgs!) {
    hotelRmRecs(filters: $filters) {
      adr
      created_by_id
      current_rate
      hotel_id
      id
      notes
      rate
      sold
      status
      stay_date
    }
  }
`;

export const FIND_RM_RECS_UPLOAD = gql`
  query FindRmRecsUpload($filters: FindRmRecArgs!) {
    hotelRmRecs(filters: $filters) {
      current_rate
      hotel_id
      id
      notes
      rate
      status
      stay_date
    }
  }
`;

export const RATE_UPLOAD = gql`
  mutation RateUpload($inputs: [UploadRatesInput]!, $brandCode: BrandCode!, $userName: String!, $userEmail: String!) {
    rateUpload(inputs: $inputs, brandCode: $brandCode, userName: $userName, userEmail: $userEmail) {
      hotel_id
      id
      notes
      rate
      status
      stay_date
      ${DATE_SEGMENT_FIELDS}
      updated_at
    }
  }
`;

export const CLEAR_COL_RM_RECS = gql`
  mutation ClearColumnRmRecs($filters: FindRmRecArgs!, $column: String!) {
    clearColumnRmRecs(filters: $filters, column: $column) {
      id
      hotel_id
      notes
      rate
      status
      stay_date
    }
  }
`;

export const RESET_RM_RECS = gql`
  mutation ResetRmRecs($filters: FindRmRecArgs!) {
    resetRmRecs(filters: $filters) {
      id
      hotel_id
      notes
      rate
      status
      stay_date
    }
  }
`;

export const UPDATE_RM_REC = gql`
  mutation UpdateRmRec($updateRmRecId: String!, $rmRec: UpdateRmRecInput!) {
    updateRmRec(id: $updateRmRecId, rmRec: $rmRec) {
      created_at
      hotel_id
      id
      notes
      rate
      status
      stay_date
      ${DATE_SEGMENT_FIELDS}
      updated_at
    }
  }
`;
