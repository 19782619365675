import {
  FindRmRecArgs,
  RmRecStatus,
} from '../../../types/__generated__/globalTypes';
import {
  GroupDailyOtQueryResult,
  useGroupDailyOtQuery,
} from '../gql/_gen_/group-ot.gql';
import { MutationTuple, QueryResult, QueryTuple } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import {
  useFindRmRecs,
  useFindRmRecsUpload,
  useRateUpload,
} from '../../../hooks/useRmRecs';

import { RateQueryParams } from '../../../graphql/types';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import { today } from '../../../helpers/dateHelpers';
import { useBestrev } from '../../../hooks/useBestrev';
import { useCombinedForecasts } from '../../../hooks/useForecasts';
import { useCurrentRate } from './use-current-rate';
import { useDailyHotelEvents } from './use-daily-events';
import { useDailyHotelNotes } from './use-daily-notes';
import { useGetHotelRmDataQuery } from '../gql/_gen_/rm-data-pg.gql';
import { useLiveDataQuery } from '../gql/_gen_/live-data.gql';
import { useRateShops } from '../../../hooks/useRateShops';
import { useRooms } from '../../../hooks/useRooms';

export type HookFuncs = {
  bestrev: QueryResult<any, any>;
  currentRate: QueryResult<any, any>;
  dailyNotes: QueryResult<any, any>;
  events: QueryResult<any, any>;
  forecasts: QueryResult<any, any>;
  groupsDaily: GroupDailyOtQueryResult;
  liveData: QueryResult<any, any>;
  rmData: QueryResult<any, any>;
  rateShops: QueryResult<any, any>;
  rateUpload: {
    find: QueryTuple<any, any>;
    send: MutationTuple<any, any>;
  };
  rmRecs: QueryResult<any, any>;
  rooms: QueryResult<any, any>;
};

type UseTableDataParams = {
  brandCode: string;
  endDate: string;
  filters: FindRmRecArgs;
  hotelId: number;
  rateParams: RateQueryParams;
  snapshotDate: string;
  startDate: string;
  useCache?: boolean;
  yearsCompare: number;
};

export function useDataHooks(params: UseTableDataParams) {
  const {
    brandCode,
    endDate,
    filters,
    hotelId,
    rateParams,
    snapshotDate,
    startDate,
    useCache,
    yearsCompare,
  } = params;

  const callback = useCallback(
    () =>
      debounce(() => ReactTooltip.rebuild(), 500, {
        leading: false,
        trailing: true,
      }),
    []
  );

  //#region Params
  const bestrevParams = useMemo(() => {
    return {
      brandCode,
      callback,
      useCache,
    };
  }, [brandCode, callback, useCache]);

  const filterParams = useMemo(() => {
    return {
      filters: {
        hotelId,
        startDate,
        endDate,
      },
    };
  }, [hotelId, startDate, endDate]);

  const forecastParams = useMemo(() => {
    return {
      hotelId,
      startDate,
      endDate,
      snapshotDate,
    };
  }, [hotelId, startDate, endDate, snapshotDate]);

  const roomParams = useMemo(() => {
    return {
      brandCode,
      callback,
      snapshotDate,
      useCache,
    };
  }, [brandCode, callback, snapshotDate, useCache]);

  const rateHookParams = useMemo(() => {
    return {
      brandCode,
      callback,
      params: rateParams,
    };
  }, [brandCode, callback, rateParams]);

  const recsAcceptedParams = useMemo(() => {
    return {
      ...filters,
      status: RmRecStatus.ACCEPTED,
      hidden: false,
      startDate: today(),
      endDate: undefined,
    };
  }, [filters]);

  //#endregion Params

  const hookFuncs: HookFuncs = {
    bestrev: useBestrev(bestrevParams),
    currentRate: useCurrentRate({
      brandCode,
      snapshotDate,
      useCache,
      yearsCompare,
      callback,
    }),
    dailyNotes: useDailyHotelNotes(filterParams),
    events: useDailyHotelEvents(filterParams),
    forecasts: useCombinedForecasts(forecastParams),
    groupsDaily: useGroupDailyOtQuery({
      skip: !brandCode || !useCache,
      variables: {
        brandCode,
        useCache,
      },
      onCompleted: () => callback(),
    }),
    liveData: useLiveDataQuery({
      skip: !brandCode,
      variables: {
        brandCode,
        snapshotDate,
        startDate,
        endDate,
      },
    }),
    rmData: useGetHotelRmDataQuery({
      skip: !brandCode || !snapshotDate,
      variables: {
        args: {
          brandCode,
          endDate,
          snapshotDate,
          startDate,
          yearsCompare,
        },
      },
    }),
    rateShops: useRateShops(rateHookParams),
    rateUpload: {
      find: useFindRmRecsUpload({ filters: recsAcceptedParams }),
      send: useRateUpload(),
    },
    rmRecs: useFindRmRecs({ filters }),
    rooms: useRooms(roomParams),
  };

  return hookFuncs;
}
