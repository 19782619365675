export function getCellColor(num: number, status: string) {
  if (status === undefined) {
    return;
  }

  if (status.includes('CLOSE') && !status.includes('SOFT')) {
    return `bg-[#ffb3b3]`;
  } else if (status.includes('SOLD_OUT')) {
    return `bg-[#17a2b8]`;
  } else if (status.includes(`CTA`)) {
    return `bg-[#ffb3b3]`;
  } else if (status.includes(`N/A`)) {
    return undefined;
  } else if (status.includes(`ZERO`) && !status.includes(`N/A`)) {
    return `bg-[#17a2b8]`;
  } else if (status.includes(`MLOS`)) {
    return `bg-[#ffc107]`;
  } else if (status.includes(`SOFT`)) {
    return `bg-[#ffc107]`;
  }

  if (num === 0 && status === 'OPEN') {
    return null;
  }
}
