import { CURRENT_RATE } from '../gql/gql-current-rate';
import { useQuery } from '@apollo/client';

export type UseHookArgs = {
  brandCode: string;
  snapshotDate: string;
  yearsCompare?: number;
  useCache?: boolean;
  callback: () => void;
};

export function useCurrentRate(args: UseHookArgs) {
  return useQuery(CURRENT_RATE, {
    variables: {
      args,
    },
    skip: !args.brandCode || !args.snapshotDate,
    onCompleted: () => args.callback(),
  });
}
