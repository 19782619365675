import {
  ActionTypes,
  SortDir,
  TableFilters,
  useDataTableOptions,
} from '../../../reducers/useDataTableOptions';
import { orderBy, pick } from 'lodash';
import { useEffect, useState } from 'react';

import { DataRow } from '../../../types/DataTable';
import { Tracking } from '../../../graphql/types';
import { dow } from '../../../helpers/dateHelpers';
import { useHotelTracking } from '../../tracking/hooks/use-get-tracking';

export const useTrackingDataHotel = (headers: string[], brandCode?: string) => {
  const defaultOptions = {
    sort: { key: 'dateAdded', dir: SortDir.DESC },
  };

  const PER_PAGE = 10;
  const limit = 500;
  const [data, setData] = useState<DataRow[]>();
  const [options, setOptions] = useDataTableOptions(defaultOptions);
  const [page, setPage] = useState<number>(0);
  const [tableFilters, setTableFilters] = useState<TableFilters>({});
  const [totalItems, setTotalItems] = useState(0);
  const [totalFiltered, setTotalFiltered] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const goToPage = (targetPage: number) => {
    if (targetPage <= totalPages - 1 && targetPage >= 0) {
      setPage(targetPage);
    }
  };

  const filterItems = (item: Tracking) => {
    return (
      filterDays(item, 'dateAdded') &&
      filterDays(item, 'meetingDate') &&
      filterString(item, 'action') &&
      filterString(item, 'meetingCategory') &&
      filterString(item, 'meetingId') &&
      filterString(item, 'brandCode') &&
      filterString(item, 'event') &&
      filterString(item, 'ipAddr') &&
      filterString(item, 'userName')
    );
  };

  const filterDays = (item: Tracking, attr: keyof Tracking) => {
    const filter = tableFilters[attr];
    if (filter) {
      const dowKey = dow(item[attr]).toLowerCase() as keyof typeof filter;
      return filter[dowKey] === true;
    }
    return true;
  };

  const filterString = (item: Tracking, attr: keyof Tracking) => {
    const filter = tableFilters[attr];
    if (filter) {
      return String(item[attr])
        .toLowerCase()
        .includes(String(filter).toLowerCase());
    }
    return true;
  };

  const resetTable = () => {
    setPage(0);
    setTableFilters({});
    setOptions({
      type: ActionTypes.SORT,
      payload: defaultOptions,
    });
  };

  function pageUp() {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  }

  function pageDown() {
    if (page >= 1) {
      setPage(page - 1);
    }
  }

  const { data: trackingData, loading } = useHotelTracking(brandCode, limit);

  useEffect(() => {
    resetTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCode]);

  useEffect(() => {
    if (!loading && trackingData && trackingData.trackingByHotel) {
      const trackData = trackingData.trackingByHotel;
      const orderedData = orderBy(
        trackData,
        [options.sort.key],
        [options.sort.dir]
      );
      const filteredData = orderedData.filter((item) =>
        filterItems(item as Tracking)
      );
      const rows = filteredData.map((item) => {
        return pick(item, ['id', ...headers]);
      });
      setData(
        rows.slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE) as DataRow[]
      );
      setTotalPages(Math.round(rows.length / PER_PAGE));
      setTotalItems(trackData.length || 0);
      setTotalFiltered(filteredData.length || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingData, loading, options.sort, page, tableFilters]);

  return {
    data,
    goToPage,
    loading,
    options,
    page,
    pageDown,
    pageUp,
    setOptions,
    setTableFilters,
    tableFilters,
    totalFiltered,
    totalItems,
    totalPages,
  };
};
