import { OPTIONS } from './status-options';
import { Status } from '../../types/status';
import { clsx } from 'clsx';

interface StatusDisplayProps {
  /**
   * The status to display. SAVING, SAVED, or UNSAVED.
   */
  status: Status;
}

export const StatusDisplay = ({ status }: StatusDisplayProps) => {
  const { color, extraClass, icon, iconClass, label } = OPTIONS[status];
  const Icon = icon;

  return (
    <div
      className={clsx(
        `flex items-center ${color} text-sm px-[5px] py-[3px]`,
        extraClass && extraClass
      )}
    >
      {label} <Icon className={clsx('ml-1', iconClass && iconClass)} />
    </div>
  );
};
