import {
  DailyGroupData,
  DailyRates,
  DailyRoomRate,
  Event,
  Forecast,
  Meeting,
  MeetingService,
  MonthlyBudget,
  RmData,
  RmRec,
  ShoppedHotel,
} from '../../../graphql/types';
import { DateHelpers, displayDate, today } from '../../../helpers/dateHelpers';
import DonutWidget, { Props as DonutProps } from '../../../reports/DonutWidget';
import HeatMap, { Props as HeatMapProps } from '../../../reports/HeatMap';
import {
  MonthlyPerformanceTable,
  MonthlyTooltip,
} from './monthly-performance-table';
import { OktaUserState, State as User } from '../../../context/userContext';

import BtnResetRecs from '../../../components/toolbar/BtnResetRecs';
import { DetailTable } from './detail-table';
import DownloadCSVButton from './btn-download-csv';
import { Editor } from '../../notes/editor';
import { EventsTable } from './events-table';
import { FaInfoCircle } from 'react-icons/fa';
import { FiveDayRates } from './sections/five-day-rates';
import { GroupsTable } from './groups-table';
import { State as Hotel } from '../../../context/hotelContext';
import { LastNightSummary } from './sections/last-night-summary';
import MeetingNotes from '../../../components/meeting-notes';
import { MeetingPanels } from './meeting-panels';
import MeetingStats from './meeting-stats';
import MonthlyRoundUp from './monthly-round-up';
import { Navigation } from './navigation';
import PropertyHeader from '../../../components/PropertyHeader';
import ReactTooltip from 'react-tooltip';
import { ReportDataType } from '../context/report-data-context';
import { RmRecsTable } from './rm-recs-table';
import { SectionContainer } from './section-container';
import { TooltipLiveData } from './tooltip-live-data';
import { classNames } from '../../../helpers/tailwindHelper';
import { tableCols } from './data/detail-table-cols';
import { useFlags } from '../../../context/feature-flags';
import { useIsShared } from '../hooks/use-report-location';
import { useShowForecast } from '../../../hooks/useShowForecast';
import { useState } from 'react';
import { useTable } from '../../overview-table/hooks/use-table';

export type ReportProps = {
  data: {
    data: ReportDataType;
    hotel: Hotel;
    meetingTools?: MeetingService;
    user: User;
    oktaUser?: OktaUserState;
  };
  dataLoading: boolean;
  meeting?: Meeting;
  meetingLoading: boolean;
  setMeeting?: (meeting: Meeting) => void;
};

export default function Report({
  data,
  dataLoading,
  meeting,
  meetingLoading,
  setMeeting,
}: ReportProps) {
  const { flags } = useFlags();
  const { showForecast } = useShowForecast(data.hotel);
  const { isShared } = useIsShared();
  const {
    dataEvents,
    dataRateShop,
    dataReport,
    dataRmRecs,
    loadingEvents,
    loadingRateShop,
    loadingReport,
    loadingRmRecs,
  } = data.data;

  const { hotel, user } = data;
  const [monthlyReportVersion, setMonthlyReportVersion] = useState('current');
  const [detailTable, setDetailTable] = useState('detail');

  // We need to pull these from the flag check directly because the user context
  // is not always available when the report is rendered. I.e. shared reports
  const isManager = flags?.['admin-menu'];
  const showNewNotes = flags?.['meeting-notes-upgrade'];

  const donuts = [
    {
      name: 'Tonight',
      content: ({ data, loading, name }: DonutProps) => {
        return <DonutWidget data={data} loading={loading} name={name} />;
      },
      chartDate: displayDate(meeting?.meetingDate || today()),
    },
    {
      name: 'Last Night',
      content: ({ data, loading, name }: DonutProps) => {
        return <DonutWidget data={data} loading={loading} name={name} />;
      },
      chartDate: displayDate(
        DateHelpers.subtract(
          meeting?.meetingDate || DateHelpers.today(),
          'days',
          1
        )
      ),
    },
  ];

  const heatMaps = [
    {
      name: 'Occupancy %, Includes Group Blocked',
      content: ({ data, loading, name }: HeatMapProps) => {
        return (
          <HeatMap
            data={data}
            loading={loading}
            metric='occ_rc'
            name={name}
            reportDate={meeting?.meetingDate || DateHelpers.today()}
          />
        );
      },
      chartDate: displayDate(meeting?.meetingDate || today()),
    },
    {
      name: 'Δ 3 Day Delta Sold',
      content: ({ data, loading, name }: HeatMapProps) => {
        return (
          <HeatMap
            data={data}
            loading={loading}
            metric='delta_3day'
            name={name}
            reportDate={meeting?.meetingDate || DateHelpers.today()}
          />
        );
      },
      chartDate: displayDate(meeting?.meetingDate || today()),
    },
  ];

  const detailCols = showForecast
    ? tableCols
    : tableCols.filter(
        (c) =>
          !['forecast_sold_1', 'forecast_adr_1', 'forecast_revenue_1'].includes(
            c.key
          )
      );

  const { headers, rows } = useTable({
    customHeaders: null,
    data: {
      rmData: dataReport?.rmData,
      currentRateTable: dataReport?.currentRateTable,
      groupDaily: dataReport?.groupDaily,
      liveData: dataReport?.liveData,
      bestRevData: dataReport?.bestrevData,
      forecastData: dataReport?.monthlyRoundUpForecast,
    },
    dateSegment: 'day',
    startDate: DateHelpers.subtract(
      meeting?.meetingDate || DateHelpers.today(),
      'month',
      1
    ),
    endDate: DateHelpers.subtract(
      meeting?.meetingDate || DateHelpers.today(),
      'days',
      -365
    ),
    detailCols,
  });

  const widgetData = {
    Tonight: {
      data: dataReport?.rmData?.find(
        (rm) => rm?.stay_date === (meeting?.meetingDate || DateHelpers.today())
      ),
      loading: loadingReport,
    },
    'Last Night': {
      data: dataReport?.rmData?.find(
        (rm) =>
          rm?.stay_date ===
          DateHelpers.subtract(
            meeting?.meetingDate || DateHelpers.today(),
            'days',
            1
          )
      ),
      loading: loadingReport,
    },
    'Occupancy %, Includes Group Blocked': {
      data: dataReport?.rmData?.filter((rm) =>
        DateHelpers.betweenOrEqual(
          rm?.stay_date,
          DateHelpers.firstOfMonth({
            date: meeting?.meetingDate || DateHelpers.today(),
          }),
          DateHelpers.monthsOut({
            date: meeting?.meetingDate || DateHelpers.today(),
            months: 11,
          })
        )
      ),
      loading: loadingReport,
    },
    'Δ 3 Day Delta Sold': {
      data: dataReport?.rmData?.filter((rm) =>
        DateHelpers.betweenOrEqual(
          rm?.stay_date,
          DateHelpers.firstOfMonth({
            date: meeting?.meetingDate || DateHelpers.today(),
          }),
          DateHelpers.monthsOut({
            date: meeting?.meetingDate || DateHelpers.today(),
            months: 11,
          })
        )
      ),
      loading: loadingReport,
    },
  };

  function handleVersionChange(version: string) {
    if (monthlyReportVersion !== version) {
      setMonthlyReportVersion(version);
    }
  }

  function handleDetailTableChange(table: string) {
    if (detailTable !== table) {
      setDetailTable(table);
    }
  }

  const loadingObject = {
    meetingLoading,
    Events: loadingEvents,
    Groups: loadingReport,
    'Heat Map': loadingReport,
    'Hotel Budget': loadingReport,
    'Last Night Summary': loadingReport,
    'Monthly Performance': loadingReport,
    'Sold, ADR, Rev': loadingReport,
    'Detail Table': loadingReport,
    'RM Recs': loadingRmRecs,
    Tonight: loadingReport,
    'Last Night': loadingReport,
  };

  const isLoading = Object.values(loadingObject).some((value) => value);

  return (
    <>
      <div className='min-h-full'>
        {/* Navigation */}
        <Navigation />

        {/* Main */}
        <main className='-mt-24 pb-8'>
          <div className='mx-auto px-4 sm:px-6 lg:px-12'>
            {/* Main 3 column grid */}
            <div className='grid grid-cols-1 gap-4 items-start'>
              {/* Left column */}
              <div className='grid grid-cols-1 gap-6 lg:col-span-2'>
                {/* Report Header */}
                <section
                  id='meeting-header'
                  aria-labelledby='header-overview-title'
                >
                  <div className='rounded-lg bg-white shadow'>
                    <h2 className='sr-only' id='header-overview-title'>
                      Property Header
                    </h2>
                    <div className='bg-white p-4 rounded-lg'>
                      <PropertyHeader
                        hotel={hotel}
                        meeting={meeting}
                        page='reports'
                        updating={isLoading || dataLoading}
                      />
                    </div>

                    {/* Meeting Stats */}
                    <MeetingStats
                      loading={loadingObject}
                      meeting={meeting}
                      user={user}
                      isAdmin={isManager}
                    />
                  </div>
                </section>
                {/* Report Widgets */}
                {meeting?.category === 'WEEKLY' ? (
                  <MeetingPanels
                    brandCode={hotel?.brand_code}
                    meeting={meeting}
                    meetingTools={data.meetingTools}
                    setMeeting={setMeeting}
                    showNewNotes={showNewNotes}
                    userId={user?.id as string | undefined}
                  />
                ) : (
                  <SectionContainer
                    anchorId='meeting-notes'
                    title='Meeting Notes'
                  >
                    {showNewNotes ? (
                      <MeetingNotes
                        brandCode={hotel?.brand_code}
                        meeting={meeting}
                        parentName='report'
                        setMeeting={setMeeting}
                        userId={user?.id as string | undefined}
                      />
                    ) : (
                      <Editor
                        brandCode={hotel?.brand_code}
                        meeting={meeting}
                        parentName='report'
                        setMeeting={setMeeting}
                        userId={user?.id as string | undefined}
                      />
                    )}
                  </SectionContainer>
                )}

                {/* Actions panel and Last Night Summary */}
                <section id='dashboard' aria-labelledby='dashboard-title'>
                  <div className='flex flex-wrap justify-between items-center p-0 rounded-lg shadow divide-x divide-y divide-gray-200'>
                    {/* Left Donut */}
                    <div className='w-full sm:w-1/4'>
                      <SectionContainer
                        title={`${donuts[1].name}, ${donuts[1].chartDate}`}
                        key={`donuts-0`}
                      >
                        <div
                          key={donuts[1].name}
                          className='relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500'
                        >
                          {donuts[1].content({
                            data: widgetData[
                              donuts[1].name as keyof typeof widgetData
                            ].data as RmData,
                            loading:
                              widgetData[
                                donuts[1].name as keyof typeof widgetData
                              ].loading,
                            name: donuts[1].name,
                          })}
                        </div>
                      </SectionContainer>
                    </div>

                    {/* Last Night Summary */}
                    <div className='w-full sm:w-1/2'>
                      <LastNightSummary
                        dataReport={dataReport}
                        loadingReport={loadingReport}
                        meeting={meeting}
                      />
                    </div>

                    {/* Right Donut */}
                    <div className='w-full sm:w-1/4'>
                      <SectionContainer
                        title={`${donuts[0].name}, ${donuts[0].chartDate}`}
                        key={`donuts-1`}
                      >
                        <div
                          key={donuts[0].name}
                          className='relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500'
                        >
                          {donuts[0].content({
                            data: widgetData[
                              donuts[0].name as keyof typeof widgetData
                            ].data as RmData,
                            loading:
                              widgetData[
                                donuts[0].name as keyof typeof widgetData
                              ].loading,
                            name: donuts[0].name,
                          })}
                        </div>
                      </SectionContainer>
                    </div>
                  </div>
                </section>

                {/* 5-day Rate Widget */}
                <FiveDayRates
                  dataReport={dataReport}
                  loadingReport={loadingReport}
                  dataRateShop={dataRateShop}
                  loadingRateShop={loadingRateShop}
                  startDate={meeting?.meetingDate || DateHelpers.today()}
                />

                <section id='charts' aria-labelledby='quick-links-title'>
                  <div className='rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px'>
                    {heatMaps.map((action, actionIdx) => (
                      <SectionContainer
                        title={`${action.name}, as of ${action.chartDate}`}
                        key={`heatmap-${actionIdx}`}
                      >
                        <div
                          key={action.name}
                          className={classNames(
                            actionIdx === 0
                              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                              : '',
                            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                            actionIdx === donuts.length - 2
                              ? 'sm:rounded-bl-lg'
                              : '',
                            actionIdx === donuts.length - 1
                              ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                              : '',
                            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500'
                          )}
                        >
                          {action.content({
                            data: widgetData[
                              action.name as keyof typeof widgetData
                            ].data as RmData[],
                            loading:
                              widgetData[action.name as keyof typeof widgetData]
                                .loading,
                            name: action.name,
                          })}
                        </div>
                      </SectionContainer>
                    ))}
                  </div>
                </section>

                {/* Monthly Round-Up */}
                <div
                  id='roundup'
                  className='rounded-lg grid-cols-1 bg-white overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:gap-px'
                >
                  <div className='overflow-x-auto'>
                    <MonthlyRoundUp
                      data={
                        dataReport?.rmData?.filter((rm) =>
                          DateHelpers.betweenOrEqual(
                            rm?.stay_date,
                            DateHelpers.firstOfMonth({
                              date: meeting?.meetingDate || DateHelpers.today(),
                            }),
                            DateHelpers.endOfMonth({
                              date: meeting?.meetingDate || DateHelpers.today(),
                              daysOut: 90,
                            })
                          )
                        ) as RmData[]
                      }
                      loading={loadingReport}
                    />
                  </div>
                </div>
                {/* RM Recs Table */}
                <SectionContainer
                  anchorId='rm-recs'
                  title='Recommendations'
                  toolbar={
                    !isShared ? (
                      !meeting ||
                      meeting?.meetingDate === DateHelpers.today() ? (
                        <BtnResetRecs
                          btnClass='overflow-visible relative inline-flex items-center px-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 focus:z-10'
                          btnIconClass='h-3 w-3'
                          filters={{
                            hotelId: hotel?.hotel_id || hotel?.brand_code,
                          }}
                        />
                      ) : (
                        <div className='relative inline-flex items-center'>
                          <BtnResetRecs
                            btnClass='overflow-visible relative inline-flex items-center px-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 focus:z-10'
                            btnIconClass='h-3 w-3'
                            filters={{
                              hotelId: hotel?.hotel_id || hotel?.brand_code,
                            }}
                          />
                          <TooltipLiveData />
                        </div>
                      )
                    ) : null
                  }
                >
                  {loadingRmRecs ? (
                    <span>Loading...</span>
                  ) : (
                    <RmRecsTable
                      data={dataRmRecs?.hotelRmRecs as RmRec[]}
                      reportData={dataReport?.rmData as RmData[]}
                      currentRateData={
                        dataReport?.currentRateTable as DailyRoomRate[]
                      }
                    />
                  )}
                </SectionContainer>
                {/* Events Table */}
                <SectionContainer
                  anchorId='events'
                  title='Events'
                  toolbar={
                    !isShared ? (
                      !meeting ||
                      meeting?.meetingDate === DateHelpers.today() ? null : (
                        <div className='relative inline-flex items-center'>
                          <TooltipLiveData />
                        </div>
                      )
                    ) : null
                  }
                >
                  {loadingReport ? (
                    <span>Loading...</span>
                  ) : (
                    <EventsTable data={dataEvents?.eventWidget as Event[]} />
                  )}
                </SectionContainer>
                {/* Upcoming Groups */}
                <SectionContainer
                  anchorId='groups'
                  title='Upcoming Groups'
                  toolbar={
                    !isShared ? (
                      !meeting ||
                      meeting?.meetingDate === DateHelpers.today() ? null : (
                        <div className='relative inline-flex items-center'>
                          <TooltipLiveData />
                        </div>
                      )
                    ) : null
                  }
                >
                  {loadingReport ? (
                    <span>Loading...</span>
                  ) : (
                    <GroupsTable
                      data={dataReport?.groupDaily as DailyGroupData[]}
                    />
                  )}
                </SectionContainer>
                {/* Detail Table */}
                <SectionContainer
                  anchorId='detail-table'
                  title='Detail Tables'
                  toolbar={<DownloadCSVButton data={rows} headers={headers} />}
                >
                  {loadingReport ? (
                    <span>Loading...</span>
                  ) : (
                    <DetailTable
                      brandCode={hotel?.brand_code}
                      rows={rows}
                      headers={headers}
                      tableVersion={detailTable}
                      handleTableChange={handleDetailTableChange}
                      rateData={{
                        dailyRates: dataRateShop?.dailyRates as DailyRates[],
                        compSet: dataRateShop?.compSet as ShoppedHotel[],
                      }}
                      todaysDate={meeting?.meetingDate || DateHelpers.today()}
                    />
                  )}
                </SectionContainer>

                {/* Monthly Performance */}
                <SectionContainer
                  anchorId='monthly'
                  title='Monthly Performance'
                  toolbar={
                    <div>
                      <FaInfoCircle
                        className='text-blue-500'
                        data-tip
                        data-for='monthly-data-disclaimer'
                      />
                      <MonthlyTooltip />
                    </div>
                  }
                >
                  {loadingReport ? (
                    <span>Loading...</span>
                  ) : (
                    <MonthlyPerformanceTable
                      hotel={data.hotel}
                      data={
                        dataReport?.rmData?.filter((rm) =>
                          DateHelpers.betweenOrEqual(
                            rm?.stay_date,
                            DateHelpers.firstOfYear(
                              meeting?.meetingDate || DateHelpers.today()
                            ),
                            DateHelpers.endOfYear(
                              meeting?.meetingDate || DateHelpers.today()
                            )
                          )
                        ) as RmData[]
                      }
                      dataRolling={
                        dataReport?.rmData?.filter((rm) =>
                          DateHelpers.betweenOrEqual(
                            rm?.stay_date,
                            DateHelpers.firstOfMonth({
                              date: meeting?.meetingDate || DateHelpers.today(),
                            }),
                            DateHelpers.monthsOut({
                              date: meeting?.meetingDate || DateHelpers.today(),
                              months: 11,
                            })
                          )
                        ) as RmData[]
                      }
                      forecastData={
                        dataReport?.monthlyRoundUpForecast as Forecast[]
                      }
                      forecastRolling={
                        dataReport?.monthlyRoundUpForecast as Forecast[]
                      }
                      budgetData={
                        dataReport?.hotelMonthlyBudgets?.filter((b) =>
                          DateHelpers.betweenOrEqual(
                            b?.stay_date,
                            DateHelpers.firstOfYear(
                              meeting?.meetingDate || DateHelpers.today()
                            ),
                            DateHelpers.endOfYear(
                              meeting?.meetingDate || DateHelpers.today()
                            )
                          )
                        ) as MonthlyBudget[]
                      }
                      budgetRolling={
                        dataReport?.hotelMonthlyBudgets?.filter((b) =>
                          DateHelpers.betweenOrEqual(
                            b?.stay_date,
                            DateHelpers.firstOfMonth({
                              date: meeting?.meetingDate || DateHelpers.today(),
                            }),
                            DateHelpers.monthsOut({
                              date: meeting?.meetingDate || DateHelpers.today(),
                              months: 11,
                            })
                          )
                        ) as MonthlyBudget[]
                      }
                      handleVersionChange={handleVersionChange}
                      loading={loadingReport}
                      reportVersion={monthlyReportVersion}
                    />
                  )}
                </SectionContainer>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
            <div className='border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left'>
              <span className='block sm:inline'>
                &copy; {DateHelpers.getYear(DateHelpers.today())} Best Western
                Hotels.
              </span>{' '}
              <span className='block sm:inline'>All rights reserved.</span>
            </div>
          </div>
        </footer>
        <ReactTooltip />
      </div>
    </>
  );
}
