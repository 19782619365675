import { DayFilters } from '../../reducers/useDataTableOptions';
import { FaSave } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import _ from 'lodash';
import { useEditable } from '../../hooks/useEditable';

type Props = {
  initialValue?: DayFilters;
  label: string;
  onChange: (value: string | number | DayFilters) => void;
};

export const defaultDays: DayFilters = {
  sun: true,
  mon: true,
  tue: true,
  wed: true,
  thu: true,
  fri: true,
  sat: true,
};

const FilterDays = ({ initialValue = defaultDays, label, onChange }: Props) => {
  const { edit, handleChange, setEdit, setValue, value } = useEditable({
    initialValue,
    onChange,
  });

  const hideInput = edit ? '' : 'hidden';

  const spanClass = !_.isEqual(value, defaultDays) ? 'text-blue-800' : '';

  const handleClear = () => {
    setEdit(false);
    setValue(defaultDays);
    onChange(defaultDays);
  };

  const handleSave = () => {
    setEdit(false);
    onChange(value);
  };

  const switchWeekdays = () => {
    const values = value as DayFilters;
    const newValues = {
      ...values,
      sun: !values.sun,
      mon: !values.mon,
      tue: !values.tue,
      wed: !values.wed,
      thu: !values.thu,
    };
    setValue(newValues);
  };

  const switchWeekends = () => {
    const values = value as DayFilters;
    const newValues = {
      ...values,
      fri: !values.fri,
      sat: !values.sat,
    };
    setValue(newValues);
  };

  return (
    <>
      {edit ? (
        <fieldset className={`flex flex-row ${hideInput}`}>
          <legend className='sr-only'>Day of Week Checkboxes</legend>
          <span className='relative z-0 inline-flex shadow-sm rounded-md'>
            <button
              type='button'
              onClick={switchWeekdays}
              className='inline-flex items-center text-xs px-0 py-0 border border-transparent shadow-sm leading-4 font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              WD
            </button>
            <button
              type='button'
              onClick={switchWeekends}
              className='inline-flex items-center text-xs mr-1 px-0 py-0 border border-transparent shadow-sm leading-4 font-medium rounded-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
            >
              WE
            </button>
            {_.map(value as DayFilters, (val, key) => {
              const isChecked = val ? 'bg-blue-200' : 'bg-white';
              return (
                <div key={`days-${key}`}>
                  <input
                    id={key}
                    name={key}
                    type='checkbox'
                    checked={val}
                    onChange={handleChange}
                    className='hidden'
                  />
                  <label
                    htmlFor={key}
                    className={`${isChecked} relative inline-flex items-center px-1 py-0 rounded-sm border border-gray-300 text-xs text-gray-700 `}
                  >
                    {key.substring(0, 1).toUpperCase()}
                  </label>
                </div>
              );
            })}
            <button
              type='button'
              onClick={handleSave}
              className='inline-flex items-center ml-1 px-1 py-0 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
            >
              <FaSave className='h-3 w-3' />
            </button>
            <button
              type='button'
              onClick={handleClear}
              className='inline-flex items-center ml-1 px-1 py-0 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
            >
              <MdClear className='h-3 w-3' />
            </button>
          </span>
        </fieldset>
      ) : (
        <span className={spanClass} onClick={() => setEdit(true)}>
          {label}
        </span>
      )}
    </>
  );
};

export default FilterDays;
