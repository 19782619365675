/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { AgendaItemFieldsFragmentDoc } from './agenda.fragment.gql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHotelAgendaQueryVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  dueDate?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetHotelAgendaQuery = { __typename?: 'Query', getHotelAgenda?: Array<{ __typename?: 'AgendaItem', brandCode?: string | null, completed?: boolean | null, createdAt?: string | null, createdById?: string | null, id?: string | null, name?: string | null, notes?: string | null, updatedAt?: string | null, isDefault?: boolean | null, order?: number | null } | null> | null };

export type CreateHotelAgendaMutationVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  createdById: Types.Scalars['String'];
}>;


export type CreateHotelAgendaMutation = { __typename?: 'Mutation', createHotelAgenda?: Array<{ __typename?: 'AgendaItem', brandCode?: string | null, completed?: boolean | null, createdAt?: string | null, createdById?: string | null, id?: string | null, name?: string | null, notes?: string | null, updatedAt?: string | null, isDefault?: boolean | null, order?: number | null } | null> | null };

export type AddAgendaItemMutationVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  createdById: Types.Scalars['String'];
  name: Types.Scalars['String'];
  completed?: Types.InputMaybe<Types.Scalars['Boolean']>;
  notes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AddAgendaItemMutation = { __typename?: 'Mutation', addAgendaItem?: { __typename?: 'AgendaItem', brandCode?: string | null, completed?: boolean | null, createdAt?: string | null, createdById?: string | null, id?: string | null, name?: string | null, notes?: string | null, updatedAt?: string | null, isDefault?: boolean | null, order?: number | null } | null };

export type RemoveAgendaItemMutationVariables = Types.Exact<{
  removeAgendaItemId: Types.Scalars['String'];
}>;


export type RemoveAgendaItemMutation = { __typename?: 'Mutation', removeAgendaItem?: boolean | null };

export type UpdateAgendaItemMutationVariables = Types.Exact<{
  updateAgendaItemId: Types.Scalars['String'];
  completed?: Types.InputMaybe<Types.Scalars['Boolean']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  notes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateAgendaItemMutation = { __typename?: 'Mutation', updateAgendaItem?: { __typename?: 'AgendaItem', brandCode?: string | null, completed?: boolean | null, createdAt?: string | null, createdById?: string | null, id?: string | null, name?: string | null, notes?: string | null, updatedAt?: string | null, isDefault?: boolean | null, order?: number | null } | null };


export const GetHotelAgendaDocument = gql`
    query GetHotelAgenda($brandCode: String!, $dueDate: String, $limit: Int) {
  getHotelAgenda(brandCode: $brandCode, dueDate: $dueDate, limit: $limit) {
    ...AgendaItemFields
  }
}
    ${AgendaItemFieldsFragmentDoc}`;

/**
 * __useGetHotelAgendaQuery__
 *
 * To run a query within a React component, call `useGetHotelAgendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelAgendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelAgendaQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      dueDate: // value for 'dueDate'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetHotelAgendaQuery(baseOptions: Apollo.QueryHookOptions<GetHotelAgendaQuery, GetHotelAgendaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHotelAgendaQuery, GetHotelAgendaQueryVariables>(GetHotelAgendaDocument, options);
      }
export function useGetHotelAgendaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHotelAgendaQuery, GetHotelAgendaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHotelAgendaQuery, GetHotelAgendaQueryVariables>(GetHotelAgendaDocument, options);
        }
export type GetHotelAgendaQueryHookResult = ReturnType<typeof useGetHotelAgendaQuery>;
export type GetHotelAgendaLazyQueryHookResult = ReturnType<typeof useGetHotelAgendaLazyQuery>;
export type GetHotelAgendaQueryResult = Apollo.QueryResult<GetHotelAgendaQuery, GetHotelAgendaQueryVariables>;
export const CreateHotelAgendaDocument = gql`
    mutation CreateHotelAgenda($brandCode: String!, $createdById: String!) {
  createHotelAgenda(brandCode: $brandCode, createdById: $createdById) {
    ...AgendaItemFields
  }
}
    ${AgendaItemFieldsFragmentDoc}`;
export type CreateHotelAgendaMutationFn = Apollo.MutationFunction<CreateHotelAgendaMutation, CreateHotelAgendaMutationVariables>;

/**
 * __useCreateHotelAgendaMutation__
 *
 * To run a mutation, you first call `useCreateHotelAgendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHotelAgendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHotelAgendaMutation, { data, loading, error }] = useCreateHotelAgendaMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      createdById: // value for 'createdById'
 *   },
 * });
 */
export function useCreateHotelAgendaMutation(baseOptions?: Apollo.MutationHookOptions<CreateHotelAgendaMutation, CreateHotelAgendaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHotelAgendaMutation, CreateHotelAgendaMutationVariables>(CreateHotelAgendaDocument, options);
      }
export type CreateHotelAgendaMutationHookResult = ReturnType<typeof useCreateHotelAgendaMutation>;
export type CreateHotelAgendaMutationResult = Apollo.MutationResult<CreateHotelAgendaMutation>;
export type CreateHotelAgendaMutationOptions = Apollo.BaseMutationOptions<CreateHotelAgendaMutation, CreateHotelAgendaMutationVariables>;
export const AddAgendaItemDocument = gql`
    mutation AddAgendaItem($brandCode: String!, $createdById: String!, $name: String!, $completed: Boolean, $notes: String) {
  addAgendaItem(
    brandCode: $brandCode
    createdById: $createdById
    name: $name
    completed: $completed
    notes: $notes
  ) {
    ...AgendaItemFields
  }
}
    ${AgendaItemFieldsFragmentDoc}`;
export type AddAgendaItemMutationFn = Apollo.MutationFunction<AddAgendaItemMutation, AddAgendaItemMutationVariables>;

/**
 * __useAddAgendaItemMutation__
 *
 * To run a mutation, you first call `useAddAgendaItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAgendaItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAgendaItemMutation, { data, loading, error }] = useAddAgendaItemMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      createdById: // value for 'createdById'
 *      name: // value for 'name'
 *      completed: // value for 'completed'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useAddAgendaItemMutation(baseOptions?: Apollo.MutationHookOptions<AddAgendaItemMutation, AddAgendaItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAgendaItemMutation, AddAgendaItemMutationVariables>(AddAgendaItemDocument, options);
      }
export type AddAgendaItemMutationHookResult = ReturnType<typeof useAddAgendaItemMutation>;
export type AddAgendaItemMutationResult = Apollo.MutationResult<AddAgendaItemMutation>;
export type AddAgendaItemMutationOptions = Apollo.BaseMutationOptions<AddAgendaItemMutation, AddAgendaItemMutationVariables>;
export const RemoveAgendaItemDocument = gql`
    mutation RemoveAgendaItem($removeAgendaItemId: String!) {
  removeAgendaItem(id: $removeAgendaItemId)
}
    `;
export type RemoveAgendaItemMutationFn = Apollo.MutationFunction<RemoveAgendaItemMutation, RemoveAgendaItemMutationVariables>;

/**
 * __useRemoveAgendaItemMutation__
 *
 * To run a mutation, you first call `useRemoveAgendaItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAgendaItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAgendaItemMutation, { data, loading, error }] = useRemoveAgendaItemMutation({
 *   variables: {
 *      removeAgendaItemId: // value for 'removeAgendaItemId'
 *   },
 * });
 */
export function useRemoveAgendaItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAgendaItemMutation, RemoveAgendaItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAgendaItemMutation, RemoveAgendaItemMutationVariables>(RemoveAgendaItemDocument, options);
      }
export type RemoveAgendaItemMutationHookResult = ReturnType<typeof useRemoveAgendaItemMutation>;
export type RemoveAgendaItemMutationResult = Apollo.MutationResult<RemoveAgendaItemMutation>;
export type RemoveAgendaItemMutationOptions = Apollo.BaseMutationOptions<RemoveAgendaItemMutation, RemoveAgendaItemMutationVariables>;
export const UpdateAgendaItemDocument = gql`
    mutation UpdateAgendaItem($updateAgendaItemId: String!, $completed: Boolean, $name: String, $notes: String) {
  updateAgendaItem(
    id: $updateAgendaItemId
    completed: $completed
    name: $name
    notes: $notes
  ) {
    ...AgendaItemFields
  }
}
    ${AgendaItemFieldsFragmentDoc}`;
export type UpdateAgendaItemMutationFn = Apollo.MutationFunction<UpdateAgendaItemMutation, UpdateAgendaItemMutationVariables>;

/**
 * __useUpdateAgendaItemMutation__
 *
 * To run a mutation, you first call `useUpdateAgendaItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgendaItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgendaItemMutation, { data, loading, error }] = useUpdateAgendaItemMutation({
 *   variables: {
 *      updateAgendaItemId: // value for 'updateAgendaItemId'
 *      completed: // value for 'completed'
 *      name: // value for 'name'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateAgendaItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgendaItemMutation, UpdateAgendaItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgendaItemMutation, UpdateAgendaItemMutationVariables>(UpdateAgendaItemDocument, options);
      }
export type UpdateAgendaItemMutationHookResult = ReturnType<typeof useUpdateAgendaItemMutation>;
export type UpdateAgendaItemMutationResult = Apollo.MutationResult<UpdateAgendaItemMutation>;
export type UpdateAgendaItemMutationOptions = Apollo.BaseMutationOptions<UpdateAgendaItemMutation, UpdateAgendaItemMutationVariables>;
export const namedOperations = {
  Query: {
    GetHotelAgenda: 'GetHotelAgenda'
  },
  Mutation: {
    CreateHotelAgenda: 'CreateHotelAgenda',
    AddAgendaItem: 'AddAgendaItem',
    RemoveAgendaItem: 'RemoveAgendaItem',
    UpdateAgendaItem: 'UpdateAgendaItem'
  }
}