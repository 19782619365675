import BtnPopover, { CloseFn, Placement } from '../../../components/BtnPopover';
import React, { useMemo, useRef, useState } from 'react';

import { ActionItemView } from './action-item-view';
import { AgendaView } from './agenda-view';
import { Editor } from '../../notes/editor';
import { FaTools } from 'react-icons/fa';
import { FixMeLater } from '../../../types/FixMeLaterType';
import { MeetingForms } from './meeting-forms';
import { TeamView } from './team-view';
import { useHotel } from '../../../context/hotelContext';
import { useIsShared } from '../../reports/hooks/use-report-location';
import { useMeeting } from '../context/meeting-context';
import { useMeetingTools } from '../hooks/use-meeting-tools';
import { useUser } from '../../../context/userContext';

export enum FormView {
  ACTION_ITEMS = 'actionItems',
  AGENDA = 'agenda',
  NOTES = 'notes',
  TEAM = 'team',
}

const MeetingTools = () => {
  const { isShared } = useIsShared();
  const { hotel } = useHotel();
  const { user } = useUser();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [view, setView] = useState(FormView.AGENDA);
  const { meetingTools } = useMeetingTools(hotel?.brand_code);
  const { meeting, setMeeting } = useMeeting();

  const offsetH = useMemo(() => {
    switch (view) {
      case FormView.AGENDA:
        return 20;
      case FormView.ACTION_ITEMS:
        return 25;
      case FormView.NOTES:
        return 30;
      default:
        return 20;
    }
  }, [view]);

  const notesRef: React.Ref<FixMeLater> = useRef();

  const handleViewChange = (nextView: FormView) => {
    if (view === FormView.NOTES) {
      notesRef.current?.handleSave();
    }
    setView(nextView);
    setShowForm(false);
  };

  const panelContent = (props?: { close?: CloseFn }) => (
    <div className='flex flex-col h-full p-4 bg-white rounded-lg drop-shadow-2xl'>
      <div className='flex justify-between'>
        <select
          value={view}
          onChange={(e) => handleViewChange(e.target.value as FormView)}
          className='w-fit font-semibold mr-2'
        >
          <option value={FormView.AGENDA}>Agenda</option>
          <option value={FormView.TEAM}>Team</option>
          <option value={FormView.ACTION_ITEMS}>Action Items</option>
          <option value={FormView.NOTES}>Meeting Notes</option>
        </select>
        {!showForm && !isShared && view !== FormView.NOTES ? (
          <button
            className='px-2 mx-2 text-sm text-green-600 border border-green-500 rounded-md hover:text-green-700 hover:border-green-600'
            onClick={() => setShowForm(true)}
          >
            Add{' '}
            {view === FormView.AGENDA || view === FormView.ACTION_ITEMS
              ? 'Item'
              : 'Member'}
          </button>
        ) : view !== FormView.NOTES ? (
          <button
            className='px-2 mx-2 text-sm text-red-600 border border-red-500 rounded-md'
            onClick={() => setShowForm(false)}
          >
            Back
          </button>
        ) : null}
      </div>
      {showForm && !isShared ? (
        <MeetingForms
          brandCode={hotel?.brand_code}
          close={props?.close}
          view={view}
        />
      ) : (
        <div className='mt-2 h-full'>
          {view === FormView.AGENDA && (
            <AgendaView
              isTool={true}
              meeting={meeting}
              meetingTools={meetingTools}
            />
          )}
          {view === FormView.TEAM && (
            <TeamView
              isTool={true}
              meeting={meeting}
              meetingTools={meetingTools}
            />
          )}
          {view === FormView.ACTION_ITEMS && (
            <ActionItemView meetingTools={meetingTools} />
          )}
          {view === FormView.NOTES && (
            <Editor
              brandCode={hotel?.brand_code}
              meeting={meeting}
              parentName='meeting-tools'
              setMeeting={setMeeting}
              userId={user?.id as string | undefined}
            />
          )}
        </div>
      )}
    </div>
  );

  const btnProps = {
    btnClass: 'focus:outline-none',
    btnIconClass: 'mx-2',
    btnIcon: FaTools,
    btnSpanClass: 'flex items-center text-gray-50 hover:text-gray-300 text-sm',
    btnTooltip: 'Meeting Tools',
    offsetH,
    offsetV: -20,
    panelContent,
    popPlacement: 'bottom' as Placement,
  };

  return <BtnPopover {...btnProps} />;
};

export default MeetingTools;
