import { Status } from '../types/status';
import { StatusDisplay } from './saved-status/status-display';

interface SavedStatusProps {
  status?: Status;
  handleSave: () => Promise<void>;
}

export const SavedStatus = ({ handleSave, status }: SavedStatusProps) => {
  if (!status) return null;

  if (status === Status.UNSAVED) {
    return (
      <button onClick={handleSave}>
        <StatusDisplay status={status} />
      </button>
    );
  }

  return <StatusDisplay status={status} />;
};
