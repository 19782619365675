import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCreateEventMutation,
  useDataTableEventsQuery,
  useDeleteEventMutation,
  useUpdateEventMutation,
} from '@/features/events/gql/_gen_/events.gql';

import { Event } from '@/graphql/types';
import { eventSchema } from '@/features/events/schemas/event-schema';
import { useForm } from 'react-hook-form';
import { useHotel } from '@/context/hotelContext';
import { useToast } from '@/components/ui/use-toast';
import { useUser } from '@/context/userContext';
import { zodResolver } from '@hookform/resolvers/zod';

const PAGE_SIZE = 20;

export function useEventManagement() {
  const { toast } = useToast();
  const { hotel } = useHotel();
  const { user } = useUser();
  const [events, setEvents] = useState<Event[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);

  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [deleteEventMutation] = useDeleteEventMutation();

  const initialPageIndex = useMemo(() => {
    if (!events.length) return 0;

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of day for accurate comparison

    // Find the event with the closest start_date to today
    const closestEvent = events.reduce((closest, current) => {
      const closestDate = new Date(closest.start_date);
      const currentDate = new Date(current.start_date);
      const closestDiff = Math.abs(closestDate.getTime() - today.getTime());
      const currentDiff = Math.abs(currentDate.getTime() - today.getTime());

      return currentDiff < closestDiff ? current : closest;
    });

    // Find the index of the closest event
    const closestEventIndex = events.findIndex(
      (event) => event.id === closestEvent.id
    );

    // Calculate the page index (assuming 10 items per page)
    const itemsPerPage = PAGE_SIZE;
    return Math.floor(closestEventIndex / itemsPerPage);
  }, [events]);

  const formDefaults = {
    name: '',
    start_date: new Date().toLocaleDateString('en-CA'),
    end_date: new Date().toLocaleDateString('en-CA'),
    details: '',
    hotel_id: hotel?.hotel_id,
    created_by_id: user?.id,
  };

  const form = useForm<Event>({
    resolver: zodResolver(eventSchema),
    defaultValues: formDefaults,
  });

  const editForm = useForm<Event>({
    resolver: zodResolver(eventSchema),
    defaultValues: formDefaults,
  });

  const { data, loading, refetch } = useDataTableEventsQuery({
    skip: !hotel?.hotel_id || !hotel?.brand_code,
    variables: {
      filters: {
        hotelId: hotel?.hotel_id || hotel?.brand_code,
      },
    },
    onCompleted: (data) => {
      if (data && data.hotelEvents) {
        const formattedEvents = data.hotelEvents
          .filter((event) => event !== null)
          .map((event) => ({
            ...event,
            details: event.details || '',
          }));
        setEvents(formattedEvents);
      }
    },
  });

  useEffect(() => {
    if (hotel && (hotel.hotel_id || hotel.brand_code)) {
      refetch({
        filters: {
          hotelId: hotel.hotel_id || hotel?.brand_code,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel]);

  useEffect(() => {
    if (user) {
      form.setValue('created_by_id', user.id);
      editForm.setValue('created_by_id', user.id);
    } else {
      form.setValue('created_by_id', '');
      editForm.setValue('created_by_id', '');
    }

    if (hotel) {
      form.setValue('hotel_id', hotel.hotel_id);
      editForm.setValue('hotel_id', hotel.hotel_id);
    } else {
      form.setValue('hotel_id', null);
      editForm.setValue('hotel_id', null);
    }
  }, [user, hotel, form, editForm]);

  const validateDates = useCallback((start_date: string, end_date: string) => {
    if (start_date && end_date && new Date(end_date) < new Date(start_date)) {
      return start_date;
    }
    return end_date;
  }, []);

  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name === 'start_date' && type === 'change') {
        const end_date = form.getValues('end_date');
        const newEndDate = validateDates(value.start_date!, end_date);
        if (newEndDate !== end_date) {
          form.setValue('end_date', newEndDate, { shouldValidate: true });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [form, validateDates]);

  useEffect(() => {
    const subscription = editForm.watch((value, { name, type }) => {
      if (name === 'start_date' && type === 'change') {
        const end_date = editForm.getValues('end_date');
        const newEndDate = validateDates(value.start_date!, end_date);
        if (newEndDate !== end_date) {
          editForm.setValue('end_date', newEndDate, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [editForm, validateDates]);

  async function onSubmit(data: Event) {
    const validData = eventSchema.safeParse(data);

    if (!validData.success) {
      toast({
        title: 'Invalid event data',
        description: validData.error.errors[0].message,
        variant: 'destructive',
      });
      return;
    }

    // Create a temporary ID for the optimistic update
    const tempId = `temp-${Date.now()}`;

    // Optimistically update the UI
    const optimisticEvent = {
      ...validData.data,
      id: tempId,
    };

    setEvents((prevEvents) =>
      [...prevEvents, optimisticEvent].sort(
        (a, b) =>
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      )
    );

    try {
      const result = await createEvent({
        variables: {
          event: {
            ...validData.data,
            start_date: validData.data.start_date,
            end_date: validData.data.end_date,
          },
        },
      });

      if (result.data?.createEvent) {
        // Update the optimistic event with the real data
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.id === tempId
              ? {
                  ...result.data?.createEvent,
                  id: result.data?.createEvent?.id,
                  start_date: validData.data.start_date,
                  end_date: validData.data.end_date,
                }
              : event
          )
        );
        form.reset(formDefaults);
        toast({
          title: 'Event created',
          description: `The event ${result.data.createEvent.name} has been successfully created.`,
        });
        refetch();
        return result.data.createEvent;
      }
      throw new Error('Failed to create event');
    } catch (error) {
      console.error(error);
      // Remove the optimistic event on error
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== tempId)
      );
      toast({
        title: 'Error',
        description: `Failed to create the event. ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
        variant: 'destructive',
      });
    }
  }

  function startEditing(eventId: Event['id']) {
    if (eventId) {
      const event = events.find((event) => event.id === eventId);
      setEditingId(eventId);
      editForm.reset(event);
    }
  }

  function cancelEditing() {
    setEditingId(null);
    editForm.reset();
  }

  const saveEdit = async (data: Event) => {
    if (!editingId) return;

    const validData = eventSchema.safeParse(data);

    if (!validData.success) {
      toast({
        title: 'Invalid event data',
        description: validData.error.errors[0].message,
        variant: 'destructive',
      });
      return;
    }

    setEditingId(null);

    // Optimistically update the UI
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === editingId
          ? {
              ...event,
              ...validData.data,
            }
          : event
      )
    );

    try {
      const { id, ...rest } = validData.data;
      const result = await updateEvent({
        variables: {
          updateEventId: editingId,
          event: {
            ...rest,
          },
        },
      });

      if (result.data?.updateEvent) {
        setEditingId(null);
        toast({
          title: 'Event updated',
          description: `The event ${result.data.updateEvent.name} has been successfully updated.`,
        });
        refetch();
      } else {
        throw new Error('Failed to update event');
      }
    } catch (error) {
      console.error(error);
      // Revert the optimistic update
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === editingId
            ? events.find((e) => e.id === editingId) || event
            : event
        )
      );
      toast({
        title: 'Error',
        description: `Failed to update the event. ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
        variant: 'destructive',
      });
    }
  };

  const deleteEvent = async (eventId: Event['id']) => {
    if (!eventId) return;

    // Optimistically update the UI
    const deletedEvent = events.find((event) => event.id === eventId);
    setEvents((prevEvents) =>
      prevEvents.filter((event) => event.id !== eventId)
    );

    try {
      const result = await deleteEventMutation({
        variables: { deleteEventId: eventId },
      });

      if (result.data?.deleteEvent) {
        toast({
          title: 'Event deleted',
          description: `The event ${deletedEvent?.name} has been successfully deleted.`,
        });
        refetch();
      } else {
        throw new Error('Failed to delete event');
      }
    } catch (error) {
      console.error(error);
      // Revert the optimistic update
      if (deletedEvent) {
        setEvents((prevEvents) => [...prevEvents, deletedEvent]);
      }
      toast({
        title: 'Error',
        description: `Failed to delete the event. ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
        variant: 'destructive',
      });
    }
  };

  return {
    cancelEditing,
    data,
    deleteEvent,
    editForm,
    editingId,
    events,
    form,
    initialPageIndex,
    loading,
    onSubmit,
    pageSize: PAGE_SIZE,
    refetch,
    saveEdit,
    startEditing,
  };
}
