import { DateHelpers } from '@/helpers/dateHelpers';
import { OtaiLowestRatesQuery } from '@/features/overview-table/gql/_gen_/rate-shop.gql';
import { RateCellRenderer } from '@/features/rate_shops/components/rate-cell-renderer';
import { ReportDataQuery } from '@/features/reports/gql/_gen_/report.gql';
import { SectionContainer } from '@/features/reports/components/section-container';
import Table from '@/components/Table';
import { useTable } from '@/features/overview-table/hooks/use-table';

type FiveDayRatesProps = {
  dataRateShop?: OtaiLowestRatesQuery;
  dataReport?: ReportDataQuery;
  loadingRateShop: boolean;
  loadingReport: boolean;
  startDate: string;
};

export const FiveDayRates = ({
  dataRateShop,
  dataReport,
  loadingRateShop,
  loadingReport,
  startDate,
}: FiveDayRatesProps) => {
  const { compSet, dailyRates } = dataRateShop || {};
  const { rmData } = dataReport || {};

  const tableCols = compSet?.length
    ? compSet.filter((comp) => comp?.key !== 'rates.0')
    : [];

  const { headers: detailHeaders, rows: detailRows } = useTable({
    customHeaders: null,
    data: {
      dailyRates,
      compSet,
      rmData,
    },
    dateSegment: 'day',
    includeSummary: false,
    startDate: startDate,
    endDate: DateHelpers.addDays(startDate, 4),
    detailCols: [
      { dataSource: 'rmData', key: 'dow' },
      { dataSource: 'dailyRates', key: 'stay_date' },
      { dataSource: 'rmData', key: 'occ' },
      { dataSource: 'rmData', key: 'ooo' },
      {
        dataSource: 'rmData',
        key: 'delta_7day',
        tooltip: '7-day delta (change in rooms committed)',
      },
      ...tableCols,
    ],
  });

  const headers = detailHeaders.map((h) => h.title);
  const rows = detailRows.map((row) => row.map((cell) => cell));

  return (
    <SectionContainer
      anchorId='five-day-rates'
      title='5-day Rate Outlook (lowest non-qualified rate)'
    >
      {loadingRateShop || loadingReport ? (
        <span>Loading...</span>
      ) : (
        <Table
          customCellAlignment={'text-center 3xl:text-left'}
          headers={headers}
          rows={rows}
          renderer={RateCellRenderer}
        />
      )}
    </SectionContainer>
  );
};
