import { DataRow } from './DataTable';

export enum RmRecStatus {
  open,
  accepted,
  declined,
  processing,
  error,
  uploaded,
}

export type RmRec = {
  __typename: string;
  hidden: boolean;
  id: string;
  notes: string;
  rate: number;
  status: RmRecStatus;
  stay_date: string;
};

export type RmRecTableRows = {
  rows: DataRow[];
  startRmRecIndex: number;
};

export type PaginatedRmRecsObject = {
  pages: DataRow[][];
  index: number;
  totalRmRecs: number;
  totalPages: number;
};

export type CreateRmRecInput = {
  rate?: number;
  stay_date: string;
  notes?: string;
  hotel_id: number;
  created_by_id: string;
};

export type UpdateRmRecInput = {
  rate?: string;
  stay_date?: string;
  notes?: string;
  status?: RmRecStatus;
};
