import DataTable from '../../../components/DataTable';
import Paginate from '../../../components/Paginate';
import { startCase } from 'lodash';
import { today } from '../../../helpers/dateHelpers';
import { useHotel } from '../../../context/hotelContext';
import { useTrackingDataHotel } from '../hooks/useTrackingData';

export const TrackingDataTable = () => {
  const headers = [
    'action',
    'meetingCategory',
    'meetingDate',
    'meetingId',
    'brandCode',
    'dateAdded',
    'event',
    'ipAddr',
    'userName',
  ];

  const formFields = headers.map((key) => {
    return {
      bulk: [''].includes(key),
      id: '',
      label: key.toLowerCase() === 'id' ? 'Actions' : startCase(key),
      name: key,
      type: key.toLowerCase().includes('date') ? 'date' : 'text',
      value: key.toLowerCase().includes('date') ? today() : '',
    };
  });

  const { hotel } = useHotel();
  const {
    data,
    goToPage,
    loading,
    options,
    page,
    pageDown,
    pageUp,
    setOptions,
    setTableFilters,
    tableFilters,
    totalFiltered,
    totalItems,
    totalPages,
  } = useTrackingDataHotel(headers, hotel?.brand_code);

  return (
    <div className='w-full mt-2'>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <Paginate
            first={page || 0}
            goToPage={goToPage}
            last={totalPages}
            previous={pageDown}
            next={pageUp}
            total={totalItems}
            totalFiltered={totalFiltered}
          />
          <DataTable
            fields={formFields}
            options={options}
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            setOptions={setOptions}
            handleCreate={() => Promise.resolve(console.log('handleCreate'))}
            name='Tracking'
            rows={data || []}
          />
        </div>
      )}
    </div>
  );
};
