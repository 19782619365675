import { ColumnParams } from '../types/Columns';
import { FixMeLater } from '../types/FixMeLaterType';
import dayjs from 'dayjs';
import { masterColumns } from './masterColumns';
import numbro from 'numbro';

type Value = string | number | boolean | null | undefined;

export const baseFormat = (
  key: string,
  value: Value,
  dateSegment: string,
  colDetails?: FixMeLater,
  isSummary?: boolean
) => {
  const renderer = getRenderer(key, colDetails);
  let adjustedValue = value;
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    isNaN(Number(value))
  ) {
    adjustedValue = key.includes('room_types') ? value : null;
  }

  if (renderer) {
    switch (renderer) {
      case 'prm.base':
        return adjustedValue === 0 ? '' : adjustedValue;
      case 'prm.dow':
        return value;
      case 'prm.note':
        return adjustedValue === 0 ? '' : adjustedValue;
      case 'prm.date':
        let year, qtr, week;
        switch (dateSegment) {
          case 'year':
            return String(value);
          case 'month':
            return dayjs(String(value), 'YYYY-MM').format('MMM YYYY');
          case 'quarter':
            [year, qtr] = String(value).split('-');
            return `QTR${qtr} ${year}`;
          case 'week':
            [year, week] = String(value).split('-');
            return `WK${week} ${year}`;
          default:
            if (isSummary) {
              const sumDate = String(value).split('-', 2).join('-');
              return dayjs(sumDate, 'YYYY-MM').format('MMM YYYY');
            } else {
              return dayjs(String(value), 'YYYY-MM-DD').format('MM/DD/YYYY');
            }
        }
      case 'prm.rate':
        return adjustedValue !== 0 && adjustedValue !== null
          ? displayRate(adjustedValue)
          : '';
      case 'prm.rate_shop':
        return adjustedValue !== 0 && adjustedValue !== null
          ? displayRate(adjustedValue)
          : '';
      case 'prm.money':
        return adjustedValue !== 0 && adjustedValue !== null
          ? displayMoney(adjustedValue)
          : '';
      case 'prm.rm_rec_notes':
        return value;
      case 'prm.rm_rec_status':
        return value;
      case 'prm.percent':
        return Math.round(Number(adjustedValue) * 100 * 1) / 1 + '%';
      case 'prm.temperature':
        return Number(adjustedValue).toFixed(1) + '°';
      case 'prm.truncated':
        return value ? String(value) : '';
      case 'prm.text':
        return value;
      default:
        return adjustedValue;
    }
  } else {
    return adjustedValue;
  }
};

export const displayMoney = (value: Value): string => {
  const numValue = Number(value);
  return numbro(numValue).formatCurrency({
    mantissa: 0,
    thousandSeparated: true,
    negative: 'parenthesis',
  });
};

export const displayRate = (value: Value): string => {
  const numValue = Number(value);
  if (numValue === 0) {
    return '';
  } else if (numValue >= 0 && numValue < 1000) {
    return numbro(numValue).formatCurrency({
      mantissa: 2,
      thousandSeparated: true,
      negative: 'parenthesis',
    });
  } else if (numValue < 0 && numValue > -1000) {
    return numbro(numValue).formatCurrency({
      mantissa: 2,
      thousandSeparated: true,
      negative: 'parenthesis',
    });
  } else {
    return numbro(numValue).formatCurrency({
      mantissa: 1,
      average: true,
      negative: 'parenthesis',
      optionalMantissa: true,
      currencyPosition: 'prefix',
    });
  }
};

const getFontColor = (key?: string, value?: number, clientRate?: number) => {
  if (value && !isNaN(value) && Number(value) < 0) {
    return 'text-red-500';
  } else if (key && value && key.includes('price_index')) {
    if (value < 100) {
      return `text-green-500`;
    } else if (value > 100) {
      return `text-red-500`;
    }
  } else if (clientRate && value) {
    if (clientRate < value) {
      return `text-green-500`;
    } else if (clientRate > value) {
      return `text-red-500`;
    }
  } else {
    return '';
  }
};

export const getRowClass = (
  stayDate: string,
  dateIdx: number,
  dateSegment?: string,
  value?: number,
  isSummary?: boolean,
  clientRate?: number,
  key?: string
) => {
  const fontColor = getFontColor(key, value, clientRate);

  if (dateSegment === 'day') {
    if (isSummary) {
      return `bg-amber-100 ${fontColor}`;
    } else {
      const stayDateObj = dayjs(stayDate, 'YYYY-MM-DD');
      const isToday = stayDateObj.isSame(dayjs(), 'day');
      const isWeekend = stayDateObj.day() >= 5;

      return isToday
        ? `bg-green-100 ${fontColor}`
        : isWeekend
        ? `bg-gray-200 ${fontColor}`
        : `${fontColor}`;
    }
  } else {
    return isOdd(dateIdx) ? `bg-gray-200 ${fontColor}` : `${fontColor}`;
  }
};

export const getRenderer = (key: string, colDetails?: ColumnParams) => {
  if (isRateOrRoomType(key)) {
    if (key.includes('price_index')) {
      return 'prm.base';
    }
    return colDetails?.renderer || 'prm.rate_shop';
  } else {
    return masterColumns[key]?.renderer;
  }
};

export const isEditable = (key: string): boolean => {
  return masterColumns[key]?.readOnly === false;
};

export const isOdd = (num: number) => {
  return num & 1;
};

const isRateOrRoomType = (key: string) => {
  return (
    key === 'client_rate' ||
    key.includes('rates.') ||
    key.includes('room_types.')
  );
};
