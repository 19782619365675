/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ComputeFeatureFlagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ComputeFeatureFlagsQuery = { __typename?: 'Query', computeFeatureFlags?: Array<{ __typename?: 'FeatureFlag', id?: string | null, active?: boolean | null, computed_value?: boolean | null, created_at?: string | null, default_value?: boolean | null, description?: string | null, key?: string | null, updated_at?: string | null, user_id?: string | null } | null> | null };

export type ListFeatureFlagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListFeatureFlagsQuery = { __typename?: 'Query', listFeatureFlags?: Array<{ __typename?: 'FeatureFlag', id?: string | null, active?: boolean | null, computed_value?: boolean | null, created_at?: string | null, default_value?: boolean | null, description?: string | null, key?: string | null, updated_at?: string | null, user_id?: string | null, users?: Array<{ __typename?: 'FeatureFlagUser', active?: boolean | null, created_at?: string | null, flag_key?: string | null, last_updated_by?: string | null, updated_at?: string | null, user_id?: string | null } | null> | null } | null> | null };

export type CreateFeatureFlagMutationVariables = Types.Exact<{
  input: Types.CreateFeatureFlagInput;
}>;


export type CreateFeatureFlagMutation = { __typename?: 'Mutation', createFeatureFlag?: { __typename?: 'FeatureFlag', id?: string | null, active?: boolean | null, computed_value?: boolean | null, created_at?: string | null, default_value?: boolean | null, description?: string | null, key?: string | null, updated_at?: string | null, user_id?: string | null } | null };

export type UpdateFeatureFlagMutationVariables = Types.Exact<{
  input: Types.UpdateFeatureFlagInput;
}>;


export type UpdateFeatureFlagMutation = { __typename?: 'Mutation', updateFeatureFlag?: { __typename?: 'FeatureFlag', id?: string | null, active?: boolean | null, computed_value?: boolean | null, created_at?: string | null, default_value?: boolean | null, description?: string | null, key?: string | null, updated_at?: string | null, user_id?: string | null } | null };

export type ToggleFlagUserMutationVariables = Types.Exact<{
  flagKey: Types.Scalars['String'];
  userId: Types.Scalars['String'];
  active: Types.Scalars['Boolean'];
}>;


export type ToggleFlagUserMutation = { __typename?: 'Mutation', toggleFlagUser?: { __typename?: 'FeatureFlagUser', id?: string | null, active?: boolean | null, created_at?: string | null, flag_key?: string | null, last_updated_by?: string | null, updated_at?: string | null, user_id?: string | null } | null };


export const ComputeFeatureFlagsDocument = gql`
    query ComputeFeatureFlags {
  computeFeatureFlags {
    id
    active
    computed_value
    created_at
    default_value
    description
    key
    updated_at
    user_id
  }
}
    `;

/**
 * __useComputeFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useComputeFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useComputeFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<ComputeFeatureFlagsQuery, ComputeFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComputeFeatureFlagsQuery, ComputeFeatureFlagsQueryVariables>(ComputeFeatureFlagsDocument, options);
      }
export function useComputeFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComputeFeatureFlagsQuery, ComputeFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComputeFeatureFlagsQuery, ComputeFeatureFlagsQueryVariables>(ComputeFeatureFlagsDocument, options);
        }
export type ComputeFeatureFlagsQueryHookResult = ReturnType<typeof useComputeFeatureFlagsQuery>;
export type ComputeFeatureFlagsLazyQueryHookResult = ReturnType<typeof useComputeFeatureFlagsLazyQuery>;
export type ComputeFeatureFlagsQueryResult = Apollo.QueryResult<ComputeFeatureFlagsQuery, ComputeFeatureFlagsQueryVariables>;
export const ListFeatureFlagsDocument = gql`
    query ListFeatureFlags {
  listFeatureFlags {
    id
    active
    computed_value
    created_at
    default_value
    description
    key
    updated_at
    user_id
    users {
      active
      created_at
      flag_key
      last_updated_by
      updated_at
      user_id
    }
  }
}
    `;

/**
 * __useListFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useListFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFeatureFlagsQuery(baseOptions?: Apollo.QueryHookOptions<ListFeatureFlagsQuery, ListFeatureFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFeatureFlagsQuery, ListFeatureFlagsQueryVariables>(ListFeatureFlagsDocument, options);
      }
export function useListFeatureFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFeatureFlagsQuery, ListFeatureFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFeatureFlagsQuery, ListFeatureFlagsQueryVariables>(ListFeatureFlagsDocument, options);
        }
export type ListFeatureFlagsQueryHookResult = ReturnType<typeof useListFeatureFlagsQuery>;
export type ListFeatureFlagsLazyQueryHookResult = ReturnType<typeof useListFeatureFlagsLazyQuery>;
export type ListFeatureFlagsQueryResult = Apollo.QueryResult<ListFeatureFlagsQuery, ListFeatureFlagsQueryVariables>;
export const CreateFeatureFlagDocument = gql`
    mutation CreateFeatureFlag($input: CreateFeatureFlagInput!) {
  createFeatureFlag(input: $input) {
    id
    active
    computed_value
    created_at
    default_value
    description
    key
    updated_at
    user_id
  }
}
    `;
export type CreateFeatureFlagMutationFn = Apollo.MutationFunction<CreateFeatureFlagMutation, CreateFeatureFlagMutationVariables>;

/**
 * __useCreateFeatureFlagMutation__
 *
 * To run a mutation, you first call `useCreateFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeatureFlagMutation, { data, loading, error }] = useCreateFeatureFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeatureFlagMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeatureFlagMutation, CreateFeatureFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeatureFlagMutation, CreateFeatureFlagMutationVariables>(CreateFeatureFlagDocument, options);
      }
export type CreateFeatureFlagMutationHookResult = ReturnType<typeof useCreateFeatureFlagMutation>;
export type CreateFeatureFlagMutationResult = Apollo.MutationResult<CreateFeatureFlagMutation>;
export type CreateFeatureFlagMutationOptions = Apollo.BaseMutationOptions<CreateFeatureFlagMutation, CreateFeatureFlagMutationVariables>;
export const UpdateFeatureFlagDocument = gql`
    mutation UpdateFeatureFlag($input: UpdateFeatureFlagInput!) {
  updateFeatureFlag(input: $input) {
    id
    active
    computed_value
    created_at
    default_value
    description
    key
    updated_at
    user_id
  }
}
    `;
export type UpdateFeatureFlagMutationFn = Apollo.MutationFunction<UpdateFeatureFlagMutation, UpdateFeatureFlagMutationVariables>;

/**
 * __useUpdateFeatureFlagMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureFlagMutation, { data, loading, error }] = useUpdateFeatureFlagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeatureFlagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeatureFlagMutation, UpdateFeatureFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeatureFlagMutation, UpdateFeatureFlagMutationVariables>(UpdateFeatureFlagDocument, options);
      }
export type UpdateFeatureFlagMutationHookResult = ReturnType<typeof useUpdateFeatureFlagMutation>;
export type UpdateFeatureFlagMutationResult = Apollo.MutationResult<UpdateFeatureFlagMutation>;
export type UpdateFeatureFlagMutationOptions = Apollo.BaseMutationOptions<UpdateFeatureFlagMutation, UpdateFeatureFlagMutationVariables>;
export const ToggleFlagUserDocument = gql`
    mutation ToggleFlagUser($flagKey: String!, $userId: String!, $active: Boolean!) {
  toggleFlagUser(flagKey: $flagKey, userId: $userId, active: $active) {
    id
    active
    created_at
    flag_key
    last_updated_by
    updated_at
    user_id
  }
}
    `;
export type ToggleFlagUserMutationFn = Apollo.MutationFunction<ToggleFlagUserMutation, ToggleFlagUserMutationVariables>;

/**
 * __useToggleFlagUserMutation__
 *
 * To run a mutation, you first call `useToggleFlagUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFlagUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFlagUserMutation, { data, loading, error }] = useToggleFlagUserMutation({
 *   variables: {
 *      flagKey: // value for 'flagKey'
 *      userId: // value for 'userId'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useToggleFlagUserMutation(baseOptions?: Apollo.MutationHookOptions<ToggleFlagUserMutation, ToggleFlagUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleFlagUserMutation, ToggleFlagUserMutationVariables>(ToggleFlagUserDocument, options);
      }
export type ToggleFlagUserMutationHookResult = ReturnType<typeof useToggleFlagUserMutation>;
export type ToggleFlagUserMutationResult = Apollo.MutationResult<ToggleFlagUserMutation>;
export type ToggleFlagUserMutationOptions = Apollo.BaseMutationOptions<ToggleFlagUserMutation, ToggleFlagUserMutationVariables>;
export const namedOperations = {
  Query: {
    ComputeFeatureFlags: 'ComputeFeatureFlags',
    ListFeatureFlags: 'ListFeatureFlags'
  },
  Mutation: {
    CreateFeatureFlag: 'CreateFeatureFlag',
    UpdateFeatureFlag: 'UpdateFeatureFlag',
    ToggleFlagUser: 'ToggleFlagUser'
  }
}