/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListOtaiSubscriptionsQueryVariables = Types.Exact<{
  lookup: Types.OtaiSubLookup;
}>;


export type ListOtaiSubscriptionsQuery = { __typename?: 'Query', listOtaiSubscriptions?: Array<{ __typename?: 'OtaiSubscription', brand_code: any, client_name?: string | null, created_at?: string | null, id: string, otai_hotel_id?: number | null, stars?: number | null, sub_id?: number | null, updated_at?: string | null, comp_sets?: Array<{ __typename?: 'CompSet', id?: string | null, name?: string | null, competitor_ids?: Array<string | null> | null } | null> | null, competitors?: Array<{ __typename?: 'Competitor', id?: string | null, name?: string | null, stars?: number | null } | null> | null, features?: Array<{ __typename?: 'Feature', id?: string | null, otas?: Array<string | null> | null } | null> | null } | null> | null };


export const ListOtaiSubscriptionsDocument = gql`
    query ListOtaiSubscriptions($lookup: OtaiSubLookup!) {
  listOtaiSubscriptions(lookup: $lookup) {
    brand_code
    client_name
    comp_sets {
      id
      name
      competitor_ids
    }
    competitors {
      id
      name
      stars
    }
    created_at
    features {
      id
      otas
    }
    id
    otai_hotel_id
    stars
    sub_id
    updated_at
  }
}
    `;

/**
 * __useListOtaiSubscriptionsQuery__
 *
 * To run a query within a React component, call `useListOtaiSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOtaiSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOtaiSubscriptionsQuery({
 *   variables: {
 *      lookup: // value for 'lookup'
 *   },
 * });
 */
export function useListOtaiSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<ListOtaiSubscriptionsQuery, ListOtaiSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOtaiSubscriptionsQuery, ListOtaiSubscriptionsQueryVariables>(ListOtaiSubscriptionsDocument, options);
      }
export function useListOtaiSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOtaiSubscriptionsQuery, ListOtaiSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOtaiSubscriptionsQuery, ListOtaiSubscriptionsQueryVariables>(ListOtaiSubscriptionsDocument, options);
        }
export type ListOtaiSubscriptionsQueryHookResult = ReturnType<typeof useListOtaiSubscriptionsQuery>;
export type ListOtaiSubscriptionsLazyQueryHookResult = ReturnType<typeof useListOtaiSubscriptionsLazyQuery>;
export type ListOtaiSubscriptionsQueryResult = Apollo.QueryResult<ListOtaiSubscriptionsQuery, ListOtaiSubscriptionsQueryVariables>;
export const namedOperations = {
  Query: {
    ListOtaiSubscriptions: 'ListOtaiSubscriptions'
  }
}