import { useEffect, useState } from 'react';

import { MeetingService } from '../../../graphql/types';
import { useMeetingToolsQuery } from '../gql/_gen_/meeting.gql';

export const useMeetingTools = (brandCode?: string) => {
  const [meetingTools, setMeetingTools] = useState<MeetingService>();

  const { data, loading } = useMeetingToolsQuery({
    skip: !brandCode,
    variables: {
      brandCode,
    },
  });

  useEffect(() => {
    if (data && data?.meetingTools) {
      setMeetingTools(data.meetingTools);
    }
  }, [data, loading]);

  return { meetingTools };
};
