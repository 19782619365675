/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { DailyGroupFieldsFragmentDoc, GroupFieldsFragmentDoc } from '../../../groups/gql/_gen_/group-daily.fragment.gql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GroupDailyOtQueryVariables = Types.Exact<{
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
  useCache?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GroupDailyOtQuery = { __typename?: 'Query', groupDaily?: Array<{ __typename?: 'DailyGroupData', brand_code?: string | null, date_year?: string | null, date_ym?: string | null, date_yq?: string | null, date_yw?: string | null, stay_date?: string | null, details?: Array<{ __typename?: 'Group', brand_code?: string | null, description?: string | null, group_rooms_committed?: number | null, group_rooms_remaining?: number | null, group_rooms_sold?: number | null, is_past?: boolean | null, lowest_group_rate?: number | null, rate_code?: string | null, snapshot_date?: string | null, stay_date?: string | null } | null> | null } | null> | null };


export const GroupDailyOtDocument = gql`
    query GroupDailyOT($brandCode: String, $startDate: String, $endDate: String, $useCache: Boolean) {
  groupDaily(
    brandCode: $brandCode
    startDate: $startDate
    endDate: $endDate
    useCache: $useCache
  ) {
    ...DailyGroupFields
    details {
      ...GroupFields
    }
  }
}
    ${DailyGroupFieldsFragmentDoc}
${GroupFieldsFragmentDoc}`;

/**
 * __useGroupDailyOtQuery__
 *
 * To run a query within a React component, call `useGroupDailyOtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDailyOtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDailyOtQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      useCache: // value for 'useCache'
 *   },
 * });
 */
export function useGroupDailyOtQuery(baseOptions?: Apollo.QueryHookOptions<GroupDailyOtQuery, GroupDailyOtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupDailyOtQuery, GroupDailyOtQueryVariables>(GroupDailyOtDocument, options);
      }
export function useGroupDailyOtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupDailyOtQuery, GroupDailyOtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupDailyOtQuery, GroupDailyOtQueryVariables>(GroupDailyOtDocument, options);
        }
export type GroupDailyOtQueryHookResult = ReturnType<typeof useGroupDailyOtQuery>;
export type GroupDailyOtLazyQueryHookResult = ReturnType<typeof useGroupDailyOtLazyQuery>;
export type GroupDailyOtQueryResult = Apollo.QueryResult<GroupDailyOtQuery, GroupDailyOtQueryVariables>;
export const namedOperations = {
  Query: {
    GroupDailyOT: 'GroupDailyOT'
  }
}