import { Actions, DataRow, FormField } from '../../types/DataTable';
import {
  State as Checked,
  Action as CheckedActions,
} from '../../reducers/useBulkCheckboxes';

import DTCell from './DTCell';

type Props = {
  actions?: Actions;
  checked: Checked;
  fields: FormField[];
  idx: number;
  row: DataRow;
  setChecked: React.Dispatch<CheckedActions>;
};

function isRecRateModel(fields: FormField[]) {
  let hasRate = false;
  let hasStatus = false;

  for (const field of fields) {
    if (field.name === 'rate') {
      hasRate = true;
    }
    if (field.name === 'status') {
      hasStatus = true;
    }
    if (hasRate && hasStatus) {
      return true;
    }
  }

  return false;
}

const DTRow = ({ actions, checked, fields, idx, row, setChecked }: Props) => {
  const { id } = row;
  const isRecRate = isRecRateModel(fields);

  const cells = fields.map(({ name, options, readOnly, type }, i) => {
    const isUploadedRecRate = row.status === 'UPLOADED' && isRecRate;

    readOnly = (isUploadedRecRate && name !== 'status') || readOnly;

    return (
      <DTCell
        key={`row-${idx}-cell-${i}`}
        actions={actions}
        attr={name}
        checked={checked}
        options={options}
        readOnly={readOnly}
        row={row}
        setChecked={setChecked}
        type={type}
      />
    );
  });

  return (
    <tr className='text-xs bg-gray-50'>
      <td className='flex items-center py-5 px-6 font-medium'>
        <input
          checked={checked[id] || false}
          readOnly={true}
          className='mr-3'
          type='checkbox'
          name={id}
          id={id}
          onClick={() => {
            setChecked({ key: id, type: 'TOGGLE_CHECK' });
          }}
        />
      </td>
      {cells}
    </tr>
  );
};

export default DTRow;
