import { Menu, Transition } from '@headlessui/react';
import { PiNumberCircleOneLight, PiNumberCircleTwoLight } from 'react-icons/pi';

import { AiFillDelete } from 'react-icons/ai';
import { BsUmbrellaFill } from 'react-icons/bs';
import { FindRmRecArgs } from '../../types/__generated__/globalTypes';
import { Fragment } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { MdOutlineContentCopy } from 'react-icons/md';
import { clsx } from 'clsx';
import { useCopyForecastsMutation } from '../../features/forecasts/gql/_gen_/forecasts.gql';
import { useResetForecastsMutation } from '../../features/forecasts/gql/_gen_/forecasts.gql';

type BtnProps = {
  btnClass: string;
  btnIconClass?: string;
  filters: FindRmRecArgs;
  handleToggleSuperscript: () => void;
};

export default function BtnForecastTools({
  btnClass,
  btnIconClass = 'h-5 w-5',
  filters,
  handleToggleSuperscript,
}: BtnProps) {
  const [copyForecasts, { loading: loadingCopy }] = useCopyForecastsMutation({
    refetchQueries: ['CombinedHotelForecast'],
  });
  const [resetForecasts, { loading: loadingReset }] = useResetForecastsMutation(
    {
      refetchQueries: ['CombinedHotelForecast'],
    }
  );

  const Copy = () => {
    return (
      <div className='text-cyan-700'>
        <MdOutlineContentCopy />
      </div>
    );
  };

  const Delete = () => {
    return (
      <div className='text-red-500'>
        <AiFillDelete />
      </div>
    );
  };

  const ForecastOne = () => {
    return (
      <div className='text-green-600'>
        <PiNumberCircleOneLight />
      </div>
    );
  };

  const ForecastTwo = () => {
    return (
      <div className='text-blue-800'>
        <PiNumberCircleTwoLight />
      </div>
    );
  };

  const items = [
    {
      key: 'copy-1-to-2',
      name: (
        <div className='flex justify-start items-center gap-2'>
          <Copy />
          <ForecastOne />
          <div>to</div>
          <ForecastTwo />
        </div>
      ),
      click: () => handleCopy('2'),
    },
    {
      key: 'copy-2-to-1',
      name: (
        <div className='flex justify-start items-center gap-2'>
          <Copy />
          <ForecastTwo />
          <div>to</div>
          <ForecastOne />
        </div>
      ),
      click: () => handleCopy('1'),
    },
    {
      key: 'clear-sold-1',
      name: (
        <div className='flex justify-start items-center gap-2'>
          <Delete />
          <div>Sold</div>
          <ForecastOne />
        </div>
      ),
      click: () => handleReset('1', 'sold'),
    },
    {
      key: 'clear-adr-1',
      name: (
        <div className='flex justify-start items-center gap-2'>
          <Delete />
          <div>ADR</div>
          <ForecastOne />
        </div>
      ),
      click: () => handleReset('1', 'adr'),
    },
    {
      key: 'clear-sold-2',
      name: (
        <div className='flex justify-start items-center gap-2'>
          <Delete />
          <div>Sold</div>
          <ForecastTwo />
        </div>
      ),
      click: () => handleReset('2', 'sold'),
    },
    {
      key: 'clear-adr-2',
      name: (
        <div className='flex justify-start items-center gap-2'>
          <Delete />
          <div>ADR</div>
          <ForecastTwo />
        </div>
      ),
      click: () => handleReset('2', 'adr'),
    },
    {
      key: 'reset-1',
      name: (
        <div className='flex justify-start items-center gap-2'>
          <div className='text-red-800 font-bold'>Reset</div>
          <ForecastOne />
        </div>
      ),
      click: () => handleReset('1', 'both'),
    },
    {
      key: 'reset-2',
      name: (
        <div className='flex justify-start items-center gap-2'>
          <div className='text-red-800 font-bold'>Reset</div>
          <ForecastTwo />
        </div>
      ),
      click: () => handleReset('2', 'both'),
    },
  ];

  const handleCopy = (target: '1' | '2') => {
    copyForecasts({
      variables: {
        hotelId: filters.hotelId,
        target,
      },
    });
  };

  const handleReset = (
    target: '1' | '2' | 'both',
    metric: 'sold' | 'adr' | 'both'
  ) => {
    resetForecasts({
      variables: {
        hotelId: filters.hotelId,
        target,
        metric,
      },
    });
  };

  const ToggleSuperscriptBtn = () => {
    return (
      <span
        onClick={handleToggleSuperscript}
        className={btnClass}
        data-tip='Toggle Forecast Superscript'
      >
        <BsUmbrellaFill
          aria-hidden='true'
          className={`${
            loadingCopy || loadingReset ? 'animate-spin' : ''
          } ${btnIconClass} cursor-pointer`}
        />
      </span>
    );
  };

  return (
    <div className='relative z-30 inline-flex shadow-sm rounded-md'>
      <ToggleSuperscriptBtn />
      <Menu as='div' className='-ml-px relative block'>
        <Menu.Button className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'>
          <span className='sr-only'>Open options</span>
          <HiChevronDown className={btnIconClass} aria-hidden='true' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='origin-top-left absolute left-0 mt-2 -mr-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='py-1 w-60 grid grid-cols-2'>
              {items.map((item) => (
                <Menu.Item key={item.key}>
                  {({ active }) => (
                    <button
                      onClick={item.click}
                      className={clsx(
                        active ? ' text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
