/* eslint-disable */
import * as Types from '../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HotelMonthlyBudgetsQueryVariables = Types.Exact<{
  filters: Types.FindHotelMonthlyBudgetArgs;
}>;


export type HotelMonthlyBudgetsQuery = { __typename?: 'Query', hotelMonthlyBudgets?: Array<{ __typename?: 'MonthlyBudget', id: string, hotel_id: number, month: number, year: number, budget_adr?: number | null, budget_sold?: number | null, budget_revenue?: number | null } | null> | null };

export type CreateMonthlyBudgetMutationVariables = Types.Exact<{
  monthlyBudget: Types.CreateMonthlyBudgetInput;
}>;


export type CreateMonthlyBudgetMutation = { __typename?: 'Mutation', createMonthlyBudget?: { __typename?: 'MonthlyBudget', budget_adr?: number | null, budget_revenue?: number | null, budget_sold?: number | null, created_at?: string | null, created_by_id?: string | null, hotel_id: number, id: string, month: number, stay_date?: any | null, year: number, updated_at?: string | null } | null };

export type UpdateMonthlyBudgetMutationVariables = Types.Exact<{
  updateMonthlyBudgetId: Types.Scalars['String'];
  monthlyBudget: Types.UpdateMonthlyBudgetInput;
}>;


export type UpdateMonthlyBudgetMutation = { __typename?: 'Mutation', updateMonthlyBudget?: { __typename?: 'MonthlyBudget', budget_adr?: number | null, budget_revenue?: number | null, budget_sold?: number | null, hotel_id: number, id: string, month: number, year: number } | null };

export type DeleteMonthlyBudgetMutationVariables = Types.Exact<{
  deleteMonthlyBudgetId: Types.Scalars['String'];
}>;


export type DeleteMonthlyBudgetMutation = { __typename?: 'Mutation', deleteMonthlyBudget?: number | null };


export const HotelMonthlyBudgetsDocument = gql`
    query HotelMonthlyBudgets($filters: FindHotelMonthlyBudgetArgs!) {
  hotelMonthlyBudgets(filters: $filters) {
    id
    hotel_id
    month
    year
    budget_adr
    budget_sold
    budget_revenue
  }
}
    `;

/**
 * __useHotelMonthlyBudgetsQuery__
 *
 * To run a query within a React component, call `useHotelMonthlyBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelMonthlyBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelMonthlyBudgetsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useHotelMonthlyBudgetsQuery(baseOptions: Apollo.QueryHookOptions<HotelMonthlyBudgetsQuery, HotelMonthlyBudgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HotelMonthlyBudgetsQuery, HotelMonthlyBudgetsQueryVariables>(HotelMonthlyBudgetsDocument, options);
      }
export function useHotelMonthlyBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HotelMonthlyBudgetsQuery, HotelMonthlyBudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HotelMonthlyBudgetsQuery, HotelMonthlyBudgetsQueryVariables>(HotelMonthlyBudgetsDocument, options);
        }
export type HotelMonthlyBudgetsQueryHookResult = ReturnType<typeof useHotelMonthlyBudgetsQuery>;
export type HotelMonthlyBudgetsLazyQueryHookResult = ReturnType<typeof useHotelMonthlyBudgetsLazyQuery>;
export type HotelMonthlyBudgetsQueryResult = Apollo.QueryResult<HotelMonthlyBudgetsQuery, HotelMonthlyBudgetsQueryVariables>;
export const CreateMonthlyBudgetDocument = gql`
    mutation CreateMonthlyBudget($monthlyBudget: CreateMonthlyBudgetInput!) {
  createMonthlyBudget(monthlyBudget: $monthlyBudget) {
    budget_adr
    budget_revenue
    budget_sold
    created_at
    created_by_id
    hotel_id
    id
    month
    stay_date
    year
    updated_at
  }
}
    `;
export type CreateMonthlyBudgetMutationFn = Apollo.MutationFunction<CreateMonthlyBudgetMutation, CreateMonthlyBudgetMutationVariables>;

/**
 * __useCreateMonthlyBudgetMutation__
 *
 * To run a mutation, you first call `useCreateMonthlyBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonthlyBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonthlyBudgetMutation, { data, loading, error }] = useCreateMonthlyBudgetMutation({
 *   variables: {
 *      monthlyBudget: // value for 'monthlyBudget'
 *   },
 * });
 */
export function useCreateMonthlyBudgetMutation(baseOptions?: Apollo.MutationHookOptions<CreateMonthlyBudgetMutation, CreateMonthlyBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMonthlyBudgetMutation, CreateMonthlyBudgetMutationVariables>(CreateMonthlyBudgetDocument, options);
      }
export type CreateMonthlyBudgetMutationHookResult = ReturnType<typeof useCreateMonthlyBudgetMutation>;
export type CreateMonthlyBudgetMutationResult = Apollo.MutationResult<CreateMonthlyBudgetMutation>;
export type CreateMonthlyBudgetMutationOptions = Apollo.BaseMutationOptions<CreateMonthlyBudgetMutation, CreateMonthlyBudgetMutationVariables>;
export const UpdateMonthlyBudgetDocument = gql`
    mutation UpdateMonthlyBudget($updateMonthlyBudgetId: String!, $monthlyBudget: UpdateMonthlyBudgetInput!) {
  updateMonthlyBudget(id: $updateMonthlyBudgetId, monthlyBudget: $monthlyBudget) {
    budget_adr
    budget_revenue
    budget_sold
    hotel_id
    id
    month
    year
  }
}
    `;
export type UpdateMonthlyBudgetMutationFn = Apollo.MutationFunction<UpdateMonthlyBudgetMutation, UpdateMonthlyBudgetMutationVariables>;

/**
 * __useUpdateMonthlyBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyBudgetMutation, { data, loading, error }] = useUpdateMonthlyBudgetMutation({
 *   variables: {
 *      updateMonthlyBudgetId: // value for 'updateMonthlyBudgetId'
 *      monthlyBudget: // value for 'monthlyBudget'
 *   },
 * });
 */
export function useUpdateMonthlyBudgetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMonthlyBudgetMutation, UpdateMonthlyBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMonthlyBudgetMutation, UpdateMonthlyBudgetMutationVariables>(UpdateMonthlyBudgetDocument, options);
      }
export type UpdateMonthlyBudgetMutationHookResult = ReturnType<typeof useUpdateMonthlyBudgetMutation>;
export type UpdateMonthlyBudgetMutationResult = Apollo.MutationResult<UpdateMonthlyBudgetMutation>;
export type UpdateMonthlyBudgetMutationOptions = Apollo.BaseMutationOptions<UpdateMonthlyBudgetMutation, UpdateMonthlyBudgetMutationVariables>;
export const DeleteMonthlyBudgetDocument = gql`
    mutation DeleteMonthlyBudget($deleteMonthlyBudgetId: String!) {
  deleteMonthlyBudget(id: $deleteMonthlyBudgetId)
}
    `;
export type DeleteMonthlyBudgetMutationFn = Apollo.MutationFunction<DeleteMonthlyBudgetMutation, DeleteMonthlyBudgetMutationVariables>;

/**
 * __useDeleteMonthlyBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteMonthlyBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonthlyBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonthlyBudgetMutation, { data, loading, error }] = useDeleteMonthlyBudgetMutation({
 *   variables: {
 *      deleteMonthlyBudgetId: // value for 'deleteMonthlyBudgetId'
 *   },
 * });
 */
export function useDeleteMonthlyBudgetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMonthlyBudgetMutation, DeleteMonthlyBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMonthlyBudgetMutation, DeleteMonthlyBudgetMutationVariables>(DeleteMonthlyBudgetDocument, options);
      }
export type DeleteMonthlyBudgetMutationHookResult = ReturnType<typeof useDeleteMonthlyBudgetMutation>;
export type DeleteMonthlyBudgetMutationResult = Apollo.MutationResult<DeleteMonthlyBudgetMutation>;
export type DeleteMonthlyBudgetMutationOptions = Apollo.BaseMutationOptions<DeleteMonthlyBudgetMutation, DeleteMonthlyBudgetMutationVariables>;
export const namedOperations = {
  Query: {
    HotelMonthlyBudgets: 'HotelMonthlyBudgets'
  },
  Mutation: {
    CreateMonthlyBudget: 'CreateMonthlyBudget',
    UpdateMonthlyBudget: 'UpdateMonthlyBudget',
    DeleteMonthlyBudget: 'DeleteMonthlyBudget'
  }
}