import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { RoomColumn, RoomType } from './tables/rooms/RoomsTable';
import { useEffect, useState } from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FcCollapse } from 'react-icons/fc';
import { FixMeLater } from '../types/FixMeLaterType';
import { GetRateLevelStatusQuery } from '../gql/_gen_/rate-levels.gql';
import ReactTooltip from 'react-tooltip';
import { RowData } from './tables/rateLevels/RateLevelTable';
import clsx from 'clsx';
import dayjs from 'dayjs';

type TableHeaderProps = {
  tableName: string;
  hidden: boolean;
  setHidden: Function;
  scrollDates: Function;
  loading: boolean;
  page: string;
};

type DateHeaderProps = {
  date: string;
  sticky?: boolean;
  page?: string;
};

type CellProps = {
  value: string;
  color: string | null | undefined;
  data: FixMeLater;
  page: string;
};

type Props = {
  tableName: string;
  data: RoomColumn[] | GetRateLevelStatusQuery['rateLevelStatus'];
  tableKey: string;
  scrollDates: Function;
  cellColor: Function;
  rowData: (string | RoomType)[][] | (string | RowData)[][];
  loading: boolean;
  roomTooltips?: Map<string, string> | undefined;
  getTableKey?: Function;
  page: string;
};

function TableHeader({
  tableName,
  hidden,
  setHidden,
  scrollDates,
  loading,
  page,
}: TableHeaderProps) {
  return (
    <div className={`text-blue-900 text-md sticky left-0 font-semibold mb-2`}>
      <span className='flex items-center'>
        {page} {tableName}{' '}
        <div className='mx-1.5'>
          <button
            onClick={() => scrollDates('prev')}
            disabled={loading}
            className='disabled:opacity-50 disabled:cursor-not-allowed relative inline-flex items-center px-1 py-1 border border-gray-300 bg-white text-gray-500 focus:z-10 rounded-l-md text-sm h-7 w-7 justify-center'
            data-tip='Previous 90 Days'
          >
            <FaArrowAltCircleLeft />
          </button>
          <button
            onClick={() => scrollDates('next')}
            disabled={loading}
            className='disabled:opacity-50 disabled:cursor-not-allowed relative inline-flex items-center px-1 py-1 border border-gray-300 bg-white text-gray-500 focus:z-10 rounded-r-md text-sm h-7 w-7 justify-center'
            data-tip='Next 90 Days'
          >
            <FaArrowAltCircleRight />
          </button>
        </div>
        <button onClick={() => setHidden(!hidden)}>
          <FcCollapse
            className={`inline mx-1 ${hidden ? null : 'rotate-180'}`}
          />
        </button>
        {loading && (
          <AiOutlineLoading3Quarters className='animate-spin ml-2 text-blue-900' />
        )}
      </span>
    </div>
  );
}

function DateHeader({ date, sticky, page }: DateHeaderProps) {
  return (
    <div
      className={clsx(
        `top-0 bg-blue-200 font-semibold tracking-tighter px-2.5 py-1 text-center ${
          sticky
            ? `sticky left-0 ${
                page === 'Room Type' ? 'min-w-[90px]' : 'min-w-[45px]'
              }`
            : `min-w-[50px]`
        }`
      )}
    >
      {date}
    </div>
  );
}

function TableCell({ value, color, data, page }: CellProps) {
  const toolTip =
    page === 'Room Type'
      ? `<div>
        <p>${dayjs(data.stay_date).format('MMMM D, YYYY')}</p>
        <p>Room ${data.room_code}</p>
        <p>Status ${data.rt_status}</p>
        <p>Sold ${data.rt_sold}</p>
        <p>Remaining ${data.rt_remaining}</p>
        <p>Flex Rate $${data.rt_flex_rate_raw || data.rt_flex_rate}</p>
      </div>
      `
      : page === 'Rate Level'
      ? `<div>
        <p>${dayjs(data.stay_date).format('MMMM D, YYYY')}</p>
        <p>Level </p>
        <p>Status ${data.status}</p>
        <p>Sold ${data.sold}</p>
        <p>Remaining ${data.remaining}</p>
      </div>
      `
      : null;

  return (
    <div
      className={`${color} text-center px-2.5 py-1 min-w-[50px]`}
      data-tip={toolTip}
      data-for='RoomsTooltip'
    >
      {value}
    </div>
  );
}

export function TableWidget({
  data,
  tableName,
  tableKey,
  scrollDates,
  cellColor,
  rowData,
  loading,
  roomTooltips,
  page,
}: Props) {
  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [rowData, hidden]);

  //#region Date Headers
  const dates = data?.map((x) => x?.stay_date) || [];

  const dateHeaders = data
    ? [
        <DateHeader date={''} key='blank' sticky={true} page={page} />,
        ...dates?.map((date: string) => {
          return (
            <DateHeader
              date={dayjs(date).format('M/D')}
              key={`${date}-${tableName}`}
            />
          );
        }),
      ]
    : null;
  //#endregion Date Headers

  const rows = rowData.map(
    (
      row: Array<string | RoomType> | Array<string | RowData>,
      index: number
    ) => {
      const toolTip = roomTooltips?.get(row[0] as string);
      return (
        <div className='flex' key={index}>
          {tableKey &&
            row.map((item: FixMeLater, index: number) => {
              return typeof item === 'string' ? (
                <div
                  className={clsx(
                    `sticky left-0 bg-white flex font-semibold`,
                    page === 'Room Type'
                      ? 'min-w-[90px] text-left pl-1.5'
                      : 'min-w-[45px] justify-center'
                  )}
                  key={item}
                  data-tip={toolTip}
                >
                  {item}
                </div>
              ) : (
                <TableCell
                  value={item[tableKey]}
                  color={cellColor(item)}
                  key={index}
                  data={item}
                  page={page}
                />
              );
            })}
        </div>
      );
    }
  );

  return (
    <div className={`flex flex-col overflow-auto `}>
      <TableHeader
        tableName={tableName}
        hidden={hidden}
        setHidden={setHidden}
        scrollDates={scrollDates}
        loading={loading}
        page={page}
      />
      {!loading && !hidden ? (
        <div>
          <div className='flex'>{dateHeaders}</div>
          {rows}
        </div>
      ) : null}
      <ReactTooltip html={true} multiline={true} id='RoomsTooltip' />
    </div>
  );
}
