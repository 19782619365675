import { AddEventForm } from './add-event-form';
import { DataTable } from './data-table';
import { getEventColumns } from './event-columns';
import { useEventManagement } from '@/features/events/hooks/use-event-management';

export function EventManagement() {
  const {
    cancelEditing,
    deleteEvent,
    editForm,
    editingId,
    events,
    form,
    initialPageIndex,
    loading,
    onSubmit,
    pageSize,
    saveEdit,
    startEditing,
  } = useEventManagement();

  const columns = getEventColumns(
    editingId,
    editForm,
    startEditing,
    saveEdit,
    cancelEditing,
    deleteEvent
  );

  return (
    <div className='container mx-auto p-4'>
      <h1 className='text-2xl font-bold mb-4'>Event Management</h1>

      <AddEventForm form={form} onSubmit={onSubmit} />

      <DataTable
        columns={columns}
        data={events}
        editForm={editForm}
        editingId={editingId}
        initialPageIndex={initialPageIndex}
        loading={loading}
        onCancel={cancelEditing}
        onDelete={deleteEvent}
        onEdit={startEditing}
        onSave={saveEdit}
        pageSize={pageSize}
      />
    </div>
  );
}
