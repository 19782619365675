import { getYear, today } from './helpers/dateHelpers';
import { useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Changelog = () => {
  const changelogPath = require('./CHANGELOG.md');
  const [changelog, setChangelog] = useState('');

  useEffect(() => {
    fetch(changelogPath)
      .then((res) => res.text())
      .then((text) => setChangelog(text));
  }, [changelogPath]);

  return (
    <>
      <div className='min-h-full'>
        <div className='flex flex-col h-full pt-16 pl-16'>
          <h1 className='text-xl text-sky-900 mb-10'>Changelog</h1>
          <ReactMarkdown children={changelog} remarkPlugins={[remarkGfm]} />
        </div>
        <footer>
          <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
            <div className='border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left'>
              <span className='block sm:inline'>
                &copy; {getYear(today())} Best Western Hotels.
              </span>{' '}
              <span className='block sm:inline'>All rights reserved.</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Changelog;
