import { displayDate, today } from '../helpers/dateHelpers';

import Chart from '../components/Chart';
import { RmData } from '../graphql/types';
import { heatMap } from './heat';
import { useMemo } from 'react';

export type Props = {
  data?: RmData[];
  loading: boolean;
  metric?: string;
  name: string;
  reportDate?: string;
};

const HeatMap = ({
  data,
  loading,
  metric = 'occ_rc',
  name,
  reportDate = today(),
}: Props) => {
  const heatMapOptions = useMemo(() => {
    if (!data) {
      return;
    }

    if (!data.length) {
      return;
    }

    const heatMapDate = displayDate(reportDate);
    const chartOptions = heatMap(data, metric, reportDate);

    return { chartOptions, heatMapDate };
  }, [data, metric, reportDate]);

  if (!heatMapOptions || loading) {
    return <div>Loading {name} Widget...</div>;
  } else {
    return (
      <div className='pt-0'>
        <Chart chartOptions={heatMapOptions.chartOptions} />
      </div>
    );
  }
};

export default HeatMap;
