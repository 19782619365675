import { BestrevData, RmData, Room } from '../graphql/types';
import {
  firstOfMonth,
  formatDate,
  getMonthYear,
  monthsOut,
  today,
} from '../helpers/dateHelpers';

import Highcharts from 'highcharts/highstock';
import dayjs from 'dayjs';
import { masterColumns } from '../renderers/masterColumns';

interface CustomPoint extends Highcharts.Point {
  metric: string;
  value?: number;
}

type HeatData = RmData | BestrevData | Room;

const defaultMetric = 'occ';

const formatValue = (metric: string, value?: number) => {
  if (metric.includes('occ') && value) {
    return `${Math.round(value)}%`;
  } else if (value && (metric.includes('adr') || metric.includes('revenue'))) {
    return `$${Math.round(value)}`;
  } else {
    return `${value}`;
  }
};

const genCategories = () => {
  return Array.from(Array(12).keys()).map((months) =>
    getMonthYear(monthsOut({ months }))
  );
};

const genData = (
  metric: string,
  dataObj: RmData[] | BestrevData[] | Room[],
  reportDate = today()
) => {
  let firstDay = firstOfMonth({ date: dayjs(reportDate) });
  let finalDay = monthsOut({
    date: dayjs(reportDate),
    months: 11,
    firstOrLast: 'last',
  });
  let results = [];

  for (let i = 0; i < dataObj.length; i++) {
    let d = dataObj[i];
    if (d.stay_date >= firstDay && d.stay_date <= finalDay) {
      let cellValue = d[metric as keyof HeatData];
      if (metric.includes('occ')) {
        cellValue = Math.round(cellValue * 100);
      }

      results.push({
        x: parseInt(formatDate(d.stay_date, 'D')),
        y: widgetDataMonth(d.stay_date, reportDate),
        value: cellValue === 0 ? undefined : cellValue,
        color: cellValue === 0 ? '#FFFFFF' : undefined,
        metric: metric,
        tooltip:
          cellValue === 0
            ? undefined
            : `${formatDate(d.stay_date, 'MMM DD, YYYY')}: <b>${formatValue(
                metric,
                cellValue
              )}</b>`,
      });
    }
  }
  return results;
};

const widgetDataMonth = (date: string, reportDate = today()) => {
  let repMonth = Number(formatDate(reportDate, 'M'));
  let dateMonth = Number(formatDate(date, 'M'));
  if (dateMonth >= repMonth) {
    return dateMonth - repMonth;
  } else {
    return 12 - (repMonth - dateMonth);
  }
};

const createSeriesData = (
  metric: string,
  dataObj: RmData[] | BestrevData[] | Room[],
  reportDate = today()
) => {
  return {
    type: 'heatmap',
    name: masterColumns[metric].tooltip,
    data: genData(metric, dataObj, reportDate),
    metric,
    dataLabels: {
      enabled: true,
      formatter: function () {
        if ((this.point as CustomPoint).value === 0) {
          return undefined;
        } else {
          return (this.point as CustomPoint).value;
        }
      },
      style: {
        color: 'black',
        fontSize: '0.75em',
        textOutline: 'none',
      },
    },
  } as Highcharts.SeriesHeatmapOptions;
};

export function heatMap(
  dataObj: RmData[] | BestrevData[] | Room[],
  metric = defaultMetric,
  reportDate = today()
): Highcharts.Options {
  let options = {
    chart: {
      plotBorderWidth: 1,
      type: 'heatmap',
    } as Highcharts.ChartOptions,
    colorAxis: {
      stops: [
        [0, '#d0ebfb'],
        [0.31, '#adebad'],
        [0.61, '#ffe099'],
        [0.85, '#ffcccc'],
      ],
    } as Highcharts.ColorAxisOptions,
    exporting: {
      enabled: false,
    } as Highcharts.ExportingOptions,
    title: {
      text: undefined,
    } as Highcharts.TitleOptions,
    plotOptions: {
      heatmap: {
        borderColor: '#ffffff',
        borderWidth: 1,
        tooltip: {
          pointFormat: '{point.tooltip}',
        },
      },
    } as Highcharts.PlotOptions,
    series: [createSeriesData(metric, dataObj, reportDate)],
    yAxis: {
      categories: genCategories(),
      reversed: true,
      title: undefined,
    } as Highcharts.YAxisOptions,
  };

  return options;
}
