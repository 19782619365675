/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { TeamMemberFieldsFragmentDoc } from './hotel-team.fragment.gql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHotelTeamQueryVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  active?: Types.InputMaybe<Types.TeamMemberActive>;
}>;


export type GetHotelTeamQuery = { __typename?: 'Query', getHotelTeam?: Array<{ __typename?: 'TeamMember', active?: Types.TeamMemberActive | null, activeChanged?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, email?: string | null, emailPref?: Types.MeetingEmail | null, id?: string | null, name?: string | null, phone?: string | null, title?: string | null, updatedAt?: string | null } | null> | null };

export type AddTeamMemberMutationVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  createdById: Types.Scalars['String'];
  email: Types.Scalars['String'];
  emailPref?: Types.InputMaybe<Types.MeetingEmail>;
  name: Types.Scalars['String'];
  active?: Types.InputMaybe<Types.TeamMemberActive>;
  phone?: Types.InputMaybe<Types.Scalars['String']>;
  title?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AddTeamMemberMutation = { __typename?: 'Mutation', addTeamMember?: { __typename?: 'TeamMember', active?: Types.TeamMemberActive | null, activeChanged?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, email?: string | null, emailPref?: Types.MeetingEmail | null, id?: string | null, name?: string | null, phone?: string | null, title?: string | null, updatedAt?: string | null } | null };

export type ReactivateTeamMemberMutationVariables = Types.Exact<{
  reactivateTeamMemberId: Types.Scalars['String'];
}>;


export type ReactivateTeamMemberMutation = { __typename?: 'Mutation', reactivateTeamMember?: { __typename?: 'TeamMember', active?: Types.TeamMemberActive | null, activeChanged?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, email?: string | null, emailPref?: Types.MeetingEmail | null, id?: string | null, name?: string | null, phone?: string | null, title?: string | null, updatedAt?: string | null } | null };

export type RemoveTeamMemberMutationVariables = Types.Exact<{
  removeTeamMemberId: Types.Scalars['String'];
}>;


export type RemoveTeamMemberMutation = { __typename?: 'Mutation', removeTeamMember?: { __typename?: 'TeamMember', active?: Types.TeamMemberActive | null, activeChanged?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, email?: string | null, emailPref?: Types.MeetingEmail | null, id?: string | null, name?: string | null, phone?: string | null, title?: string | null, updatedAt?: string | null } | null };

export type UpdateTeamMemberMutationVariables = Types.Exact<{
  updateTeamMemberId: Types.Scalars['String'];
  active?: Types.InputMaybe<Types.TeamMemberActive>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
  emailPref?: Types.InputMaybe<Types.MeetingEmail>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  phone?: Types.InputMaybe<Types.Scalars['String']>;
  title?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateTeamMemberMutation = { __typename?: 'Mutation', updateTeamMember?: { __typename?: 'TeamMember', active?: Types.TeamMemberActive | null, activeChanged?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, email?: string | null, emailPref?: Types.MeetingEmail | null, id?: string | null, name?: string | null, phone?: string | null, title?: string | null, updatedAt?: string | null } | null };


export const GetHotelTeamDocument = gql`
    query GetHotelTeam($brandCode: String!, $active: TeamMemberActive) {
  getHotelTeam(brandCode: $brandCode, active: $active) {
    ...TeamMemberFields
  }
}
    ${TeamMemberFieldsFragmentDoc}`;

/**
 * __useGetHotelTeamQuery__
 *
 * To run a query within a React component, call `useGetHotelTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelTeamQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetHotelTeamQuery(baseOptions: Apollo.QueryHookOptions<GetHotelTeamQuery, GetHotelTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHotelTeamQuery, GetHotelTeamQueryVariables>(GetHotelTeamDocument, options);
      }
export function useGetHotelTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHotelTeamQuery, GetHotelTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHotelTeamQuery, GetHotelTeamQueryVariables>(GetHotelTeamDocument, options);
        }
export type GetHotelTeamQueryHookResult = ReturnType<typeof useGetHotelTeamQuery>;
export type GetHotelTeamLazyQueryHookResult = ReturnType<typeof useGetHotelTeamLazyQuery>;
export type GetHotelTeamQueryResult = Apollo.QueryResult<GetHotelTeamQuery, GetHotelTeamQueryVariables>;
export const AddTeamMemberDocument = gql`
    mutation AddTeamMember($brandCode: String!, $createdById: String!, $email: String!, $emailPref: MeetingEmail, $name: String!, $active: TeamMemberActive, $phone: String, $title: String) {
  addTeamMember(
    brandCode: $brandCode
    createdById: $createdById
    email: $email
    emailPref: $emailPref
    name: $name
    active: $active
    phone: $phone
    title: $title
  ) {
    ...TeamMemberFields
  }
}
    ${TeamMemberFieldsFragmentDoc}`;
export type AddTeamMemberMutationFn = Apollo.MutationFunction<AddTeamMemberMutation, AddTeamMemberMutationVariables>;

/**
 * __useAddTeamMemberMutation__
 *
 * To run a mutation, you first call `useAddTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMemberMutation, { data, loading, error }] = useAddTeamMemberMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      createdById: // value for 'createdById'
 *      email: // value for 'email'
 *      emailPref: // value for 'emailPref'
 *      name: // value for 'name'
 *      active: // value for 'active'
 *      phone: // value for 'phone'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useAddTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTeamMemberMutation, AddTeamMemberMutationVariables>(AddTeamMemberDocument, options);
      }
export type AddTeamMemberMutationHookResult = ReturnType<typeof useAddTeamMemberMutation>;
export type AddTeamMemberMutationResult = Apollo.MutationResult<AddTeamMemberMutation>;
export type AddTeamMemberMutationOptions = Apollo.BaseMutationOptions<AddTeamMemberMutation, AddTeamMemberMutationVariables>;
export const ReactivateTeamMemberDocument = gql`
    mutation ReactivateTeamMember($reactivateTeamMemberId: String!) {
  reactivateTeamMember(id: $reactivateTeamMemberId) {
    ...TeamMemberFields
  }
}
    ${TeamMemberFieldsFragmentDoc}`;
export type ReactivateTeamMemberMutationFn = Apollo.MutationFunction<ReactivateTeamMemberMutation, ReactivateTeamMemberMutationVariables>;

/**
 * __useReactivateTeamMemberMutation__
 *
 * To run a mutation, you first call `useReactivateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateTeamMemberMutation, { data, loading, error }] = useReactivateTeamMemberMutation({
 *   variables: {
 *      reactivateTeamMemberId: // value for 'reactivateTeamMemberId'
 *   },
 * });
 */
export function useReactivateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<ReactivateTeamMemberMutation, ReactivateTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReactivateTeamMemberMutation, ReactivateTeamMemberMutationVariables>(ReactivateTeamMemberDocument, options);
      }
export type ReactivateTeamMemberMutationHookResult = ReturnType<typeof useReactivateTeamMemberMutation>;
export type ReactivateTeamMemberMutationResult = Apollo.MutationResult<ReactivateTeamMemberMutation>;
export type ReactivateTeamMemberMutationOptions = Apollo.BaseMutationOptions<ReactivateTeamMemberMutation, ReactivateTeamMemberMutationVariables>;
export const RemoveTeamMemberDocument = gql`
    mutation RemoveTeamMember($removeTeamMemberId: String!) {
  removeTeamMember(id: $removeTeamMemberId) {
    ...TeamMemberFields
  }
}
    ${TeamMemberFieldsFragmentDoc}`;
export type RemoveTeamMemberMutationFn = Apollo.MutationFunction<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;

/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMemberMutation, { data, loading, error }] = useRemoveTeamMemberMutation({
 *   variables: {
 *      removeTeamMemberId: // value for 'removeTeamMemberId'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, options);
      }
export type RemoveTeamMemberMutationHookResult = ReturnType<typeof useRemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationResult = Apollo.MutationResult<RemoveTeamMemberMutation>;
export type RemoveTeamMemberMutationOptions = Apollo.BaseMutationOptions<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>;
export const UpdateTeamMemberDocument = gql`
    mutation UpdateTeamMember($updateTeamMemberId: String!, $active: TeamMemberActive, $email: String, $emailPref: MeetingEmail, $name: String, $phone: String, $title: String) {
  updateTeamMember(
    id: $updateTeamMemberId
    active: $active
    email: $email
    emailPref: $emailPref
    name: $name
    phone: $phone
    title: $title
  ) {
    ...TeamMemberFields
  }
}
    ${TeamMemberFieldsFragmentDoc}`;
export type UpdateTeamMemberMutationFn = Apollo.MutationFunction<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>;

/**
 * __useUpdateTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberMutation, { data, loading, error }] = useUpdateTeamMemberMutation({
 *   variables: {
 *      updateTeamMemberId: // value for 'updateTeamMemberId'
 *      active: // value for 'active'
 *      email: // value for 'email'
 *      emailPref: // value for 'emailPref'
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(UpdateTeamMemberDocument, options);
      }
export type UpdateTeamMemberMutationHookResult = ReturnType<typeof useUpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationResult = Apollo.MutationResult<UpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>;
export const namedOperations = {
  Query: {
    GetHotelTeam: 'GetHotelTeam'
  },
  Mutation: {
    AddTeamMember: 'AddTeamMember',
    ReactivateTeamMember: 'ReactivateTeamMember',
    RemoveTeamMember: 'RemoveTeamMember',
    UpdateTeamMember: 'UpdateTeamMember'
  }
}