import { BulkDates, DayFilters, FormField } from '../types/DataTable';

import { defaultDays } from '../components/data_table/FilterDays';
import { today } from '../helpers/dateHelpers';
import { useReducer } from 'react';

export enum ActionTypes {
  DEFAULTS,
  UPDATE,
}

export type Action = {
  type: ActionTypes;
  payload?: {
    bulkDates?: BulkDates;
    bulkDays?: DayFilters;
    createAnother?: boolean;
    enableBulk?: boolean;
    form?: FormField[];
  };
};

export type State = {
  bulkDates: BulkDates;
  bulkDays: DayFilters;
  createAnother: boolean;
  enableBulk: boolean;
  form: FormField[];
};

type OptionOverrides = {
  bulkDates?: BulkDates;
  bulkDays?: DayFilters;
  createAnother?: boolean;
  enableBulk?: boolean;
  form?: FormField[];
};

const initialState: State = {
  bulkDates: { bulkStart: today(), bulkEnd: today() },
  bulkDays: defaultDays,
  createAnother: false,
  enableBulk: false,
  form: [],
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.DEFAULTS:
      return { ...initialState, ...payload };
    case ActionTypes.UPDATE:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const useDTCreateForm = (overrides?: OptionOverrides) => {
  return useReducer(reducer, { ...initialState, ...overrides });
};
