import { FaCheck, FaSpinner } from 'react-icons/fa';
import { Status, StatusDisplayOptions } from '../../types/status';

import { AiFillSave } from 'react-icons/ai';

export const OPTIONS: Record<string, StatusDisplayOptions> = {
  [Status.SAVING]: {
    color: 'text-orange-500',
    icon: FaSpinner,
    iconClass: 'animate-spin',
    label: 'Saving',
  },
  [Status.SAVED]: {
    color: 'text-green-600',
    icon: FaCheck,
    label: 'Saved',
  },
  [Status.UNSAVED]: {
    color: 'text-red-600',
    extraClass: 'min-w-fit',
    icon: AiFillSave,
    label: 'Save',
  },
};
