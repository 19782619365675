import { getValue, saveValue } from '../../../helpers/localStorageHelper';

import { AiOutlineLink } from 'react-icons/ai';
import BtnSaveMeeting from './btn-save-meeting';
import { Email } from '../utils/email-old';
import ReactTooltip from 'react-tooltip';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useHotel } from '../../../context/hotelContext';
import { useMeeting } from '../../meetings/context/meeting-context';
import { useReportData } from '../context/report-data-context';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';
import { useUploadData } from '../../meetings/hooks/use-upload-data';
import { useUser } from '../../../context/userContext';

type BtnShareMeetingProps = {
  btnClass: string;
  btnIconClass?: string;
  loading: Array<string>;
};

type LoadingTooltipProps = {
  buttonType?: string;
  loading: Array<string>;
};

export const LoadingTooltip = ({
  buttonType,
  loading,
}: LoadingTooltipProps) => {
  return (
    <ReactTooltip id='loading-data' aria-haspopup='true'>
      <span className='text-xs'>
        {loading.length
          ? 'Loading:'
          : buttonType
          ? `${buttonType} Meeting`
          : ''}
      </span>
      <ul className='mb-1'>
        {loading.length
          ? loading.map((item) => (
              <li key={item} className='text-xs'>
                {item}
              </li>
            ))
          : null}
      </ul>
    </ReactTooltip>
  );
};

const BtnShareMeeting = ({
  btnClass,
  btnIconClass = 'h-5 w-5',
  loading,
}: BtnShareMeetingProps) => {
  const { meeting, meetingTools, saveMeeting, loadingUpdateMeeting } =
    useMeeting();
  const { data, loading: dataLoading } = useReportData();
  const { user } = useUser();
  const { hotel } = useHotel();
  const { storeMeetingData } = useUploadData({
    data,
    dataLoading,
    hotel,
    meeting,
    meetingTools,
    user,
  });

  const track = useTrackMeetingEvent({ meeting, page: 'btn_share_meeting' });

  const readyToShare = (showToast = true) => {
    if (!meeting) {
      showToast && toastError('Meeting not found');
      return false;
    }
    if (!meetingTools) {
      showToast && toastError('Meeting tools not found');
      return false;
    }
    if (!user) {
      showToast && toastError('User not found');
      return false;
    }
    const { brandCode, meetingDate, bucket, bucketKey } = meeting;
    const { email: from } = user;

    if (!brandCode) {
      showToast && toastError('Brand code not found');
      return false;
    }
    if (!meetingDate) {
      showToast && toastError('Meeting date not found');
      return false;
    }
    if (!bucket || !bucketKey) {
      showToast && toastError('S3 bucket info not found');
      return false;
    }
    if (!from) {
      showToast && toastError('From email not found');
      return false;
    }
    return true;
  };

  const handleShareLinkClick = async () => {
    console.log('readyToShare', readyToShare());
    if (!readyToShare()) return;
    console.log('checking', meeting, meetingTools, user, user?.email);
    if (!meeting || !meetingTools || !user || !user.email) return;
    const { email: from } = user;

    const { mailUrl, messageBody } = Email.createMailtoLink({
      from,
      meeting,
      meetingTools,
    });

    console.log('mailUrl', mailUrl);
    console.log('messageBody', messageBody);

    const result = copy(messageBody, {
      format: 'text/html',
    });

    console.log('result', result);

    window.open(mailUrl, '_blank');

    const showAlert = getValue('showAlert');

    if (!showAlert) {
      window.alert(
        'Email body copied to your clipboard. Paste into your email that popped open.'
      );
      saveValue('showAlert', 'completed');
    }

    storeMeetingData && (await storeMeetingData());
    await handleSave();

    track('share_meeting', `meeting_${meeting.id}`);
  };

  const handleSave = async () => {
    await saveMeeting({ data, meetingTools });
  };

  const toastError = (msg: string) => {
    toast.error(msg, { position: 'top-left' });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [loading]);

  const ShareLinkBtn = () => {
    return (
      <>
        <span
          onClick={() => (loading.length ? null : handleShareLinkClick())}
          className={`${btnClass} ${'text-blue-500 ml-2'} ${
            loading.length
              ? 'opacity-50 text-orange-500 cursor-progress animate-spin'
              : 'cursor-pointer'
          }`}
          data-tip={'Share Link'}
        >
          <AiOutlineLink className={btnIconClass} aria-hidden='true' />
        </span>
        <LoadingTooltip buttonType='Share' loading={loading} />
      </>
    );
  };

  if (readyToShare(false)) {
    if (loadingUpdateMeeting) {
      return <span>Updating...</span>;
    }
    return (
      <span>
        <ShareLinkBtn />
      </span>
    );
  } else {
    return <BtnSaveMeeting btnClass={btnClass} loading={loading} />;
  }
};

export default BtnShareMeeting;
