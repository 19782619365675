import React, { useCallback, useState } from 'react';

import { FeatureFlag } from '../../../graphql/types';
import { Switch } from '../../../components/ui/switch';
import { useToast } from '../../../components/ui/use-toast';

type ToggleFlagBooleanProps = {
  columnName: 'active' | 'defaultValue';
  flag: FeatureFlag;
  updating: boolean;
  onToggle: (input: {
    id: string;
    description?: string;
    defaultValue?: boolean;
    key?: string;
    active?: boolean;
  }) => Promise<any>;
};

export const ToggleFlagBoolean: React.FC<ToggleFlagBooleanProps> = ({
  columnName,
  flag,
  onToggle,
  updating = false,
}) => {
  const { toast } = useToast();
  const [optimisticValue, setOptimisticValue] = useState<boolean | null>(null);

  const label = columnName === 'active' ? 'Active' : 'Default Value';
  const isChecked =
    optimisticValue !== null
      ? optimisticValue
      : columnName === 'active'
      ? flag.active
      : flag.default_value;

  const handleToggleActive = useCallback(async () => {
    const newValue = !isChecked;

    // Optimistically update the UI
    setOptimisticValue(newValue);

    try {
      const result = await onToggle({
        id: flag.id!,
        [columnName]: newValue,
      });

      if (result.success) {
        toast({
          title: result.title,
          description: result.description,
        });
        // Clear the optimistic value as the actual data has been updated
        setOptimisticValue(null);
      } else {
        throw new Error(result.description);
      }
    } catch (error) {
      // Revert the optimistic update on error
      setOptimisticValue(null);
      toast({
        title: 'Error',
        description:
          error instanceof Error
            ? error.message
            : 'An error occurred while updating the flag.',
        variant: 'destructive',
      });
    }
  }, [columnName, flag.id, isChecked, onToggle, toast]);

  return (
    <div className='flex items-center justify-between space-x-2'>
      <p className='text-sm font-medium'>{label}</p>
      <Switch
        checked={isChecked ?? false}
        disabled={updating}
        onCheckedChange={handleToggleActive}
      />
    </div>
  );
};
