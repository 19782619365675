import { CloseFn } from '../../../components/BtnPopover';
import { clsx } from 'clsx';
import { toast } from 'react-toastify';
import { useMeeting } from '../context/meeting-context';
import { useState } from 'react';
import { useUser } from '../../../context/userContext';

type FormProps = {
  brandCode?: string;
  closeForm?: CloseFn;
};

const AddTeamMemberForm = ({ brandCode, closeForm }: FormProps) => {
  const { addTeamMember } = useMeeting();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const { user } = useUser();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    validateEmail(e);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    validateName(e);
  };

  const handleSuccess = () => {
    toast.success(`${name} Added to Team`, { position: 'top-left' });
    // closeForm && closeForm();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (nameError) {
      toast.error('Please enter a valid name', { position: 'top-left' });
      return;
    }

    if (emailError) {
      toast.error('Please enter a valid email', { position: 'top-left' });
      return;
    }

    if (brandCode && user?.id) {
      await addTeamMember({
        variables: {
          brandCode,
          createdById: user.id,
          name,
          email,
          phone,
          title,
        },
        onCompleted: handleSuccess,
      });
    }

    setName('');
    setEmail('');
    setPhone('');
    setTitle('');
  };

  const validateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const email = target.value;
    const valid = email
      .toLowerCase()
      .match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    if (!valid) {
      return setEmailError(true);
    }

    if (target.value.length > 0) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const validateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    if (target.value.length > 0) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  return (
    <form className='mt-2' onSubmit={handleSubmit}>
      <div className='flex flex-col'>
        <div className='my-1 flex flex-col'>
          <div className='flex items-center'>
            <label className='text-gray-600 text-sm' htmlFor='name'>
              Name
            </label>
            {nameError && (
              <p className='text-xs text-red-600 ml-2'>Name cannot be empty</p>
            )}
          </div>
          <input
            type='text'
            id='name'
            className='border rounded-lg w-full p-1.5 text-sm'
            value={name}
            onChange={(e) => handleNameChange(e)}
          />
        </div>
        <div className='my-1 flex flex-col'>
          <div className='flex items-center'>
            <label className='text-gray-600 text-sm' htmlFor='email'>
              Email
            </label>
            {emailError && (
              <p className='text-xs text-red-600 ml-2'>
                Please enter a valid email
              </p>
            )}
          </div>
          <input
            type='text'
            id='email'
            className='border rounded-lg w-full p-1.5 text-sm'
            value={email}
            onChange={(e) => handleEmailChange(e)}
          />
        </div>

        <div className='my-1 flex flex-col'>
          <label className='text-gray-600 text-sm' htmlFor='phone'>
            Phone #
          </label>
          <input
            type='text'
            id='phone'
            className='border rounded-lg w-full p-1.5 text-sm'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className='my-1 flex flex-col'>
          <label className='text-gray-600 text-sm' htmlFor='title'>
            Title
          </label>
          <input
            type='text'
            id='title'
            className='border rounded-lg w-full p-1.5 text-sm'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </div>

      <div className='w-full flex justify-center mt-2'>
        <button
          type='submit'
          className={clsx(
            'bg-blue-800 py-1 px-2 rounded-md text-white text-sm mt-1.5',
            (nameError || emailError || !name.length || !email.length) &&
              'opacity-50'
          )}
          disabled={!name.length || !email.length || nameError || emailError}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export { AddTeamMemberForm };
