import { TableColumn, TableStyles } from 'react-data-table-component';

import DataTable from '../../../components/react-data-table/data-table-base';
import { RateChangeLog } from '../../../graphql/types';
import { dataDate } from '../../../helpers/dateHelpers';

const customStyles: TableStyles = {
  table: {
    style: {
      border: 'none',
      height: '80%',
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#6e6e6e',
    },
  },
  cells: {
    style: {
      fontSize: '12px',
      color: '#4a4a4a',
    },
  },
};

export const columns: TableColumn<RateChangeLog>[] = [
  {
    name: 'Stay Date',
    id: 'stayDate',
    center: true,
    selector: (row) => row.stayDate!,
    sortable: true,
    cell: (row) => dataDate(row.stayDate!),
  },
  {
    name: 'Rate',
    center: true,
    selector: (row) => row.rate!,
    sortable: true,
    cell: (row) => `$${row.rate!.toFixed(2)}`,
  },
  {
    name: 'Updated By',
    center: true,
    selector: (row) => row.updatedBy!,
    sortable: true,
    cell: (row) => row.updatedBy!.toUpperCase(),
  },
  {
    name: 'Uploaded On',
    id: 'createdAt',
    center: true,
    selector: (row) => row.createdAt!,
    sortable: true,
  },
];

interface RateChangeTableProps {
  data: RateChangeLog[];
}

export const RateChangeTable = ({ data = [] }: RateChangeTableProps) => {
  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={customStyles}
      defaultSortAsc={false}
      defaultSortFieldId={'stayDate'}
    />
  );
};
