import { CloseFn } from '../../../components/BtnPopover';
import { clsx } from 'clsx';
import { toast } from 'react-toastify';
import { useMeeting } from '../context/meeting-context';
import { useState } from 'react';
import { useUser } from '../../../context/userContext';

type FormProps = {
  brandCode?: string;
  closeForm?: CloseFn;
};

const AddAgendaItemForm = ({ brandCode, closeForm }: FormProps) => {
  const { createAgendaItem, createHotelAgenda, meetingTools } = useMeeting();
  const [newItemName, setNewItemName] = useState<string>('');
  const [newItemNotes, setNewItemNotes] = useState<string>('');
  const { user } = useUser();

  const numberOfItems = meetingTools?.agendaItems?.length;

  const handleSuccess = () => {
    toast.success(`${newItemName} Added to Agenda`, { position: 'top-left' });
    // closeForm && closeForm();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!numberOfItems || numberOfItems < 1) {
      if (brandCode && user?.id) {
        await createHotelAgenda({
          variables: {
            brandCode,
            createdById: user.id,
          },
        });
      }
    }

    if (brandCode && user?.id) {
      createAgendaItem({
        variables: {
          brandCode,
          createdById: user.id,
          name: newItemName,
          notes: newItemNotes,
        },
        onCompleted: handleSuccess,
      });
    }

    setNewItemName('');
    setNewItemNotes('');
  };

  return (
    <form className='mt-2' onSubmit={handleSubmit}>
      <div className='flex flex-col'>
        <label className='text-gray-600 text-sm' htmlFor='name'>
          Name
        </label>
        <input
          type='text'
          id='name'
          className='border rounded-lg p-2'
          value={newItemName}
          onChange={(e) => setNewItemName(e.target.value)}
        />
      </div>
      <div className='flex flex-col mt-2'>
        <label className='text-gray-600 text-sm' htmlFor='notes'>
          Notes
        </label>
        <textarea
          id='notes'
          name='notes'
          className='border rounded-lg p-2'
          rows={4}
          value={newItemNotes}
          onChange={(e) => setNewItemNotes(e.target.value)}
        />
      </div>
      <div className='w-full flex justify-center mt-2'>
        <button
          type='submit'
          className={clsx(
            'bg-blue-800 py-1 px-2 rounded-md text-white text-sm mt-1.5',
            !newItemName && 'opacity-50'
          )}
          disabled={!newItemName}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export { AddAgendaItemForm };
