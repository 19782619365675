import { DATE_SEGMENT_FIELDS } from './fragments';
import { gql } from '@apollo/client';

export const GET_ROOMTYPES = gql`
  query GetRoomTypes($brandCode: String!, $snapshotDate: String, $startDate: String, $endDate: String, $useCache: Boolean) {
    allRoomData(brandCode: $brandCode, snapshotDate: $snapshotDate, startDate: $startDate, endDate: $endDate, useCache: $useCache) {
      stay_date
      ${DATE_SEGMENT_FIELDS}
      room_types {
        rt_details
        room_code
        room_category
        rt_details
        rt_sold
        rt_remaining
        rt_flex_rate
        rt_room_allocation
        rt_status
        stay_date
      }
    }
    roomList(brandCode: $brandCode, snapshotDate: $snapshotDate) {
      columnSize
      header
      dataSource
      key
      order
      renderer
      tooltip
    }
  }
`;
