import { Menu, Transition } from '@headlessui/react';

import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../../helpers/tailwindHelper';
import { delPrefix } from '../../../helpers/localStorageHelper';
import gravatar from 'gravatar';
import { toast } from 'react-toastify';
import { useOktaAuth } from '@okta/okta-react';

type UserMenuProps = {
  name?: string | null;
  email: string;
  role?: string | null;
};

function UserMenu({ email, name, role }: UserMenuProps) {
  const { oktaAuth } = useOktaAuth();
  const avatarUrl = gravatar.url(email, { s: '100', r: 'x', d: 'retro' }, true);

  const clearLocalSettings = () => {
    if (window.confirm('Are you sure you want to clear your table settings?')) {
      toast.promise(delPrefix(['TableSettings', 'Shop']), {
        pending: 'Clearing table settings...',
        success: 'Table settings cleared',
        error: 'Error clearing table settings',
      });
    }
  };

  const logout = async () => {
    await oktaAuth.signOut();
  };

  const userNavigation = [
    { name: 'Changelog', href: '/changelog' },
    {
      formatting: 'text-red-600 hover:text-red-800 hover:bg-red-100',
      func: clearLocalSettings,
      href: '/dashboard/overview',
      name: 'Clear Settings',
    },
    { name: 'Status', href: 'https://status.rmws.bwhhotelgroup.com' },
    { name: 'Sign out', href: '/', func: logout },
  ];

  return (
    <Menu as='div' className='ml-4 relative flex-shrink-0'>
      <div>
        <Menu.Button className='flex text-center '>
          <span className='sr-only'>Open user menu</span>
          <div className='mr-3'>
            <p className='text-sm text-white'>{name}</p>
            <p className='text-sm text-gray-400 lowercase'>{role}</p>
          </div>
          <div className='mr-2'>
            <img
              className='h-10 w-10 rounded-sm'
              src={avatarUrl}
              alt='user_profile'
            />
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => {
                if (item.name.toLowerCase() !== 'status') {
                  return (
                    <Link
                      to={item.href}
                      onClick={item.func}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        item.formatting ? item.formatting : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  );
                } else {
                  return (
                    <a
                      href={item.href}
                      target='_blank'
                      rel='noreferrer'
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        item.formatting ? item.formatting : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </a>
                  );
                }
              }}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default UserMenu;
