import {
  dateFormatData,
  dayRange,
  endOfMonth,
  firstOfMonth,
  sameTimeYearsAgo,
} from '../../helpers/dateHelpers';
import { useEffect, useMemo, useRef, useState } from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import FieldSelect from '../../components/FieldSelect';
import { MdDataThresholding } from 'react-icons/md';
import { MdLegendToggle } from 'react-icons/md';
import MonthlyPerformancePanel from '../../components/monthly-performance-panel';
import OverviewTable from '../tables/overview/OverviewTable';
import Toolbar from '../../features/overview-table/components/Toolbar';
import { TrackingInputType } from '../../types/Tracking';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useDashboard } from '../../context/dashboardContext';
import { useDataHooks } from '../../features/overview-table/hooks/use-data-hooks';
import { useReportData } from '../../features/reports/context/report-data-context';
import { useShowForecast } from '../../hooks/useShowForecast';
import { useTracking } from 'react-tracking';
import { useUser } from '../../context/userContext';

const dateSegmentOptions = [
  { value: 'day', label: 'Daily' },
  { value: 'week', label: 'Weekly' },
  { value: 'month', label: 'Monthly' },
  { value: 'quarter', label: 'Quarterly' },
  { value: 'year', label: 'Annually' },
];

function Overview() {
  const {
    showForecast,
    toggleUseForecast,
    loading: toggleForecastLoading,
  } = useShowForecast();
  const { data, loading } = useReportData();
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const { daysOut, hotel, hotelSettings, options, rateParams, setOptions } =
    useDashboard();
  const { user, oktaUser, userIp } = useUser();

  const {
    dateSegment,
    endDate,
    monthOffset,
    snapshotDate,
    startDate,
    yearsCompare,
  } = options;

  const prevSnapshotDate = useRef(snapshotDate);

  const trackingData = (action: string): TrackingInputType | undefined => {
    if (user && hotel && oktaUser) {
      return {
        action,
        brandCode: hotel.brand_code,
        dateSegment,
        endDate,
        ipAddr: userIp,
        page: 'dashboard_overview',
        snapshotDate,
        startDate,
        userEmail: user.email as string,
        userName: oktaUser.name,
        userRole: oktaUser.userRole,
        yearsCompare,
      };
    }
  };

  const { trackEvent } = useTracking();

  const brandCode = useMemo(() => hotel?.brand_code, [hotel]);

  const filters = useMemo(() => {
    return {
      hotelId: hotel?.hotel_id || hotel?.brand_code,
      startDate,
      endDate,
    };
  }, [hotel, startDate, endDate]);

  const hooks = useDataHooks({
    brandCode,
    endDate,
    filters,
    hotelId: filters.hotelId,
    rateParams,
    snapshotDate,
    startDate,
    useCache: hotelSettings?.useCache,
    yearsCompare,
  });

  const { rmData, events, liveData } = hooks;

  const compareYearsLabel = (years: number) => {
    const firstYear = `${sameTimeYearsAgo(snapshotDate, years, 'YYYY')}`;
    const secondYear = `${sameTimeYearsAgo(snapshotDate, years - 1, 'YYYY')}`;
    return `Δ ${firstYear}-${secondYear}`;
  };

  const yearsCompareOptions = [
    { value: '2', label: compareYearsLabel(2) },
    { value: '3', label: compareYearsLabel(3) },
    { value: '4', label: compareYearsLabel(4) },
  ];

  useEffect(() => {
    if (prevSnapshotDate.current !== snapshotDate) {
      prevSnapshotDate.current = snapshotDate;
      const snapJs = dayjs(snapshotDate, dateFormatData);
      setOptions({
        startDate: firstOfMonth({ date: snapJs }),
        endDate: endOfMonth({ date: snapJs, daysOut }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotDate]);

  return (
    <>
      <Toolbar isLoading={rmData.networkStatus < 3} />
      <span
        onClick={() => setIsPanelOpen(!isPanelOpen)}
        className={`relative inline-flex items-center rounded-md px-2 py-2 border text-sm font-medium focus:z-10 ml-px cursor-pointer`}
        data-tip='Monthly Performance Panel'
      >
        <MdDataThresholding className='h-3 w-3' aria-hidden='true' />
      </span>
      <span
        onClick={() => {
          !toggleForecastLoading && toggleUseForecast();
        }}
        className={clsx(
          toggleForecastLoading
            ? 'opacity-50 cursor-not-allowed'
            : 'cursor-pointer',
          showForecast ? '' : 'bg-blue-400/70',
          `relative inline-flex items-center rounded-md px-2 py-2 border text-sm font-medium focus:z-10 ml-px`
        )}
        data-tip={
          showForecast ? 'Hide Forecast In Report' : 'Show Forecast In Report'
        }
      >
        <MdLegendToggle className='h-3 w-3' aria-hidden='true' />
      </span>
      <div className='ml-2 inline-block py-1 px-2 border rounded text-xs text-gray-500'>
        <select
          disabled={rmData.networkStatus < 3}
          className={`pr-1 ${
            rmData.networkStatus < 3 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          name='month-select'
          id='month-select'
          value={monthOffset}
          onChange={(e) => {
            trackEvent({
              ...trackingData('month_offset_change'),
              month: Number(e.target.value) + 1,
            });
            setOptions({ monthOffset: Number(e.target.value) });
          }}
        >
          {dayRange(startDate, endDate, 'month').map((month) => {
            const dayjsMonth = dayjs(month, dateFormatData);
            const actMonthIndex =
              dayjsMonth.month() + (dayjsMonth.year() - dayjs().year()) * 12;
            return (
              <option
                key={`monthIdx-${dayjsMonth.month()}-${dayjsMonth.year()}`}
                value={actMonthIndex}
              >
                {dayjsMonth.format('MMMM YYYY')}
              </option>
            );
          })}
        </select>
      </div>
      <FieldSelect
        disabled={rmData.networkStatus < 3}
        field='dateSegment'
        onChange={(newSegment: string) => {
          trackEvent({
            ...trackingData('date_segment_change'),
            dateSegment: newSegment,
          });
          setOptions({
            dateSegment: newSegment,
          });
        }}
        options={dateSegmentOptions}
        value={dateSegment}
      />
      <FieldSelect
        disabled={rmData.networkStatus < 3}
        field='yearsCompare'
        onChange={(newValue: string) => {
          trackEvent({
            ...trackingData('years_compare_change'),
            yearsCompare: Number(newValue),
          });
          setOptions({
            yearsCompare: Number(newValue),
          });
        }}
        options={yearsCompareOptions}
        value={String(yearsCompare)}
      />
      <span className='relative inline-flex'>
        {rmData.networkStatus === 3 && (
          <AiOutlineLoading3Quarters className='animate-spin ml-2 text-blue-900' />
        )}
      </span>
      <span className='relative inline-flex' data-tip='Loading Live Data'>
        {liveData.loading && (
          <AiOutlineLoading3Quarters className='animate-spin ml-2 text-orange-600' />
        )}
      </span>
      <span className='relative inline-flex' data-tip='Loading Events'>
        {events.loading && (
          <AiOutlineLoading3Quarters className='animate-spin ml-2 text-green-600' />
        )}
      </span>
      <OverviewTable hooks={hooks} />
      <MonthlyPerformancePanel
        data={data}
        loading={loading}
        isOpen={isPanelOpen}
        setIsOpen={setIsPanelOpen}
      />
    </>
  );
}

export default Overview;
