import {
  FaCheckCircle,
  FaCloudUploadAlt,
  FaDotCircle,
  FaSpinner,
  FaTimesCircle,
} from 'react-icons/fa';

import { BiErrorAlt } from 'react-icons/bi';
import { CellObject } from '../features/overview-table/hooks/use-table';
import { IconType } from 'react-icons/lib';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import { useUpdateRmRec } from '../hooks/useRmRecs';

type RendererProps = {
  cell: CellObject;
  editEnabled: boolean;
};

type IconProps = {
  icon: IconType;
  value: string;
  cls: string;
};

const getIconProps = (value: CellObject['value']): IconProps | undefined => {
  switch (value) {
    case 'OPEN':
      return { icon: FaDotCircle, value, cls: 'text-grey-500' };
    case 'ACCEPTED':
      return {
        icon: FaCheckCircle,
        value,
        cls: 'text-green-400',
      };
    case 'DECLINED':
      return {
        icon: FaTimesCircle,
        value,
        cls: 'text-red-400',
      };
    case 'PROCESSING':
      return { icon: FaSpinner, value, cls: 'text-yellow-500' };
    case 'UPLOADED':
      return {
        icon: FaCloudUploadAlt,
        value,
        cls: 'text-green-600',
      };
    case 'ERROR':
      return { icon: BiErrorAlt, value, cls: 'text-red-600' };
  }
};

const StatusTooltip = ({ value }: { value: string }): JSX.Element => {
  return (
    <div className='flex flex-col items-center'>
      <div>Status: {value}</div>
    </div>
  );
};

const StatusIcon = (props: {
  iconProps: IconProps;
  handleClick: (value: string) => void;
  loading: boolean;
}) => {
  const { icon: Icon, cls, value } = props.iconProps;

  const statusUpdating = props.loading ? 'animate-pulse' : '';

  return (
    <>
      <span
        className={`${cls} pt-1 ${statusUpdating}`}
        data-html={true}
        data-tip={ReactDOMServer.renderToString(
          <StatusTooltip value={value} />
        )}
        onClick={() => props.handleClick(value)}
      >
        <Icon />
      </span>
    </>
  );
};

const cycleValue = (value: string) => {
  switch (value) {
    case 'OPEN':
      return 'ACCEPTED';
    case 'ACCEPTED':
      return 'DECLINED';
    case 'DECLINED':
      return 'OPEN';
    case 'UPLOADED':
      // For right now we don't want to let a user change its already uploaded
      return value;
    case 'ERROR':
      // For right now we don't want to let a user change its already uploaded
      return value;
    case 'PROCESSING':
      // For right now we don't want to let a user change its already uploaded
      return value;
    default:
      return 'OPEN';
  }
};

export default function RenderRmRecStatus({
  cell,
  editEnabled,
}: RendererProps) {
  const callback = () => ReactTooltip.rebuild();
  const [updateRmRec, { loading }] = useUpdateRmRec({ callback });

  const rmRec = cell.meta?.data;

  if (!rmRec || (rmRec.rate === null && rmRec.notes === null)) {
    return <span>{null}</span>;
  }

  const iconProps = getIconProps(cell.value);

  function handleStatusUpdate(value: CellObject['displayValue']) {
    if (!editEnabled) {
      return;
    }
    const BLOCKED_STATUSES = ['UPLOADED', 'ERROR', 'PROCESSING'];
    if (value) {
      if (cell.meta?.id && BLOCKED_STATUSES.indexOf(value) === -1) {
        updateRmRec({
          variables: {
            updateRmRecId: cell.meta?.id,
            rmRec: {
              status: cycleValue(value),
            },
          },
        });
      }
    }
  }

  return iconProps ? (
    <StatusIcon
      iconProps={iconProps}
      handleClick={handleStatusUpdate}
      loading={loading}
    />
  ) : (
    <span>{cell.value}</span>
  );
}
