import DataTableContainer from '../../dashboards/DataTableContainer';
import { TrackingDataTable } from './components/tracking-data-table';

export const TrackingSection = () => {
  return (
    <DataTableContainer>
      <TrackingDataTable />
    </DataTableContainer>
  );
};
