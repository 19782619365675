import { useEffect, useState } from 'react';

import { DayFilters } from '../reducers/useDataTableOptions';
import _ from 'lodash';

type Props = {
  fallback?: string | number;
  initialValue: string | number | DayFilters;
  inputRef?: React.RefObject<HTMLInputElement>;
  onChange: (value: string | number | DayFilters) => void;
};

export const useEditable = ({
  fallback = '...',
  initialValue,
  inputRef,
  onChange,
}: Props) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string | number | DayFilters>(
    initialValue
  );

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    setValue(initialValue || fallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const cleanValue = (value: string | number | DayFilters) => {
    return typeof value === 'string' ? value.replace('$', '') : value;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.type === 'select-one') {
      onChange(e.target.value);
    } else if (e.target.type === 'checkbox') {
      setValue({
        ...(value as DayFilters),
        [e.target.name]: (e.target as HTMLInputElement).checked,
      });
    } else {
      setValue(e.target.value);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { key } = e;

    switch (key) {
      case 'ArrowDown':
        if (inputRef) {
          handleSave();
        }
        break;
      case 'ArrowLeft':
        if (inputRef && inputRef.current?.selectionStart === 0) {
          handleSave();
        }
        break;
      case 'ArrowUp':
        if (inputRef) {
          handleSave();
        }
        break;
      case 'ArrowRight':
        // We only want to move cells to the right if the user
        // is at the end of the value. This prevents it from moving cells
        // if they are only using the arrow keys to navigate within the input.
        if (
          inputRef &&
          inputRef.current?.selectionEnd === inputRef.current?.value.length
        ) {
          handleSave();
        }
        break;
      case 'Enter':
        handleSave();
        break;
      case 'Delete':
      case 'Escape':
        setValue('');
        break;
      case 'Tab':
        e.preventDefault();
        handleSave();
        break;
      default:
        break;
    }
  };

  const handleOnBlur = () => {
    handleSave();
  };

  const handleSave = () => {
    if (validateValue(value)) {
      setEdit(false);

      if (valuesAreNew()) {
        onChange && onChange(cleanValue(value));
      } else {
        initialValue === '' ? setValue(fallback) : setValue(initialValue);
      }
    }
  };

  const validateValue = (value: string | number | DayFilters) => {
    // TODO: utilize JOI validation
    return true;
  };

  const valuesAreNew = (): boolean => {
    if (typeof value === 'object') {
      return !_.isEqual(value, initialValue);
    }

    if (value === null && value === '') {
      return false;
    }
    return String(value) !== String(initialValue);
  };

  return {
    edit,
    handleOnBlur,
    handleChange,
    handleKeyDown,
    setEdit,
    setValue,
    value,
  };
};
