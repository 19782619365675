import { addMonths, parse } from 'date-fns';

import { z } from 'zod';

const dateStringSchema = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in YYYY-MM-DD format');

export const eventSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1, 'Name is required'),
    start_date: dateStringSchema.refine(
      (date) => !isNaN(new Date(date).getTime()),
      'Invalid date format'
    ),
    end_date: dateStringSchema.refine(
      (date) => !isNaN(new Date(date).getTime()),
      'Invalid date format'
    ),
    details: z.string().optional().nullable(),
    created_by_id: z.string(),
    hotel_id: z.number(),
  })
  .refine(
    (data) => {
      if (!data.end_date) {
        data.end_date = data.start_date;
      }
      return (
        parse(data.end_date, 'yyyy-MM-dd', new Date()) >=
        parse(data.start_date, 'yyyy-MM-dd', new Date())
      );
    },
    {
      message: 'End date must be greater than or equal to start date',
      path: ['end_date'],
    }
  )
  .refine(
    (data) => {
      const startDate = parse(data.start_date, 'yyyy-MM-dd', new Date());
      const endDate = parse(data.end_date, 'yyyy-MM-dd', new Date());
      const oneMonthLater = addMonths(startDate, 1);
      return endDate <= oneMonthLater;
    },
    {
      message: 'End date must not be more than 1 month after the start date',
      path: ['end_date'],
    }
  );
