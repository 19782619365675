import { Maybe, MeetingService } from '../../../graphql/types';
import { useEffect, useState } from 'react';

import { State as HotelState } from '../../../context/hotelContext';
import { ReportDataType } from '../../reports/context/report-data-context';
import { State as UserState } from '../../../context/userContext';
import axios from 'axios';
import { useShareUrlLazyQuery } from '../gql/_gen_/meeting.gql';

interface UseStoredDataProps {
  bucket?: Maybe<string>;
  key?: Maybe<string>;
}

export const useStoredData = ({ bucket, key }: UseStoredDataProps) => {
  const [data, setReportData] = useState<{
    data: ReportDataType;
    hotel: HotelState;
    meetingTools: MeetingService;
    user: UserState;
  }>();

  // const [expired, setExpired] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [getShareUrl] = useShareUrlLazyQuery();

  const getReportData = async () => {
    if (!bucket || !key) {
      return;
    }

    setFetching(true);

    try {
      const url = await getShareUrl({ variables: { bucket, key } });
      if (url && url.data && url.data.shareUrl) {
        const response = await axios.get(url.data.shareUrl);
        setReportData(response.data);
        setFetching(false);
      } else {
        setFetching(false);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.includes('expired')) {
          // setExpired(true);
          setFetching(false);
          return;
        }
        setFetching(false);
      }
      setFetching(false);
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (bucket && key) {
      // setFetching(true);
      getReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucket, key]);

  return { data, fetching };
};
