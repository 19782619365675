import { Hotel, Maybe } from '../../graphql/types';
import {
  useAddHotelToPortfolioMutation,
  useCreatePortfolioMutation,
} from './gql/_gen_/portfolio.gql';
import { useEffect, useState } from 'react';

import BtnPopover from '../../components/BtnPopover';
import HeatMap from './components/heat-map';
import HotelWidget from './components/hotel-widget';
import { MdAddCircle } from 'react-icons/md';
import { Placement } from '@popperjs/core';
import ReactTooltip from 'react-tooltip';
import { SectionContainer } from '../reports/components/section-container';
import { useHotel } from '../../context/hotelContext';
import { useUser } from '../../context/userContext';

type AddHotelButtonProps = {
  portfolioId?: string;
  userId?: string | null;
};

type PanelContentProps = {
  close: () => void;
};

function AddHotelButton({ portfolioId, userId }: AddHotelButtonProps) {
  const [newHotel, setNewHotel] = useState<string>('');
  const [addHotel] = useAddHotelToPortfolioMutation();
  const [inputVal, setInputVal] = useState<string>('');

  const [createPortfolio] = useCreatePortfolioMutation();

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    props: PanelContentProps
  ) => {
    const { close } = props;

    e.preventDefault();

    if (newHotel.length === 5) {
      if (portfolioId) {
        await addHotel({
          variables: {
            brandCode: newHotel,
            portfolioId: portfolioId || '',
          },
        });
        close();
        setNewHotel('');
      } else if (userId) {
        await createPortfolio({
          variables: {
            portfolio: {
              user_id: userId,
            },
          },
          onCompleted: (data) => {
            addHotel({
              variables: {
                brandCode: newHotel,
                portfolioId: data?.createPortfolio?.id || '',
              },
            });
          },
        });
        close();
        setNewHotel('');
      }
    } else {
      setInputVal('Brand Code must be 5 characters');
    }
  };

  const handleChange = (value: string) => {
    if (inputVal.length) {
      setInputVal('');
    }
    setNewHotel(value);
  };

  const panelContent = (props: any) => (
    <div className='flex flex-col px-4 pt-2 pb-4 bg-white border border-gray-200 rounded shadow'>
      <form onSubmit={(e) => handleSubmit(e, props)} className='flex flex-col'>
        <label className='text-sm font-semibold' htmlFor='brandCode'>
          Add Hotel:
        </label>
        {inputVal.length ? (
          <p className='text-red-500 text-xs'>{inputVal}</p>
        ) : null}
        <input
          className='text-sm p-1'
          type='text'
          id='brandCode'
          placeholder='Brand Code'
          autoFocus
          onChange={(e) => handleChange(e.target.value)}
          value={newHotel}
          maxLength={5}
        />
      </form>
    </div>
  );

  const btnProps = {
    btnIcon: MdAddCircle,
    btnIconClass: 'text-gray-200',
    btnTooltip: 'Add Hotel',
    btnClass: 'mt-1.5',
    btnSpanClass: 'h-full',
    popPlacement: 'right' as Placement,
    offsetV: 20,
    offsetH: 5,
    panelContent,
  };
  return (
    <>
      <BtnPopover {...btnProps} />
      <ReactTooltip />
    </>
  );
}

function Portfolio() {
  const { user } = useUser();
  const hotels = user?.portfolio?.hotels;
  const portfolioId = user?.portfolio?.id;
  const [selectedHotel, setSelectedHotel] = useState<Maybe<Hotel>>(null);
  const { hotel, setBrandCode } = useHotel();

  useEffect(() => {
    if (hotels && hotels.length > 0) {
      const selectedHotel = hotels.find(
        (h) => h?.brand_code === hotel?.brand_code
      );
      setSelectedHotel(selectedHotel || hotels[0]);
    }
  }, [hotels, hotel]);

  const handleSelect = (hotel?: Hotel | null) => {
    if (hotel) {
      setSelectedHotel(hotel);
      setBrandCode(hotel.brand_code);
    }
  };

  const heatMapHeader = () => (
    <span data-tip={selectedHotel?.name}>
      {selectedHotel && selectedHotel?.brand_code} Heat Map
    </span>
  );

  return (
    <div className='p-4'>
      <SectionContainer
        title='Portfolio'
        toolbar={<AddHotelButton portfolioId={portfolioId} userId={user?.id} />}
      >
        <div className='flex flex-wrap'>
          {hotels &&
            hotels.map((hotel) => (
              <HotelWidget
                key={hotel?.brand_code}
                data={hotel}
                selected={hotel?.brand_code === selectedHotel?.brand_code}
                portfolioId={portfolioId}
                setSelected={handleSelect}
              />
            ))}
        </div>
      </SectionContainer>
      <br />
      <SectionContainer title={heatMapHeader()}>
        <HeatMap brandCode={selectedHotel?.brand_code} />
      </SectionContainer>
      <ReactTooltip />
    </div>
  );
}

export default Portfolio;
