import { Actions, FormField } from '../../types/DataTable';
import {
  State as Options,
  Action as OptionsAction,
  TableFilters,
} from '../../reducers/useDataTableOptions';

import { BiEraser } from 'react-icons/bi';
import { State as Checked } from '../../reducers/useBulkCheckboxes';
import DTHeaderFilter from './DTHeaderFilter';
import DTSortButton from './DTSortButton';
import _ from 'lodash';
import { clsx } from 'clsx';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';

type Props = {
  actions?: Actions;
  checked: Checked;
  field: FormField;
  options: Options;
  setOptions: React.Dispatch<OptionsAction>;
  setTableFilters: React.Dispatch<React.SetStateAction<TableFilters>>;
  tableFilters: TableFilters;
  width: string;
};

const DTHeaderCell = ({
  actions,
  checked,
  field,
  options,
  setOptions,
  setTableFilters,
  tableFilters,
  width,
}: Props) => {
  const { bulk, label, name } = field;

  const handleClearColumn = () => {
    if (actions) {
      const targetIds: string[] = [];
      _.forEach(_.omit(checked, ['bulk']), (value, key) => {
        if (value) {
          targetIds.push(key);
        }
      });
      toast.promise(actions.bulkUpsert(targetIds, { [name]: null }), {
        pending: 'Update is pending...',
        success: `Success! Updated ${pluralize(label, targetIds.length, true)}`,
        error: 'There was an issue, sorry.',
      });
    }
  };

  return (
    <th className={`py-4 font-medium ${width}`}>
      <div className='flex items-center'>
        <DTHeaderFilter
          field={field}
          setTableFilters={setTableFilters}
          tableFilters={tableFilters}
        />

        <DTSortButton
          fieldName={name}
          options={options}
          setOptions={setOptions}
        />

        {actions ? (
          <BiEraser
            data-tip={`Clear ${label.toLowerCase()} of selected rows`}
            className={clsx(
              !checked.bulk && 'hidden',
              !bulk && 'hidden',
              'h-4 w-4 text-blue-600 ml-2'
            )}
            onClick={handleClearColumn}
          />
        ) : null}
      </div>
    </th>
  );
};

export default DTHeaderCell;
