import { DataGridCell } from '../types/DataGrid';

export const MIN_RATE = 40.0;
export const MAX_RATE = 2200.0;

/**
 * Is this a valid rate?
 * @param rate A rate passed as a number to be validated.
 * @param dataRow If defined, is an object containing the hotel's current price for that day.
 * @returns A boolean indicating if the rate is valid.
 */
export const isValidRate = (
  rate: number,
  dataRow?: DataGridCell[] | undefined
): boolean => {
  // validate against min and max
  return Number(rate) >= MIN_RATE && Number(rate) <= MAX_RATE;
};
