type PaginateProps = {
  first: number;
  goToPage?: (targetPage: number) => void;
  last: number;
  total: number;
  totalFiltered?: number;
  previous: () => void;
  next: () => void;
};

export default function Paginate({
  first,
  goToPage,
  last,
  total,
  totalFiltered,
  previous,
  next,
}: PaginateProps) {
  const handleNumberClick = (targetPage: number) => {
    if (goToPage) {
      goToPage(targetPage);
    }
  };

  const btnCls =
    'disabled:opacity-50 ml-2 relative inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50';

  return (
    <nav
      className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'
      aria-label='Pagination'
    >
      <div className='flex-1 flex justify-between sm:justify-start'>
        <button disabled={first === 0} onClick={previous} className={btnCls}>
          Previous
        </button>
        <button disabled={last === first + 1} onClick={next} className={btnCls}>
          Next
        </button>
      </div>
      <div className='hidden sm:block'>
        <p className='text-sm text-gray-700'>
          Showing page{' '}
          <button onClick={() => handleNumberClick(0)} className={btnCls}>
            {first + 1}
          </button>{' '}
          of{' '}
          <span onClick={() => handleNumberClick(last - 1)} className={btnCls}>
            {last}
          </span>{' '}
          with{' '}
          <span className='font-medium'>
            {totalFiltered && totalFiltered !== total ? (
              <span className='text-blue-800 font-bold'>{totalFiltered}</span>
            ) : (
              total
            )}
          </span>{' '}
          items
        </p>
      </div>
    </nav>
  );
}
