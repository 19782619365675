import * as Meetings from '../gql/_gen_/meeting.gql';

import { HandleSaveMeetingOptions } from '../context/meeting-context';
import { Meeting } from '../../../graphql/types';
import { omit } from 'lodash';
import { today } from '../../../helpers/dateHelpers';
import { useHotel } from '../../../context/hotelContext';
import { useUser } from '../../../context/userContext';

export const useSaveMeeting = (
  meeting?: Meeting,
  setMeeting?: (meeting: Meeting) => void
) => {
  const { hotel } = useHotel();
  const { user } = useUser();

  const [saveMeeting, saveResults] = Meetings.useAddMeetingMutation({
    refetchQueries: [Meetings.namedOperations.Query.GetMeetingByDate],
  });
  const [updateMeeting, updateResults] = Meetings.useUpdateMeetingMutation();
  const handleSaveMeeting = async (options?: HandleSaveMeetingOptions) => {
    if (meeting && meeting.id && meeting.brandCode === hotel?.brand_code) {
      return updateMeeting({
        variables: {
          updateMeetingId: meeting.id,
          ...omit(options, [
            'brandCode',
            'bucket',
            'bucketKey',
            'data',
            'meetingDate',
            'meetingTools',
            'reportDataFile',
          ]),
        },
        onCompleted: (data) => {
          if (data && data.updateMeeting && setMeeting) {
            setMeeting(data.updateMeeting);
          }
        },
      });
    } else {
      return saveMeeting({
        variables: {
          brandCode: hotel?.brand_code,
          meetingDate: today(),
          createdById: user?.id as string,
          ...omit(options, [
            'bucket',
            'bucketKey',
            'data',
            'meetingTools',
            'reportDataFile',
          ]),
        },
        onCompleted: (data) => {
          if (data && data.addMeeting && setMeeting) {
            setMeeting(data.addMeeting);
          }
        },
      });
    }
  };

  return { handleSaveMeeting, saveResults, updateResults };
};
