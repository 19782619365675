import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Popover, Transition } from '@headlessui/react';

import { BtnScrollToTop } from '../../general/components/btn-scroll-to-top';
import { Fragment } from 'react';
import { classNames } from '../../../helpers/tailwindHelper';
import { useState } from 'react';

const navigation = [
  { name: 'Meeting', id: 'meeting-header', current: true },
  { name: 'Notes', id: 'meeting-notes', current: false },
  { name: 'Charts', id: 'charts', current: false },
  { name: 'Round Up', id: 'roundup', current: false },
  { name: 'Rate Recs', id: 'rm-recs', current: false },
  { name: 'Events', id: 'events', current: false },
  { name: 'Groups', id: 'groups', current: false },
  { name: 'Details', id: 'detail-table', current: false },
  { name: 'Monthly', id: 'monthly', current: false },
];

const Navigation = () => {
  const [currentNav, setCurrentNav] = useState<string | undefined>();

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setCurrentNav(id);
    } else {
      setCurrentNav(undefined);
    }
  };

  return (
    <>
      <Popover as='header' className='pb-24 bg-blue-900'>
        {({ open }) => (
          <>
            <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8'>
              <div className='relative flex items-center justify-center lg:justify-between'>
                <div className='w-full py-5 lg:border-t lg:border-white lg:border-opacity-20'>
                  <div className='lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center'>
                    {/* Left nav */}
                    <div className='hidden lg:block lg:col-span-2'>
                      <nav className='flex space-x-4'>
                        {navigation.map((item) => (
                          <button
                            key={item.name}
                            onClick={() => scrollToElement(item.id)}
                            className={classNames(
                              item.id === currentNav
                                ? 'text-white underline'
                                : 'text-cyan-100',
                              'text-sm font-medium rounded-md bg-white bg-opacity-0 py-2 hover:bg-opacity-10'
                            )}
                            aria-current={
                              item.id === currentNav ? 'page' : undefined
                            }
                          >
                            {item.name}
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                {/* Menu button */}
                <div className='absolute right-0 flex-shrink-0 lg:hidden'>
                  {/* Mobile menu button */}
                  <Popover.Button className='bg-transparent p-2 rounded-md inline-flex items-center justify-center text-cyan-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white'>
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Popover.Button>
                </div>
              </div>
            </div>

            <Transition.Root as={Fragment}>
              <div className='lg:hidden'>
                <Transition.Child
                  as={Fragment}
                  enter='duration-150 ease-out'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='duration-150 ease-in'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Popover.Overlay className='z-20 fixed inset-0 bg-black bg-opacity-25' />
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter='duration-150 ease-out'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='duration-150 ease-in'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Popover.Panel
                    focus
                    className='z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top'
                  >
                    <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200'>
                      <div className='pt-3 pb-2'>
                        <div className='flex items-center justify-between px-4'>
                          <div>
                            <img
                              className='h-8 w-auto'
                              src='https://tailwindui.com/img/logos/workflow-mark-cyan-600.svg'
                              alt='Workflow'
                            />
                          </div>
                          <div className='-mr-2'>
                            <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500'>
                              <span className='sr-only'>Close menu</span>
                              <XIcon className='h-6 w-6' aria-hidden='true' />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className='mt-3 px-2 space-y-1'>
                          {navigation.map((item) => (
                            <button
                              key={item.name}
                              onClick={() => scrollToElement(item.id)}
                              className='block rounded-md py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition.Child>
              </div>
            </Transition.Root>
          </>
        )}
      </Popover>
      <BtnScrollToTop callback={setCurrentNav} />
    </>
  );
};

export { Navigation };
