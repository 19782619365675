import { RmRec } from '../graphql/types';
import { baseFormat } from '../renderers/baseFormat';
import { masterColumns } from '../renderers/masterColumns';

const dataRow = (metrics: string[], rmRec?: RmRec) => {
  if (!rmRec) {
    return [];
  }

  return metrics.map((fullMetric) => {
    const metric = fullMetric.replace('rm_recs.', '');
    if (metric === 'status') {
      return rmRec.status;
    } else {
      const value = rmRec[metric as keyof RmRec];
      return baseFormat(fullMetric, value, 'day');
    }
  });
};

export const tableData = (rmRecs?: RmRec[]) => {
  const metrics = [
    'stay_date',
    'rm_recs.rate',
    'current_rate',
    'rm_recs.notes',
    'rm_recs.status',
    'sold',
    'adr',
  ];

  const headers = metrics.map((metric) => {
    if (metric === 'current_rate') {
      return 'Current Rate';
    } else {
      return masterColumns[metric].header;
    }
  });
  const rows = rmRecs ? rmRecs.map((rmRec) => dataRow(metrics, rmRec)) : [];

  return {
    headers,
    rows,
  };
};
