import FieldSelect, { FieldSelectOption } from './FieldSelect';
import InsertLocation, {
  InsertLocationProps,
} from './data_grid/InsertLocation';

import { Action } from '../reducers/useRateShopParams';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import CheckboxGroup from './CheckboxGroup';
import { FiUpload } from 'react-icons/fi';
import { QueryResult } from '@apollo/client';
import { RateQueryParams } from '../graphql/types';
import { isEqual } from 'lodash';
import { paramOptions } from './rate_params/paramOptions';
import { useHotel } from '../context/hotelContext';
import { useState } from 'react';

type RateParamsProps = {
  insertProps: InsertLocationProps;
  params: RateQueryParams;
  rateShops: QueryResult;
  onChange: (payload: Action['payload']) => void;
};

export default function RateParams(props: RateParamsProps) {
  const { otaiSubs } = useHotel();
  const selectKeys = [
    { key: 'bar', label: 'Rate Type' },
    { key: 'los', label: 'LOS' },
    { key: 'ota', label: 'Channel' },
    { key: 'roomType', label: 'Room Type' },
    { key: 'shopLength', label: 'Days Out' },
  ];
  const { insertProps, params, rateShops, onChange } = props;

  const [tempParams, setTempParams] = useState<RateQueryParams>(params);

  const areEqual = isEqual(params, tempParams);

  const handleSubmit = () => {
    onChange(tempParams as Action['payload']);
  };

  const handleChange = (key: string, value: string) => {
    setTempParams({
      ...tempParams,
      [key]: modValue(key, value),
    });
  };

  const modValue = (key: string, value: string) => {
    if (['los', 'shopLength'].includes(key)) {
      return Number(value);
    } else if (key === 'bar') {
      return value === 'true';
    }
    return value;
  };

  const handleCbChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const changeDays = tempParams.changeDays || [];

    if (name === null || name === undefined) {
      return;
    }

    if (checked) {
      const tempDays = changeDays.filter((day) => day !== Number(name));
      tempDays.push(Number(name));
      setTempParams({ ...tempParams, changeDays: tempDays });
    } else {
      const tempDays = changeDays.filter((day) => day !== Number(name));
      setTempParams({ ...tempParams, changeDays: tempDays });
    }
  };

  const subIdOptions = () => {
    if (otaiSubs && otaiSubs.length > 0) {
      return otaiSubs.map((sub) => {
        return {
          value: sub?.sub_id?.toString(),
          label: sub?.sub_id?.toString(),
        } as FieldSelectOption;
      });
    }
    return [];
  };

  return (
    <div>
      <div className='flex flex-col gap-y-3 mt-1'>
        {selectKeys.map((opt) => {
          const options = paramOptions[opt.key as keyof typeof paramOptions];
          return (
            <FieldSelect
              key={opt.key}
              customClass='text-xs w-full'
              customLabel={opt.label}
              disabled={false}
              field={opt.key}
              onChange={(v) => handleChange(opt.key, v)}
              options={options}
              showLabel={true}
              value={
                tempParams[
                  opt.key as keyof RateQueryParams
                ] as FieldSelectOption['value']
              }
            />
          );
        })}
        <div>
          <CheckboxGroup
            handleChange={handleCbChange}
            changeDays={tempParams.changeDays}
          />
        </div>
        <div className='leading-4'>
          <InsertLocation {...insertProps} />
        </div>
        <div>
          <FieldSelect
            key='subscriptionId'
            customClass='text-xs w-full'
            customLabel='OTAI Sub ID'
            disabled={false}
            field='subscriptionId'
            onChange={(v) => handleChange('subscriptionId', v)}
            options={subIdOptions()}
            showLabel={true}
            value={tempParams.subscriptionId as FieldSelectOption['value']}
          />
        </div>
        <button
          className={
            `w-full px-1 border-2 text-center ` +
            (areEqual ? 'bg-white' : 'bg-blue-300')
          }
          onClick={handleSubmit}
        >
          {rateShops.loading ? (
            <AiOutlineLoading3Quarters className='animate-spin text-blue-900 m-1 inline' />
          ) : (
            <>
              <FiUpload className='inline' />
              <span className='text-sm ml-1'>Submit Rates</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
}
