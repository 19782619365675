import * as HotelTeam from '../gql/_gen_/hotel-team.gql';
import * as Meetings from '../gql/_gen_/meeting.gql';

import { Maybe, TeamMember, TeamMemberActive } from '../../../graphql/types';
import { useEffect, useState } from 'react';

import { ExpandedTeamItem } from './expanded-team-item';
import { MeetingPanelsProps } from '../../reports/components/meeting-panels';
import { TeamListItem } from './team-list-item';
import clsx from 'clsx';
import { toast } from 'react-toastify';

interface ITeamView {
  isTool?: boolean;
  meeting?: MeetingPanelsProps['meeting'];
  meetingTools?: MeetingPanelsProps['meetingTools'];
}

const TeamView = ({ isTool, meeting, meetingTools }: ITeamView) => {
  const [expanded, setExpanded] = useState<Maybe<TeamMember>>();
  const [members, setMembers] = useState<TeamMember[]>();

  const [reactivateTeamMember] = HotelTeam.useReactivateTeamMemberMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const [removeTeamMember] = HotelTeam.useRemoveTeamMemberMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  useEffect(() => {
    if (meetingTools?.hotelTeams && meetingTools?.hotelTeams.length > 0) {
      const hotelTeam = meetingTools?.hotelTeams as TeamMember[];

      setMembers(hotelTeam.filter((m) => m?.active === 'ACTIVE'));
    }
  }, [meetingTools?.hotelTeams]);

  const handleToggleActive = async (member: TeamMember) => {
    if (member.active === TeamMemberActive.Active) {
      await removeTeamMember({
        variables: {
          removeTeamMemberId: String(member.id),
        },
        onCompleted: () =>
          toast.success('Team member removed', { position: 'top-left' }),
      });
    } else if (member.active === TeamMemberActive.Inactive) {
      await reactivateTeamMember({
        variables: {
          reactivateTeamMemberId: String(member.id),
        },
        onCompleted: () =>
          toast.success('Team member reactivated', { position: 'top-left' }),
      });
    }
  };

  return (
    <section className={clsx('px-3', isTool === true ? 'w-[500px]' : '')}>
      <div className='overflow-auto mt-3'>
        {expanded ? (
          <ExpandedTeamItem expanded={expanded} setExpanded={setExpanded} />
        ) : (
          <div className='flex flex-col'>
            <div
              className={clsx(
                isTool
                  ? 'grid grid-cols-2'
                  : 'grid grid-cols-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-4 xl:grid-cols-6',
                'gap-1'
              )}
            >
              {members &&
                members.map((member) => (
                  <TeamListItem
                    data={member}
                    isTool={isTool}
                    key={member.id}
                    meeting={meeting}
                    setExpanded={setExpanded}
                    toggleActive={handleToggleActive}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export { TeamView };
