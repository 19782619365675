import { DataRow, FormField } from '../../../types/DataTable';
import {
  GetMeetingsQuery,
  useGetMeetingsQuery,
} from '../../../features/meetings/gql/_gen_/meeting.gql';
import {
  SortDir,
  TableFilters,
  useDataTableOptions,
} from '../../../reducers/useDataTableOptions';
import _, { Many } from 'lodash';
import { dayDiff, dow, today } from '../../../helpers/dateHelpers';
import { useEffect, useState } from 'react';

import DataTable from '../../../components/DataTable';
import { Meeting } from '../../../graphql/types';
import Paginate from '../../../components/Paginate';
import { useHotel } from '../../../context/hotelContext';

interface PaginatedMeetingsObject {
  pages: DataRow[][];
  index: number;
  totalMeetings: number;
  totalPages: number;
}

interface MeetingTableRows {
  rows: DataRow[];
  startEventIndex: number;
}

function MeetingsTable() {
  const objFields = ['id', 'link', 'shareLink', 'meetingDate', 'createdById'];
  const { hotel } = useHotel();

  const [options, setOptions] = useDataTableOptions({
    sort: { key: 'meetingDate', dir: SortDir.DESC },
  });
  const [dataRange, setDataRange] = useState<string[]>([]);
  const [tableData, setTableData] = useState<DataRow[]>([]);
  const [tableFilters, setTableFilters] = useState<TableFilters>({});
  const [totalPages, setTotalPages] = useState(1);
  const [paginatedMeetingsObject, setPaginatedMeetingsObject] =
    useState<PaginatedMeetingsObject>({
      pages: [],
      index: 0,
      totalMeetings: 0,
      totalPages: 1,
    });
  const [page, setPage] = useState<number | undefined>(undefined);
  const PAGE_SIZE = 10;

  const {
    data: dataMeetings,
    loading: loadingMeetings,
    refetch: refetchMeetings,
  } = useGetMeetingsQuery({
    skip: !hotel?.brand_code,
    variables: {
      brandCode: hotel?.brand_code,
    },
    onCompleted: (data) => {
      if (data && data.getMeetings) {
        const minDate = _.minBy(data.getMeetings, 'meetingDate')?.meetingDate;
        const maxDate = _.maxBy(data.getMeetings, 'meetingDate')?.meetingDate;
        if (minDate && maxDate) {
          setDataRange([minDate, maxDate]);
        }
      }
    },
  });

  useEffect(() => {
    if (hotel && hotel.brand_code) {
      refetchMeetings({
        brandCode: hotel.brand_code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel]);

  useEffect(() => {
    if (dataMeetings) {
      const rows = getRows(dataMeetings);
      const paginatedMeetings = paginateRows(
        rows.rows,
        rows.startEventIndex,
        PAGE_SIZE,
        page
      );
      setTableData(paginatedMeetings.pages[paginatedMeetings.index]);
      setPaginatedMeetingsObject(paginatedMeetings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMeetings, loadingMeetings, options, tableFilters]);

  useEffect(() => {
    setTotalPages(paginatedMeetingsObject.totalPages);
    setPage(paginatedMeetingsObject.index);
  }, [paginatedMeetingsObject]);

  useEffect(() => {
    if (page !== undefined) {
      setTableData(paginatedMeetingsObject.pages[page]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function pageUp() {
    if (page !== undefined) {
      if (page < totalPages - 1) {
        setPage(page + 1);
      }
    }
  }

  function pageDown() {
    if (page !== undefined) {
      if (page >= 1) {
        setPage(page - 1);
      }
    }
  }

  const filterItems = (meeting: Meeting) => {
    return filterDays(meeting, 'meetingDate') && filterString(meeting, 'notes');
  };

  const filterDays = (meeting: Meeting, attr: keyof Meeting) => {
    const filter = tableFilters[attr];
    if (filter) {
      const targetDate = meeting[attr] as string;
      const dowKey = dow(targetDate).toLowerCase() as keyof typeof filter;
      return filter[dowKey] === true;
    }
    return true;
  };

  const filterString = (meeting: Meeting, attr: keyof Meeting) => {
    const filter = tableFilters[attr];
    if (filter) {
      return String(meeting[attr])
        .toLowerCase()
        .includes(String(filter).toLowerCase());
    }
    return true;
  };

  const getRows = (data: GetMeetingsQuery): MeetingTableRows => {
    const rows: DataRow[] = [];
    if (data && data.getMeetings) {
      const startMeeting = {
        diff: 1000,
        index: 0,
      };
      _.orderBy(
        data.getMeetings as Meeting[],
        [
          (meeting) =>
            meeting[options.sort.key as keyof Meeting] === null
              ? ''
              : String(
                  meeting[options.sort.key as keyof Meeting]
                ).toLowerCase(),
        ],
        options.sort.dir as Many<boolean | 'desc' | 'asc'> | undefined
      )
        .filter(filterItems)
        .forEach((meeting: Meeting) => {
          const diff = dayDiff(meeting.meetingDate!, today());
          if (Math.abs(diff) < startMeeting.diff) {
            startMeeting.diff = Math.abs(diff);
            startMeeting.index = rows.length;
          }
          let row = _.pick(meeting, objFields) as DataRow;
          row = {
            ...row,
            link: <a href={`/report/${meeting.id}`}>{`${meeting.id}`}</a>,
            shareLink: (
              <a
                href={`/shared_report/${meeting.id}`}
                target='_blank'
                rel='noreferrer'
              >
                Shared Link{' '}
              </a>
            ),
          };
          rows.push(row);
        });
      return {
        rows: rows,
        startEventIndex: startMeeting.index,
      };
    } else {
      return {
        rows: rows,
        startEventIndex: 0,
      };
    }
  };

  const splitRows = (rows: DataRow[], startIndex: number, pageSize: number) => {
    if (rows.length <= pageSize) {
      return [rows];
    }
    const secondHalf = rows.splice(startIndex);
    while (secondHalf.length < pageSize) {
      secondHalf.unshift(rows.pop() as DataRow);
    }
    return [rows, secondHalf];
  };

  const paginateRows = (
    rows: DataRow[],
    startIndex: number,
    pageSize: number,
    currentPage?: number
  ): PaginatedMeetingsObject => {
    const rowGroups = splitRows(rows, startIndex, pageSize);
    if (rowGroups.length === 1) {
      return {
        pages: [rows],
        index: 0,
        totalMeetings: dataMeetings?.getMeetings?.length || 0,
        totalPages: 1,
      };
    }
    const pagesArr = [];
    while (rowGroups[0].length > 0) {
      pagesArr.unshift(rowGroups[0].splice(-pageSize));
    }
    const startPage = pagesArr.length;
    while (rowGroups[1].length > 0) {
      pagesArr.push(rowGroups[1].slice(0, pageSize));
      rowGroups[1].splice(0, pageSize);
    }
    return {
      pages: pagesArr,
      index: currentPage || startPage,
      totalMeetings: dataMeetings?.getMeetings?.length || 0,
      totalPages: pagesArr.length,
    };
  };

  const formFields = objFields.map((key) => {
    return {
      bulk: [''].includes(key),
      id: 'id',
      label: _.startCase(key),
      name: key,
      readOnly: true,
      type: key.toLowerCase().includes('date') ? 'date' : 'text',
      value: key.toLowerCase().includes('date') ? today() : '',
    };
  });

  return (
    <div className='w-full mt-2'>
      {loadingMeetings ? (
        <p>Loading...</p>
      ) : (
        <div>
          <Paginate
            first={page || 0}
            last={totalPages}
            previous={pageDown}
            next={pageUp}
            total={paginatedMeetingsObject.totalMeetings || 0}
          />
          <DataTable
            dataRange={dataRange}
            fields={formFields as FormField[]}
            name='Meeting'
            options={options}
            rows={tableData}
            setOptions={setOptions}
            setTableFilters={setTableFilters}
            tableFilters={tableFilters}
          />
        </div>
      )}
    </div>
  );
}

export default MeetingsTable;
