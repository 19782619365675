import { CellObject } from '../features/overview-table/hooks/use-table';
import { RateCellRenderer } from '@/features/rate_shops/components/rate-cell-renderer';
import { RateShopToolTip } from '@/features/rate_shops/components/rate-shop-tooltip';
import ReactDOMServer from 'react-dom/server';
import { useFlags } from '@/context/feature-flags';

type RendererProps = {
  cell: CellObject;
};

const RateShopCell = (cell: CellObject) => {
  const { dataKey, value, displayValue } = cell;

  return (
    <>
      <span
        data-html={true}
        data-type='light'
        data-tip={ReactDOMServer.renderToString(<RateShopToolTip {...cell} />)}
      >
        {value === 0
          ? renderMessage(cell.meta?.data?.message)
          : dataKey === 'rates.0'
          ? value
          : displayValue}
      </span>
    </>
  );
};

export const renderMessage = (message?: string) => {
  switch (message) {
    // No rates shopped
    case 'general.missing':
      return 'N/A';
    // no availability
    case 'rates.soldout':
      return 'Sold Out';
    //no Best Flex Rate Found
    case 'rates.nobar':
      return 'No BstFlx';
    //No rates for 1 person found
    case 'rates.no_person.1':
      return 'No 1P';
    //Mealtype breakfast is missing
    case 'rates.meal.missing.1':
      return 'No Bfst';
    //Roomtype is missing
    case 'rates.roomtypes.unavailable':
      return 'No RmType';
    //Only availability for LOS2 or above
    case 'rates.restrictionlos2':
      return 'LOS2';
    //Only availability for LOS3 or above
    case 'rates.restrictionlos3':
      return 'LOS3';
    default:
      return message;
  }
};
export default function RenderRateShop(props: RendererProps) {
  const { flags } = useFlags();

  const flagShowSuperscript = flags?.['rate-change-superscript'];

  const { cell } = props;

  if (flagShowSuperscript) {
    return <RateCellRenderer {...cell} />;
  } else {
    return <RateShopCell {...cell} />;
  }
}
