import { Dialog, Transition } from '@headlessui/react';
import { Forecast, MonthlyBudget, RmData } from '../graphql/types';
import { Fragment, useState } from 'react';

import { DateHelpers } from '../helpers/dateHelpers';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { MonthlyPerformanceTable } from '../features/reports/components/monthly-performance-table';
import { ReportDataType } from '../features/reports/context/report-data-context';

type Props = {
  data: ReportDataType;
  loading: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function MonthlyPerformancePanel({
  data,
  loading,
  isOpen,
  setIsOpen,
}: Props) {
  const [monthlyReportVersion, setMonthlyReportVersion] = useState('current');

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        className='relative z-50'
        unmount={false}
        onClose={() => setIsOpen(false)}
      >
        <div className='fixed inset-0 flex justify-around items-end'>
          <Transition.Child
            as={Fragment}
            enter='transform transition ease-in-out duration-500 sm:duration-700'
            enterFrom='translate-y-full'
            enterTo='translate-y-0'
            leave='transform transition ease-in-out duration-500 sm:duration-700'
            leaveFrom='translate-y-0'
            leaveTo='translate-y-full'
          >
            <Dialog.Panel className='pointer-events-auto w-full mx-4 mb-4 shadow-xl border border-sky-700'>
              <div className='flex flex-col bg-white py-1 shadow-xl'>
                <div className='px-4'>
                  <div className='ml-1 flex h-7 items-center'>
                    <button
                      type='button'
                      className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none'
                      data-tip='Close Panel'
                      onClick={() => setIsOpen(false)}
                    >
                      <span className='sr-only'>Close panel</span>
                      <IoIosCloseCircleOutline
                        className='h-5 w-5'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                  <MonthlyPerformanceTable
                    data={
                      data.dataReport?.rmData?.filter((rm) =>
                        DateHelpers.betweenOrEqual(
                          rm?.stay_date,
                          DateHelpers.firstOfYear(DateHelpers.today()),
                          DateHelpers.endOfYear(DateHelpers.today())
                        )
                      ) as RmData[]
                    }
                    dataRolling={
                      data.dataReport?.rmData?.filter((rm) =>
                        DateHelpers.betweenOrEqual(
                          rm?.stay_date,
                          DateHelpers.firstOfMonth({
                            date: DateHelpers.today(),
                          }),
                          DateHelpers.monthsOut({
                            date: DateHelpers.today(),
                            months: 11,
                          })
                        )
                      ) as RmData[]
                    }
                    forecastData={
                      data.dataReport?.monthlyRoundUpForecast as Forecast[]
                    }
                    forecastRolling={
                      data.dataReport?.monthlyRoundUpForecast as Forecast[]
                    }
                    budgetData={
                      data.dataReport?.hotelMonthlyBudgets?.filter((b) =>
                        DateHelpers.betweenOrEqual(
                          b?.stay_date,
                          DateHelpers.firstOfYear(DateHelpers.today()),
                          DateHelpers.endOfYear(DateHelpers.today())
                        )
                      ) as MonthlyBudget[]
                    }
                    budgetRolling={
                      data.dataReport?.hotelMonthlyBudgets?.filter((b) =>
                        DateHelpers.betweenOrEqual(
                          b?.stay_date,
                          DateHelpers.firstOfMonth({
                            date: DateHelpers.today(),
                          }),
                          DateHelpers.monthsOut({
                            date: DateHelpers.today(),
                            months: 11,
                          })
                        )
                      ) as MonthlyBudget[]
                    }
                    loading={loading}
                    handleVersionChange={setMonthlyReportVersion}
                    reportVersion={monthlyReportVersion}
                  />
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
