/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEventMutationVariables = Types.Exact<{
  event: Types.CreateEventInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent?: { __typename?: 'Event', created_by_id?: string | null, details?: string | null, end_date?: any | null, hotel_id?: number | null, id?: string | null, name?: string | null, start_date?: any | null } | null };

export type DataTableEventsQueryVariables = Types.Exact<{
  filters: Types.FindEventArgs;
}>;


export type DataTableEventsQuery = { __typename?: 'Query', hotelEvents?: Array<{ __typename?: 'Event', created_by_id?: string | null, created_at?: string | null, details?: string | null, end_date?: any | null, hotel_id?: number | null, id?: string | null, name?: string | null, start_date?: any | null, updated_at?: string | null, created_by?: { __typename?: 'User', name?: string | null } | null } | null> | null };

export type DeleteEventMutationVariables = Types.Exact<{
  deleteEventId: Types.Scalars['String'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent?: number | null };

export type DeleteEventsMutationVariables = Types.Exact<{
  deleteEventId: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type DeleteEventsMutation = { __typename?: 'Mutation', deleteEvents?: Array<string | null> | null };

export type UpdateEventMutationVariables = Types.Exact<{
  updateEventId: Types.Scalars['String'];
  event: Types.UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent?: { __typename?: 'Event', created_by_id?: string | null, details?: string | null, end_date?: any | null, hotel_id?: number | null, id?: string | null, name?: string | null, start_date?: any | null } | null };


export const CreateEventDocument = gql`
    mutation CreateEvent($event: CreateEventInput!) {
  createEvent(event: $event) {
    created_by_id
    details
    end_date
    hotel_id
    id
    name
    start_date
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const DataTableEventsDocument = gql`
    query DataTableEvents($filters: FindEventArgs!) {
  hotelEvents(filters: $filters) {
    created_by_id
    created_by {
      name
    }
    created_at
    details
    end_date
    hotel_id
    id
    name
    start_date
    updated_at
  }
}
    `;

/**
 * __useDataTableEventsQuery__
 *
 * To run a query within a React component, call `useDataTableEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataTableEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataTableEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDataTableEventsQuery(baseOptions: Apollo.QueryHookOptions<DataTableEventsQuery, DataTableEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataTableEventsQuery, DataTableEventsQueryVariables>(DataTableEventsDocument, options);
      }
export function useDataTableEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataTableEventsQuery, DataTableEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataTableEventsQuery, DataTableEventsQueryVariables>(DataTableEventsDocument, options);
        }
export type DataTableEventsQueryHookResult = ReturnType<typeof useDataTableEventsQuery>;
export type DataTableEventsLazyQueryHookResult = ReturnType<typeof useDataTableEventsLazyQuery>;
export type DataTableEventsQueryResult = Apollo.QueryResult<DataTableEventsQuery, DataTableEventsQueryVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($deleteEventId: String!) {
  deleteEvent(id: $deleteEventId)
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      deleteEventId: // value for 'deleteEventId'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteEventsDocument = gql`
    mutation DeleteEvents($deleteEventId: [String!]!) {
  deleteEvents(id: $deleteEventId)
}
    `;
export type DeleteEventsMutationFn = Apollo.MutationFunction<DeleteEventsMutation, DeleteEventsMutationVariables>;

/**
 * __useDeleteEventsMutation__
 *
 * To run a mutation, you first call `useDeleteEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventsMutation, { data, loading, error }] = useDeleteEventsMutation({
 *   variables: {
 *      deleteEventId: // value for 'deleteEventId'
 *   },
 * });
 */
export function useDeleteEventsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventsMutation, DeleteEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventsMutation, DeleteEventsMutationVariables>(DeleteEventsDocument, options);
      }
export type DeleteEventsMutationHookResult = ReturnType<typeof useDeleteEventsMutation>;
export type DeleteEventsMutationResult = Apollo.MutationResult<DeleteEventsMutation>;
export type DeleteEventsMutationOptions = Apollo.BaseMutationOptions<DeleteEventsMutation, DeleteEventsMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($updateEventId: String!, $event: UpdateEventInput!) {
  updateEvent(id: $updateEventId, event: $event) {
    created_by_id
    details
    end_date
    hotel_id
    id
    name
    start_date
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      updateEventId: // value for 'updateEventId'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const namedOperations = {
  Query: {
    DataTableEvents: 'DataTableEvents'
  },
  Mutation: {
    CreateEvent: 'CreateEvent',
    DeleteEvent: 'DeleteEvent',
    DeleteEvents: 'DeleteEvents',
    UpdateEvent: 'UpdateEvent'
  }
}