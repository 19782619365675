import { generateHTML, generateJSON } from '@tiptap/react';
import { getObject, saveObject } from '@/helpers/localStorageHelper';

import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { today } from '@/helpers/dateHelpers';

Image.configure({
  inline: true,
  allowBase64: true,
});

export const extensions = [StarterKit, Underline, Image];

export const handlePaste = (view: any, event: ClipboardEvent) => {
  const items = Array.from(event.clipboardData?.items || []);
  const image = items.find((item) => item.type.indexOf('image') === 0);

  if (image) {
    event.preventDefault();
    const blob = image.getAsFile();
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      const dataUrl = readerEvent.target?.result as string;
      view.dispatch(
        view.state.tr.replaceSelectionWith(
          view.state.schema.nodes.image.create({ src: dataUrl, size: 100 })
        )
      );
    };
    if (blob) reader.readAsDataURL(blob);
    return true;
  }
  return false;
};

export const parseContent = (
  content: string | object | null
): object | null => {
  if (content === null || content === undefined) return null;

  if (typeof content === 'string') {
    try {
      return JSON.parse(content);
    } catch (error) {
      console.warn(
        'Failed to parse content as JSON, creating a text node:',
        error
      );
      return generateJSON(content, extensions);
    }
  }
  return content || null;
};

export const parseHTML = (content?: string | object | null): string => {
  if (content === null || content === undefined) return '';

  if (typeof content === 'object') {
    try {
      return generateHTML(content, extensions);
    } catch (error) {
      console.warn('Failed to generate HTML from JSON:', error);
      return '';
    }
  }

  if (typeof content === 'string') {
    try {
      const parsed = JSON.parse(content);
      return generateHTML(parsed, extensions);
    } catch (error) {
      console.warn(
        'Failed to parse content as JSON, treating it as HTML string:',
        error
      );
      return content;
    }
  }
  return '';
};

type LoadFromLocalStorage = {
  brandCode?: string;
};

export const loadFromLocalStorage = ({ brandCode }: LoadFromLocalStorage) => {
  const savedNotes = getObject(`meeting-notes-${brandCode}`) as {
    content: string;
    date: string;
  };
  if (savedNotes.date !== today()) return null;
  try {
    return JSON.parse(savedNotes.content);
  } catch (error) {
    console.warn('Failed to parse saved notes, returning as string:', error);
    return savedNotes.content;
  }
};

type SaveToLocalStorage = {
  brandCode?: string;
  content: object;
};

export const saveToLocalStorage = ({
  brandCode,
  content,
}: SaveToLocalStorage) => {
  saveObject(`meeting-notes-${brandCode}`, {
    content: JSON.stringify(content),
    date: today(),
  });
};
