import { DayFilters } from '../../reducers/useDataTableOptions';
import { useEditable } from '../../hooks/useEditable';
import { useRef } from 'react';

type Props = {
  initialValue: string | number;
  label: string;
  onChange: (value: string | number | DayFilters) => void;
  spanClass?: string;
};

const FilterNumber = ({
  initialValue,
  label,
  onChange,
  spanClass = '',
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { edit, handleOnBlur, handleChange, handleKeyDown, setEdit, value } =
    useEditable({ initialValue, inputRef, onChange });

  const hideInput = edit ? '' : 'hidden';

  const id = `${label.toLowerCase().replace(/ /g, '-')}-range`;

  const displayLabel = (value: string | number) => {
    if (value === label) {
      return '';
    } else {
      return ` > ${value}`;
    }
  };

  return (
    <>
      {edit ? (
        <>
          <input
            className={`${hideInput} text-xs text-left`}
            id={id}
            ref={inputRef}
            min={0}
            max={200}
            type='range'
            value={value as number}
            onBlur={handleOnBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <label className='text-xs ml-1' htmlFor={id}>
            {displayLabel(value as number)}
          </label>
        </>
      ) : (
        <span className={spanClass} onClick={() => setEdit(true)}>
          {label}
        </span>
      )}
    </>
  );
};

export default FilterNumber;
