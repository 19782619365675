/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataTableEventsQueryVariables = Types.Exact<{
  filters: Types.FindEventArgs;
}>;


export type DataTableEventsQuery = { __typename?: 'Query', hotelEvents?: Array<{ __typename?: 'Event', details?: string | null, end_date?: any | null, id?: string | null, name?: string | null, start_date?: any | null } | null> | null };


export const DataTableEventsDocument = gql`
    query DataTableEvents($filters: FindEventArgs!) {
  hotelEvents(filters: $filters) {
    details
    end_date
    id
    name
    start_date
  }
}
    `;

/**
 * __useDataTableEventsQuery__
 *
 * To run a query within a React component, call `useDataTableEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataTableEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataTableEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDataTableEventsQuery(baseOptions: Apollo.QueryHookOptions<DataTableEventsQuery, DataTableEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataTableEventsQuery, DataTableEventsQueryVariables>(DataTableEventsDocument, options);
      }
export function useDataTableEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataTableEventsQuery, DataTableEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataTableEventsQuery, DataTableEventsQueryVariables>(DataTableEventsDocument, options);
        }
export type DataTableEventsQueryHookResult = ReturnType<typeof useDataTableEventsQuery>;
export type DataTableEventsLazyQueryHookResult = ReturnType<typeof useDataTableEventsLazyQuery>;
export type DataTableEventsQueryResult = Apollo.QueryResult<DataTableEventsQuery, DataTableEventsQueryVariables>;
export const namedOperations = {
  Query: {
    DataTableEvents: 'DataTableEvents'
  }
}