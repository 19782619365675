import { DayFilters } from '../../reducers/useDataTableOptions';
import { useEditable } from '../../hooks/useEditable';

type Props = {
  initialValue: string | number;
  label: string;
  onChange: (value: string | number | DayFilters) => void;
  options?: { name: string; value: string }[];
  spanClass?: string;
};

const FilterSelect = ({
  initialValue,
  label,
  onChange,
  options,
  spanClass = '',
}: Props) => {
  const { edit, handleChange, handleOnBlur, handleKeyDown, setEdit, value } =
    useEditable({
      initialValue,
      onChange,
    });

  const hideInput = edit ? '' : 'hidden';

  return (
    <>
      {edit ? (
        <select
          className={`${hideInput} text-xs text-left`}
          value={value as string}
          onBlur={handleOnBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        >
          {options &&
            options.map(({ name, value }) => (
              <option key={`${name}-${value}`} value={value}>
                {name}
              </option>
            ))}
        </select>
      ) : (
        <span className={spanClass} onClick={() => setEdit(true)}>
          {label}
        </span>
      )}
    </>
  );
};

export default FilterSelect;
