/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
export type TeamMemberFieldsFragment = { __typename?: 'TeamMember', active?: Types.TeamMemberActive | null, activeChanged?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, email?: string | null, emailPref?: Types.MeetingEmail | null, id?: string | null, name?: string | null, phone?: string | null, title?: string | null, updatedAt?: string | null };

export const TeamMemberFieldsFragmentDoc = gql`
    fragment TeamMemberFields on TeamMember {
  active
  activeChanged
  brandCode
  createdAt
  createdById
  email
  emailPref
  id
  name
  phone
  title
  updatedAt
}
    `;
export const namedOperations = {
  Fragment: {
    TeamMemberFields: 'TeamMemberFields'
  }
}