import { getValue, saveValue } from '../../../helpers/localStorageHelper';
import { useEffect, useState } from 'react';

import axios from 'axios';
import { today } from '../../../helpers/dateHelpers';

const useIp = () => {
  const [ip, setIp] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSetIp = (ip: string) => {
    saveValue('ip', JSON.stringify({ ip, date: today() }));
    setIp(ip);
  };

  useEffect(() => {
    if (ip === null) {
      const getIp = async () => {
        const ipFromStorage = getValue('ip');
        if (ipFromStorage) {
          const { ip, date } = JSON.parse(ipFromStorage);
          if (date === today()) {
            handleSetIp(ip);
            return;
          }
        }
        const { data } = await axios.get('https://api.ipify.org?format=json');
        handleSetIp(data.ip);
      };

      setLoading(true);
      getIp();
      setLoading(false);
    }
  }, [ip]);

  return { ip, loading };
};

export { useIp };
