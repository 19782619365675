import {
  ActionTypes,
  TableFilters,
  useDataTableOptions,
} from '../../../reducers/useDataTableOptions';
import {
  Actions,
  BulkCreateParams,
  DataRow,
  FormField,
} from '../../../types/DataTable';
import {
  DataTableRmRecsQuery,
  DtRmRecsCurrentRateQuery,
  DtRmRecsRmDataQuery,
  useDataTableRmRecsQuery,
  useDtRmRecsCurrentRateQuery,
  useDtRmRecsRmDataQuery,
} from '../../../features/rm_rec_data_table/gql/_gen_/rm_recs_table.gql';
import {
  PaginatedRmRecsObject,
  RmRec,
  RmRecStatus,
  RmRecTableRows,
  UpdateRmRecInput,
} from '../../../types/RmRecsTable';
import _, { Many } from 'lodash';
import {
  betweenOrEqual,
  dayDiff,
  dow,
  dowIndex,
  recur,
  today,
} from '../../../helpers/dateHelpers';
import {
  useCreateRmRec,
  useResetRmRecs,
  useUpdateRmRec,
  useUpsertRmRecs,
} from '../../../hooks/useRmRecs';
import { useEffect, useState } from 'react';

import DataTable from '../../../components/DataTable';
import { NetworkStatus } from '@apollo/client';
import Paginate from '../../../components/Paginate';
import RateUploadBtn from '../../../components/RateUploadBtn';
import { ValidationResult } from 'joi';
import { masterColumns } from '../../../renderers/masterColumns';
import { useHotel } from '../../../context/hotelContext';
import { useUser } from '../../../context/userContext';

const Joi = require('joi').extend(require('@joi/date'));

function RmRecsTable() {
  const objFields = [
    'stay_date',
    'rate',
    'notes',
    'status',
    'sold',
    'adr',
    'current_rate',
    'id',
  ];

  const { hotel } = useHotel();
  const { user } = useUser();

  // These are RM Rec status options that a user can select from.
  const userRecStatus = ['OPEN', 'ACCEPTED', 'DECLINED'];
  const allStatus = ['', ...userRecStatus, 'PROCESSING', 'ERROR', 'UPLOADED'];

  const [options, setOptions] = useDataTableOptions();
  const [dataRange, setDataRange] = useState<string[]>([]);
  const [tableData, setTableData] = useState<DataRow[]>([]);
  const [tableFilters, setTableFilters] = useState<TableFilters>({});
  const [totalPages, setTotalPages] = useState(1);
  const [paginatedObject, setPaginatedObject] = useState<PaginatedRmRecsObject>(
    {
      pages: [],
      index: 0,
      totalRmRecs: 0,
      totalPages: 1,
    }
  );
  const [page, setPage] = useState<number | undefined>(undefined);
  const PAGE_SIZE = 10;

  const emptyRec = () => {
    return {
      hotel_id: hotel?.hotel_id || hotel?.brand_code,
      created_by_id: user?.id,
      stay_date: '',
      rate: '',
      status: RmRecStatus.open,
      notes: '',
    };
  };

  const { data: dataRmData } = useDtRmRecsRmDataQuery({
    skip: !hotel?.hotel_id || !hotel?.brand_code || dataRange.length === 0,
    variables: {
      args: {
        brandCode: hotel?.brand_code,
        endDate: dataRange[1],
        startDate: dataRange[0],
        snapshotDate: today(),
      },
    },
  });

  const { data: dataCurrentRate } = useDtRmRecsCurrentRateQuery({
    skip: !hotel?.hotel_id || !hotel?.brand_code || dataRange.length === 0,
    variables: {
      args: {
        brandCode: hotel?.brand_code,
        endDate: dataRange[1],
        startDate: dataRange[0],
        snapshotDate: today(),
      },
    },
  });

  const {
    data: dataRecs,
    networkStatus: networkStatusRecs,
    refetch: refetchRecs,
  } = useDataTableRmRecsQuery({
    skip: !hotel?.hotel_id || !hotel?.brand_code,
    variables: {
      filters: {
        hotelId: hotel?.hotel_id || hotel?.brand_code,
      },
    },
    onCompleted: (data) => {
      if (data && data.hotelRmRecs) {
        const minDate = _.minBy(data.hotelRmRecs, 'stay_date')?.stay_date;
        const maxDate = _.maxBy(data.hotelRmRecs, 'stay_date')?.stay_date;
        if (minDate && maxDate) {
          setDataRange([minDate, maxDate]);
          setTableFilters({
            ...tableFilters,
            startDate: minDate,
            endDate: maxDate,
          });
        }
      }
    },
  });

  const setStartEnd = (startDate: string, endDate: string) => {
    setOptions({ type: ActionTypes.FILTERS, payload: { startDate, endDate } });
  };

  const createRec = useCreateRmRec({
    callback: () => console.log('createRec callback'),
  });

  const createRecs = useUpsertRmRecs(['FindRmRecs', 'DataTableRmRecs']);

  const resetRmRecs = useResetRmRecs(['FindRmRecs', 'DataTableRmRecs']);

  const updateRmRec = useUpdateRmRec({
    callback: () => console.log(`updateRmRec ${page}`),
  });

  const handleBulkUpsert = (recIds: string[], changes: UpdateRmRecInput) => {
    let recs: DataRow[] = [];
    if (recIds[0] === 'all') {
      recs = tableData;
    } else {
      recs = tableData.filter((r) => recIds.includes(r.id));
    }
    const rmRecs = recs.map((rec) => {
      return {
        hotel_id: hotel?.hotel_id || hotel?.brand_code,
        ..._.omit(rec, ['sold', 'adr', 'current_rate']),
        ...changes,
      };
    });
    return createRecs[0]({
      variables: {
        rmRecs,
      },
    });
  };

  const handleCreate = (fields: FormField[], bulk?: BulkCreateParams) => {
    // Create the base RM Rec object
    let rmRec = emptyRec();
    fields.forEach((f: FormField) => {
      rmRec = {
        ...rmRec,
        [f.name]: f.value === '' ? null : f.value,
      };
    });

    if (bulk) {
      const daysToIndex: number[] = [];
      Object.keys(bulk.bulkDays).forEach((day) => {
        if (bulk.bulkDays[day as keyof typeof bulk.bulkDays]) {
          daysToIndex.push(dowIndex(day));
        }
      });
      const rmRecs = recur({
        startDate: bulk.bulkStart,
        endDate: bulk.bulkEnd,
        days: daysToIndex,
      }).map((date) => {
        return {
          ...rmRec,
          stay_date: date,
        };
      });
      return createRecs[0]({
        variables: {
          rmRecs,
        },
        onCompleted: () => setStartEnd(bulk.bulkStart, bulk.bulkEnd),
      });
    } else {
      return createRec[0]({
        variables: {
          rmRec,
        },
      });
    }
  };

  const handleDelete = async (id: string | string[]) => {
    return await resetRmRecs[0]({
      variables: {
        filters: {
          endDate: options.endDate,
          hotelId: hotel?.hotel_id || hotel?.brand_code,
          startDate: options.startDate,
          ids: Array.isArray(id) ? id : [id],
        },
      },
    });
  };

  const handleDeleteAll = async () => {
    if (!dataRecs?.hotelRmRecs) return;
    return await resetRmRecs[0]({
      variables: {
        filters: {
          endDate: options.endDate,
          hotelId: hotel?.hotel_id || hotel?.brand_code,
          startDate: options.startDate,
          ids: dataRecs.hotelRmRecs.map((r) => r?.id),
        },
      },
    });
  };

  const handleUpdate = async (id: string, data: RmRec) => {
    return await updateRmRec[0]({
      variables: {
        updateRmRecId: id,
        rmRec: data,
      },
    });
  };

  const actions: Actions = {
    bulkUpsert: handleBulkUpsert,
    delete: handleDelete,
    deleteStatus: resetRmRecs[1].loading,
    deleteAll: handleDeleteAll,
    networkStatus: networkStatusRecs,
    update: handleUpdate,
    updateStatus: updateRmRec[1].loading,
  };

  useEffect(() => {
    if (hotel && (hotel.hotel_id || hotel.brand_code)) {
      refetchRecs({
        filters: {
          hotelId: hotel.hotel_id || hotel.brand_code,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel]);

  useEffect(() => {
    if (dataRecs) {
      const rows = getRows(dataRecs, dataCurrentRate, dataRmData);
      const paginatedRecs = paginateRows(
        rows.rows,
        rows.startRmRecIndex,
        PAGE_SIZE,
        page
      );
      setTableData(paginatedRecs.pages[paginatedRecs.index]);
      setPaginatedObject(paginatedRecs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRecs, dataCurrentRate, dataRmData, options, tableFilters]);

  useEffect(() => {
    setTotalPages(paginatedObject.totalPages);
    setPage(paginatedObject.index);
  }, [paginatedObject]);

  useEffect(() => {
    if (page !== undefined) {
      setTableData(paginatedObject.pages[page]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function pageUp() {
    if (page !== undefined) {
      if (page < totalPages - 1) {
        setPage(page + 1);
      }
    }
  }

  function pageDown() {
    if (page !== undefined) {
      if (page >= 1) {
        setPage(page - 1);
      }
    }
  }

  const filterItems = (rec: RmRec) => {
    return (
      filterDates(rec) &&
      filterDays(rec) &&
      filterNotes(rec) &&
      filterStatus(rec) &&
      filterRate(rec)
    );
  };

  const filterDates = (rec: RmRec) => {
    if (tableFilters.startDate && tableFilters.endDate) {
      return betweenOrEqual(
        rec.stay_date,
        String(tableFilters.startDate),
        String(tableFilters.endDate)
      );
    }
    return true;
  };

  const filterDays = (rec: RmRec) => {
    if (tableFilters.stay_date) {
      const dowKey = dow(
        rec.stay_date
      ).toLowerCase() as keyof typeof tableFilters.stay_date;
      return tableFilters.stay_date[dowKey] === true;
    }
    return true;
  };

  const filterNotes = (rec: RmRec) => {
    if (tableFilters.notes) {
      return String(rec.notes)
        .toLowerCase()
        .includes(String(tableFilters.notes).toLowerCase());
    }
    return true;
  };

  const filterRate = (rec: RmRec) => {
    if (tableFilters.rate) {
      return Number(rec.rate) > Number(tableFilters.rate);
    }
    return true;
  };

  const filterStatus = (rec: RmRec) => {
    if (tableFilters.status) {
      return rec.status === tableFilters.status;
    }
    return true;
  };

  const sortValue = (rec: RmRec, key: string) => {
    switch (key) {
      case 'notes':
      case 'status':
        return String(rec[key]).toLowerCase();
      case 'stay_date':
        return rec[key];
      default:
        return Number(rec[key as keyof RmRec]);
    }
  };

  const getRows = (
    data: DataTableRmRecsQuery,
    dataCurrentRate?: DtRmRecsCurrentRateQuery,
    dataRmData?: DtRmRecsRmDataQuery
  ): RmRecTableRows => {
    const rows: DataRow[] = [];
    if (data && data.hotelRmRecs) {
      const startRmRec = {
        diff: 1000,
        index: 0,
      };
      _.orderBy(
        data.hotelRmRecs as unknown as RmRec[],
        [(rec) => sortValue(rec, options.sort.key)],
        options.sort.dir as Many<boolean | 'desc' | 'asc'> | undefined
      )
        .filter(filterItems)
        .forEach((rec: RmRec) => {
          const diff = dayDiff(rec.stay_date, today());
          const rmData = dataRmData?.rmData?.find(
            (x) => x?.stay_date === rec.stay_date
          );
          const currentRate = dataCurrentRate?.currentRateTable?.find(
            (x) => x?.stay_date === rec.stay_date
          );
          if (Math.abs(diff) < startRmRec.diff) {
            startRmRec.diff = Math.abs(diff);
            startRmRec.index = rows.length;
          }
          rows.push(
            _.pick(
              {
                ...rec,
                sold: rmData?.sold,
                adr: rmData?.adr,
                current_rate: currentRate?.current_rate,
              },
              objFields
            ) as DataRow
          );
        });
      return {
        rows: rows,
        startRmRecIndex: startRmRec.index,
      };
    } else {
      return {
        rows: rows,
        startRmRecIndex: 0,
      };
    }
  };

  const splitRows = (rows: DataRow[], startIndex: number, pageSize: number) => {
    if (rows.length <= pageSize) {
      return [rows];
    }
    const secondHalf = rows.splice(startIndex);
    while (secondHalf.length < pageSize) {
      secondHalf.unshift(rows.pop() as DataRow);
    }
    return [rows, secondHalf];
  };

  const paginateRows = (
    rows: DataRow[],
    startIndex: number,
    pageSize: number,
    currentPage?: number
  ): PaginatedRmRecsObject => {
    const rowGroups = splitRows(rows, startIndex, pageSize);
    if (rowGroups.length === 1) {
      return {
        pages: [rows],
        index: 0,
        totalRmRecs: dataRecs?.hotelRmRecs?.length || 0,
        totalPages: 1,
      };
    }
    const pagesArr = [];
    while (rowGroups[0].length > 0) {
      pagesArr.unshift(rowGroups[0].splice(-pageSize));
    }
    const startPage = pagesArr.length;
    while (rowGroups[1].length > 0) {
      pagesArr.push(rowGroups[1].slice(0, pageSize));
      rowGroups[1].splice(0, pageSize);
    }
    return {
      pages: pagesArr,
      index: currentPage || startPage,
      totalRmRecs: dataRecs?.hotelRmRecs?.length || 0,
      totalPages: pagesArr.length,
    };
  };

  const getLabel = (key: string) => {
    switch (key) {
      case 'id':
        return 'Actions';
      case 'sold':
      case 'adr':
        return masterColumns[key].header;
      case 'current_rate':
        return 'Current Rate';
      default:
        return masterColumns[`rm_recs.${key}`].header;
    }
  };

  const formFields = objFields.map((key) => {
    const options: { name: string; value: string }[] = [];
    const optionsFiltersOnly: { name: string; value: string }[] = [];
    allStatus.forEach((status) => {
      optionsFiltersOnly.push({
        value: status,
        name: status,
      });
    });

    ['', ...userRecStatus].forEach((status) => {
      options.push({
        value: status,
        name: status,
      });
    });

    return {
      bulk: ['rate', 'notes'].includes(key),
      id: '',
      label: getLabel(key),
      name: key,
      type: key.includes('date')
        ? 'date'
        : key.includes('status')
        ? 'select'
        : key.includes('rate')
        ? 'number'
        : 'text',
      options: key.includes('status') ? options : undefined,
      optionsFiltersOnly: key.includes('status')
        ? optionsFiltersOnly
        : undefined,
      readOnly: ['sold', 'adr', 'current_rate'].includes(key),
      value: key.includes('date')
        ? today()
        : key.includes('status')
        ? 'OPEN'
        : '',
    };
  });

  const validateRmRec = (
    rmRec: RmRec,
    type: 'bulk' | 'standard'
  ): ValidationResult => {
    const schema = Joi.object({
      notes: Joi.string().allow(''),
      rate: Joi.when('notes', { is: '', then: Joi.number().greater(0) }),
      status: Joi.string().valid('OPEN', 'ACCEPTED', 'DECLINED'),
      stay_date: Joi.date()
        .format('YYYY-MM-DD')
        .alter({
          bulk: (schema: any) => schema.optional(),
          standard: (schema: any) => schema.required(),
        }),
    });

    return schema.tailor(type).validate(rmRec);
  };

  return (
    <div className='w-full mt-2'>
      {networkStatusRecs === NetworkStatus.loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <RateUploadBtn />
          <Paginate
            first={page || 0}
            last={totalPages}
            previous={pageDown}
            next={pageUp}
            total={paginatedObject.totalRmRecs || 0}
          />
          <DataTable
            actions={actions}
            dataRange={dataRange}
            fields={formFields as FormField[]}
            handleCreate={handleCreate}
            name='Rm Rec'
            options={options}
            rows={tableData}
            setOptions={setOptions}
            setTableFilters={setTableFilters}
            tableFilters={tableFilters}
            validation={validateRmRec}
          />
        </div>
      )}
    </div>
  );
}

export default RmRecsTable;
