import {
  LastNightTableDates,
  formatDate,
  yesterday,
} from '../helpers/dateHelpers';

import ReactTooltip from 'react-tooltip';
import { RmData } from '../graphql/types';
import Table from '../components/Table';
import { tableData } from './dataLastNightTable';

type Props = {
  currentData?: RmData[];
  lastYearData?: RmData[];
  lastNight?: string;
};

type TooltipProps = {
  tableDates: LastNightTableDates;
};

export const LastNightTooltip = ({ tableDates }: TooltipProps) => {
  return (
    <ReactTooltip id='last-night-summary' aria-haspopup='true'>
      <div>
        <p>
          <strong>Last Night Summary Table</strong>
        </p>
        <br />
        <ul>
          <li>
            MTD - This is the total from the 1st of the month up to Last Night
          </li>
          <li>
            MTD LY - This is the total from the same day of the month last year
            up to last night last year.
          </li>
        </ul>
        <br />
        <p>
          <em>{`Example. If today is ${formatDate(
            tableDates.baseDate,
            'MMMM D, YYYY'
          )}`}</em>
        </p>
        <ul>
          <li>{`MTD covers ${formatDate(tableDates.mtdStart)} - ${formatDate(
            tableDates.mtdEnd
          )} for actuals`}</li>
          <li>
            {` MTD LY covers ${formatDate(
              tableDates.mtdStartLy
            )} - ${formatDate(tableDates.mtdEndLy)} for actuals (not same point
            in time)`}
          </li>
        </ul>
      </div>
    </ReactTooltip>
  );
};

const LastNightTable = ({
  currentData,
  lastYearData,
  lastNight = yesterday(),
}: Props) => {
  return <Table {...tableData(lastNight, currentData, lastYearData)} />;
};

export default LastNightTable;
