/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OtaiLowestRatesQueryVariables = Types.Exact<{
  params: Types.RateQueryParams;
  brandCode: Types.Scalars['String'];
}>;


export type OtaiLowestRatesQuery = { __typename?: 'Query', dailyRates?: Array<{ __typename?: 'DailyRates', stay_date?: any | null, date_year?: string | null, date_ym?: string | null, date_yq?: string | null, date_yw?: string | null, rates?: Array<{ __typename?: 'OtaiRate', arrivalDate?: any | null, best_flex?: boolean | null, cancellable?: boolean | null, cityTaxIncl?: boolean | null, clientRate?: number | null, currency?: string | null, extractDateTime?: string | null, hotelId?: number | null, hotelName?: string | null, los?: number | null, maxPersons?: number | null, mealTypeIncluded?: Types.MealType | null, message?: string | null, otherTaxesIncl?: boolean | null, roomName?: string | null, shopCurrency?: string | null, value?: number | null, vatIncl?: boolean | null, competitor_id?: string | null, competitor_name?: string | null, rate?: number | null, changes?: Array<{ __typename?: 'RateChangesList', days?: number | null, change?: { __typename?: 'RateChange', best_flex?: boolean | null, cancellable?: boolean | null, message?: string | null, roomName?: string | null, roomType?: string | null, value?: number | null } | null } | null> | null } | { __typename?: 'RateShop' } | null> | null } | null> | null, compSet?: Array<{ __typename?: 'ShoppedHotel', columnSize?: number | null, competitor_id?: string | null, competitor_name?: string | null, key?: string | null, dataSource?: string | null, header?: string | null, order?: number | null, renderer?: string | null, tooltip?: string | null } | null> | null };


export const OtaiLowestRatesDocument = gql`
    query OtaiLowestRates($params: RateQueryParams!, $brandCode: String!) {
  dailyRates: dailyOtaiRates(brandCode: $brandCode, params: $params) {
    stay_date
    date_year
    date_ym
    date_yq
    date_yw
    rates {
      ... on OtaiRate {
        arrivalDate
        best_flex
        cancellable
        changes {
          days
          change {
            best_flex
            cancellable
            message
            roomName
            roomType
            value
          }
        }
        cityTaxIncl
        clientRate
        currency
        extractDateTime
        hotelId
        hotelName
        los
        maxPersons
        mealTypeIncluded
        message
        otherTaxesIncl
        roomName
        shopCurrency
        value
        vatIncl
        competitor_id
        competitor_name
        rate
      }
    }
  }
  compSet: compSetOtai(brandCode: $brandCode) {
    columnSize
    competitor_id
    competitor_name
    key
    dataSource
    header
    order
    renderer
    tooltip
  }
}
    `;

/**
 * __useOtaiLowestRatesQuery__
 *
 * To run a query within a React component, call `useOtaiLowestRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtaiLowestRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtaiLowestRatesQuery({
 *   variables: {
 *      params: // value for 'params'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useOtaiLowestRatesQuery(baseOptions: Apollo.QueryHookOptions<OtaiLowestRatesQuery, OtaiLowestRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OtaiLowestRatesQuery, OtaiLowestRatesQueryVariables>(OtaiLowestRatesDocument, options);
      }
export function useOtaiLowestRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtaiLowestRatesQuery, OtaiLowestRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OtaiLowestRatesQuery, OtaiLowestRatesQueryVariables>(OtaiLowestRatesDocument, options);
        }
export type OtaiLowestRatesQueryHookResult = ReturnType<typeof useOtaiLowestRatesQuery>;
export type OtaiLowestRatesLazyQueryHookResult = ReturnType<typeof useOtaiLowestRatesLazyQuery>;
export type OtaiLowestRatesQueryResult = Apollo.QueryResult<OtaiLowestRatesQuery, OtaiLowestRatesQueryVariables>;
export const namedOperations = {
  Query: {
    OtaiLowestRates: 'OtaiLowestRates'
  }
}