import { useEffect, useState } from 'react';

import { MeetingCategory as CategoryType } from '../../../graphql/types';
import { Meeting } from '../../../graphql/types';
import { useIsShared } from '../hooks/use-report-location';
import { useMeeting } from '../../meetings/context/meeting-context';

function MeetingCategory({ meeting: presetMeeting }: { meeting?: Meeting }) {
  const { isShared } = useIsShared();
  const { meeting, saveMeeting, saveResults, updateResults } = useMeeting();
  const [category, setCategory] = useState<CategoryType>(CategoryType.Daily);

  const isLoading = saveResults.loading || updateResults.loading;

  useEffect(() => {
    if (presetMeeting) {
      setCategory(presetMeeting.category as CategoryType);
    } else if (meeting) {
      setCategory(meeting.category as CategoryType);
    } else {
      setCategory(CategoryType.Daily);
    }
  }, [meeting, presetMeeting]);

  return (
    <form>
      <label htmlFor='category' className='font-medium'>
        Meeting Type:{' '}
      </label>
      {isLoading && <span className='text-gray-500'>Saving...</span>}
      <select
        id='category'
        disabled={isShared}
        onChange={(e) => {
          setCategory(e.target.value as CategoryType);
          saveMeeting({ category: e.target.value as CategoryType });
        }}
        value={category}
      >
        <option value={CategoryType.Daily}>Daily</option>
        <option value={CategoryType.Weekly}>Weekly</option>
        <option value={CategoryType.Adhoc}>Adhoc</option>
      </select>
    </form>
  );
}

export default MeetingCategory;
