import { AddActionItemForm } from './add-action-item-form';
import { AddAgendaItemForm } from './add-agenda-item-form';
import { AddTeamMemberForm } from './add-team-member-form';
import { CloseFn } from '../../../components/BtnPopover';
import { FormView } from './meeting-tools';

type MeetingFormProps = {
  brandCode?: string;
  close?: CloseFn;
  view: FormView;
};

const MeetingForms = ({ brandCode, close, view }: MeetingFormProps) => {
  return (
    <div className='pr-4'>
      {view === FormView.AGENDA && (
        <AddAgendaItemForm brandCode={brandCode} closeForm={close} />
      )}
      {view === FormView.TEAM && (
        <AddTeamMemberForm brandCode={brandCode} closeForm={close} />
      )}
      {view === FormView.ACTION_ITEMS && (
        <AddActionItemForm brandCode={brandCode} closeForm={close} />
      )}
    </div>
  );
};

export { MeetingForms };
