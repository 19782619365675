import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { TabItems } from '../../../dashboards/tabItems';
import { classNames } from '../../../helpers/tailwindHelper';

type Props = {
  icon: IconType;
  href: string;
  isActive?: boolean;
  tabItems?: TabItems[];
  title?: string;
};

const NavDropdown = ({
  href = '/',
  icon,
  isActive,
  tabItems,
  title = '',
}: Props) => {
  const Icon = icon;
  let linkClass = 'flex mr-10 items-center text-sm';
  linkClass = isActive ? `${linkClass} text-blue-300` : linkClass;

  return (
    <Menu as={'li'} className='text-gray-50 hover:text-gray-300'>
      <Menu.Button className={linkClass}>
        <Icon className='w-5 h-5 mr-2' />
        <span>{title}</span>
      </Menu.Button>
      <Menu.Items className='z-40 absolute top-10 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
        {tabItems &&
          tabItems.length > 0 &&
          tabItems.map((item) => {
            return (
              <Menu.Item key={item.key}>
                {({ active }) => (
                  <Link
                    to={item.to}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700'
                    )}
                  >
                    {item.title}
                  </Link>
                )}
              </Menu.Item>
            );
          })}
      </Menu.Items>
    </Menu>
  );
};

export default NavDropdown;
