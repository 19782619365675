import { ColumnBase } from '../../types/Columns';
import FieldSelect from '../FieldSelect';
import { masterColumns } from '../../renderers/masterColumns';

export type InsertLocationProps = {
  disabled: boolean;
  field: string;
  onChange: (value: string) => void;
  tableCols: ColumnBase[];
  value: string;
};

const masterHeader = (key: string) => {
  const col = masterColumns[key];
  return col ? col.header : key;
};

export default function InsertLocation(
  props: InsertLocationProps
): JSX.Element {
  const { disabled, field, onChange, tableCols, value } = props;
  const options = tableCols.map((col) => {
    return { value: col.key, label: col.header || masterHeader(col.key) };
  });
  return (
    <div className=''>
      <FieldSelect
        customClass='inline-block text-xs w-full'
        customLabel='Insert After:'
        disabled={disabled}
        field={field}
        onChange={onChange}
        options={options}
        showLabel={true}
        value={value}
      />
    </div>
  );
}
