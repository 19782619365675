/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { MeetingFieldsFragmentDoc } from './meeting.fragment.gql';
import { AgendaItemFieldsFragmentDoc } from './agenda.fragment.gql';
import { ActionItemFieldsFragmentDoc } from './action-item.fragment.gql';
import { TeamMemberFieldsFragmentDoc } from './hotel-team.fragment.gql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMeetingsQueryVariables = Types.Exact<{
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
  meetingDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetMeetingsQuery = { __typename?: 'Query', getMeetings?: Array<{ __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null> | null };

export type GetMeetingByIdQueryVariables = Types.Exact<{
  meetingId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetMeetingByIdQuery = { __typename?: 'Query', getMeetingById?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };

export type GetMeetingByDateQueryVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  meetingDate: Types.Scalars['String'];
}>;


export type GetMeetingByDateQuery = { __typename?: 'Query', getMeetingByDate?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };

export type MeetingToolsQueryVariables = Types.Exact<{
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MeetingToolsQuery = { __typename?: 'Query', meetingTools?: { __typename?: 'MeetingService', agendaItems?: Array<{ __typename?: 'AgendaItem', brandCode?: string | null, completed?: boolean | null, createdAt?: string | null, createdById?: string | null, id?: string | null, name?: string | null, notes?: string | null, updatedAt?: string | null, isDefault?: boolean | null, order?: number | null } | null> | null, actionItems?: Array<{ __typename?: 'ActionItem', assignedToId?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, dueDate?: string | null, id?: string | null, name?: string | null, status?: Types.ActionItemStatus | null, statusUpdated?: string | null } | null> | null, hotelTeams?: Array<{ __typename?: 'TeamMember', active?: Types.TeamMemberActive | null, activeChanged?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, email?: string | null, emailPref?: Types.MeetingEmail | null, id?: string | null, name?: string | null, phone?: string | null, title?: string | null, updatedAt?: string | null } | null> | null } | null };

export type ShareUrlQueryVariables = Types.Exact<{
  bucket: Types.Scalars['String'];
  key: Types.Scalars['String'];
}>;


export type ShareUrlQuery = { __typename?: 'Query', shareUrl?: string | null };

export type UploadUrlQueryVariables = Types.Exact<{
  bucket: Types.Scalars['String'];
  key: Types.Scalars['String'];
}>;


export type UploadUrlQuery = { __typename?: 'Query', uploadUrl?: string | null };

export type AddMeetingMutationVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  createdById: Types.Scalars['String'];
  agendaItems?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  agendaItemNotes?: Types.InputMaybe<Array<Types.InputMaybe<Types.AgendaItemNoteInput>> | Types.InputMaybe<Types.AgendaItemNoteInput>>;
  attendees?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  category?: Types.InputMaybe<Types.MeetingCategory>;
  meetingDate?: Types.InputMaybe<Types.Scalars['String']>;
  notes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AddMeetingMutation = { __typename?: 'Mutation', addMeeting?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };

export type LogAgendaMutationVariables = Types.Exact<{
  meetingId: Types.Scalars['String'];
  agendaId: Types.Scalars['String'];
}>;


export type LogAgendaMutation = { __typename?: 'Mutation', addAgenda?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };

export type UnlogAgendaMutationVariables = Types.Exact<{
  meetingId: Types.Scalars['String'];
  agendaId: Types.Scalars['String'];
}>;


export type UnlogAgendaMutation = { __typename?: 'Mutation', removeAgenda?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };

export type LogAttendeeMutationVariables = Types.Exact<{
  meetingId: Types.Scalars['String'];
  attendeeId: Types.Scalars['String'];
}>;


export type LogAttendeeMutation = { __typename?: 'Mutation', addAttendee?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };

export type RemoveMeetingMutationVariables = Types.Exact<{
  meetingId: Types.Scalars['String'];
}>;


export type RemoveMeetingMutation = { __typename?: 'Mutation', removeMeeting?: boolean | null };

export type UnlogAttendeeMutationVariables = Types.Exact<{
  meetingId: Types.Scalars['String'];
  attendeeId: Types.Scalars['String'];
}>;


export type UnlogAttendeeMutation = { __typename?: 'Mutation', removeAttendee?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };

export type UpdateMeetingMutationVariables = Types.Exact<{
  updateMeetingId: Types.Scalars['String'];
  agendaItems?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  agendaItemNotes?: Types.InputMaybe<Array<Types.InputMaybe<Types.AgendaItemNoteInput>> | Types.InputMaybe<Types.AgendaItemNoteInput>>;
  attendees?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  category?: Types.InputMaybe<Types.MeetingCategory>;
  notes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateMeetingMutation = { __typename?: 'Mutation', updateMeeting?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };

export type UpdateAgendaItemNotesMutationVariables = Types.Exact<{
  meetingId?: Types.InputMaybe<Types.Scalars['String']>;
  itemId?: Types.InputMaybe<Types.Scalars['String']>;
  notes?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateAgendaItemNotesMutation = { __typename?: 'Mutation', updateAgendaItemNotes?: { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null } | null };


export const GetMeetingsDocument = gql`
    query GetMeetings($brandCode: String, $meetingDate: String) {
  getMeetings(brandCode: $brandCode, meetingDate: $meetingDate) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;

/**
 * __useGetMeetingsQuery__
 *
 * To run a query within a React component, call `useGetMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeetingsQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      meetingDate: // value for 'meetingDate'
 *   },
 * });
 */
export function useGetMeetingsQuery(baseOptions?: Apollo.QueryHookOptions<GetMeetingsQuery, GetMeetingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeetingsQuery, GetMeetingsQueryVariables>(GetMeetingsDocument, options);
      }
export function useGetMeetingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeetingsQuery, GetMeetingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeetingsQuery, GetMeetingsQueryVariables>(GetMeetingsDocument, options);
        }
export type GetMeetingsQueryHookResult = ReturnType<typeof useGetMeetingsQuery>;
export type GetMeetingsLazyQueryHookResult = ReturnType<typeof useGetMeetingsLazyQuery>;
export type GetMeetingsQueryResult = Apollo.QueryResult<GetMeetingsQuery, GetMeetingsQueryVariables>;
export const GetMeetingByIdDocument = gql`
    query GetMeetingById($meetingId: String) {
  getMeetingById(meetingId: $meetingId) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;

/**
 * __useGetMeetingByIdQuery__
 *
 * To run a query within a React component, call `useGetMeetingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeetingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeetingByIdQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useGetMeetingByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetMeetingByIdQuery, GetMeetingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeetingByIdQuery, GetMeetingByIdQueryVariables>(GetMeetingByIdDocument, options);
      }
export function useGetMeetingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeetingByIdQuery, GetMeetingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeetingByIdQuery, GetMeetingByIdQueryVariables>(GetMeetingByIdDocument, options);
        }
export type GetMeetingByIdQueryHookResult = ReturnType<typeof useGetMeetingByIdQuery>;
export type GetMeetingByIdLazyQueryHookResult = ReturnType<typeof useGetMeetingByIdLazyQuery>;
export type GetMeetingByIdQueryResult = Apollo.QueryResult<GetMeetingByIdQuery, GetMeetingByIdQueryVariables>;
export const GetMeetingByDateDocument = gql`
    query GetMeetingByDate($brandCode: String!, $meetingDate: String!) {
  getMeetingByDate(brandCode: $brandCode, meetingDate: $meetingDate) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;

/**
 * __useGetMeetingByDateQuery__
 *
 * To run a query within a React component, call `useGetMeetingByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeetingByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeetingByDateQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      meetingDate: // value for 'meetingDate'
 *   },
 * });
 */
export function useGetMeetingByDateQuery(baseOptions: Apollo.QueryHookOptions<GetMeetingByDateQuery, GetMeetingByDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeetingByDateQuery, GetMeetingByDateQueryVariables>(GetMeetingByDateDocument, options);
      }
export function useGetMeetingByDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeetingByDateQuery, GetMeetingByDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeetingByDateQuery, GetMeetingByDateQueryVariables>(GetMeetingByDateDocument, options);
        }
export type GetMeetingByDateQueryHookResult = ReturnType<typeof useGetMeetingByDateQuery>;
export type GetMeetingByDateLazyQueryHookResult = ReturnType<typeof useGetMeetingByDateLazyQuery>;
export type GetMeetingByDateQueryResult = Apollo.QueryResult<GetMeetingByDateQuery, GetMeetingByDateQueryVariables>;
export const MeetingToolsDocument = gql`
    query MeetingTools($brandCode: String) {
  meetingTools(brandCode: $brandCode) {
    agendaItems {
      ...AgendaItemFields
    }
    actionItems {
      ...ActionItemFields
    }
    hotelTeams {
      ...TeamMemberFields
    }
  }
}
    ${AgendaItemFieldsFragmentDoc}
${ActionItemFieldsFragmentDoc}
${TeamMemberFieldsFragmentDoc}`;

/**
 * __useMeetingToolsQuery__
 *
 * To run a query within a React component, call `useMeetingToolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingToolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingToolsQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useMeetingToolsQuery(baseOptions?: Apollo.QueryHookOptions<MeetingToolsQuery, MeetingToolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingToolsQuery, MeetingToolsQueryVariables>(MeetingToolsDocument, options);
      }
export function useMeetingToolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingToolsQuery, MeetingToolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingToolsQuery, MeetingToolsQueryVariables>(MeetingToolsDocument, options);
        }
export type MeetingToolsQueryHookResult = ReturnType<typeof useMeetingToolsQuery>;
export type MeetingToolsLazyQueryHookResult = ReturnType<typeof useMeetingToolsLazyQuery>;
export type MeetingToolsQueryResult = Apollo.QueryResult<MeetingToolsQuery, MeetingToolsQueryVariables>;
export const ShareUrlDocument = gql`
    query ShareUrl($bucket: String!, $key: String!) {
  shareUrl(bucket: $bucket, key: $key)
}
    `;

/**
 * __useShareUrlQuery__
 *
 * To run a query within a React component, call `useShareUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareUrlQuery({
 *   variables: {
 *      bucket: // value for 'bucket'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useShareUrlQuery(baseOptions: Apollo.QueryHookOptions<ShareUrlQuery, ShareUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShareUrlQuery, ShareUrlQueryVariables>(ShareUrlDocument, options);
      }
export function useShareUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShareUrlQuery, ShareUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShareUrlQuery, ShareUrlQueryVariables>(ShareUrlDocument, options);
        }
export type ShareUrlQueryHookResult = ReturnType<typeof useShareUrlQuery>;
export type ShareUrlLazyQueryHookResult = ReturnType<typeof useShareUrlLazyQuery>;
export type ShareUrlQueryResult = Apollo.QueryResult<ShareUrlQuery, ShareUrlQueryVariables>;
export const UploadUrlDocument = gql`
    query UploadUrl($bucket: String!, $key: String!) {
  uploadUrl(bucket: $bucket, key: $key)
}
    `;

/**
 * __useUploadUrlQuery__
 *
 * To run a query within a React component, call `useUploadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadUrlQuery({
 *   variables: {
 *      bucket: // value for 'bucket'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUploadUrlQuery(baseOptions: Apollo.QueryHookOptions<UploadUrlQuery, UploadUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadUrlQuery, UploadUrlQueryVariables>(UploadUrlDocument, options);
      }
export function useUploadUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadUrlQuery, UploadUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadUrlQuery, UploadUrlQueryVariables>(UploadUrlDocument, options);
        }
export type UploadUrlQueryHookResult = ReturnType<typeof useUploadUrlQuery>;
export type UploadUrlLazyQueryHookResult = ReturnType<typeof useUploadUrlLazyQuery>;
export type UploadUrlQueryResult = Apollo.QueryResult<UploadUrlQuery, UploadUrlQueryVariables>;
export const AddMeetingDocument = gql`
    mutation AddMeeting($brandCode: String!, $createdById: String!, $agendaItems: [String], $agendaItemNotes: [AgendaItemNoteInput], $attendees: [String], $category: MeetingCategory, $meetingDate: String, $notes: String) {
  addMeeting(
    brandCode: $brandCode
    createdById: $createdById
    agendaItems: $agendaItems
    agendaItemNotes: $agendaItemNotes
    attendees: $attendees
    category: $category
    meetingDate: $meetingDate
    notes: $notes
  ) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;
export type AddMeetingMutationFn = Apollo.MutationFunction<AddMeetingMutation, AddMeetingMutationVariables>;

/**
 * __useAddMeetingMutation__
 *
 * To run a mutation, you first call `useAddMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMeetingMutation, { data, loading, error }] = useAddMeetingMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      createdById: // value for 'createdById'
 *      agendaItems: // value for 'agendaItems'
 *      agendaItemNotes: // value for 'agendaItemNotes'
 *      attendees: // value for 'attendees'
 *      category: // value for 'category'
 *      meetingDate: // value for 'meetingDate'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useAddMeetingMutation(baseOptions?: Apollo.MutationHookOptions<AddMeetingMutation, AddMeetingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMeetingMutation, AddMeetingMutationVariables>(AddMeetingDocument, options);
      }
export type AddMeetingMutationHookResult = ReturnType<typeof useAddMeetingMutation>;
export type AddMeetingMutationResult = Apollo.MutationResult<AddMeetingMutation>;
export type AddMeetingMutationOptions = Apollo.BaseMutationOptions<AddMeetingMutation, AddMeetingMutationVariables>;
export const LogAgendaDocument = gql`
    mutation LogAgenda($meetingId: String!, $agendaId: String!) {
  addAgenda(meetingId: $meetingId, agendaId: $agendaId) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;
export type LogAgendaMutationFn = Apollo.MutationFunction<LogAgendaMutation, LogAgendaMutationVariables>;

/**
 * __useLogAgendaMutation__
 *
 * To run a mutation, you first call `useLogAgendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogAgendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logAgendaMutation, { data, loading, error }] = useLogAgendaMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      agendaId: // value for 'agendaId'
 *   },
 * });
 */
export function useLogAgendaMutation(baseOptions?: Apollo.MutationHookOptions<LogAgendaMutation, LogAgendaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogAgendaMutation, LogAgendaMutationVariables>(LogAgendaDocument, options);
      }
export type LogAgendaMutationHookResult = ReturnType<typeof useLogAgendaMutation>;
export type LogAgendaMutationResult = Apollo.MutationResult<LogAgendaMutation>;
export type LogAgendaMutationOptions = Apollo.BaseMutationOptions<LogAgendaMutation, LogAgendaMutationVariables>;
export const UnlogAgendaDocument = gql`
    mutation UnlogAgenda($meetingId: String!, $agendaId: String!) {
  removeAgenda(meetingId: $meetingId, agendaId: $agendaId) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;
export type UnlogAgendaMutationFn = Apollo.MutationFunction<UnlogAgendaMutation, UnlogAgendaMutationVariables>;

/**
 * __useUnlogAgendaMutation__
 *
 * To run a mutation, you first call `useUnlogAgendaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlogAgendaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlogAgendaMutation, { data, loading, error }] = useUnlogAgendaMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      agendaId: // value for 'agendaId'
 *   },
 * });
 */
export function useUnlogAgendaMutation(baseOptions?: Apollo.MutationHookOptions<UnlogAgendaMutation, UnlogAgendaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlogAgendaMutation, UnlogAgendaMutationVariables>(UnlogAgendaDocument, options);
      }
export type UnlogAgendaMutationHookResult = ReturnType<typeof useUnlogAgendaMutation>;
export type UnlogAgendaMutationResult = Apollo.MutationResult<UnlogAgendaMutation>;
export type UnlogAgendaMutationOptions = Apollo.BaseMutationOptions<UnlogAgendaMutation, UnlogAgendaMutationVariables>;
export const LogAttendeeDocument = gql`
    mutation LogAttendee($meetingId: String!, $attendeeId: String!) {
  addAttendee(meetingId: $meetingId, attendeeId: $attendeeId) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;
export type LogAttendeeMutationFn = Apollo.MutationFunction<LogAttendeeMutation, LogAttendeeMutationVariables>;

/**
 * __useLogAttendeeMutation__
 *
 * To run a mutation, you first call `useLogAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logAttendeeMutation, { data, loading, error }] = useLogAttendeeMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useLogAttendeeMutation(baseOptions?: Apollo.MutationHookOptions<LogAttendeeMutation, LogAttendeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogAttendeeMutation, LogAttendeeMutationVariables>(LogAttendeeDocument, options);
      }
export type LogAttendeeMutationHookResult = ReturnType<typeof useLogAttendeeMutation>;
export type LogAttendeeMutationResult = Apollo.MutationResult<LogAttendeeMutation>;
export type LogAttendeeMutationOptions = Apollo.BaseMutationOptions<LogAttendeeMutation, LogAttendeeMutationVariables>;
export const RemoveMeetingDocument = gql`
    mutation RemoveMeeting($meetingId: String!) {
  removeMeeting(id: $meetingId)
}
    `;
export type RemoveMeetingMutationFn = Apollo.MutationFunction<RemoveMeetingMutation, RemoveMeetingMutationVariables>;

/**
 * __useRemoveMeetingMutation__
 *
 * To run a mutation, you first call `useRemoveMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMeetingMutation, { data, loading, error }] = useRemoveMeetingMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useRemoveMeetingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMeetingMutation, RemoveMeetingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMeetingMutation, RemoveMeetingMutationVariables>(RemoveMeetingDocument, options);
      }
export type RemoveMeetingMutationHookResult = ReturnType<typeof useRemoveMeetingMutation>;
export type RemoveMeetingMutationResult = Apollo.MutationResult<RemoveMeetingMutation>;
export type RemoveMeetingMutationOptions = Apollo.BaseMutationOptions<RemoveMeetingMutation, RemoveMeetingMutationVariables>;
export const UnlogAttendeeDocument = gql`
    mutation UnlogAttendee($meetingId: String!, $attendeeId: String!) {
  removeAttendee(meetingId: $meetingId, attendeeId: $attendeeId) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;
export type UnlogAttendeeMutationFn = Apollo.MutationFunction<UnlogAttendeeMutation, UnlogAttendeeMutationVariables>;

/**
 * __useUnlogAttendeeMutation__
 *
 * To run a mutation, you first call `useUnlogAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlogAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlogAttendeeMutation, { data, loading, error }] = useUnlogAttendeeMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useUnlogAttendeeMutation(baseOptions?: Apollo.MutationHookOptions<UnlogAttendeeMutation, UnlogAttendeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlogAttendeeMutation, UnlogAttendeeMutationVariables>(UnlogAttendeeDocument, options);
      }
export type UnlogAttendeeMutationHookResult = ReturnType<typeof useUnlogAttendeeMutation>;
export type UnlogAttendeeMutationResult = Apollo.MutationResult<UnlogAttendeeMutation>;
export type UnlogAttendeeMutationOptions = Apollo.BaseMutationOptions<UnlogAttendeeMutation, UnlogAttendeeMutationVariables>;
export const UpdateMeetingDocument = gql`
    mutation UpdateMeeting($updateMeetingId: String!, $agendaItems: [String], $agendaItemNotes: [AgendaItemNoteInput], $attendees: [String], $category: MeetingCategory, $notes: String) {
  updateMeeting(
    id: $updateMeetingId
    agendaItems: $agendaItems
    agendaItemNotes: $agendaItemNotes
    attendees: $attendees
    category: $category
    notes: $notes
  ) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;
export type UpdateMeetingMutationFn = Apollo.MutationFunction<UpdateMeetingMutation, UpdateMeetingMutationVariables>;

/**
 * __useUpdateMeetingMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingMutation, { data, loading, error }] = useUpdateMeetingMutation({
 *   variables: {
 *      updateMeetingId: // value for 'updateMeetingId'
 *      agendaItems: // value for 'agendaItems'
 *      agendaItemNotes: // value for 'agendaItemNotes'
 *      attendees: // value for 'attendees'
 *      category: // value for 'category'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateMeetingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeetingMutation, UpdateMeetingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeetingMutation, UpdateMeetingMutationVariables>(UpdateMeetingDocument, options);
      }
export type UpdateMeetingMutationHookResult = ReturnType<typeof useUpdateMeetingMutation>;
export type UpdateMeetingMutationResult = Apollo.MutationResult<UpdateMeetingMutation>;
export type UpdateMeetingMutationOptions = Apollo.BaseMutationOptions<UpdateMeetingMutation, UpdateMeetingMutationVariables>;
export const UpdateAgendaItemNotesDocument = gql`
    mutation UpdateAgendaItemNotes($meetingId: String, $itemId: String, $notes: String) {
  updateAgendaItemNotes(meetingId: $meetingId, itemId: $itemId, notes: $notes) {
    ...MeetingFields
  }
}
    ${MeetingFieldsFragmentDoc}`;
export type UpdateAgendaItemNotesMutationFn = Apollo.MutationFunction<UpdateAgendaItemNotesMutation, UpdateAgendaItemNotesMutationVariables>;

/**
 * __useUpdateAgendaItemNotesMutation__
 *
 * To run a mutation, you first call `useUpdateAgendaItemNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgendaItemNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgendaItemNotesMutation, { data, loading, error }] = useUpdateAgendaItemNotesMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      itemId: // value for 'itemId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateAgendaItemNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgendaItemNotesMutation, UpdateAgendaItemNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgendaItemNotesMutation, UpdateAgendaItemNotesMutationVariables>(UpdateAgendaItemNotesDocument, options);
      }
export type UpdateAgendaItemNotesMutationHookResult = ReturnType<typeof useUpdateAgendaItemNotesMutation>;
export type UpdateAgendaItemNotesMutationResult = Apollo.MutationResult<UpdateAgendaItemNotesMutation>;
export type UpdateAgendaItemNotesMutationOptions = Apollo.BaseMutationOptions<UpdateAgendaItemNotesMutation, UpdateAgendaItemNotesMutationVariables>;
export const namedOperations = {
  Query: {
    GetMeetings: 'GetMeetings',
    GetMeetingById: 'GetMeetingById',
    GetMeetingByDate: 'GetMeetingByDate',
    MeetingTools: 'MeetingTools',
    ShareUrl: 'ShareUrl',
    UploadUrl: 'UploadUrl'
  },
  Mutation: {
    AddMeeting: 'AddMeeting',
    LogAgenda: 'LogAgenda',
    UnlogAgenda: 'UnlogAgenda',
    LogAttendee: 'LogAttendee',
    RemoveMeeting: 'RemoveMeeting',
    UnlogAttendee: 'UnlogAttendee',
    UpdateMeeting: 'UpdateMeeting',
    UpdateAgendaItemNotes: 'UpdateAgendaItemNotes'
  }
}