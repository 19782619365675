import logo from './best-western.jpg';
import logoAiden from './aiden.jpeg';
import logoBWSignatureCol from './bw-signature-collection.jpeg';
import logoExec from './executive-residency.jpg';
import logoGlo from './glo.jpg';
import logoPlus from './best-western-plus.jpg';
import logoPremier from './bw-premier.jpg';
import logoPremierCl from './bw-premier-cl.jpg';
import logoSureHotel from './sure-hotel.jpeg';
import logoSureHotelCollection from './sure-hotel-collection.jpeg';
import logoSureHotelPlus from './sure-hotel-plus.jpeg';
import logoSureHotelStudio from './sure-hotel-studio.jpeg';
import logoSureStayCollection from './surestay-collection.jpeg';
import logoSureStayHotel from './surestay-hotel.jpeg';
import logoSureStayPlus from './surestay-plus.jpeg';
import logoSureStayStudio from './surestay-studio.jpeg';
import logoVib from './vib.jpg';
import logoWorldHotelsCrafted from './worldhotels-crafted.jpeg';
import logoWorldHotelsDist from './worldhotels-distinctive.jpeg';
import logoWorldHotelsElite from './worldhotels-elite.jpeg';
import logoWorldHotelsLux from './worldhotels-luxury.jpeg';

export const allLogos = [
  logo,
  logoAiden,
  logoBWSignatureCol,
  logoExec,
  logoGlo,
  logoPlus,
  logoPremier,
  logoPremierCl,
  logoVib,
  logoSureHotel,
  logoSureHotelCollection,
  logoSureHotelPlus,
  logoSureHotelStudio,
  logoSureStayCollection,
  logoSureStayHotel,
  logoSureStayPlus,
  logoSureStayStudio,
  logoWorldHotelsCrafted,
  logoWorldHotelsDist,
  logoWorldHotelsElite,
  logoWorldHotelsLux,
];

export const logoMap = {
  default: logo,
  ADEN: logoAiden,
  BEST: logo,
  BWSC: logoBWSignatureCol,
  EXRE: logoExec,
  GLO: logoGlo,
  PLUS: logoPlus,
  PMCL: logoPremierCl,
  PRMR: logoPremier,
  SSES: logoSureStayStudio,
  SSH: logoSureStayHotel,
  SSPL: logoSureStayPlus,
  SSSC: logoSureStayCollection,
  SUES: logoSureHotelStudio,
  SUH: logoSureHotel,
  SUPL: logoSureHotelPlus,
  SUSC: logoSureHotelCollection,
  VIB: logoVib,
  WHCC: logoWorldHotelsCrafted,
  WHDI: logoWorldHotelsDist,
  WHEL: logoWorldHotelsElite,
  WHLX: logoWorldHotelsLux,
};
