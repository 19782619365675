import BtnPopover from '../BtnPopover';
import { FaCogs } from 'react-icons/fa';
import { FcLock } from 'react-icons/fc';
import { Placement } from '@popperjs/core';
import ToggleSwitch from '../ToggleSwitch';
import { ToggleSwitchProps } from '../../types/DataGrid';

type SectionSwitchesProps = {
  lockDropDown: JSX.Element;
  togglesList: ToggleSwitchProps[];
};

export default function SectionSwitches(props: SectionSwitchesProps) {
  const { lockDropDown, togglesList } = props;

  const panelContent = () => (
    <div className='bg-blue-200 rounded-md border-4 border-white border-opacity-40 p-2 shadow-lg w-[400px]'>
      <ul className='grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-2'>
        <li className='col-span-1 flex shadow-md rounded-md'>
          <div className='flex-shrink-0 flex items-center justify-center w-10 text-4xl text-white font-medium rounded-l-md'>
            <FcLock />
          </div>
          <div className='flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate'>
            {lockDropDown}
          </div>
        </li>
        {togglesList.map((toggle, i) => {
          return <ToggleSwitch key={`overview-toggle-${i}`} {...toggle} />;
        })}
      </ul>
    </div>
  );

  const btnProps = {
    btnClass:
      'transform transition-all relative border-2 opacity-20 text-4xl -left-4 hover:scale-125 hover:translate-x-4 hover:opacity-100',
    btnIcon: FaCogs,
    btnIconClass: 'h-10 w-10',
    btnSpanClass: '',
    btnTooltip: 'Toggle Columns',
    offsetV: 90,
    panelContent,
    popPlacement: 'right' as Placement,
  };

  return (
    <div className='fixed left-0 top-96 z-30'>
      <BtnPopover {...btnProps} />
    </div>
  );
}
