import { DayFilters } from '../../types/DataTable';
import _ from 'lodash';

type Props = {
  onChange: (value: DayFilters) => void;
  value: DayFilters;
};

const DTFormDays = ({ onChange, value }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    onChange({ ...value, [name]: checked });
  };

  const switchAll = () => {
    onChange({
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
    });
  };

  const switchWeekdays = () => {
    onChange({
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: false,
      sat: false,
    });
  };

  const switchWeekends = () => {
    onChange({
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: true,
      sat: true,
    });
  };

  return (
    <fieldset className={`flex flex-row`}>
      <legend className='sr-only'>Day of Week Checkboxes</legend>
      <span className='relative z-0 inline-flex shadow-sm rounded-md'>
        <button
          type='button'
          onClick={switchWeekdays}
          className='inline-flex items-center text-xs px-0 py-0 border border-transparent shadow-sm leading-4 font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-800'
        >
          WD
        </button>
        <button
          type='button'
          onClick={switchWeekends}
          className='inline-flex items-center text-xs px-0 py-0 border border-transparent shadow-sm leading-4 font-medium rounded-sm text-white bg-yellow-600 hover:bg-yellow-800'
        >
          WE
        </button>
        <button
          type='button'
          onClick={switchAll}
          className='inline-flex items-center text-xs mr-3 px-0 py-0 border border-transparent shadow-sm leading-4 font-medium rounded-sm text-white bg-blue-600 hover:bg-blue-800'
        >
          ALL
        </button>
        {_.map(value as DayFilters, (val, key) => {
          const isChecked = val ? 'bg-blue-200' : 'bg-white';
          return (
            <div key={`days-${key}`}>
              <input
                id={key}
                name={key}
                type='checkbox'
                checked={val}
                onChange={handleChange}
                className='hidden'
              />
              <label
                htmlFor={key}
                className={`${isChecked} relative inline-flex items-center px-1 py-0 rounded-sm border border-gray-300 text-xs text-gray-700 `}
              >
                {key.substring(0, 1).toUpperCase()}
              </label>
            </div>
          );
        })}
      </span>
    </fieldset>
  );
};

export default DTFormDays;
