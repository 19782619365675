/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
export type DailyGroupFieldsFragment = { __typename?: 'DailyGroupData', brand_code?: string | null, date_year?: string | null, date_ym?: string | null, date_yq?: string | null, date_yw?: string | null, stay_date?: string | null };

export type GroupFieldsFragment = { __typename?: 'Group', brand_code?: string | null, description?: string | null, group_rooms_committed?: number | null, group_rooms_remaining?: number | null, group_rooms_sold?: number | null, is_past?: boolean | null, lowest_group_rate?: number | null, rate_code?: string | null, snapshot_date?: string | null, stay_date?: string | null };

export const DailyGroupFieldsFragmentDoc = gql`
    fragment DailyGroupFields on DailyGroupData {
  brand_code
  date_year
  date_ym
  date_yq
  date_yw
  stay_date
}
    `;
export const GroupFieldsFragmentDoc = gql`
    fragment GroupFields on Group {
  brand_code
  description
  group_rooms_committed
  group_rooms_remaining
  group_rooms_sold
  is_past
  lowest_group_rate
  rate_code
  snapshot_date
  stay_date
}
    `;
export const namedOperations = {
  Fragment: {
    DailyGroupFields: 'DailyGroupFields',
    GroupFields: 'GroupFields'
  }
}