import RateLevelTable from '../tables/rateLevels/RateLevelTable';
import { scrollDates } from '../../helpers/dateHelpers';
import { useDashboard } from '../..//context/dashboardContext';
import { useGetRateLevelStatusQuery } from '../../gql/_gen_/rate-levels.gql';

function RateLevels() {
  const tables = ['sold', 'remaining', 'occupancy'];

  const { brandCode, options, setOptions } = useDashboard();
  const { endDate, snapshotDate, startDate } = options;
  const brandCodeStr = brandCode || '';

  const {
    data: rateLevelData,
    loading,
    fetchMore,
  } = useGetRateLevelStatusQuery({
    skip: !brandCodeStr || !snapshotDate,
    variables: {
      args: {
        brandCode: brandCodeStr,
        snapshotDate,
        startDate,
        endDate,
      },
    },
  });

  const handleScrollDates = (direction: 'next' | 'prev') => {
    const newDates = scrollDates({ startDate, endDate, direction, days: 90 });

    setOptions(newDates);

    fetchMore({
      variables: {
        ...newDates,
      },
    });
  };

  return (
    <>
      {tables.map((table: string) => {
        return (
          <RateLevelTable
            rateLevelData={rateLevelData?.rateLevelStatus}
            table={table}
            key={table}
            loading={loading}
            scrollDates={handleScrollDates}
          />
        );
      })}
    </>
  );
}

export default RateLevels;
