import RoomsTable from '../tables/rooms/RoomsTable';
import { scrollDates } from '../../helpers/dateHelpers';
import { useDashboard } from '../..//context/dashboardContext';
import { useRooms } from '../../hooks/useRooms';

function Rooms() {
  const tables = ['sold', 'remaining', 'occupancy', 'allocation', 'flex'];

  const { brandCode, options, setOptions } = useDashboard();
  const { endDate, snapshotDate, startDate } = options;
  const brandCodeStr = brandCode || '';

  const {
    data: roomData,
    fetchMore,
    loading,
  } = useRooms({
    brandCode: brandCodeStr,
    startDate,
    endDate,
    snapshotDate,
    useCache: false,
    callback: () => null,
  });

  const handleScrollDates = (direction: 'next' | 'prev') => {
    const newDates = scrollDates({ startDate, endDate, direction, days: 90 });

    setOptions(newDates);

    fetchMore({
      variables: {
        ...newDates,
      },
    });
  };

  return (
    <>
      {tables.map((table: string) => {
        return (
          <RoomsTable
            table={table}
            roomData={roomData}
            key={table}
            scrollDates={handleScrollDates}
            loading={loading}
          />
        );
      })}
    </>
  );
}

export default Rooms;
