import {
  Bold,
  Italic,
  List,
  ListOrdered,
  Underline as UnderlineIcon,
} from 'lucide-react';
import { EditorContent, useEditor } from '@tiptap/react';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '../../../components/ui/button';
import { GiNotebook } from 'react-icons/gi';
import { Popover } from '@headlessui/react';
import StarterKit from '@tiptap/starter-kit';
import { Status } from '../../notes/types/status';
import Underline from '@tiptap/extension-underline';
import { useDebouncedCallback } from 'use-debounce';
import { useNotepad } from '../hooks/use-notepad-new';
import { usePopper } from 'react-popper';

interface NotepadButtonProps {
  brandCode?: string;
  userId?: string | null;
}

const NotepadButton = ({ brandCode, userId }: NotepadButtonProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const {
    notepad,
    setNotepad,
    loadingSave,
    saveNotes,
    savedValue,
    setEditorInstance,
  } = useNotepad({
    brandCode,
    userId,
  });

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: notepad,
    editable: !loadingSave,
    onUpdate: ({ editor }) => {
      setNotepad(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: 'outline-none w-full min-h-[200px] p-4 leading-extra-tight',
      },
    },
  });

  useEffect(() => {
    editor?.commands.setContent('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCode]);

  useEffect(() => {
    if (notepad) {
      if (editor?.isEmpty) {
        editor.commands.setContent(notepad);
      }
    }
  }, [editor, notepad]);

  useEffect(() => {
    if (editor) {
      setEditorInstance(editor);
    }
  }, [editor, setEditorInstance]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'left-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [20, 5],
        },
      },
    ],
  });

  const debouncedSave = useDebouncedCallback(async () => {
    if (editor && editor.getHTML() !== savedValue) {
      await saveNotes();
    }
  }, 2000);

  useEffect(() => {
    if (editor) {
      editor.on('update', () => {
        debouncedSave();
      });
    }
  }, [editor, debouncedSave]);

  const handleManualSave = useCallback(async () => {
    if (editor) {
      await saveNotes();
    }
  }, [editor, saveNotes]);

  const savedStatus = loadingSave
    ? Status.SAVING
    : notepad === savedValue
    ? Status.SAVED
    : notepad.length
    ? Status.UNSAVED
    : undefined;

  return (
    <Popover>
      {userId && (
        <Popover.Button
          type='button'
          ref={setReferenceElement}
          data-tip='Notepad'
          className='relative inline-flex items-center mx-2 bg-blue-900 text-white hover:text-gray-300 rounded-r-md focus:outline-none'
        >
          <GiNotebook />
        </Popover.Button>
      )}

      <Popover.Panel
        className='bg-white shadow-md max-w-[50%] min-w-[410px] rounded-lg p-2'
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: 9999 }}
        {...attributes.popper}
      >
        <h1 className='font-medium ml-2'>Notepad for {brandCode}</h1>
        <div className='p-2 text-left border rounded-md border-dashed border-gray-400'>
          <div className='mb-4 flex gap-2'>
            <Button
              variant='outline'
              size='icon'
              onClick={() => editor?.chain().focus().toggleBold().run()}
              className={editor?.isActive('bold') ? 'bg-muted' : ''}
            >
              <Bold className='h-4 w-4' />
            </Button>
            <Button
              variant='outline'
              size='icon'
              onClick={() => editor?.chain().focus().toggleItalic().run()}
              className={editor?.isActive('italic') ? 'bg-muted' : ''}
            >
              <Italic className='h-4 w-4' />
            </Button>
            <Button
              variant='outline'
              size='icon'
              onClick={() => editor?.chain().focus().toggleUnderline().run()}
              className={editor?.isActive('underline') ? 'bg-muted' : ''}
            >
              <UnderlineIcon className='h-4 w-4' />
            </Button>
            <Button
              variant='outline'
              size='icon'
              onClick={() => editor?.chain().focus().toggleBulletList().run()}
              className={editor?.isActive('bulletList') ? 'bg-muted' : ''}
            >
              <List className='h-4 w-4' />
            </Button>
            <Button
              variant='outline'
              size='icon'
              onClick={() => editor?.chain().focus().toggleOrderedList().run()}
              className={editor?.isActive('orderedList') ? 'bg-muted' : ''}
            >
              <ListOrdered className='h-4 w-4' />
            </Button>
          </div>
          <div className='w-full border rounded-sm mx-1'>
            <EditorContent
              editor={editor}
              className='prose prose-sm max-w-none w-full'
            />
          </div>
          <div className='mt-4 flex justify-between items-center'>
            <span className='text-sm text-muted-foreground'>
              {savedStatus === Status.SAVING
                ? 'Saving...'
                : savedStatus === Status.SAVED
                ? 'All changes saved'
                : savedStatus === Status.UNSAVED
                ? 'Unsaved changes'
                : ''}
            </span>
            <Button onClick={handleManualSave} disabled={loadingSave}>
              Save
            </Button>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export { NotepadButton };
