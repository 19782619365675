export const staticOptions = {
  deltas: [
    {
      dataKey: 'delta_1day',
      label: 'Δ 1 Day Delta Sold',
      source: 'rmData',
    },
    {
      dataKey: 'delta_3day',
      label: 'Δ 3 Day Delta Sold',
      source: 'rmData',
    },
    {
      dataKey: 'delta_7day',
      label: 'Δ 7 Day Delta Sold',
      source: 'rmData',
    },
    {
      dataKey: 'group_delta_intraday',
      label: 'Δ Live Group Delta Intraday',
      source: 'liveData',
    },
    {
      dataKey: 'transient_delta_intraday',
      label: 'Δ Live Transient Delta Intraday',
      source: 'liveData',
    },
  ],
  metrics: [
    {
      dataKey: 'recommended_rate',
      label: 'BestREV Recommended Rate',
      source: 'bestrevData',
    },
    {
      dataKey: 'capacity',
      label: 'Capacity Exludes OOO',
      source: 'rmData',
    },
    {
      dataKey: 'compadr_py',
      label: 'Competitor ADR Last Year (STR)',
      source: 'rmData',
    },
    {
      dataKey: 'compocc_py',
      label: 'Competitor OCC Last Year (STR)',
      source: 'rmData',
    },
    {
      dataKey: 'comprevpar_py',
      label: 'Competitor RevPAR Last Year (STR)',
      source: 'rmData',
    },
    {
      dataKey: 'rate',
      label: 'Current Rate',
      source: 'rmData',
    },
    {
      dataKey: 'group_blocked',
      label: 'Group Block Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'group_sold',
      label: 'Group Sold (P/U from block)',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_a_occ',
      label: 'Level A Occupancy %',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_a_remaining',
      label: 'Level A Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_a_sold',
      label: 'Level A Sold',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_b_occ',
      label: 'Level B Occupancy %',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_b_remaining',
      label: 'Level B Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_b_sold',
      label: 'Level B Sold',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_c_occ',
      label: 'Level C Occupancy %',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_c_remaining',
      label: 'Level C Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_c_sold',
      label: 'Level C Sold',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_d_occ',
      label: 'Level D Occupancy %',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_d_remaining',
      label: 'Level D Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_d_sold',
      label: 'Level D Sold',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_e_occ',
      label: 'Level E Occupancy %',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_e_remaining',
      label: 'Level E Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_e_sold',
      label: 'Level E Sold',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_f_occ',
      label: 'Level F Occupancy %',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_f_remaining',
      label: 'Level F Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_f_sold',
      label: 'Level F Sold',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_g_occ',
      label: 'Level G Occupancy %',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_g_remaining',
      label: 'Level G Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_g_sold',
      label: 'Level G Sold',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_h_occ',
      label: 'Level H Occupancy %',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_h_remaining',
      label: 'Level H Remaining',
      source: 'rmData',
    },
    {
      dataKey: 'lvl_h_sold',
      label: 'Level H Sold',
      source: 'rmData',
    },
    {
      dataKey: 'market_rate',
      label: 'MRP',
      source: 'bestrevData',
    },
    {
      dataKey: 'ooo',
      label: 'Out of Order',
      source: 'rmData',
    },
    {
      dataKey: 'rooms_committed',
      label: 'Rooms Committed (Total Sold + Group Block Remaining)',
      source: 'rmData',
    },
    {
      dataKey: 'total_capacity',
      label: 'Total Capacity Includes OOO',
      source: 'rmData',
    },
    {
      dataKey: 'total_potential_group',
      label: 'Total Group Rooms Blocked (P/U and Block Left)',
      source: 'rmData',
    },
    {
      dataKey: 'sold',
      label: 'Sold',
      source: 'rmData',
    },
    {
      dataKey: 'total_sold',
      label: 'Total Sold (Transient Sold + Group Sold)',
      source: 'rmData',
    },
    {
      dataKey: 'transient_sold',
      label: 'Transient Sold',
      source: 'rmData',
    },
  ],
};
