import { Forecast } from '../graphql/types';

export const calculateForecast = (forecast: Forecast) => {
  const { forecast_sold_1, forecast_sold_2, forecast_adr_1, forecast_adr_2 } =
    forecast;

  // We need to add temp values to the object to satisfy the type checker when we
  // update the Apollo Client cache.
  const newObj = {
    id: 'tempfcstid',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    date_ym: 'tempdateym',
    date_yq: 'tempdateyq',
    date_yw: 'tempdateyw',
    date_year: 'tempdateyear',
    fcstType: 'tempfcsttype',
    ...forecast,
  };

  if (forecast_sold_1 && forecast_adr_1) {
    const revenue_1 = Number(forecast_sold_1) * Number(forecast_adr_1);
    if (!isNaN(revenue_1)) {
      newObj.forecast_revenue_1 = revenue_1;
    } else {
      newObj.forecast_revenue_1 = 0;
    }
  } else {
    newObj.forecast_revenue_1 = 0;
  }

  if (forecast_sold_2 && forecast_adr_2) {
    const revenue_2 = Number(forecast_sold_2) * Number(forecast_adr_2);
    if (!isNaN(revenue_2)) {
      newObj.forecast_revenue_2 = revenue_2;
    } else {
      newObj.forecast_revenue_2 = 0;
    }
  } else {
    newObj.forecast_revenue_2 = 0;
  }

  return newObj;
};
