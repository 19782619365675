import {
  useCreateFeatureFlagMutation,
  useListFeatureFlagsQuery,
  useToggleFlagUserMutation,
  useUpdateFeatureFlagMutation,
} from '../../../gql/_gen_/feature-flags.gql';

type CreateFeatureFlagInput = {
  key: string;
  description: string;
  defaultValue: boolean;
  active: boolean;
};

type UpdateFeatureFlagInput = {
  id: string;
  description?: string;
  defaultValue?: boolean;
  key?: string;
  active?: boolean;
};

export const useManageFlags = () => {
  const { data, loading, error, refetch } = useListFeatureFlagsQuery();
  const [createFeatureFlag, { loading: creating }] =
    useCreateFeatureFlagMutation();
  const [updateFeatureFlag, { loading: updating }] =
    useUpdateFeatureFlagMutation();
  const [toggleUserFlag, { loading: updatingUser }] =
    useToggleFlagUserMutation();

  const handleToggleUserFlag = async (
    flagKey?: string | null,
    userId?: string | null,
    active = false
  ) => {
    if (!flagKey || !userId) return;

    try {
      const result = await toggleUserFlag({
        variables: { flagKey, userId, active },
      });
      if (result.data?.toggleFlagUser) {
        await refetch();
        return {
          success: true,
          title: 'User flag updated',
          description: `The feature flag ${flagKey} has been ${
            active ? 'enabled' : 'disabled'
          } for user ${userId}.`,
          data: result.data.toggleFlagUser,
        };
      }
      throw new Error('Failed to update user flag');
    } catch (error) {
      console.error(error);
      return {
        success: false,
        title: 'Error',
        description: `Failed to update the user flag. ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
      };
    }
  };

  const handleCreateFlag = async (input: CreateFeatureFlagInput) => {
    try {
      const { defaultValue, ...rest } = input;
      const result = await createFeatureFlag({
        variables: { input: { ...rest, default_value: defaultValue } },
      });
      if (result.data?.createFeatureFlag) {
        await refetch();
        return {
          success: true,
          title: 'Feature flag created',
          description: 'The new feature flag has been successfully created.',
          data: result.data.createFeatureFlag,
        };
      }
      throw new Error('Failed to create feature flag');
    } catch (error) {
      console.error(error);
      return {
        success: false,
        title: 'Error',
        description: `Failed to create the feature flag. ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
      };
    }
  };

  const handleToggleFlag = async (input: UpdateFeatureFlagInput) => {
    try {
      const { id, defaultValue, active } = input;

      const result = await updateFeatureFlag({
        variables: {
          input: {
            id,
            ...(input.hasOwnProperty('active') && { active }),
            ...(input.hasOwnProperty('defaultValue') && {
              default_value: defaultValue,
            }),
          },
        },
      });
      if (result.data?.updateFeatureFlag) {
        await refetch();
        return {
          success: true,
          title: 'Feature flag updated',
          description: `The feature flag ${
            result.data.updateFeatureFlag.key
          } has been set to ${
            result.data.updateFeatureFlag.active ? 'Active' : 'Inactive'
          }.`,
          data: result.data.updateFeatureFlag,
        };
      }
      throw new Error('Failed to update feature flag');
    } catch (error) {
      console.error(error);
      return {
        success: false,
        title: 'Error',
        description: `Failed to update the feature flag. ${
          error instanceof Error ? error.message : 'Unknown error'
        }`,
      };
    }
  };

  return {
    createFlag: handleCreateFlag,
    creating,
    error,
    flags: data?.listFeatureFlags || [],
    loading,
    toggleFlag: handleToggleFlag,
    toggleUserFlag: handleToggleUserFlag,
    updating,
    updatingUser,
  };
};
