import * as AgendaItems from '../gql/_gen_/agenda.gql';
import * as Meetings from '../gql/_gen_/meeting.gql';

import { AgendaItem } from '../../../graphql/types';
import { AgendaListItem } from './agenda-list-item';
import { ExpandedAgendaItem } from './expanded-agenda-item';
import { MeetingPanelsProps } from '../../reports/components/meeting-panels';
import { clsx } from 'clsx';
import { toast } from 'react-toastify';
import { useState } from 'react';

interface IAgendaView {
  isTool?: boolean;
  meeting?: MeetingPanelsProps['meeting'];
  meetingTools?: MeetingPanelsProps['meetingTools'];
}

const AgendaView = ({ isTool, meeting, meetingTools }: IAgendaView) => {
  const [expanded, setExpanded] = useState<AgendaItem | null>();

  const [removeAgendaItem] = AgendaItems.useRemoveAgendaItemMutation({
    refetchQueries: [Meetings.namedOperations.Query.MeetingTools],
  });

  const handleDelete = async (itemId: string) => {
    await removeAgendaItem({
      variables: {
        removeAgendaItemId: itemId,
      },
    });
    toast.success('Agenda item deleted', { position: 'top-left' });
  };

  const getItemNotes = (itemId: string | undefined | null) => {
    if (meeting?.agendaItemNotes && itemId) {
      return meeting.agendaItemNotes.find((note) => note?.itemId === itemId)
        ?.notes;
    }
  };

  return (
    <section className={clsx('px-3', isTool === true ? 'w-[500px]' : '')}>
      <div className='overflow-auto mt-3'>
        {expanded ? (
          <ExpandedAgendaItem
            expanded={expanded}
            handleDelete={handleDelete}
            setExpanded={setExpanded}
            meetingId={meeting?.id}
            itemNotes={getItemNotes(expanded?.id) as string}
          />
        ) : (
          <div className='flex flex-col'>
            <ul
              className={clsx(
                isTool
                  ? 'grid grid-cols-2'
                  : 'grid grid-cols-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-4 xl:grid-cols-6',
                'mt-3 gap-1'
              )}
            >
              {meetingTools?.agendaItems &&
                meetingTools?.agendaItems.map((item) => (
                  <AgendaListItem
                    data={item}
                    handleDelete={handleDelete}
                    key={item?.id}
                    meeting={meeting}
                    setExpanded={setExpanded}
                    itemNotes={getItemNotes(item?.id) as string}
                  />
                ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export { AgendaView };
