/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventWidgetQueryVariables = Types.Exact<{
  hotelId?: Types.InputMaybe<Types.Scalars['Int']>;
  startDate?: Types.InputMaybe<Types.Scalars['String']>;
  endDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EventWidgetQuery = { __typename?: 'Query', eventWidget?: Array<{ __typename?: 'Event', name?: string | null, start_date?: any | null, end_date?: any | null, details?: string | null, id?: string | null } | null> | null };


export const EventWidgetDocument = gql`
    query EventWidget($hotelId: Int, $startDate: String, $endDate: String) {
  eventWidget(hotelId: $hotelId, startDate: $startDate, endDate: $endDate) {
    name
    start_date
    end_date
    details
    id
  }
}
    `;

/**
 * __useEventWidgetQuery__
 *
 * To run a query within a React component, call `useEventWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventWidgetQuery({
 *   variables: {
 *      hotelId: // value for 'hotelId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEventWidgetQuery(baseOptions?: Apollo.QueryHookOptions<EventWidgetQuery, EventWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventWidgetQuery, EventWidgetQueryVariables>(EventWidgetDocument, options);
      }
export function useEventWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventWidgetQuery, EventWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventWidgetQuery, EventWidgetQueryVariables>(EventWidgetDocument, options);
        }
export type EventWidgetQueryHookResult = ReturnType<typeof useEventWidgetQuery>;
export type EventWidgetLazyQueryHookResult = ReturnType<typeof useEventWidgetLazyQuery>;
export type EventWidgetQueryResult = Apollo.QueryResult<EventWidgetQuery, EventWidgetQueryVariables>;
export const namedOperations = {
  Query: {
    EventWidget: 'EventWidget'
  }
}