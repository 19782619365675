import { gql } from '@apollo/client';

export const CREATE_EVENT = gql`
  mutation CreateEvent($event: CreateEventInput!) {
    createEvent(event: $event) {
      id
      name
      start_date
      end_date
      details
      hotel_id
      created_by_id
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($deleteEventId: String!) {
    deleteEvent(id: $deleteEventId)
  }
`;

export const DELETE_EVENTS = gql`
  mutation DeleteEvents($deleteEventId: [String!]!) {
    deleteEvents(id: $deleteEventId)
  }
`;

export const EVENT_TABLE = gql`
  query HotelEvents($filters: FindEventArgs!) {
    hotelEvents(filters: $filters) {
      name
      start_date
      end_date
      details
      id
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($updateEventId: String!, $event: UpdateEventInput!) {
    updateEvent(id: $updateEventId, event: $event) {
      id
      name
      start_date
      end_date
      created_by_id
      hotel_id
      details
    }
  }
`;
