import { useCallback, useEffect, useState } from 'react';
import {
  useGetUserOptionQuery,
  useUpdateUserOptionMutation,
} from '@/features/user-options/gql/_gen_/user-options.gql';

import { Editor } from '@tiptap/react';
import { parseContent } from '@/lib/tiptap';
import { useToast } from '@/components/ui/use-toast';

interface UseNotepadProps {
  brandCode?: string;
  userId?: string | null;
}

const useNotepad = ({ brandCode, userId }: UseNotepadProps) => {
  const { toast } = useToast();
  const [notepad, setNotepad] = useState<object | null>(null);
  const [savedValue, setSavedValue] = useState<object | null>(null);
  const [editor, setEditor] = useState<Editor | null>(null);

  const skip =
    !brandCode ||
    brandCode === undefined ||
    brandCode === null ||
    brandCode === 'undefined' ||
    !userId ||
    userId === undefined ||
    userId === null ||
    userId === 'undefined';

  const { data, loading } = useGetUserOptionQuery({
    skip,
    variables: {
      brandCode: String(brandCode),
      userId: String(userId),
    },
  });

  const [updateUserOption, { loading: loadingSave }] =
    useUpdateUserOptionMutation({
      onError: (error) => {
        toast({
          title: 'Notepad Update Error',
          description: error.message,
          variant: 'destructive',
        });
      },
      onCompleted: (data) => {
        if (data?.updateUserOption?.appNotes) {
          setNotepad(parseContent(data?.updateUserOption?.appNotes));
          setSavedValue(parseContent(data?.updateUserOption?.appNotes));
        }
        toast({
          title: 'Updated',
          description: `Your notepad for ${brandCode} has been updated.`,
        });
      },
    });

  useEffect(() => {
    if (data) {
      if (data.getUserOption?.appNotes) {
        setNotepad(parseContent(data.getUserOption?.appNotes));
        setSavedValue(parseContent(data.getUserOption?.appNotes));
      } else {
        setNotepad(null);
        setSavedValue(null);
      }
    }
  }, [data, loading]);

  const saveNotes = useCallback(async () => {
    if (brandCode && userId && editor) {
      const content = editor.getJSON();
      await updateUserOption({
        variables: {
          brandCode,
          userId,
          appNotes: JSON.stringify(content),
        },
        onError: (error) => {
          toast({
            title: 'Notepad Save Error',
            description: error.message,
            variant: 'destructive',
          });
        },
        onCompleted: () => {
          toast({
            title: 'Saved',
            description: `Your notepad for ${brandCode} has been saved.`,
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCode, userId, editor, updateUserOption]);

  const setEditorInstance = useCallback((newEditor: Editor | null) => {
    setEditor(newEditor);
  }, []);

  return {
    notepad,
    loadingSave,
    setNotepad,
    saveNotes,
    savedValue,
    setEditorInstance,
  };
};

export { useNotepad };
