import 'react-toastify/dist/ReactToastify.css';

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import AppRoutes from './AppRoutes';
import { AppTrackProvider } from './context/appTrackContext';
import { DashboardProvider } from './context/dashboardContext';
import { FlagProvider } from './context/feature-flags';
import { HotelProvider } from './context/hotelContext';
import { LogTrackingDocument } from './features/tracking/gql/_gen_/tracking.gql';
import { MeetingProvider } from './features/meetings/context/meeting-context';
import Navbar from './features/navbar/navbar';
import { RetryLink } from '@apollo/client/link/retry';
import { Security } from '@okta/okta-react';
import { ToastContainer } from 'react-toastify';
import { Toaster } from './components/ui/toaster';
import { TrackingInput } from './graphql/types';
import { UserProvider } from './context/userContext';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { useHistory } from 'react-router-dom';
import { useTracking } from 'react-tracking';

declare global {
  interface Window {
    dataLayer: TrackingInput[];
  }
}

const coreTrack = {
  app: 'rmws_sst_client',
} as TrackingInput;

function App() {
  const { Track } = useTracking(coreTrack, {
    dispatch: (data) => logTrackingData(data),
  });

  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['email', 'openid', 'profile', 'offline_access', 'rmw'],
  });

  const history = useHistory();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
  });

  const retryLink = new RetryLink();

  const authLink = setContext((_, { headers }) => {
    const token = oktaAuth.getAccessToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: from([retryLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        FeatureFlag: {
          keyFields: ['id', 'user_id'],
        },
        Forecast: {
          keyFields: ['hotel_id', 'stay_date', 'fcstType'],
        },
        Query: {
          fields: {
            hotelEvents: offsetLimitPagination(),
          },
        },
      },
    }),
    name: 'RM Workspace Client',
    version: `${process.env.REACT_APP_VERSION}`,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  const logTrackingData = (data: TrackingInput) => {
    if (data) {
      // Required columns
      const { ipAddr, userEmail, app, brandCode } = data;
      if (userEmail === 'unknown' && ipAddr === null) {
        return;
      }
      if (userEmail && app && brandCode) {
        client.mutate({
          mutation: LogTrackingDocument,
          variables: {
            tracking: data,
          },
        });
      }
    }
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ApolloProvider client={client}>
        <Track>
          <UserProvider>
            <FlagProvider>
              <HotelProvider>
                <DashboardProvider>
                  <AppTrackProvider>
                    <MeetingProvider>
                      <div>
                        <Navbar />
                        <AppRoutes />
                        <ToastContainer />
                        {/* New shadcnui toaster */}
                        <Toaster />
                      </div>
                    </MeetingProvider>
                  </AppTrackProvider>
                </DashboardProvider>
              </HotelProvider>
            </FlagProvider>
          </UserProvider>
        </Track>
      </ApolloProvider>
    </Security>
  );
}

export default App;
