import { AgendaItem, Meeting } from '../../../graphql/types';
import {
  useLogAgendaMutation,
  useUnlogAgendaMutation,
} from '../gql/_gen_/meeting.gql';

import { useEffect } from 'react';
import { useMeeting } from '../context/meeting-context';
import { useSaveMeeting } from './use-save-meeting';

export const useToggleAgenda = (meeting?: Meeting) => {
  const { handleSaveMeeting } = useSaveMeeting(meeting);
  const { refetchMeetingByDate } = useMeeting();
  const [logAgenda, { data: logData, loading: logLoading }] =
    useLogAgendaMutation();
  const [unlogAgenda, { data: unlogData, loading: unlogLoading }] =
    useUnlogAgendaMutation();

  const loading = logLoading || unlogLoading;

  useEffect(() => {
    if (logData || unlogData) {
      refetchMeetingByDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logData, unlogData]);

  const toggleAgendaItem = async (item: AgendaItem) => {
    if (meeting && meeting.id && meeting.agendaItems && item.id) {
      if (meeting.agendaItems.includes(item.id)) {
        await unlogAgenda({
          variables: {
            meetingId: meeting.id,
            agendaId: item.id,
          },
        });
      } else {
        await logAgenda({
          variables: {
            meetingId: meeting.id,
            agendaId: item.id,
          },
        });
      }
    } else if (!meeting?.id && item.id) {
      await handleSaveMeeting({
        agendaItems: [item.id],
      });
    }
  };

  return { loading, toggleAgendaItem };
};
