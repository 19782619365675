import Chart from '../components/Chart';
import { RmData } from '../graphql/types';
import { displayDate } from '../helpers/dateHelpers';
import { tonightDonut } from './donut';
import { useMemo } from 'react';

export type Props = {
  data?: RmData;
  loading: boolean;
  name: string;
};

const DonutWidget = ({ data, loading, name }: Props) => {
  const donutOptions = useMemo(() => {
    if (!data) {
      return;
    }

    const donutDate = displayDate(data.stay_date);
    const chartOptions = tonightDonut(data);

    return { donutDate, chartOptions };
  }, [data]);

  if (!donutOptions || loading) {
    return <div>Loading {name} Widget...</div>;
  } else {
    return (
      <div className='py-1 flex'>
        <Chart chartOptions={donutOptions.chartOptions} />
      </div>
    );
  }
};

export default DonutWidget;
