import { useEffect, useState } from 'react';

import { allLogos } from '../../../img/logos';
import { messages } from '../../../dashboards/tables/overview/loadingMessages';

export const ReportLoading = () => {
  const [currentLogo, setLogo] = useState(
    allLogos[Math.floor(Math.random() * allLogos.length)]
  );
  const [message, setMessage] = useState(
    messages[Math.floor(Math.random() * messages.length)]
  );

  useEffect(() => {
    let interval = setInterval(() => {
      setMessage(messages[Math.floor(Math.random() * messages.length)]);
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let logoInterval = setInterval(() => {
      setLogo(allLogos[Math.floor(Math.random() * allLogos.length)]);
    }, 4000);
    return () => clearInterval(logoInterval);
  }, []);

  return (
    <div className='bg-white'>
      <div className='mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <div className='flex justify-center mb-2'>
          <p className='col-span-1  w-96 animate-pulse text-center justify-center text-lg font-normal text-blue-600'>
            We are downloading the data for your report. While you wait, here
            are some fun facts about Best Western.
          </p>
        </div>

        <p className='animate-pulse text-center text-base font-semibold uppercase text-gray-600 tracking-wider'>
          {message}
        </p>
        <div className='flex justify-center'>
          <img
            className='col-span-1 rounded-lg h-32 mt-4'
            src={currentLogo}
            alt='Best Western'
          />
        </div>
      </div>
    </div>
  );
};
