import { MdCloudUpload } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useHotel } from '../../../context/hotelContext';
import { useMeeting } from '../../meetings/context/meeting-context';
import { useReportData } from '../context/report-data-context';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';
import { useUploadData } from '../../meetings/hooks/use-upload-data';
import { useUser } from '../../../context/userContext';

type BtnProps = {
  btnClass: string;
  btnIconClass?: string;
  loading: Array<string>;
};

const BtnReuploadMeeting = ({
  btnClass,
  btnIconClass = 'h-5 w-5',
  loading,
}: BtnProps) => {
  const { meeting, meetingTools, saveMeeting, loadingUpdateMeeting } =
    useMeeting();
  const { data, loading: dataLoading } = useReportData();
  const { user } = useUser();
  const { hotel } = useHotel();
  const { storeMeetingData } = useUploadData({
    data,
    dataLoading,
    hotel,
    meeting,
    meetingTools,
    user,
  });

  const track = useTrackMeetingEvent({ meeting, page: 'btn_reupload_meeting' });

  const readyToShare = (showToast = true) => {
    if (!meeting) {
      showToast && toastError('Meeting not found');
      return false;
    }
    if (!meetingTools) {
      showToast && toastError('Meeting tools not found');
      return false;
    }
    if (!user) {
      showToast && toastError('User not found');
      return false;
    }
    const { brandCode, meetingDate, bucket, bucketKey } = meeting;
    const { email: from } = user;

    if (!brandCode) {
      showToast && toastError('Brand code not found');
      return false;
    }
    if (!meetingDate) {
      showToast && toastError('Meeting date not found');
      return false;
    }
    if (!bucket || !bucketKey) {
      showToast && toastError('S3 bucket info not found');
      return false;
    }
    if (!from) {
      showToast && toastError('From email not found');
      return false;
    }
    return true;
  };

  const handleUploadMeeting = async () => {
    if (!readyToShare()) return;

    if (!meeting || !meetingTools || !user || !user.email) return;

    storeMeetingData && (await storeMeetingData());

    await handleSave();

    track('reupload_meeting', `meeting_${meeting.id}`);
  };

  const handleSave = async () => {
    await saveMeeting({ data, meetingTools });
  };

  const toastError = (msg: string) => {
    toast.error(msg, { position: 'top-left' });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [loading]);

  const UploadMeetingBtn = () => {
    return (
      <>
        <span
          onClick={() => (loading.length ? null : handleUploadMeeting())}
          className={`${btnClass} ${'text-blue-500 ml-2'} ${
            loading.length
              ? 'opacity-50 text-orange-500 cursor-progress animate-spin'
              : 'cursor-pointer'
          }`}
          data-tip={'Reupload Meeting Data After Making Change'}
        >
          <MdCloudUpload className={btnIconClass} aria-hidden='true' />
        </span>
      </>
    );
  };

  if (readyToShare(false)) {
    if (loadingUpdateMeeting) {
      return null;
    }
    return (
      <span>
        <UploadMeetingBtn />
      </span>
    );
  } else {
    return null;
  }
};

export default BtnReuploadMeeting;
