import { AiFillDelete } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { useMeeting } from '../../meetings/context/meeting-context';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';

type BtnSaveMeetingProps = {
  btnClass: string;
  btnIconClass?: string;
};

const BtnDeleteMeeting = ({
  btnClass,
  btnIconClass = 'h-5 w-5',
}: BtnSaveMeetingProps) => {
  const { meeting, deleteMeeting, deleteLoading } = useMeeting();
  const track = useTrackMeetingEvent({ meeting, page: 'btn_delete_meeting' });

  const handleClick = () => {
    if (meeting && meeting.id) {
      ReactTooltip.hide();
      deleteMeeting({ variables: { meetingId: meeting.id } });
      track('delete_meeting', 'delete_meeting');
    }
  };

  const BtnIcon = deleteLoading ? FaSpinner : AiFillDelete;

  return (
    <span
      onClick={() => (deleteLoading ? null : handleClick())}
      className={`${btnClass} text-red-500 cursor-pointer ${
        deleteLoading ? 'opacity-50 text-red-800 animate-spin' : ''
      }`}
      data-tip={deleteLoading ? 'deleting...' : 'Delete Meeting'}
    >
      <BtnIcon className={btnIconClass} aria-hidden='true' />
    </span>
  );
};

export default BtnDeleteMeeting;
