import { CellObject } from '@/features/overview-table/hooks/use-table';
import { OtaiRate } from '@/graphql/types';
import { RateShopToolTip } from '@/features/rate_shops/components/rate-shop-tooltip';
import ReactDOMServer from 'react-dom/server';
import { renderMessage } from '@/renderers/RenderRateShop';
import { useFlags } from '@/context/feature-flags';

export function RateCellRenderer(cell: CellObject) {
  const { flags } = useFlags();
  const flagShowSuperscript = flags?.['rate-change-superscript'];

  const extractedClass = cell.cls.match(/\btext-[a-zA-Z0-9-]+\b/)?.[0];
  const rateDetails: OtaiRate | undefined = cell.meta?.data;

  const cellClass = `text-left ${extractedClass}`;

  // Extract common JSX into a variable
  const commonJSX = flagShowSuperscript ? (
    <div
      className={cellClass}
      data-html={true}
      data-type='light'
      data-tip={ReactDOMServer.renderToString(<RateShopToolTip {...cell} />)}
    >
      {cell.dataKey === 'rates.0' ? cell.value : cell.displayValue}
    </div>
  ) : (
    <div className={cellClass}>
      {cell.dataKey === 'rates.0' ? cell.value : cell.displayValue}
    </div>
  );

  // Return common JSX if cell is of type 'rates.0' or if there are no rate details
  if (
    cell.dataKey === 'rates.0' ||
    !rateDetails ||
    rateDetails?.changes?.length === 0
  ) {
    return commonJSX;
  }

  const displayValue =
    cell.displayValue === ''
      ? renderMessage(cell.meta?.data?.message)
      : cell.displayValue;

  const change =
    rateDetails && rateDetails.changes ? rateDetails.changes[0] : undefined;

  const previousRate = change?.change?.value ?? 0;

  const priceChange =
    previousRate === 0
      ? renderMessage(cell.meta?.data?.message) ?? 'NA'
      : Math.round((rateDetails?.value ?? 0) - previousRate);

  // Return common JSX if priceChange is 0
  if (priceChange === 0) {
    return commonJSX;
  }

  const spanClass =
    typeof priceChange === 'string'
      ? 'text-orange-500'
      : priceChange > 0
      ? 'text-green-500'
      : 'text-red-500';
  const changeIcon =
    typeof priceChange === 'string' ? '' : priceChange > 0 ? '↑' : '↓';

  // Render JSX with price change and appropriate color class
  if (flagShowSuperscript) {
    return (
      <div
        className={cellClass}
        data-html={true}
        data-type='light'
        data-tip={ReactDOMServer.renderToString(<RateShopToolTip {...cell} />)}
      >
        {cell.dataKey === 'rates.0' ? cell.value : displayValue}{' '}
        {priceChange === displayValue ? null : (
          <span className={spanClass}>
            {changeIcon}
            <sup>
              {typeof priceChange === 'number'
                ? Math.abs(priceChange)
                : priceChange}
            </sup>
          </span>
        )}
      </div>
    );
  } else {
    return (
      <div className={cellClass}>
        {cell.dataKey === 'rates.0' ? cell.value : displayValue}{' '}
        {priceChange === displayValue ? null : (
          <span className={spanClass}>
            {changeIcon}
            <sup>
              {typeof priceChange === 'number'
                ? Math.abs(priceChange)
                : priceChange}
            </sup>
          </span>
        )}
      </div>
    );
  }
}
