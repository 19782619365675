import { useGetHotelRateChangeLogsQuery } from '../gql/_gen_/rate-change-log.gql';
import { useHotel } from '../../../context/hotelContext';

export const useRateChangeLogs = () => {
  const FETCH_LIMIT = 300;

  const { hotel } = useHotel();
  const { data, loading } = useGetHotelRateChangeLogsQuery({
    skip: !hotel?.brand_code,
    variables: {
      brandCode: hotel?.brand_code,
      limit: FETCH_LIMIT,
    },
  });

  return {
    data,
    loading,
    brandCode: hotel?.brand_code,
  };
};
