import { getValue, saveValue } from '../helpers/localStorageHelper';

import type { OtaiSubs } from '../context/hotelContext';
import { RateQueryParams } from '../graphql/types';
import { useReducer } from 'react';

export type Action = {
  type: string;
  payload: {
    bar?: boolean;
    changeDays?: RateQueryParams['changeDays'];
    los?: number;
    ota?: string;
    roomType?: string;
    shopLength?: number;
    subscriptionId?: RateQueryParams['subscriptionId'];
  };
};

export const defaultSubId = (otaiSubs?: OtaiSubs) => {
  if (otaiSubs && otaiSubs.length > 0) {
    const sub = otaiSubs.find((s) =>
      s?.features?.find((f) => f?.id === 'rates')
    );
    return sub?.sub_id?.toString();
  }
};

const defaultParams = (otaiSubs?: OtaiSubs): RateQueryParams => {
  const subscriptionId = defaultSubId(otaiSubs)!;
  return {
    subscriptionId,
    los: 1,
    bar: true,
    ota: 'branddotcom',
    roomType: 'all',
    shopLength: 365,
    changeDays: [1],
  };
};

const retrieve = (subId: string | undefined): RateQueryParams => {
  return JSON.parse(getValue(storageKey(subId)) as string);
};

const storageKey = (subId: string | undefined) => {
  return `ShopParams_${subId}`;
};

const storeAndReturn = (rateShopParams: RateQueryParams) => {
  const { subscriptionId: subId } = rateShopParams;
  saveValue(storageKey(subId), JSON.stringify(rateShopParams));
  return rateShopParams;
};

const shopParamsReducer = (
  state: RateQueryParams,
  action: Action,
  otaiSubs?: OtaiSubs
) => {
  const storedState = retrieve(defaultSubId(otaiSubs)!);

  switch (action.type) {
    case 'UPDATE_PARAMS':
      if (storedState) {
        return storeAndReturn({ ...storedState, ...action.payload });
      }
      if (state.subscriptionId) {
        if (action.payload.subscriptionId) {
          // This means we are probably switching hotels
          // because there is a sub id in the payload
          if (state.subscriptionId !== action.payload.subscriptionId) {
            // We are switching hotels, so we need to clear the state
            // and return the default params
            return storeAndReturn({ ...defaultParams(otaiSubs) });
          }
          // We are not switching hotels, so we can just return the
          // state with the new params
          return storeAndReturn({ ...state, ...action.payload });
        }
        return storeAndReturn({ ...state, ...action.payload });
      }
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export function useRateShopParams(otaiSubs?: OtaiSubs) {
  return useReducer(
    (state: RateQueryParams, action: Action) =>
      shopParamsReducer(state, action, otaiSubs),
    { ...defaultParams(otaiSubs) }
  );
}
