import { Meeting, MeetingService } from '../../../graphql/types';

import { AgendaView } from '../../meetings/components/agenda-view';
import { Editor } from '../../notes/editor';
import MeetingNotes from '../../../components/meeting-notes';
import { SectionContainer } from './section-container';
import { TeamView } from '../../meetings/components/team-view';

export type MeetingPanelsProps = {
  brandCode?: string;
  meeting?: Meeting;
  meetingTools?: MeetingService;
  setMeeting?: (meeting: Meeting) => void;
  showNewNotes?: boolean;
  userId?: string;
};

const MeetingPanels = ({
  brandCode,
  meeting,
  meetingTools,
  setMeeting,
  showNewNotes = false,
  userId,
}: MeetingPanelsProps) => {
  return (
    <div className='grid grid-cols-1 gap-4'>
      <SectionContainer anchorId='team-members' title='Team Members'>
        <TeamView meeting={meeting} meetingTools={meetingTools} />
      </SectionContainer>
      <SectionContainer anchorId='agenda-items' title='Agenda'>
        <AgendaView meeting={meeting} meetingTools={meetingTools} />
      </SectionContainer>
      <SectionContainer anchorId='meeting-notes' title='Meeting Notes'>
        {showNewNotes ? (
          <MeetingNotes
            brandCode={brandCode}
            meeting={meeting}
            parentName='meeting-panels'
            setMeeting={setMeeting}
            userId={userId}
          />
        ) : (
          <Editor
            brandCode={brandCode}
            meeting={meeting}
            parentName='meeting-panels'
            setMeeting={setMeeting}
            userId={userId}
          />
        )}
      </SectionContainer>
    </div>
  );
};

export { MeetingPanels };
