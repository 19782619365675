import {
  ActionTypes,
  State as Options,
  Action as OptionsAction,
} from '../../reducers/useDataTableOptions';

import DTSortIcon from './DTSortIcon';

type Props = {
  fieldName: string;
  options: Options;
  setOptions: React.Dispatch<OptionsAction>;
};

const DTSortButton = ({ fieldName, options, setOptions }: Props) => {
  return (
    <button
      className='flex items-center'
      onClick={() =>
        setOptions({
          type: ActionTypes.SORT,
          payload: { sort: { key: fieldName } },
        })
      }
    >
      <span className='ml-2'>
        <DTSortIcon
          name={fieldName}
          sortDir={options.sort.dir}
          sortKey={options.sort.key}
        />
      </span>
    </button>
  );
};

export default DTSortButton;
