import {
  Bold,
  Italic,
  List,
  ListOrdered,
  Underline as UnderlineIcon,
} from 'lucide-react';
import { Card, CardContent } from './ui/card';
import { EditorContent, useEditor } from '@tiptap/react';
import {
  UseMeetingNotesProps,
  useMeetingNotes,
} from '../features/notes/hooks/use-notes-new';
import {
  extensions,
  handlePaste,
  loadFromLocalStorage,
  parseContent,
  saveToLocalStorage,
} from '@/lib/tiptap';
import { memo, useEffect, useState } from 'react';

import { BtnInsertNotepad } from '../features/notes/components/btn-insert-notepad';
import { BtnInsertNotes } from '../features/notes/components/btn-insert-notes';
import { Button } from './ui/button';
import { Meeting } from '../graphql/types';
import clsx from 'clsx';
import { toast } from './ui/use-toast';
import { useDebouncedCallback } from 'use-debounce';
import { useIsShared } from '../features/reports/hooks/use-report-location';

const AUTOSAVE_DELAY = 10000;

interface MeetingNotesProps extends UseMeetingNotesProps {
  parentName: string;
  setMeeting?: (meeting: Meeting) => void;
}

const MeetingNotes = memo(function MeetingNotes(props: MeetingNotesProps) {
  const [isMounted, setIsMounted] = useState(true);
  const { isShared } = useIsShared();
  const { parentName, ...rest } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { handleSave, notes, notepad, prevNotes } = useMeetingNotes({
    ...rest,
  });

  const flexWidth = parentName === 'meeting-tools' ? 'max-w-[400px]' : 'w-full';

  const editor = useEditor({
    extensions,
    content: notes,
    editable: !isShared,
    autofocus: true,
    editorProps: {
      attributes: {
        class: `outline-none ${flexWidth} min-h-[5px] p-1 leading-extra-tight text-sm`,
      },
      handlePaste,
    },
  });

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  const handleInsert = (source: 'notepad' | 'prevNotes') => {
    const contentToInsert = source === 'notepad' ? notepad : prevNotes;
    const jsonToInsert = parseContent(contentToInsert);

    if (editor) {
      if (editor.isEmpty) {
        editor.commands.setContent(jsonToInsert);
      } else {
        editor.commands.insertContent(jsonToInsert);
        // Insert at cursor position
        // editor.chain().focus().insertContent(jsonToInsert.content).run();
      }
    }
  };

  const saveNotes = async (content: object) => {
    if (!isMounted) return;
    setIsSaving(true);
    try {
      await handleSave(JSON.stringify(content));
      saveToLocalStorage({ brandCode: rest.brandCode, content });
      if (isMounted) {
        toast({
          title: 'Notes saved',
          description: 'Your meeting notes have been saved successfully.',
        });
      }
    } catch (error) {
      console.error('Failed to save notes:', error);
      if (isMounted) {
        toast({
          title: 'Error saving notes',
          description:
            "There was a problem saving your notes. They've been stored locally.",
          variant: 'destructive',
        });
      }
    } finally {
      if (isMounted) setIsSaving(false);
    }
  };

  const debouncedSave = useDebouncedCallback(async (content: object) => {
    await saveNotes(content);
  }, AUTOSAVE_DELAY);

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useEffect(() => {
    if (editor && props.brandCode) {
      const savedContent = loadFromLocalStorage({ brandCode: rest.brandCode });
      const contentToSet = parseContent(notes || savedContent);

      if (
        editor.isEmpty ||
        JSON.stringify(editor.getJSON()) !== JSON.stringify(contentToSet)
      ) {
        editor.commands.setContent(contentToSet || null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, notes, props.brandCode]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (
        editor &&
        !editor.isEmpty &&
        JSON.stringify(editor.getJSON()) !== JSON.stringify(parseContent(notes))
      ) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, notes]);

  useEffect(() => {
    if (editor) {
      editor.on('update', ({ editor }) => {
        const content = editor.getJSON();
        debouncedSave(content);
      });
    }
  }, [editor, debouncedSave]);

  const handleManualSave = async () => {
    if (editor) {
      const content = editor.getJSON();
      await saveNotes(content);
    }
  };

  return (
    <Card className='w-full mx-auto'>
      <CardContent className='p-2'>
        <div className='p-2'>
          {!isShared && (
            <div className='flex gap-2 flex-wrap'>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() => editor?.chain().focus().toggleBold().run()}
                className={editor?.isActive('bold') ? 'bg-muted' : ''}
              >
                <Bold className='h-3 w-3' />
              </Button>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                className={editor?.isActive('italic') ? 'bg-muted' : ''}
              >
                <Italic className='h-3 w-3' />
              </Button>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() => editor?.chain().focus().toggleUnderline().run()}
                className={editor?.isActive('underline') ? 'bg-muted' : ''}
              >
                <UnderlineIcon className='h-3 w-3' />
              </Button>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
                className={editor?.isActive('bulletList') ? 'bg-muted' : ''}
              >
                <List className='h-3 w-3' />
              </Button>
              <Button
                variant='outline'
                size='icon-sm'
                onClick={() =>
                  editor?.chain().focus().toggleOrderedList().run()
                }
                className={editor?.isActive('orderedList') ? 'bg-muted' : ''}
              >
                <ListOrdered className='h-3 w-3' />
              </Button>
              <BtnInsertNotes handleInsert={() => handleInsert('prevNotes')} />
              <BtnInsertNotepad handleInsert={() => handleInsert('notepad')} />
            </div>
          )}
        </div>
        <div className='w-full border rounded-sm mx-1'>
          <EditorContent
            editor={editor}
            className='prose prose-sm max-w-none w-full'
          />
        </div>
        <div className='flex justify-start px-1 py-1'>
          <Button
            className={clsx(isShared && 'hidden')}
            onClick={handleManualSave}
            disabled={isSaving || isShared}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
});

export default MeetingNotes;
