import { DAILY_HOTEL_EVENTS } from '../gql/gql-event';
import { useQuery } from '@apollo/client';

export type UseHookArgs = {
  filters: {
    endDate: string;
    hotelId?: number | null;
    startDate: string;
    limit?: number;
    offset?: number;
  };
};

export function useDailyHotelEvents(args: UseHookArgs) {
  return useQuery(DAILY_HOTEL_EVENTS, {
    variables: {
      ...args,
    },
    skip: !args.filters.hotelId,
  });
}
