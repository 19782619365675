import { AiFillWarning } from 'react-icons/ai';
import ReactDOMServer from 'react-dom/server';

export const TooltipLiveData = () => {
  const Message = () => {
    return (
      <div>
        <p>We've detected that you are viewing an older report.</p>
        <br />
        <p>
          Please be aware that some data does not have "point in time" data
          <br /> and cannot be reconstructed "as of" a past date. <br />
          For this reason, we are pulling in the current data for this widget.
        </p>
      </div>
    );
  };

  return (
    <span
      className='text-amber-500'
      data-html={true}
      data-tip={ReactDOMServer.renderToString(<Message />)}
    >
      <AiFillWarning className='w-5 h-5 ml-2' />
    </span>
  );
};
