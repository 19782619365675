import { IconType } from 'react-icons/lib';

export enum Status {
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  UNSAVED = 'UNSAVED',
}

export interface StatusDisplayOptions {
  /**
   * The color of the status display. Use the Tailwind CSS color classes.
   * example: 'text-green-600'
   */
  color: string;
  /**
   * Any extra classes to add to the status display.
   */
  extraClass?: string;
  /**
   * The icon to display next to the label.
   */
  icon: IconType;
  /** The additional classes to add to the icon. */
  iconClass?: string;
  /**
   * The label to display.
   */
  label: string;
}
