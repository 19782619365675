import { ApolloError } from '@apollo/client';

type ErrorDisplayProps = {
  error?: ApolloError;
};

export default function ErrorDisplay(props: ErrorDisplayProps) {
  const { error } = props;
  if (!error) return null;
  const { graphQLErrors, networkError } = error;

  return (
    <div className='text-red-500 text-xs'>
      <span>Error</span>
      <pre>
        {graphQLErrors &&
          graphQLErrors.map(({ message }, i) => {
            return <span key={i}>{message}</span>;
          })}
        {networkError && <span>{networkError.message}</span>}
      </pre>
    </div>
  );
}
