import {
  dateFormatData,
  endOfMonth,
  endOfYear,
  firstOfMonth,
  firstOfYear,
  fromToday,
  yearsToDays,
} from '../../../helpers/dateHelpers';

import { BsCalendar2Range } from 'react-icons/bs';
import BtnPopover from '../../../components/BtnPopover';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { MdClear } from 'react-icons/md';
import { Placement } from '@popperjs/core';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { isSameDay } from 'date-fns';

type Props = {
  btnClass: string;
  btnIconClass?: string;
  daysOut: number;
  endDate: string;
  loading: boolean;
  onChange: (startDate: string, endDate: string) => void;
  startDate: string;
};

export default function DateRangeSelector({
  btnClass,
  btnIconClass = 'h-5 w-5',
  daysOut,
  endDate,
  loading,
  onChange,
  startDate,
}: Props) {
  type Range = [Date, Date];

  type QuickPick = {
    isSelected: () => boolean;
    label: string;
    range: Range;
  };

  const selectedRange: Range = [
    dayjs(startDate).toDate(),
    dayjs(endDate).toDate(),
  ];

  const rangeDefault: Range = [
    dayjs(firstOfMonth({})).toDate(),
    dayjs(endOfMonth({ daysOut })).toDate(),
  ];

  const rangeCurrentYear: Range = [
    dayjs(firstOfYear()).toDate(),
    dayjs(endOfYear()).toDate(),
  ];

  const rangeLastYear: Range = [
    dayjs().subtract(yearsToDays(), 'days').toDate(),
    dayjs(endOfYear(dayjs().subtract(yearsToDays(), 'days'))).toDate(),
  ];

  const rangeFastInput: Range = [
    dayjs().toDate(),
    dayjs(fromToday(31)).toDate(),
  ];

  const range90Days: Range = [dayjs().toDate(), dayjs(fromToday(90)).toDate()];

  const range180Days: Range = [
    dayjs().toDate(),
    dayjs(fromToday(180)).toDate(),
  ];

  const range365Days: Range = [
    dayjs().toDate(),
    dayjs(fromToday(365)).toDate(),
  ];

  const isSelected = (selRange: Range, baseRange: Range) => {
    return (
      isSameDay(selRange[0], baseRange[0]) &&
      isSameDay(selRange[1], baseRange[1])
    );
  };

  const handleSelect = (range: Range) => {
    const [selectedStartDate, selectedEndDate] = range;
    onChange(
      dayjs(selectedStartDate).format(dateFormatData),
      dayjs(selectedEndDate).format(dateFormatData)
    );
  };

  const quickPicks: QuickPick[] = [
    {
      isSelected: () => isSelected(selectedRange, rangeDefault),
      label: 'Default',
      range: rangeDefault,
    },
    {
      isSelected: () => isSelected(selectedRange, rangeCurrentYear),
      label: 'Current Year',
      range: rangeCurrentYear,
    },
    {
      isSelected: () => isSelected(selectedRange, rangeLastYear),
      label: 'Last Year',
      range: rangeLastYear,
    },
    {
      isSelected: () => isSelected(selectedRange, rangeFastInput),
      label: 'Fast Input',
      range: rangeFastInput,
    },
    {
      isSelected: () => isSelected(selectedRange, range90Days),
      label: '90 Days',
      range: range90Days,
    },
    {
      isSelected: () => isSelected(selectedRange, range180Days),
      label: '180 Days',
      range: range180Days,
    },
    {
      isSelected: () => isSelected(selectedRange, range365Days),
      label: '365 Days',
      range: range365Days,
    },
  ];

  const ClearIcon = () => {
    return (
      <span
        onClick={() =>
          handleSelect([
            dayjs(firstOfMonth({})).toDate(),
            dayjs(endOfMonth({ daysOut })).toDate(),
          ])
        }
      >
        <MdClear />
      </span>
    );
  };

  const quickPickClass =
    'items-center justify-center rounded-md border border-transparent px-2 py-1 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm';

  const panelContent = () => (
    <div className='z-50 w-max shadow-md border rounded-lg'>
      <div className='bg-white shadow rounded-lg'>
        <div className='px-4 py-5 sm:p-3'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Choose a date range
          </h3>
          <div className='grid grid-cols-1 gap-3'>
            <div className='mt-2 w-72 text-sm text-gray-500'>
              <DateRangePicker
                clearIcon={<ClearIcon />}
                maxDate={dayjs().add(2, 'years').toDate()}
                minDate={dayjs().subtract(364, 'days').toDate()}
                onChange={(v) => handleSelect(v as unknown as Range)}
                rangeDivider='to'
                value={selectedRange}
              />
            </div>

            <div className='grid grid-cols-2 gap-1'>
              {quickPicks.map((preset) => {
                return (
                  <button
                    type='button'
                    disabled={loading}
                    key={preset.label.toLocaleUpperCase()}
                    onClick={() => handleSelect(preset.range)}
                    className={clsx(
                      quickPickClass,
                      preset.isSelected()
                        ? 'bg-blue-100 text-blue-700 hover:bg-blue-200 focus:ring-blue-500'
                        : 'bg-green-100 text-green-700 hover:bg-green-200 focus:ring-green-500'
                    )}
                  >
                    {preset.label}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const btnProps = {
    btnClass: loading ? `${btnClass} opacity-50 cursor-not-allowed` : btnClass,
    btnIconClass,
    btnIcon: BsCalendar2Range,
    btnSpanClass: '',
    btnTooltip: 'Start/End Date',
    offsetH: 15,
    offsetV: 150,
    panelContent,
    popPlacement: 'bottom' as Placement,
  };

  return <BtnPopover {...btnProps} />;
}
