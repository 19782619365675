/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrackingByHotelQueryVariables = Types.Exact<{
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type TrackingByHotelQuery = { __typename?: 'Query', trackingByHotel?: Array<{ __typename?: 'Tracking', action?: string | null, agendaItems?: Array<string | null> | null, app?: string | null, attendees?: Array<string | null> | null, brandCode?: any | null, colLock?: number | null, createdAt?: string | null, dateAdded?: any | null, dateSegment?: string | null, endDate?: any | null, event?: string | null, id?: string | null, ipAddr?: string | null, meetingCategory?: string | null, meetingDate?: any | null, meetingId?: string | null, month?: number | null, page?: string | null, snapshotDate?: any | null, startDate?: any | null, updatedAt?: string | null, userEmail?: string | null, userName?: string | null, userRole?: string | null, yearsCompare?: number | null } | null> | null };

export type LogTrackingMutationVariables = Types.Exact<{
  tracking: Types.TrackingInput;
}>;


export type LogTrackingMutation = { __typename?: 'Mutation', logTracking?: boolean | null };


export const TrackingByHotelDocument = gql`
    query TrackingByHotel($brandCode: String, $cursor: String, $limit: Int) {
  trackingByHotel(brandCode: $brandCode, cursor: $cursor, limit: $limit) {
    action
    agendaItems
    app
    attendees
    brandCode
    colLock
    createdAt
    dateAdded
    dateSegment
    endDate
    event
    id
    ipAddr
    meetingCategory
    meetingDate
    meetingId
    month
    page
    snapshotDate
    startDate
    updatedAt
    userEmail
    userName
    userRole
    yearsCompare
  }
}
    `;

/**
 * __useTrackingByHotelQuery__
 *
 * To run a query within a React component, call `useTrackingByHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackingByHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackingByHotelQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTrackingByHotelQuery(baseOptions?: Apollo.QueryHookOptions<TrackingByHotelQuery, TrackingByHotelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackingByHotelQuery, TrackingByHotelQueryVariables>(TrackingByHotelDocument, options);
      }
export function useTrackingByHotelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackingByHotelQuery, TrackingByHotelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackingByHotelQuery, TrackingByHotelQueryVariables>(TrackingByHotelDocument, options);
        }
export type TrackingByHotelQueryHookResult = ReturnType<typeof useTrackingByHotelQuery>;
export type TrackingByHotelLazyQueryHookResult = ReturnType<typeof useTrackingByHotelLazyQuery>;
export type TrackingByHotelQueryResult = Apollo.QueryResult<TrackingByHotelQuery, TrackingByHotelQueryVariables>;
export const LogTrackingDocument = gql`
    mutation LogTracking($tracking: TrackingInput!) {
  logTracking(tracking: $tracking)
}
    `;
export type LogTrackingMutationFn = Apollo.MutationFunction<LogTrackingMutation, LogTrackingMutationVariables>;

/**
 * __useLogTrackingMutation__
 *
 * To run a mutation, you first call `useLogTrackingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogTrackingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logTrackingMutation, { data, loading, error }] = useLogTrackingMutation({
 *   variables: {
 *      tracking: // value for 'tracking'
 *   },
 * });
 */
export function useLogTrackingMutation(baseOptions?: Apollo.MutationHookOptions<LogTrackingMutation, LogTrackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogTrackingMutation, LogTrackingMutationVariables>(LogTrackingDocument, options);
      }
export type LogTrackingMutationHookResult = ReturnType<typeof useLogTrackingMutation>;
export type LogTrackingMutationResult = Apollo.MutationResult<LogTrackingMutation>;
export type LogTrackingMutationOptions = Apollo.BaseMutationOptions<LogTrackingMutation, LogTrackingMutationVariables>;
export const namedOperations = {
  Query: {
    TrackingByHotel: 'TrackingByHotel'
  },
  Mutation: {
    LogTracking: 'LogTracking'
  }
}