import { useEffect, useRef, useState } from 'react';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

require('highcharts/modules/heatmap')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);

type ChartProps = {
  callback?: (chart: Highcharts.Chart) => void;
  chartOptions: Highcharts.Options;
};

export default function AppChart({ callback, chartOptions }: ChartProps) {
  const [options, setOptions] = useState({});
  const chartRef = useRef<HighchartsReact.RefObject | null>(null);

  const chartType = chartOptions?.chart?.type;

  useEffect(() => {
    window.Highcharts = Highcharts;
    setOptions({ ...options, ...chartOptions });
    // eslint-disable-next-line
  }, [chartOptions]);

  useEffect(() => {
    if (callback && chartRef.current) {
      callback(chartRef.current.chart);
    }
    // eslint-disable-next-line
  }, [options]);

  return (
    <HighchartsReact
      constructorType={'chart'}
      highcharts={Highcharts}
      options={options}
      ref={chartRef}
      containerProps={{
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: `${chartType === 'pie' ? '300px' : '400px'}`,
        },
      }}
    />
  );
}
