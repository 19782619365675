/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
export type MeetingFieldsFragment = { __typename?: 'Meeting', agendaItems?: Array<string | null> | null, attendees?: Array<string | null> | null, brandCode?: string | null, bucket?: string | null, bucketKey?: string | null, category?: Types.MeetingCategory | null, createdAt?: string | null, createdById?: string | null, id?: string | null, meetingDate?: string | null, notes?: string | null, updatedAt?: string | null, agendaItemNotes?: Array<{ __typename?: 'AgendaItemNote', itemId?: string | null, notes?: string | null } | null> | null };

export const MeetingFieldsFragmentDoc = gql`
    fragment MeetingFields on Meeting {
  agendaItems
  agendaItemNotes {
    itemId
    notes
  }
  attendees
  brandCode
  bucket
  bucketKey
  category
  createdAt
  createdById
  id
  meetingDate
  notes
  updatedAt
}
    `;
export const namedOperations = {
  Fragment: {
    MeetingFields: 'MeetingFields'
  }
}