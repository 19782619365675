import _ from 'lodash';
import { useReducer } from 'react';

export type Action = {
  type: string;
  key: string;
  ids?: string[];
};

export type State = {
  bulk: boolean;
  [id: string]: boolean;
};

const bulkReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'CHECK':
      return { ...state, [action.key]: true };

    case 'TOGGLE_CHECK':
      let newState = { ...state, [action.key]: !state[action.key] };
      return { ...newState, bulk: isBulk(newState) };

    case 'CHECK_ALL':
      return _.mapValues(state, () => true);

    case 'UNCHECK_ALL':
      return _.mapValues(state, () => false);

    case 'RESET_CHECKS':
      if (action.ids) {
        return setupState(action.ids);
      } else {
        return state;
      }

    default:
      return state;
  }
};

/**
 * Are multiple rows checked?
 * @param state State object
 * @returns A boolean indicating whether multiple rows are checked. Excludes the bulk checkbox from the count.
 */
const isBulk = (state: State): boolean => {
  return _.countBy(_.omit(state, ['bulk']), (value: boolean) => value).true > 1;
};

const setupState = (ids: string[]): State => {
  const state: State = { bulk: false };
  ids.forEach((id) => (state[id] = false));
  return state;
};

export function useBulkCheckboxes(ids: string[]) {
  return useReducer(bulkReducer, setupState(ids));
}
