import * as DateHelpers from '../helpers/dateHelpers';

import { Forecast, Hotel, MonthlyBudget, RmData } from '../graphql/types';
import { useEffect, useState } from 'react';

import { CurrencyCell } from '../components/TableCells';
import { FixMeLater } from '../types/FixMeLaterType';
import { SoldCell } from '../components/TableCells';
import _ from 'lodash';
import clsx from 'clsx';
import dayjs from 'dayjs';
import numbro from 'numbro';
import { useShowForecast } from '../hooks/useShowForecast';

type MonthlyPerformanceProps = {
  data?: RmData[];
  forecast?: Forecast[];
  hotel?: Hotel;
  budget?: MonthlyBudget[];
};

type MonthlyPerformanceData = {
  stay_date: string;
  adr: number;
  adr_ly: number | null;
  adr_ly_final: number | null;
  revenue: number;
  revenue_ly: number | null;
  revenue_ly_final: number | null;
  sold: number;
  sold_ly: number | null;
  sold_ly_final: number | null;
  date_month: string;
  forecast_adr_1: number | null;
  forecast_adr_2: number | null;
  forecast_revenue_1: number | null;
  forecast_revenue_2: number | null;
  forecast_sold_1: number | null;
  forecast_sold_2: number | null;
  month?: number;
  year?: number;
  budget_adr?: number;
  budget_revenue?: number;
  budget_sold?: number;
};

export default function MonthlyPerformance({
  data,
  forecast,
  hotel,
  budget,
}: MonthlyPerformanceProps) {
  const { showForecast } = useShowForecast(hotel);

  const [tableData, setTableData] =
    useState<Map<string, MonthlyPerformanceData>>();

  const headers = showForecast
    ? {
        top: [
          'Actual',
          'Same Time Last Year',
          'Last Year Final',
          'Forecast #1',
          'Forecast #2',
          'Budget',
        ],
        bottom: [
          'Date',
          'Sold',
          'ADR',
          'Rev',
          'Sold LY',
          'ADR LY',
          'Rev LY',
          'Sold LYF',
          'ADR LYF',
          'Rev LYF',
          'F SLD',
          'F ADR',
          'F REV',
          'F2 SLD',
          'F2 ADR',
          'F2 REV',
          'BDG SLD',
          'BDG ADR',
          'BDG REV',
        ],
      }
    : {
        top: ['Actual', 'Same Time Last Year', 'Last Year Final', 'Budget'],
        bottom: [
          'Date',
          'Sold',
          'ADR',
          'Rev',
          'Sold LY',
          'ADR LY',
          'Rev LY',
          'Sold LYF',
          'ADR LYF',
          'Rev LYF',
          'BDG SLD',
          'BDG ADR',
          'BDG REV',
        ],
      };

  useEffect(() => {
    if (data && forecast && showForecast) {
      const mergedData = data.map((d) => {
        const fcst = forecast.find((f) => f?.stay_date === d?.stay_date);
        return {
          ...d,
          ...fcst,
        };
      });

      const groupedData = _.groupBy(mergedData, 'date_month');

      const sortedGroupData = _.orderBy(groupedData, (d) => d[0].stay_date);

      const dataMap = new Map(
        sortedGroupData.map((month) => [month[0].date_month, month])
      );

      const tableMap = new Map();

      dataMap.forEach((value, key) => {
        const monthTotal = value.reduce((acc, curr): FixMeLater => {
          return {
            adr: (acc.revenue! + curr.revenue!) / (acc.sold! + curr.sold!),
            adr_ly:
              (acc.revenue_ly! + curr.revenue_ly!) /
              (acc.sold_ly! + curr.sold_ly!),
            adr_ly_final:
              (acc.revenue_ly_final! + curr.revenue_ly_final!) /
              (acc.sold_ly_final! + curr.sold_ly_final!),
            revenue: acc.revenue! + curr.revenue!,
            revenue_ly: acc.revenue_ly! + curr.revenue_ly!,
            revenue_ly_final: acc.revenue_ly_final! + curr.revenue_ly_final!,
            sold: acc.sold! + curr.sold!,
            sold_ly: acc.sold_ly! + curr.sold_ly!,
            sold_ly_final: acc.sold_ly_final! + curr.sold_ly_final!,
            forecast_adr_1:
              (acc.forecast_revenue_1! + curr.forecast_revenue_1!) /
              (acc.forecast_sold_1! + curr.forecast_sold_1!),
            forecast_adr_2:
              ((acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0)) /
              ((acc.forecast_sold_2 || 0) + (curr.forecast_sold_2 || 0)),
            forecast_revenue_1:
              acc.forecast_revenue_1! + curr.forecast_revenue_1!,
            forecast_revenue_2:
              (acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0),
            forecast_sold_1: acc.forecast_sold_1! + curr.forecast_sold_1!,
            forecast_sold_2:
              (acc.forecast_sold_2 || 0) + (curr.forecast_sold_2 || 0),
          };
        });

        const budgetData = budget?.find((b) => {
          return key && parseInt(DateHelpers.formatDate(key, 'M')) === b?.month;
        });

        const month = dayjs(value[0].stay_date).format('MMM YYYY');
        tableMap.set(month, { ...monthTotal, ...budgetData });
      });

      const grandTotal = Array.from(tableMap)
        .map(([, value]) => value)
        .reduce((acc, curr): FixMeLater => {
          return {
            adr: (acc.revenue + curr.revenue) / (acc.sold + curr.sold),
            adr_ly:
              (acc.revenue_ly + curr.revenue_ly) / (acc.sold_ly + curr.sold_ly),
            adr_ly_final:
              (acc.revenue_ly_final + curr.revenue_ly_final) /
              (acc.sold_ly_final + curr.sold_ly_final),
            revenue: acc.revenue + curr.revenue,
            revenue_ly: acc.revenue_ly + curr.revenue_ly,
            revenue_ly_final: acc.revenue_ly_final + curr.revenue_ly_final,
            sold: acc.sold + curr.sold,
            sold_ly: acc.sold_ly + curr.sold_ly,
            sold_ly_final: acc.sold_ly_final + curr.sold_ly_final,
            forecast_adr_1:
              ((acc.forecast_revenue_1 || acc.revenue) +
                (curr.forecast_revenue_1 || curr.revenue)) /
              ((acc.forecast_sold_1 || acc.sold) +
                (curr.forecast_sold_1 || curr.sold)),
            forecast_adr_2:
              ((acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0)) /
              ((acc.forecast_sold_2 || 0) + (curr.forecast_sold_2 || 0)),
            forecast_revenue_1:
              (acc.forecast_revenue_1 || acc.revenue) +
              (curr.forecast_revenue_1 || curr.revenue),
            forecast_revenue_2:
              (acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0),
            forecast_sold_1:
              (acc.forecast_sold_1 || acc.sold) +
              (curr.forecast_sold_1 || curr.sold),
            forecast_sold_2:
              (acc.forecast_sold_2 || 0) + (curr.forecast_sold_2 || 0),
            budget_sold: (acc.budget_sold || 0) + (curr.budget_sold || 0),
            budget_adr:
              ((acc.budget_revenue || 0) + (curr.budget_revenue || 0)) /
              ((acc.budget_sold || 0) + (curr.budget_sold || 0)),
            budget_revenue:
              (acc.budget_revenue || 0) + (curr.budget_revenue || 0),
          };
        });

      tableMap.set('Total', grandTotal);

      setTableData(tableMap);
    } else if (data && !showForecast) {
      const mergedData = data;

      const groupedData = _.groupBy(mergedData, 'date_month');

      const sortedGroupData = _.orderBy(groupedData, (d) => d[0].stay_date);

      const dataMap = new Map(
        sortedGroupData.map((month) => [month[0].date_month, month])
      );

      const tableMap = new Map();

      dataMap.forEach((value, key) => {
        const monthTotal = value.reduce((acc, curr): FixMeLater => {
          return {
            adr: (acc.revenue! + curr.revenue!) / (acc.sold! + curr.sold!),
            adr_ly:
              (acc.revenue_ly! + curr.revenue_ly!) /
              (acc.sold_ly! + curr.sold_ly!),
            adr_ly_final:
              (acc.revenue_ly_final! + curr.revenue_ly_final!) /
              (acc.sold_ly_final! + curr.sold_ly_final!),
            revenue: acc.revenue! + curr.revenue!,
            revenue_ly: acc.revenue_ly! + curr.revenue_ly!,
            revenue_ly_final: acc.revenue_ly_final! + curr.revenue_ly_final!,
            sold: acc.sold! + curr.sold!,
            sold_ly: acc.sold_ly! + curr.sold_ly!,
            sold_ly_final: acc.sold_ly_final! + curr.sold_ly_final!,
          };
        });

        const budgetData = budget?.find((b) => {
          return key && parseInt(DateHelpers.formatDate(key, 'M')) === b?.month;
        });

        const month = dayjs(value[0].stay_date).format('MMM YYYY');
        tableMap.set(month, { ...monthTotal, ...budgetData });
      });

      const grandTotal = Array.from(tableMap)
        .map(([, value]) => value)
        .reduce((acc, curr): FixMeLater => {
          return {
            adr: (acc.revenue + curr.revenue) / (acc.sold + curr.sold),
            adr_ly:
              (acc.revenue_ly + curr.revenue_ly) / (acc.sold_ly + curr.sold_ly),
            adr_ly_final:
              (acc.revenue_ly_final + curr.revenue_ly_final) /
              (acc.sold_ly_final + curr.sold_ly_final),
            revenue: acc.revenue + curr.revenue,
            revenue_ly: acc.revenue_ly + curr.revenue_ly,
            revenue_ly_final: acc.revenue_ly_final + curr.revenue_ly_final,
            sold: acc.sold + curr.sold,
            sold_ly: acc.sold_ly + curr.sold_ly,
            sold_ly_final: acc.sold_ly_final + curr.sold_ly_final,
            budget_sold: (acc.budget_sold || 0) + (curr.budget_sold || 0),
            budget_adr:
              ((acc.budget_revenue || 0) + (curr.budget_revenue || 0)) /
              ((acc.budget_sold || 0) + (curr.budget_sold || 0)),
            budget_revenue:
              (acc.budget_revenue || 0) + (curr.budget_revenue || 0),
          };
        });

      tableMap.set('Total', grandTotal);

      setTableData(tableMap);
    }
  }, [data, forecast, budget, showForecast]);

  function prepForNumbro(value: number | null, thousandSeparated = true) {
    if (isNaN(value as number) || value === null) {
      return '';
    } else {
      return numbro(value).format({
        thousandSeparated,
      });
    }
  }

  function getRow(key: string, data: MonthlyPerformanceData) {
    return (
      <tr
        key={key}
        className={clsx(
          'whitespace-nowrap text-sm border-2',
          'hover:bg-slate-200',
          key === 'Total' && 'font-semibold'
        )}
      >
        <td className='text-center border-r-2 pr-1.5 py-1 font-semibold'>
          {key}
        </td>
        <td className='text-center'>
          {numbro(data.sold).format({
            thousandSeparated: true,
          })}
        </td>
        <CurrencyCell value={data.adr} metric='adr' />
        <CurrencyCell
          value={data.revenue}
          metric='revenue'
          style={['border-r-2', 'pr-1.5']}
        />
        <td className='text-center'>{prepForNumbro(data.sold_ly)}</td>
        <CurrencyCell value={data.adr_ly} metric='adr' />
        <CurrencyCell
          value={data.revenue_ly}
          metric='revenue'
          style={['border-r-2', 'pr-1.5']}
        />
        <td className='text-center'>{prepForNumbro(data.sold_ly_final)}</td>
        <CurrencyCell value={data.adr_ly_final} metric='adr' />
        <CurrencyCell
          value={data.revenue_ly_final}
          metric='revenue'
          style={['border-r-2', 'pr-1.5']}
        />
        {showForecast ? (
          <>
            <td className='text-center'>
              {prepForNumbro(data.forecast_sold_1)}
            </td>
            <CurrencyCell value={data.forecast_adr_1} metric='adr' />
            <CurrencyCell
              value={data.forecast_revenue_1 || ''}
              metric='revenue'
              style={['border-r-2', 'pr-1.5']}
            />
            <td className='text-center'>
              {prepForNumbro(data.forecast_sold_2)}
            </td>
            <CurrencyCell value={data.forecast_adr_2 || ''} metric='adr' />
            <CurrencyCell
              value={data.forecast_revenue_2 || ''}
              metric='revenue'
              style={['border-r-2', 'pr-1.5']}
            />
          </>
        ) : null}
        <td className='text-center'>{data.budget_sold}</td>
        <CurrencyCell value={data.budget_adr} metric='adr' />
        <CurrencyCell
          value={data.budget_revenue}
          metric='revenue'
          style={['border-r-2', 'pr-1.5']}
        />
      </tr>
    );
  }

  function getVarianceRow(key: string, data: MonthlyPerformanceData) {
    return (
      <tr
        key={key}
        className={clsx(
          'whitespace-nowrap text-sm border-2',
          'hover:bg-slate-200',
          key === 'Total' && 'font-semibold'
        )}
      >
        <td className='text-center pr-1.5 py-1 font-semibold'>{key}</td>
        <td></td>
        <td></td>
        <td className='border-r-2'></td>
        <SoldCell value={data.sold - (data.sold_ly || 0)} />
        <CurrencyCell
          value={data.adr - (data.adr_ly || 0)}
          metric='adr'
          style={['border-r-2', 'pr-1.5']}
        />
        <CurrencyCell
          value={data.revenue - (data.revenue_ly || 0)}
          metric='revenue'
          style={['border-r-2', 'pr-1.5']}
        />
        <SoldCell value={data.sold - (data.sold_ly_final || 0)} />
        <CurrencyCell
          value={data.adr - (data.adr_ly_final || 0)}
          metric='adr'
          style={['border-r-2', 'pr-1.5']}
        />
        <CurrencyCell
          value={data.revenue - (data.revenue_ly_final || 0)}
          metric='revenue'
          style={['border-r-2', 'pr-1.5']}
        />
        {showForecast ? (
          <>
            <SoldCell
              value={
                data.forecast_sold_1 ? data.sold - data.forecast_sold_1 : ''
              }
            />
            <CurrencyCell
              value={data.forecast_adr_1 ? data.adr - data.forecast_adr_1 : ''}
              metric='adr'
              style={['border-r-2', 'pr-1.5']}
            />
            <CurrencyCell
              value={
                data.forecast_revenue_1
                  ? data.revenue - data.forecast_revenue_1
                  : ''
              }
              metric='revenue'
              style={['border-r-2', 'pr-1.5']}
            />
            <SoldCell
              value={
                data.forecast_sold_2
                  ? numbro(data.sold - data.forecast_sold_2).format({
                      thousandSeparated: true,
                    })
                  : ''
              }
            />
            <CurrencyCell
              value={data.forecast_adr_2 ? data.adr - data.forecast_adr_2 : ''}
              metric='adr'
            />
            <CurrencyCell
              value={
                data.forecast_revenue_2
                  ? data.revenue - data.forecast_revenue_2
                  : ''
              }
              metric='revenue'
              style={['border-r-2', 'pr-1.5']}
            />
          </>
        ) : null}
        <SoldCell
          value={data.budget_sold ? data.sold - data.budget_sold : ''}
        />
        <CurrencyCell
          value={data.budget_adr ? data.adr - data.budget_adr : ''}
          metric='adr'
        />
        <CurrencyCell
          value={data.budget_revenue ? data.revenue - data.budget_revenue : ''}
          metric='revenue'
        />
      </tr>
    );
  }

  return (
    <table className='border-collapse'>
      <thead>
        <tr className='border-b-2 whitespace-nowrap'>
          <th></th>
          {headers.top.map((header) => (
            <th
              key={header}
              colSpan={3}
              className='px-2 py-1 font-medium text-sm text-gray-900'
            >
              {header}
            </th>
          ))}
        </tr>
        <tr className='border-b-2'>
          {headers.bottom.map((header) => (
            <th
              key={header}
              className='px-2 py-1 whitespace-nowrap font-medium text-sm text-gray-900'
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData &&
          Array.from(tableData).map(([key, value]) => getRow(key, value))}
        <tr className='border-y-2'>
          <td className='font-bold pt-2'>Variances</td>
        </tr>
        {tableData &&
          Array.from(tableData).map(([key, value]) =>
            getVarianceRow(key, value)
          )}
      </tbody>
    </table>
  );
}
