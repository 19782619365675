import { useEffect, useState } from 'react';

import { TableFilters } from '../../reducers/useDataTableOptions';
import Toggle from '../Toggle';
import _ from 'lodash';
import dayjs from 'dayjs';

type Props = {
  setTableFilters: React.Dispatch<React.SetStateAction<TableFilters>>;
  tableFilters: TableFilters;
};

export default function DTDateRange({ tableFilters, setTableFilters }: Props) {
  const [filters, setFilters] = useState(tableFilters);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (tableFilters && !_.isEqual(tableFilters, filters)) {
      setFilters(tableFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newFilters = {
      ...filters,
      [name]: value,
    };
    if (
      dayjs(newFilters.startDate as string).isAfter(
        newFilters.endDate as string
      )
    ) {
      newFilters = { ...newFilters, endDate: newFilters.startDate };
    }
    setFilters(newFilters);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    switch (key) {
      case 'Enter':
        setTableFilters(filters);
        break;
      default:
        break;
    }
  };

  return (
    <div className='w-full shadow-sm p-1'>
      <div className='w-full rounded-2xl bg-white'>
        <div className='text-gray-500'>
          <Toggle
            checked={isOpen}
            label={'Table Options'}
            onChange={setIsOpen}
          />
          <div
            className={`${
              isOpen ? '' : 'hidden'
            } grid grid-cols-2 gap-x-2 gap-y-2`}
          >
            {Object.keys(_.omit(filters, ['dow', 'sort', 'textFilter'])).map(
              (key, index) => {
                const value = filters[key as keyof TableFilters];
                const type = key.toLowerCase().includes('date')
                  ? 'date'
                  : 'text';
                return (
                  <div key={`form-input-${key}-${index}`}>
                    <label
                      htmlFor={key}
                      className='block text-sm font-medium text-gray-700'
                    >
                      {'Table ' + _.startCase(key)}
                    </label>
                    <div className='mt-1'>
                      <input
                        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs border-gray-300 rounded-md'
                        id={key}
                        name={key}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        type={type}
                        value={value as string}
                      />
                    </div>
                  </div>
                );
              }
            )}
            <button
              className='items-center mt-2 text-xs shadow-sm p-2 text-white bg-blue-500 hover:bg-blue-600 rounded'
              onClick={() => setTableFilters(filters)}
            >
              <span>Change Dates</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
