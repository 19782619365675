import { RmData } from '../graphql/types';
import _ from 'lodash';
import { baseFormat } from '../renderers/baseFormat';
import { masterColumns } from '../renderers/masterColumns';

const dataRow = (
  lastNight: string,
  metric: string,
  currentData?: RmData[],
  lastYearData?: RmData[]
) => {
  if (!currentData) {
    return [];
  }

  const header = masterColumns[metric].header;
  const metricLy = `${metric}_ly_final`;
  const metricVar = `var_${metricLy}`;
  const mtdVal = ['occ', 'adr', 'revpar'].includes(metric)
    ? metric === 'adr'
      ? _.sumBy(currentData, 'revenue') / _.sumBy(currentData, 'sold')
      : _.meanBy(currentData, metric)
    : _.sumBy(currentData, metric);
  const mtdValLy = ['occ', 'adr', 'revpar'].includes(metric)
    ? metric === 'adr'
      ? _.sumBy(lastYearData, 'revenue_ly_final') /
        _.sumBy(lastYearData, 'sold_ly_final')
      : _.meanBy(lastYearData, metricLy)
    : _.sumBy(lastYearData, metricLy);
  const lastNightData = currentData.find((d) => d.stay_date === lastNight);

  return [
    header,
    lastNightData
      ? baseFormat(metric, lastNightData[metric as keyof RmData], 'day')
      : '',
    lastNightData
      ? baseFormat(metric, lastNightData[metricLy as keyof RmData], 'day')
      : '',
    lastNightData
      ? baseFormat(metric, lastNightData[metricVar as keyof RmData], 'day')
      : '',
    baseFormat(metric, mtdVal, 'day'),
    baseFormat(metric, mtdValLy, 'day'),
    baseFormat(metric, mtdVal - mtdValLy, 'day'),
  ];
};

export const tableData = (
  lastNight: string,
  currentData?: RmData[],
  lastYearData?: RmData[]
) => {
  const metrics = [
    'sold',
    'occ',
    'adr',
    'revenue',
    'revpar',
    'group_sold',
    'ooo',
  ];
  const headers = [
    '',
    'Last Night',
    'Last Year',
    'Variance',
    'MTD',
    'MTD LY',
    'Variance',
  ];
  return {
    headers,
    rows: metrics.map((metric) =>
      dataRow(lastNight, metric, currentData, lastYearData)
    ),
    noWrap: true,
    variant: 'default' as const,
  };
};
