import { createContext, useContext } from 'react';

import { TrackingInputType } from '../types/Tracking';
import { useHotel } from './hotelContext';
import { useTracking } from 'react-tracking';
import { useUser } from './userContext';

type AppTrackContextProps = {
  track: (payload: TrackingInputType) => void;
};

type AppTrackProviderProps = { children: React.ReactNode };

const AppTrackContext = createContext<AppTrackContextProps | undefined>(
  undefined
);

const AppTrackProvider = ({ children }: AppTrackProviderProps) => {
  const { hotel } = useHotel();
  const { oktaUser, user, userIp } = useUser();
  const { trackEvent } = useTracking();

  const track = (payload: TrackingInputType) => {
    if (user && hotel && oktaUser) {
      trackEvent({
        ...payload,
        ...(!payload.brandCode && { brandCode: hotel.brand_code }),
        ipAddr: userIp,
        userEmail: user.email as string,
        userName: oktaUser.name,
        userRole: oktaUser.userRole,
      });
    }
  };

  return (
    <AppTrackContext.Provider value={{ track }}>
      {children}
    </AppTrackContext.Provider>
  );
};

const useAppTrack = () => {
  const context = useContext(AppTrackContext);
  if (context === undefined) {
    throw new Error(`useAppTrack must be used within a AppTrackProvider`);
  }
  return context;
};

export { AppTrackProvider, useAppTrack };
