/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RmRecsWidgetQueryVariables = Types.Exact<{
  filters: Types.FindRmRecArgs;
}>;


export type RmRecsWidgetQuery = { __typename?: 'Query', hotelRmRecs?: Array<{ __typename?: 'RmRec', id?: string | null, notes?: string | null, rate?: number | null, status?: Types.RmRecStatus | null, stay_date?: any | null } | null> | null };


export const RmRecsWidgetDocument = gql`
    query RmRecsWidget($filters: FindRmRecArgs!) {
  hotelRmRecs(filters: $filters) {
    id
    notes
    rate
    status
    stay_date
  }
}
    `;

/**
 * __useRmRecsWidgetQuery__
 *
 * To run a query within a React component, call `useRmRecsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useRmRecsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRmRecsWidgetQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRmRecsWidgetQuery(baseOptions: Apollo.QueryHookOptions<RmRecsWidgetQuery, RmRecsWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RmRecsWidgetQuery, RmRecsWidgetQueryVariables>(RmRecsWidgetDocument, options);
      }
export function useRmRecsWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RmRecsWidgetQuery, RmRecsWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RmRecsWidgetQuery, RmRecsWidgetQueryVariables>(RmRecsWidgetDocument, options);
        }
export type RmRecsWidgetQueryHookResult = ReturnType<typeof useRmRecsWidgetQuery>;
export type RmRecsWidgetLazyQueryHookResult = ReturnType<typeof useRmRecsWidgetLazyQuery>;
export type RmRecsWidgetQueryResult = Apollo.QueryResult<RmRecsWidgetQuery, RmRecsWidgetQueryVariables>;
export const namedOperations = {
  Query: {
    RmRecsWidget: 'RmRecsWidget'
  }
}