import Report from './components/report';
import { today } from '../../helpers/dateHelpers';
import { useEffect } from 'react';
import { useHotel } from '../../context/hotelContext';
import { useIsReport } from './hooks/use-report-location';
import { useMeeting } from '../meetings/context/meeting-context';
import { useParams } from 'react-router-dom';
import { useReportData } from './context/report-data-context';
import { useUser } from '../../context/userContext';

const LiveReport = () => {
  const { isReport } = useIsReport();
  const { meetingId } = useParams<{ meetingId?: string }>();
  const { data, loading: dataLoading } = useReportData();
  const { hotel, setBrandCode } = useHotel();
  const { user, oktaUser } = useUser();
  const {
    loading,
    meeting,
    meetingTools,
    meetingUser,
    setMeeting,
    setMeetingId,
  } = useMeeting();

  useEffect(() => {
    if (meetingId) {
      setMeetingId(meetingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId]);

  useEffect(() => {
    if (isReport && meeting && meeting.brandCode) {
      setBrandCode(meeting.brandCode);
    }
    if (!isReport && meeting && meeting.meetingDate !== today()) {
      setMeetingId(undefined);
      setMeeting(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReport]);

  return (
    <div>
      {data && (
        <Report
          data={{
            data,
            hotel,
            meetingTools,
            user: meetingUser || user,
            oktaUser,
          }}
          dataLoading={dataLoading}
          meeting={meeting}
          meetingLoading={loading}
          setMeeting={setMeeting}
        />
      )}
    </div>
  );
};

export { LiveReport };

// Report component needs to take out anything that is reliant on
// the context and make sure its just using the meeting and data props
// passed through from the parent component. The parent component is where
// we can use the context to get the meeting and data for a LIVE report,
// and the shared report can get the meeting and data from the URL params
