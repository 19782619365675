import { CurrencyCell, SoldCell } from '../../../components/TableCells';

import { RmData } from '../../../graphql/types';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useMemo } from 'react';

type MonthlyRoundUpProps = {
  data?: RmData[];
  loading: boolean;
};

type RoundUpTableProps = {
  data?: Array<Array<string | number | undefined | null>>;
  metric: string;
};

type RoundUpTableData = {
  otb?: number | string | null;
  sameTimeLy?: number | string | null;
  lastYearFinal?: number | string | null;
  varianceToStly?: number | string | null;
  stay_date?: string | null;
  month?: string | null;
};

const MonthlyRoundUp = ({ data, loading }: MonthlyRoundUpProps) => {
  const tableData = useMemo(() => {
    const groupedData = _.groupBy(data, 'date_ym');
    const sortedGroupData = _.orderBy(groupedData, (d) => d[0].stay_date);
    const months = Object.keys(groupedData).map((month) =>
      dayjs(month).format('MMM')
    );

    const getMetricData = (metric: string) => {
      switch (metric) {
        case 'sold':
          const soldReducedData: RoundUpTableData[] = sortedGroupData?.map(
            (monthData) => {
              return {
                otb: _.sumBy(monthData, 'sold'),
                sameTimeLy: _.sumBy(monthData, 'sold_ly'),
                lastYearFinal: _.sumBy(monthData, 'sold_ly_final'),
                varianceToStly:
                  _.sumBy(monthData, 'sold') - _.sumBy(monthData, 'sold_ly'),
              };
            }
          );

          const soldData = soldReducedData?.map((monthData) => {
            const { otb, sameTimeLy, lastYearFinal, varianceToStly } =
              monthData;
            return [otb, sameTimeLy, lastYearFinal, varianceToStly];
          });

          return soldData;
        case 'adr':
          const adrReducedData = sortedGroupData?.map((monthData) => {
            const otb =
              _.sumBy(monthData, 'revenue') / _.sumBy(monthData, 'sold') || 0;
            const sameTimeLy =
              _.sumBy(monthData, 'revenue_ly') /
                _.sumBy(monthData, 'sold_ly') || 0;
            const lastYearFinal =
              _.sumBy(monthData, 'revenue_ly_final') /
                _.sumBy(monthData, 'sold_ly_final') || 0;
            const varianceToStly = otb - sameTimeLy;

            return {
              otb,
              sameTimeLy,
              lastYearFinal,
              varianceToStly,
            };
          });

          const adrData = adrReducedData?.map((monthData) => {
            const { otb, sameTimeLy, lastYearFinal, varianceToStly } =
              monthData;
            return [otb, sameTimeLy, lastYearFinal, varianceToStly];
          });

          return adrData;
        case 'revenue':
          const revReducedData = sortedGroupData?.map((monthData) => {
            return {
              otb: _.sumBy(monthData, 'revenue'),
              sameTimeLy: _.sumBy(monthData, 'revenue_ly'),
              lastYearFinal: _.sumBy(monthData, 'revenue_ly_final'),
              varianceToStly:
                _.sumBy(monthData, 'revenue') -
                _.sumBy(monthData, 'revenue_ly'),
            };
          });

          const revData = revReducedData?.map((monthData) => {
            const { otb, sameTimeLy, lastYearFinal, varianceToStly } =
              monthData;
            return [otb, sameTimeLy, lastYearFinal, varianceToStly];
          });

          return revData;
        default:
          return [];
      }
    };

    const soldData = getMetricData('sold');
    const adrData = getMetricData('adr');
    const revData = getMetricData('revenue');

    return { months, soldData, adrData, revData };
  }, [data]);

  return (
    <>
      {!tableData || loading ? (
        <span>Loading Monthly Round Up Table...</span>
      ) : (
        <div className='w-full flex rounded-lg bg-gray-200 shadow min-w-[1100px] overflow-x-auto'>
          <table className='bg-white border-collapse text-center'>
            <thead className='h-[27px]'>
              <tr className='border-b-2 bg-blue-900'>
                <td className='h-[22px] px-2'>{''}</td>
              </tr>
            </thead>
            <tbody className='bg-gray-100'>
              <tr>
                <td className='h-[22px]'></td>
              </tr>
              {tableData.months.map((month) => (
                <tr key={month}>
                  <td className='text-sm font-medium px-2'>{month}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='w-full grid grid-cols-3 bg-gray-200 shadow divide-y divide-gray-200 sm:divide-y-0 sm:gap-px'>
            <RoundUpTable data={tableData.soldData} metric='Sold' />
            <RoundUpTable data={tableData.adrData} metric='ADR' />
            <RoundUpTable data={tableData.revData} metric='Revenue' />
          </div>
        </div>
      )}
    </>
  );
};

const RoundUpTable = ({ data, metric }: RoundUpTableProps) => {
  const headers = ['OTB', 'Same Time LY', 'LY Final', 'Variance to STLY'];

  return (
    <table className='bg-white border-collapse text-center'>
      <thead>
        <tr className='border-b-2 bg-blue-900 text-white'>
          <th colSpan={5} className='font-medium'>
            {metric}
          </th>
        </tr>
        <tr>
          {headers.map((header) => (
            <th key={header} className='font-semibold text-sm'>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((row, i) => (
          <tr key={i}>
            {row.map((cell: string | number | undefined | null, j: number) =>
              metric === 'Sold' ? (
                <SoldCell value={cell} key={j} style={['text-sm']} />
              ) : metric === 'ADR' ? (
                <CurrencyCell
                  value={cell}
                  metric='adr'
                  style={['text-sm']}
                  key={j}
                />
              ) : metric === 'Revenue' ? (
                <CurrencyCell
                  value={cell}
                  metric='revenue'
                  style={['text-sm']}
                  key={j}
                />
              ) : (
                <td key={j}>{cell}</td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MonthlyRoundUp;
