import { dateFormatData, dateFormatDisplay } from '../helpers/dateHelpers';
import { useEffect, useRef, useState } from 'react';

import { DataGridCell } from '../types/DataGrid';
import dayjs from 'dayjs';
import { isValidRate } from '../helpers/rateShopHelpers';

type EditableProps = {
  activeCell?: { row: number; col: number };
  col?: number;
  dataRow?: DataGridCell[];
  disabled?: boolean;
  fallback?: string;
  initialValue: string;
  inputAlign?: string;
  inputTextSize?: string;
  inputType?: string;
  inputWidth?: string;
  dataKey?: string;
  max?: number | string;
  maxLength?: number;
  min?: number | string;
  onChange?: (value: string) => void;
  row?: number;
  setActiveCell?: (activeCell: { row: number; col: number }) => void;
  truncate?: boolean;
};

export default function Editable(props: EditableProps) {
  const {
    activeCell,
    col,
    dataRow,
    disabled = false,
    fallback = '...',
    initialValue,
    inputAlign = 'text-center',
    inputTextSize = 'text-xl',
    inputType = 'text',
    inputWidth = 'w-28',
    dataKey,
    max,
    maxLength = 8,
    min,
    onChange,
    row,
    setActiveCell,
    truncate = false,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue);

  const defaultValue = initialValue || fallback;

  useEffect(() => {
    setValue(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (isActiveCell) {
      setEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCell]);

  useEffect(() => {
    if (edit === false) {
      if (value === null || value === undefined || value === '') {
        setValue(defaultValue);
      }
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputType === 'text' && inputRef.current.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  const cleanValue = (value: string) => {
    return value.replace('$', '');
  };

  const formattedValue =
    inputType === 'date'
      ? dayjs(value, dateFormatData).format(dateFormatDisplay)
      : value;

  const handleSave = () => {
    setEdit(false);
    if (valuesAreNew()) {
      onChange && onChange(cleanValue(value));
    } else {
      initialValue === '' ? setValue(fallback) : setValue(initialValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleOnBlur = () => {
    handleSave();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    switch (key) {
      case 'ArrowDown':
        if (validateValue(value)) {
          hasActiveCellProps && setActiveCell({ row: row + 1, col });
          handleSave();
        }
        break;
      case 'ArrowLeft':
        if (validateValue(value)) {
          if (inputRef.current?.selectionStart === 0) {
            hasActiveCellProps && setActiveCell({ row, col: col - 1 });
            handleSave();
          }
        }
        break;
      case 'ArrowUp':
        if (validateValue(value)) {
          hasActiveCellProps && setActiveCell({ row: row - 1, col });
          handleSave();
        }
        break;
      case 'ArrowRight':
        if (validateValue(value)) {
          if (
            inputRef.current?.selectionEnd === inputRef.current?.value.length
          ) {
            hasActiveCellProps && setActiveCell({ row, col: col + 1 });
            handleSave();
          }
        }
        break;
      case 'Delete':
        setValue('');
        break;
      case 'Enter':
        if (validateValue(value)) {
          hasActiveCellProps && setActiveCell({ row: row + 1, col });
          handleSave();
        }
        break;
      case 'Escape':
        setEdit(false);
        setValue(defaultValue);
        break;
      case 'Tab':
        if (validateValue(value)) {
          hasActiveCellProps && setActiveCell({ row, col: col + 1 });
          handleSave();
        }
        break;
      default:
        break;
    }
  };

  const hasActiveCellProps = activeCell && row && col && setActiveCell;
  const hideInput = edit ? '' : 'hidden';
  const isActiveCell =
    hasActiveCellProps && activeCell.row === row && activeCell.col === col;
  const spanClass = 'rounded-sm hover:bg-gray-100';

  const NonEditable = () => {
    if (truncate) {
      return (
        <span
          className={`${spanClass}`}
          data-tip={formattedValue}
          onClick={() => setEdit(true)}
        >
          {String(formattedValue).length > maxLength
            ? String(formattedValue).substring(0, maxLength)
            : formattedValue}
        </span>
      );
    } else {
      return (
        <span className={`${spanClass}`} onClick={() => setEdit(true)}>
          {formattedValue}
        </span>
      );
    }
  };

  const validateValue = (value: string) => {
    if (dataKey && dataKey.includes('rate')) {
      if (isValidRate(Number(cleanValue(value)), dataRow)) {
        return true;
      } else {
        return window.confirm(
          `${value} does not appear to be a valid rate.\nAre you sure you want to save this value? \nRate can be saved but will not be uploaded.`
        );
      }
    } else {
      return true;
    }
  };

  const valuesAreNew = (): boolean => {
    return value !== fallback && value !== initialValue;
  };

  return (
    <>
      {edit && !disabled ? (
        <input
          className={`${hideInput} ${inputTextSize} ${inputWidth} ${inputAlign}`}
          ref={inputRef}
          type={inputType}
          value={value}
          max={max}
          min={min}
          onBlur={handleOnBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <NonEditable />
      )}
    </>
  );
}
