import { DayFilters, TableFilters } from '../../reducers/useDataTableOptions';

import FilterDays from './FilterDays';
import FilterNumber from './FilterNumber';
import FilterSelect from './FilterSelect';
import FilterText from './FilterText';
import { FormField } from '../../types/DataTable';

type Props = {
  field: FormField;
  setTableFilters: React.Dispatch<React.SetStateAction<TableFilters>>;
  tableFilters: TableFilters;
};

const DTHeaderFilter = ({ field, setTableFilters, tableFilters }: Props) => {
  const { label, name, readOnly, type } = field;

  const handleChange = (value: string | number | DayFilters) => {
    setTableFilters({ ...tableFilters, [name]: value });
  };

  const hasFilter =
    tableFilters[name] !== undefined && tableFilters[name] !== '';

  const spanClass = hasFilter ? 'text-blue-800' : '';

  if (readOnly || name === 'id') {
    return <span>{label}</span>;
  }

  switch (type) {
    case 'date':
      return (
        <FilterDays
          label={label}
          initialValue={tableFilters[name] as DayFilters}
          onChange={handleChange}
        />
      );
    case 'number':
      return (
        <FilterNumber
          initialValue={label}
          label={label}
          onChange={handleChange}
          spanClass={spanClass}
        />
      );
    case 'select':
      return (
        <FilterSelect
          initialValue={label}
          label={label}
          onChange={handleChange}
          options={field.optionsFiltersOnly || field.options}
          spanClass={spanClass}
        />
      );
    case 'text':
      return (
        <FilterText
          initialValue={label}
          label={label}
          onChange={handleChange}
          spanClass={spanClass}
        />
      );
    default:
      return <span>{label}</span>;
  }
};

export default DTHeaderFilter;
