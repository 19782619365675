import {
  ActionTypes,
  SortDir,
  TableFilters,
  useDataTableOptions,
} from '../../../reducers/useDataTableOptions';
import { orderBy, pick } from 'lodash';
import { useEffect, useState } from 'react';

import { AgendaItem } from '../../../graphql/types';
import { DataRow } from '../../../types/DataTable';
import { dow } from '../../../helpers/dateHelpers';
import { useGetHotelAgendaQuery } from '../../meetings/gql/_gen_/agenda.gql';

export const useAgendaData = (headers: string[], brandCode?: string) => {
  const defaultOptions = {
    sort: { key: 'name', dir: SortDir.ASC },
  };

  const PER_PAGE = 20;
  const [data, setData] = useState<DataRow[]>();
  const [options, setOptions] = useDataTableOptions(defaultOptions);
  const [page, setPage] = useState<number>(0);
  const [tableFilters, setTableFilters] = useState<TableFilters>({});
  const [totalItems, setTotalItems] = useState(0);
  const [totalFiltered, setTotalFiltered] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const goToPage = (targetPage: number) => {
    if (targetPage <= totalPages - 1 && targetPage >= 0) {
      setPage(targetPage);
    }
  };

  const filterItems = (item: AgendaItem) => {
    return (
      filterDays(item, 'createdAt') &&
      filterDays(item, 'updatedAt') &&
      filterString(item, 'completed') &&
      filterString(item, 'createdById') &&
      filterString(item, 'id') &&
      filterString(item, 'isDefault') &&
      filterString(item, 'name') &&
      filterString(item, 'notes') &&
      filterString(item, 'order')
    );
  };

  const filterDays = (item: AgendaItem, attr: keyof AgendaItem) => {
    const filter = tableFilters[attr];
    if (filter) {
      const dowKey = dow(
        item[attr] as string
      ).toLowerCase() as keyof typeof filter;
      return filter[dowKey] === true;
    }
    return true;
  };

  const filterString = (item: AgendaItem, attr: keyof AgendaItem) => {
    const filter = tableFilters[attr];
    if (filter) {
      return String(item[attr])
        .toLowerCase()
        .includes(String(filter).toLowerCase());
    }
    return true;
  };

  const resetTable = () => {
    setPage(0);
    setTableFilters({});
    setOptions({
      type: ActionTypes.SORT,
      payload: defaultOptions,
    });
  };

  function pageUp() {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  }

  function pageDown() {
    if (page >= 1) {
      setPage(page - 1);
    }
  }

  const { data: agendaData, loading } = useGetHotelAgendaQuery({
    skip: !brandCode,
    variables: {
      brandCode: brandCode!,
    },
  });

  useEffect(() => {
    resetTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCode]);

  useEffect(() => {
    if (!loading && agendaData && agendaData.getHotelAgenda) {
      const agendas = agendaData.getHotelAgenda;
      const orderedData = orderBy(
        agendas,
        [options.sort.key],
        [options.sort.dir]
      );
      const filteredData = orderedData.filter((item) =>
        filterItems(item as AgendaItem)
      );
      const rows = filteredData.map((item) => {
        return pick(item, ['id', ...headers]);
      });
      setData(
        rows.slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE) as DataRow[]
      );
      setTotalPages(Math.round(rows.length / PER_PAGE));
      setTotalItems(agendas.length || 0);
      setTotalFiltered(filteredData.length || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaData, loading, options.sort, page, tableFilters]);

  return {
    data,
    goToPage,
    loading,
    options,
    page,
    pageDown,
    pageUp,
    setOptions,
    setTableFilters,
    tableFilters,
    totalFiltered,
    totalItems,
    totalPages,
  };
};
