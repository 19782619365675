export const paramOptions = {
  bar: [
    { label: 'Best Flex', value: 'true' },
    { label: 'Lowest', value: 'false' },
  ],
  changeDays: [
    { label: '1', value: 1 },
    { label: '3', value: 3 },
    { label: '7', value: 7 },
  ],
  los: [
    { label: '1', value: 1 },
    { label: '3', value: 3 },
    { label: '7', value: 7 },
  ],
  ota: [
    { label: 'Branded', value: 'branddotcom' },
    { label: 'Booking.com', value: 'bookingdotcom' },
    { label: 'Expedia', value: 'expedia' },
    { label: 'GDS', value: 'gds' },
    { label: 'HRS', value: 'hrs' },
  ],
  roomType: [
    { label: 'All Types', value: 'all' },
    { label: 'Standard', value: 'standard' },
    { label: 'Single', value: 'single' },
    { label: 'Budget', value: 'budget' },
    { label: 'Family', value: 'family' },
    { label: 'Suite', value: 'suite' },
    { label: 'Premium', value: 'premium' },
    { label: 'Dorm', value: 'dormitory' },
    { label: 'Shared Bath', value: 'sharedbathroom' },
    { label: 'Apartment', value: 'apartment' },
    { label: 'Park & Fly', value: 'parkandfly' },
    { label: 'Camper', value: 'camper' },
    { label: 'Villa', value: 'villa' },
    { label: 'Bungalow', value: 'bungalow' },
  ],
  shopLength: [
    { label: '90 Days', value: 90 },
    { label: '120 Days', value: 120 },
    { label: '180 Days', value: 180 },
    { label: '365 Days', value: 365 },
  ],
};
