import { AgendasDataTable } from './components/agendas-data-table';
import DataTableContainer from '../../dashboards/DataTableContainer';

export const AgendasSection = () => {
  return (
    <DataTableContainer>
      <h1>Agendas</h1>
      <AgendasDataTable />
    </DataTableContainer>
  );
};
