import { CgToolbarBottom } from 'react-icons/cg';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { FaSuperscript } from 'react-icons/fa';
import { Popover } from '@headlessui/react';
import { Switch } from '@headlessui/react';
import { ToggleSwitchProps } from '../types/DataGrid';
import { usePopper } from 'react-popper';
import { useState } from 'react';

export default function ToggleSwitch({
  enabled,
  toolbar,
  extension,
  fetchData,
  label,
  loading,
  popVertOffset = 10,
  setEnabled,
  setSuperscript,
  setToolbar,
  superscript = false,
}: ToggleSwitchProps) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [popVertOffset, 3],
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
    ],
  });

  const handleChange = (v: boolean) => {
    if (setEnabled) {
      setEnabled(v);
      // Only fetch data if the toggle is being enabled
      v && fetchData !== undefined && fetchData();
    }
  };

  const handleToolbarChange = (v: boolean) => {
    if (setToolbar !== undefined) {
      setToolbar(v);
    }
  };

  return (
    <Switch.Group>
      <li className='col-span-1 flex shadow-sm rounded-md'>
        <div className='flex-shrink-0 flex items-center justify-center w-10 text-white text-sm font-medium rounded-l-md'>
          <Switch
            checked={enabled}
            onChange={handleChange}
            className={`${
              enabled
                ? String(label).includes('Reset Settings')
                  ? 'bg-red-600 bg-opacity-75'
                  : 'bg-blue-600'
                : 'bg-green-100'
            } relative inline-flex items-center h-10 rounded-l-md w-10`}
          />
        </div>
        <div className='flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate'>
          <div className='flex-1 px-4 py-2 text-sm truncate'>
            <Switch.Label className='ml-2'>
              {loading ? 'Fetching...' : label}
            </Switch.Label>
          </div>
          {extension && (
            <div>
              <Popover>
                {String(label).toLowerCase().includes('forecast') && (
                  <button
                    onClick={() =>
                      setSuperscript && setSuperscript(!superscript)
                    }
                    className={`${
                      superscript
                        ? 'flex-shrink-0 w-4 h-4 mr-2 bg-white inline-flex items-center justify-center text-red-400 rounded-full bg-transparent hover:text-red-500 focus:outline-none'
                        : 'flex-shrink-0 w-4 h-4 mr-2 bg-white inline-flex items-center justify-center text-green-400 rounded-full bg-transparent hover:text-green-500 focus:outline-none'
                    }`}
                    title={`${
                      superscript
                        ? `Hide BestREV superscripts on forecasts`
                        : `Show BestREV superscripts on forecasts`
                    }`}
                  >
                    <FaSuperscript className='w-4 h-4' aria-hidden='true' />
                  </button>
                )}
                <button
                  onClick={() => handleToolbarChange(!toolbar)}
                  className={`${
                    toolbar
                      ? 'flex-shrink-0 w-4 h-4 bg-white inline-flex items-center justify-center text-blue-700 rounded-full bg-transparent focus:outline-none'
                      : 'flex-shrink-0 w-4 h-4 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-black focus:outline-none'
                  }`}
                  title={`${
                    toolbar
                      ? `Remove the ${label} quick toggle from the toolbar`
                      : `Add a ${label} quick toggle to the toolbar`
                  }`}
                >
                  <CgToolbarBottom className='w-4 h-4' aria-hidden='true' />
                </button>
                <Popover.Button
                  ref={setReferenceElement}
                  className='flex-shrink-0 w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none'
                >
                  <span className='sr-only'>Open options</span>
                  <DotsVerticalIcon className='w-5 h-5' aria-hidden='true' />
                </Popover.Button>
                <Popover.Panel
                  ref={setPopperElement}
                  style={{ ...styles.popper, zIndex: 9999 }}
                  {...attributes.popper}
                  className='bg-gray-100 overflow-hidden shadow rounded-lg p-2'
                >
                  <div className='text-xs truncate font-bold'>
                    <p>{label}</p>
                  </div>
                  {extension}
                  <div ref={setArrowElement} style={styles.arrow} />
                </Popover.Panel>
              </Popover>
            </div>
          )}
        </div>
      </li>
    </Switch.Group>
  );
}
