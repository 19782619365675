import DataTableContainer from '../DataTableContainer';
import { EventManagement } from '@/components/events/event-management';
import EventTable from '../tables/event/EventTable';
import LoadingSpinner from '@/components/loading/loading-spinner';
import { useFlags } from '@/context/feature-flags';

function Events() {
  // TODO - Remove feature flag when no longer needed
  const { flags } = useFlags();

  if (!flags) {
    return <LoadingSpinner text='Events' />;
  }

  const eventTableFlag = flags['event-table-upgrade'];

  if (eventTableFlag) {
    return <EventManagement />;
  } else {
    return (
      <DataTableContainer>
        <EventTable />
      </DataTableContainer>
    );
  }
}

export default Events;
