import { CREATE_NOTE, UPDATE_NOTE } from '../gql/gqlNote';

import { useMutation } from '@apollo/client';

export function useCreateNote() {
  return useMutation(CREATE_NOTE);
}

export function useUpdateNote() {
  return useMutation(UPDATE_NOTE);
}
