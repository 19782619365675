import DataTableContainer from '../DataTableContainer';
import MeetingsTable from '../tables/meetings/meetings-table';

function Meetings() {
  return (
    <DataTableContainer>
      <MeetingsTable />
    </DataTableContainer>
  );
}

export default Meetings;
