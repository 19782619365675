import { useEffect, useRef } from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ComponentProps } from '../types/DataTable';
import DTCoreButtons from './data_table/DTCoreButtons';
import DTDateRange from './data_table/DTDateRange';
import DTForm from './data_table/DTForm';
import DTHeaderRow from './data_table/DTHeaderRow';
import DTRow from './data_table/DTRow';
import { Event } from '../types/Event';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { useBulkCheckboxes } from '../reducers/useBulkCheckboxes';

const HIDE_CORE_BUTTONS = ['Event', 'Tracking'];

const DataTable = ({
  actions,
  dataRange,
  fields,
  handleCreate,
  name,
  options,
  rows = [],
  setOptions,
  setTableFilters,
  tableFilters,
  validation,
}: ComponentProps) => {
  const initIds = useRef<string[]>(rows.map((row) => row.id) || []);
  const [checked, setChecked] = useBulkCheckboxes(initIds.current);

  useEffect(() => {
    if (
      !_.isEqual(
        initIds.current,
        rows.map((row) => row.id)
      )
    ) {
      initIds.current = rows.map((row) => row.id);
      setChecked({ key: 'reset', type: 'RESET_CHECKS', ids: initIds.current });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className='px-2'>
      <div className='pt-2 bg-white shadow rounded'>
        <div className='px-2 border-b'>
          <div className='flex flex-row mb-4'>
            <div
              className={HIDE_CORE_BUTTONS.includes(name) ? 'hidden' : 'ml-2'}
            >
              {actions ? (
                <DTCoreButtons actions={actions} fields={fields} />
              ) : null}
            </div>
            <div
              className={HIDE_CORE_BUTTONS.includes(name) ? 'hidden' : 'ml-2'}
            >
              <DTDateRange
                tableFilters={tableFilters}
                setTableFilters={setTableFilters}
              />
            </div>
            <div className='ml-2'>
              {handleCreate ? (
                <DTForm
                  fields={fields}
                  handleCreate={handleCreate}
                  modelName={name}
                  validation={validation}
                  events={rows as Event[]}
                />
              ) : null}
            </div>
            {actions ? (
              <span className='relative inline-flex'>
                {actions.networkStatus < 7 && (
                  <AiOutlineLoading3Quarters className='animate-spin ml-2 text-blue-900' />
                )}
              </span>
            ) : null}
          </div>
        </div>
        <div>
          <table className='table-auto w-full'>
            <DTHeaderRow
              actions={actions}
              dataRange={dataRange}
              checked={checked}
              fields={fields}
              options={options}
              setChecked={setChecked}
              setOptions={setOptions}
              setTableFilters={setTableFilters}
              tableFilters={tableFilters}
              tableName={name}
            />
            <tbody>
              {rows.map((row, idx) => {
                return (
                  <DTRow
                    actions={actions}
                    checked={checked}
                    fields={fields}
                    key={`row-${idx}`}
                    idx={idx}
                    row={row}
                    setChecked={setChecked}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
