import { Meeting, TeamMember } from '../../../graphql/types';
import {
  namedOperations,
  useLogAttendeeMutation,
  useUnlogAttendeeMutation,
} from '../gql/_gen_/meeting.gql';

import { useEffect } from 'react';
import { useMeeting } from '../context/meeting-context';
import { useSaveMeeting } from './use-save-meeting';

export const useToggleAttend = (meeting?: Meeting) => {
  const { handleSaveMeeting } = useSaveMeeting(meeting);
  const { loading: meetingLoading, refetchMeetingByDate } = useMeeting();
  const [logAttendee, { data: dataAttend, loading: logLoading }] =
    useLogAttendeeMutation({
      refetchQueries: [namedOperations.Query.MeetingTools],
    });
  const [unlogAttendee, { data: dataUnattend, loading: unlogLoading }] =
    useUnlogAttendeeMutation({
      refetchQueries: [namedOperations.Query.MeetingTools],
    });

  const loading = logLoading || unlogLoading || meetingLoading;

  useEffect(() => {
    if (dataAttend || dataUnattend) {
      refetchMeetingByDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const toggleAttend = async (item: TeamMember) => {
    if (meeting && meeting.id && meeting.attendees && item.id) {
      if (meeting.attendees.includes(item.id)) {
        await unlogAttendee({
          variables: {
            meetingId: meeting.id,
            attendeeId: item.id,
          },
        });
      } else {
        await logAttendee({
          variables: {
            meetingId: meeting.id,
            attendeeId: item.id,
          },
        });
      }
    } else if (!meeting?.id && item.id) {
      await handleSaveMeeting({
        attendees: [item.id],
      });
    }
  };

  return { loading, toggleAttend };
};
