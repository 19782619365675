/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
export type ActionItemFieldsFragment = { __typename?: 'ActionItem', assignedToId?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, dueDate?: string | null, id?: string | null, name?: string | null, status?: Types.ActionItemStatus | null, statusUpdated?: string | null };

export const ActionItemFieldsFragmentDoc = gql`
    fragment ActionItemFields on ActionItem {
  assignedToId
  brandCode
  createdAt
  createdById
  dueDate
  id
  name
  status
  statusUpdated
}
    `;
export const namedOperations = {
  Fragment: {
    ActionItemFields: 'ActionItemFields'
  }
}