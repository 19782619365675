export const tableCols = [
  {
    dataSource: 'rmData',
    key: 'stay_date',
  },
  {
    dataSource: 'rmData',
    key: 'dow',
  },
  {
    dataSource: 'rmData',
    key: 'sold',
  },
  {
    dataSource: 'liveData',
    key: 'rt_rooms_committed',
  },
  {
    dataSource: 'rmData',
    key: 'sold_ly',
  },
  {
    dataSource: 'rmData',
    key: 'remaining',
  },
  {
    dataSource: 'rmData',
    key: 'group_sold',
  },
  {
    dataSource: 'rmData',
    key: 'group_blocked',
  },
  {
    dataSource: 'rmData',
    key: 'occ',
  },
  {
    dataSource: 'rmData',
    key: 'delta_1day',
  },
  {
    dataSource: 'rmData',
    key: 'delta_7day',
  },
  {
    dataSource: 'rmData',
    key: 'sold_ly_final',
  },
  {
    dataSource: 'rmData',
    key: 'revpar',
    header: 'RevPAR',
  },
  {
    dataSource: 'rmData',
    key: 'revenue',
    header: 'Revenue',
  },
  {
    dataSource: 'rmData',
    key: 'adr',
  },
  {
    dataSource: 'rmData',
    key: 'adr_ly',
  },
  {
    dataSource: 'currentRateTable',
    key: 'current_rate',
  },
  {
    dataSource: 'bestRevData',
    key: 'recommended_rate',
  },
  {
    dataSource: 'forecastData',
    key: 'forecast_sold_1',
  },
  {
    dataSource: 'forecastData',
    key: 'forecast_adr_1',
  },
  {
    dataSource: 'forecastData',
    key: 'forecast_revenue_1',
  },
  {
    dataSource: 'rmData',
    key: 'lvl_a_status',
  },
  {
    dataSource: 'rmData',
    key: 'lvl_b_status',
  },
  {
    dataSource: 'rmData',
    key: 'lvl_c_status',
  },
  {
    dataSource: 'rmData',
    key: 'lvl_d_status',
  },
  {
    dataSource: 'rmData',
    key: 'lvl_e_status',
  },
  {
    dataSource: 'rmData',
    key: 'lvl_f_status',
  },
  {
    dataSource: 'rmData',
    key: 'lvl_g_status',
  },
  {
    dataSource: 'rmData',
    key: 'lvl_h_status',
  },
];
