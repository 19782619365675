import {
  CLEAR_COL_RM_RECS,
  CREATE_RM_REC,
  DATA_TABLE_RM_RECS,
  FIND_RM_RECS,
  FIND_RM_RECS_UPLOAD,
  RATE_UPLOAD,
  RESET_RM_RECS,
  UPDATE_RM_REC,
  UPSERT_RM_RECS,
} from '../gql/gqlRmRecs';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { FindRmRecArgs } from '../types/__generated__/globalTypes';
import { RmRec } from '../graphql/types';

type TableFindRmRecArgs = {
  filters: {
    hotelId?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    limit?: number | null;
    offset?: number | null;
  };
};

type UseFindArgs = {
  filters?: FindRmRecArgs;
};

export function useCreateRmRec(props: { callback: () => void }) {
  return useMutation(CREATE_RM_REC, {
    update(cache, { data: { createRmRec } }) {
      cache.modify({
        fields: {
          hotelRmRecs(existingRmRecs = [] as any[]) {
            const hotelRecs = existingRmRecs.filter(
              (rec: RmRec) => rec.hotel_id === createRmRec.hotel_id
            );
            const newRmRecRef = cache.writeFragment({
              data: createRmRec,
              fragment: gql`
                fragment NewRmRec on RmRec {
                  id
                  notes
                  rate
                  status
                  stay_date
                }
              `,
            });
            return [...hotelRecs, newRmRecRef];
          },
        },
      });
    },
    onCompleted: () => props.callback(),
  });
}

export function useUpsertRmRecs(refetchQueriesList?: string[]) {
  return useMutation(UPSERT_RM_RECS, {
    ...(refetchQueriesList && { refetchQueries: refetchQueriesList }),
  });
}

export const useDataTable = ({ filters }: TableFindRmRecArgs) => {
  return useQuery(DATA_TABLE_RM_RECS, {
    variables: {
      filters,
    },
    skip: !filters.hotelId,
  });
};

export function useFindRmRecs(find: UseFindArgs) {
  const { filters } = find;
  return useQuery(FIND_RM_RECS, {
    fetchPolicy: 'network-only',
    variables: {
      filters,
    },
    skip: !filters || !filters.hotelId,
  });
}

export function useFindRmRecsUpload(find: UseFindArgs) {
  const { filters } = find;
  return useLazyQuery(FIND_RM_RECS_UPLOAD, {
    variables: {
      filters,
    },
  });
}

export function useRateUpload() {
  return useMutation(RATE_UPLOAD);
}

export function useClearColumnRmRecs(refetchQueriesList?: string[]) {
  return useMutation(CLEAR_COL_RM_RECS, {
    ...(refetchQueriesList && { refetchQueries: refetchQueriesList }),
  });
}

export function useResetRmRecs(refetchQueriesList?: string[]) {
  return useMutation(RESET_RM_RECS, {
    ...(refetchQueriesList && { refetchQueries: refetchQueriesList }),
  });
}

export function useUpdateRmRec(props: { callback: () => void }) {
  return useMutation(UPDATE_RM_REC, {
    update(cache, { data: { updateRmRec } }) {
      cache.modify({
        fields: {
          hotelRmRecs(existingRmRecs = [] as any[]) {
            const hotelRecs = existingRmRecs.filter((rec: RmRec) => {
              return (
                rec.hotel_id === updateRmRec.hotel_id &&
                rec.id !== updateRmRec.id
              );
            });
            return [...hotelRecs, updateRmRec];
          },
        },
      });
    },
    onCompleted: () => props.callback(),
  });
}
