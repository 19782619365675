import { ColTogSub, ColumnToggles } from '../../types/DataGrid';

import { ColumnParams } from '../../types/Columns';
import { HeaderObject } from '../../features/overview-table/hooks/use-table';
import _ from 'lodash';

type HelperArgs = {
  dataKey: string;
  fallbackColor: string;
  hiddenCols: string[];
  isCtrl: boolean;
  isTarget: boolean;
  colToggles: ColumnToggles;
};

export const defaultWidth = 55;
export const rowHeight = 25;
export const rowScroll = rowHeight;

export const bgColor = (args: HelperArgs) => {
  const { dataKey, fallbackColor, hiddenCols, isCtrl, isTarget, colToggles } =
    args;

  const subCols = colToggles[dataKey];

  if (isTarget) {
    const ctrl = _.findKey(colToggles, (t) => t.targets.includes(dataKey));
    const ctrlCols = colToggles[ctrl as keyof typeof colToggles];
    return ctrlCols?.colors?.target || 'bg-blue-100';
  } else if (isCtrl && !targetsHidden(subCols, hiddenCols)) {
    return subCols?.colors?.control || 'bg-blue-200';
  } else {
    return fallbackColor;
  }
};

export const calcLeft = (
  headers: ColumnParams[] | HeaderObject[],
  colIdx: number
): number => {
  let left = 0;
  for (let i = 0; i < colIdx; i++) {
    left += headers[i].columnSize || defaultWidth;
  }
  return left;
};

export const targetsHidden = (subCols: ColTogSub, hiddenCols: string[]) => {
  return _.difference(subCols?.targets, hiddenCols).length === 0;
};
