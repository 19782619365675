import {
  Maybe,
  Meeting,
  TeamMember,
  TeamMemberActive,
} from '../../../graphql/types';
import { useEffect, useState } from 'react';

import { FiUserPlus } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { clsx } from 'clsx';
import gravatar from 'gravatar';
import { useIsShared } from '../../reports/hooks/use-report-location';
import { useToggleAttend } from '../hooks/use-toggle-attend';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';

type TeamListItemProps = {
  data: Maybe<TeamMember>;
  isTool?: boolean;
  meeting?: Meeting;
  toggleActive: (member: TeamMember) => Promise<void>;
  setExpanded: React.Dispatch<
    React.SetStateAction<Maybe<TeamMember> | undefined>
  >;
};

export const TeamListItem = ({
  data,
  isTool,
  meeting,
  toggleActive,
  setExpanded,
}: TeamListItemProps) => {
  const { isShared } = useIsShared();
  const { loading, toggleAttend } = useToggleAttend(meeting);
  const [attended, setAttended] = useState(false);
  const track = useTrackMeetingEvent({ meeting, page: 'team_list_item' });

  useEffect(() => {
    if (meeting?.attendees && data?.id && !loading) {
      const isAttended = meeting.attendees.includes(data.id);
      if (isAttended !== attended) {
        setAttended(isAttended);
      }
    }
  }, [data, meeting?.attendees, attended, loading]);

  const handleAttendChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (data) {
      setAttended(e.target.checked);
      toggleAttend(data);
      track('toggle_attended', `teamId_${data.id}_${e.target.checked}`);
    }
  };

  if (!data) return null;

  return (
    <div
      key={data.id}
      className={clsx(
        'relative flex items-center text-sm justify-between gap-2 rounded-lg border border-gray-300 bg-white px-3 py-2 shadow-sm hover:border-gray-400',
        loading ? 'bg-sky-600/60' : '',
        isTool ? 'flex-wrap' : ''
      )}
    >
      <div className='flex items-center flex-grow'>
        <div className='flex-shrink-0 mr-2'>
          <img
            alt=''
            src={gravatar.url(data.email || '', { size: '100', d: 'mp' })}
            className='h-5 w-5 rounded-full'
          />
        </div>
        <div className='flex-grow'>
          <button
            onClick={() => setExpanded(data)}
            disabled={isShared || loading}
            className={clsx(
              'text-left',
              attended
                ? `text-blue-800 font-extrabold focus:outline-none decoration-2 decoration-gray-400`
                : `hover:underline focus:outline-none`
            )}
          >
            {data.name}
          </button>
          {isTool ? (
            <span className='text-blue-500 ml-2 text-xs'>{data.emailPref}</span>
          ) : null}
        </div>
      </div>

      {isTool && (
        <div className='w-full mt-1'>
          <p className='text-gray-600 text-xs truncate font-mono'>
            {data?.email}
          </p>
        </div>
      )}

      <div className='flex items-center'>
        {isTool && data?.active && (
          <button
            className={clsx(
              data.active === TeamMemberActive.Active
                ? 'text-red-500'
                : 'text-green-500',
              'mr-2'
            )}
            disabled={isShared || loading}
            onClick={() => toggleActive(data)}
          >
            {data.active === TeamMemberActive.Active ? (
              <MdOutlineDeleteOutline />
            ) : (
              <FiUserPlus />
            )}
          </button>
        )}
        <input
          type='checkbox'
          checked={attended}
          disabled={isShared || loading}
          onChange={handleAttendChange}
        />
      </div>
    </div>
  );
};
