import { gql } from '@apollo/client';

export const DAILY_HOTEL_NOTES = gql`
  query HotelNotes($filters: FindNotesArgs!) {
    hotelNotes(filters: $filters) {
      stay_date
      note_ty {
        content
        stay_date
        hotel_id
        id
      }
      note_ly {
        content
        hotel_id
        id
        stay_date
      }
    }
  }
`;
