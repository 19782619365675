import {
  CREATE_EVENT,
  DELETE_EVENT,
  DELETE_EVENTS,
  EVENT_TABLE,
  UPDATE_EVENT,
} from '../gql/gqlEvent';
import { useMutation, useQuery } from '@apollo/client';

export type UseHookArgs = {
  filters: {
    endDate: string;
    hotelId?: string | null;
    startDate: string;
    limit?: number;
    offset?: number;
  };
};

export function useEventTable(a: UseHookArgs) {
  const { filters } = a;
  return useQuery(EVENT_TABLE, {
    variables: {
      filters,
    },
    skip: !filters.hotelId,
  });
}

export function useCreateEvent(props: { callback: () => void }) {
  return useMutation(CREATE_EVENT, {
    refetchQueries: ['DataTableEvents'],
    onCompleted: () => props.callback(),
  });
}

export function useDeleteEvent() {
  return useMutation(DELETE_EVENT, {
    update(cache, { data: { deleteEvent } }) {
      cache.modify({
        fields: {
          hotelEvents(existingEvents = [] as any[], { readField }) {
            return existingEvents.filter((eventRef: any) => {
              return String(deleteEvent) !== readField('id', eventRef);
            });
          },
        },
      });
    },
  });
}

export function useDeleteEvents() {
  return useMutation(DELETE_EVENTS, {
    update(cache, { data: { deleteEvents } }) {
      cache.modify({
        fields: {
          hotelEvents(existingEvents = [] as any[], { readField }) {
            return existingEvents.filter((eventRef: any) => {
              return !deleteEvents.includes(readField('id', eventRef));
            });
          },
        },
      });
    },
  });
}

export function useUpdateEvent() {
  return useMutation(UPDATE_EVENT, {
    refetchQueries: ['DataTableEvents'],
  });
}
