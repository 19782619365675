import { AgendaItem } from '../../../graphql/types';
import { BiEdit } from 'react-icons/bi';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { clsx } from 'clsx';
import { toast } from 'react-toastify';
import { useAddMeetingMutation } from '../gql/_gen_/meeting.gql';
import { useState } from 'react';
import { useUpdateAgendaItemMutation } from '../gql/_gen_/agenda.gql';
import { useUpdateAgendaItemNotesMutation } from '../gql/_gen_/meeting.gql';

type ExpandedProps = {
  expanded?: AgendaItem | null;
  handleDelete: Function;
  setExpanded: Function;
  meetingId?: string | null;
  itemNotes?: string | null;
};

const ExpandedAgendaItem = ({
  expanded,
  handleDelete,
  setExpanded,
  meetingId,
  itemNotes,
}: ExpandedProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [notes, setNotes] = useState(itemNotes);
  const [name, setName] = useState(expanded?.name);

  const [updateAgendaItem] = useUpdateAgendaItemMutation();
  const [updateItemNotes] = useUpdateAgendaItemNotesMutation();
  const [saveMeeting] = useAddMeetingMutation();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!editMode) {
      setEditMode(true);
    }
    setName(e.target.value);
  };

  const handleSave = async () => {
    if (expanded?.id && expanded?.brandCode) {
      if (name !== expanded.name) {
        await updateAgendaItem({
          variables: {
            updateAgendaItemId: expanded.id,
            name,
          },
        });
      }

      if (meetingId) {
        if (notes !== itemNotes) {
          await updateItemNotes({
            variables: {
              meetingId,
              itemId: expanded.id,
              notes,
            },
          });
        }
      } else if (expanded?.brandCode && notes && notes !== '') {
        await saveMeeting({
          variables: {
            brandCode: expanded.brandCode,
            agendaItemNotes: [{ itemId: expanded?.id, notes }],
            createdById: expanded.createdById || '',
          },
          refetchQueries: ['GetMeetingByDate'],
        });
        toast.success('New meeting created, and agenda item notes saved', {
          position: 'top-left',
        });
      }

      setEditMode(false);
    }
  };

  const handleBack = () => {
    setExpanded(undefined);
    setEditMode(false);
  };

  return (
    <div className='my-2 flex flex-row'>
      <button onClick={() => handleBack()}>
        <MdOutlineArrowBackIosNew className='h-full' />
      </button>
      <div className='flex flex-col ml-2 px-2 w-full'>
        <div className='flex justify-between'>
          {!expanded?.isDefault && editMode ? (
            <input
              type='text'
              value={name || ''}
              className='w-[90%] border border-blue-600 rounded-md text-gray-800 text-sm font-semibold p-1'
              onChange={(e) => handleNameChange(e)}
            />
          ) : (
            <h2
              className={clsx(
                {
                  'text-gray-500 line-through focus:outline-none decoration-2 decoration-gray-400':
                    expanded?.completed,
                },
                { 'text-gray-800 ml-1': !expanded?.completed },
                `text-sm font-semibold`
              )}
            >
              {expanded?.name}
            </h2>
          )}
          <button onClick={() => setEditMode(true)} className='ml-2'>
            <BiEdit />
          </button>
        </div>
        {editMode ? (
          <textarea
            rows={5}
            value={notes || ''}
            onChange={(e) => setNotes(e.target.value)}
            className='mt-1 text-sm border-gray-300 border rounded-md p-1.5'
          />
        ) : (
          <pre>
            <p className='mt-1 text-sm text-gray-600 font-sans'>{notes}</p>
          </pre>
        )}
        {editMode && (name !== expanded?.name || notes !== itemNotes) ? (
          <div className='flex justify-center'>
            <button
              onClick={() => handleSave()}
              className='text-blue-700 hover:text-blue-900 text-sm rounded-md m-2'
            >
              Save
            </button>
            <button
              onClick={() => setEditMode(false)}
              className='text-red-500 hover:text-red-700 text-sm rounded-md m-2 p-1'
            >
              Cancel
            </button>
          </div>
        ) : null}
        {expanded?.createdById && (
          <p className='text-xs text-gray-500 mt-3'>
            Added by: {expanded.createdById}
          </p>
        )}
        {expanded?.isDefault === false && (
          <button
            onClick={() => handleDelete(expanded.id)}
            className='border rounded-lg p-1 mt-4 text-sm w-full text-gray-700 hover:text-red-500 hover:border-red-500'
          >
            Remove Item
          </button>
        )}
      </div>
    </div>
  );
};

export { ExpandedAgendaItem };
