import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';

type NavButtonProps = {
  icon: IconType;
  href?: string;
  isActive?: boolean;
  title?: string;
};

function NavButton({ href = '/', icon, isActive, title = '' }: NavButtonProps) {
  const Icon = icon;
  let linkClass = 'flex mr-10 items-center text-sm';
  linkClass = isActive ? `${linkClass} text-blue-300` : linkClass;

  return (
    <li className='text-gray-50 hover:text-gray-300'>
      <Link className={linkClass} to={href}>
        <Icon className='w-5 h-5 mr-2' />
        <span>{title}</span>
      </Link>
    </li>
  );
}

export default NavButton;
