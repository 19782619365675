import {
  BestrevData,
  DailyRates,
  OtaiRate,
  RmData,
  Room,
} from '../../../graphql/types';
import {
  HeatRoomDataQuery,
  useHeatMapBestRevQuery,
  useHeatMapRmDataQuery,
  useHeatRoomDataQuery,
} from '../gql/_gen_/heat-map.gql';
import {
  OtaiLowestRatesQuery,
  useOtaiLowestRatesQuery,
} from '../../overview-table/gql/_gen_/rate-shop.gql';
import { useEffect, useMemo, useState } from 'react';

import Highcharts from 'highcharts/highstock';
import _ from 'lodash';
import { defaultSubId } from '../../../reducers/useRateShopParams';
import { heatMap } from '../../../reports/heat';
import { useHotel } from '../../../context/hotelContext';
import { usePortfolioWidgetQuery } from '.././gql/_gen_/portfolio.gql';

interface UseHeatMapProps {
  dataKey: string;
  brandCode: string;
  snapshotDate: string;
  startDate: string;
  endDate: string;
  dataSource: string;
  roomType?: string;
  compId?: string;
  handleRoomOptions: (data?: HeatRoomDataQuery) => void;
  handleRateShopOptions: (data?: OtaiLowestRatesQuery) => void;
}

export const useHeatMap = ({
  brandCode,
  dataKey,
  snapshotDate,
  startDate,
  endDate,
  dataSource,
  roomType,
  compId,
  handleRoomOptions,
  handleRateShopOptions,
}: UseHeatMapProps) => {
  const [options, setOptions] = useState<Highcharts.Options>();
  const { otaiSubs } = useHotel();

  const { data: heatRmData, loading: rmDataLoading } = useHeatMapRmDataQuery({
    skip: !brandCode,
    variables: {
      args: {
        brandCode,
        snapshotDate,
        startDate,
        endDate,
      },
    },
  });

  const { data: heatBestRevData, loading: bestRevDataLoading } =
    useHeatMapBestRevQuery({
      skip: !brandCode,
      variables: {
        brandCode,
        useCache: true,
      },
    });

  const { data: heatRoomData, loading: roomDataLoading } = useHeatRoomDataQuery(
    {
      skip: !brandCode,
      variables: {
        brandCode,
        snapshotDate,
        startDate,
        endDate,
      },
      onCompleted: (data) => handleRoomOptions(data),
    }
  );

  const { data: heatRateData, loading: rateShopDataLoading } =
    useOtaiLowestRatesQuery({
      skip: !brandCode || !otaiSubs,
      variables: {
        params: {
          subscriptionId: defaultSubId(otaiSubs) as string,
          los: 1,
          bar: true,
          ota: 'branddotcom',
          roomType: 'all',
          shopLength: 365,
          changeDays: [1, 3, 7],
        },
        brandCode: brandCode || '',
      },
      onCompleted: (data) => handleRateShopOptions(data),
    });

  const { data: heatLiveData, loading: liveDataLoading } =
    usePortfolioWidgetQuery({
      skip: !brandCode,
      variables: {
        args: {
          brandCode,
          snapshotDate,
          startDate,
          endDate,
        },
        brandCode,
        startDate,
        endDate,
      },
    });

  const getCompetitorData = (
    data: DailyRates[],
    compId: string,
    dataKey: string
  ) => {
    if (data) {
      switch (dataKey) {
        case 'rate':
          return data?.map((date: DailyRates) => {
            return {
              stay_date: date?.stay_date,
              rate: date?.rates?.filter((r) => r?.competitor_id === compId)?.[0]
                ?.rate,
            };
          });
        case 'rate_change_1_day':
          return data?.map((date: DailyRates) => {
            const rate = _.find(
              date?.rates,
              (r) => r?.competitor_id === compId
            ) as OtaiRate;

            const rateChange = _.find(rate?.changes, (c) => c?.days === 1)
              ?.change?.value;

            return {
              stay_date: date?.stay_date,
              rate_change_1_day:
                rateChange && rate.rate
                  ? Math.round(rate?.rate - rateChange)
                  : 0,
            };
          });
        case 'rate_change_3_day':
          return data?.map((date: DailyRates) => {
            const rate = _.find(
              date?.rates,
              (r) => r?.competitor_id === compId
            ) as OtaiRate;

            const rateChange = _.find(rate?.changes, (c) => c?.days === 3)
              ?.change?.value;

            return {
              stay_date: date?.stay_date,
              rate_change_3_day:
                rateChange && rate.rate
                  ? Math.round(rate?.rate - rateChange)
                  : 0,
            };
          });
        case 'rate_change_7_day':
          return data?.map((date: DailyRates) => {
            const rate = _.find(
              date?.rates,
              (r) => r?.competitor_id === compId
            ) as OtaiRate;

            const rateChange = _.find(rate?.changes, (c) => c?.days === 7)
              ?.change?.value;

            return {
              stay_date: date?.stay_date,
              rate_change_7_day:
                rateChange && rate.rate
                  ? Math.round(rate?.rate - rateChange)
                  : 0,
            };
          });
        default:
          return [];
      }
    }
  };

  useEffect(() => {
    if (dataSource === 'bestrevData') {
      const newOptions = heatMap(
        (heatBestRevData?.bestrevData || []) as BestrevData[],
        dataKey
      );
      setOptions(newOptions);
    } else if (dataSource === 'liveData') {
      const newOptions = heatMap(
        (heatLiveData?.liveData || []) as RmData[],
        dataKey
      );
      setOptions(newOptions);
    } else if (dataSource === 'rmData') {
      const newOptions = heatMap(
        (heatRmData?.rmData || []) as RmData[],
        dataKey
      );
      setOptions(newOptions);
    } else if (dataSource === 'roomData') {
      const roomTypeData = heatRoomData?.roomData?.filter(
        (room) => room?.room_code === roomType
      );
      const newOptions = heatMap((roomTypeData || []) as Room[], dataKey);
      setOptions(newOptions);
    } else if (dataSource === 'rate_shop') {
      const compData = getCompetitorData(
        heatRateData?.dailyRates as DailyRates[],
        compId as string,
        dataKey
      );
      if (compData?.length) {
        const newOptions = heatMap(compData as RmData[], dataKey);
        setOptions(newOptions);
      }
    }
  }, [
    brandCode,
    dataKey,
    heatRmData,
    heatBestRevData,
    heatRoomData,
    heatRateData,
    heatLiveData,
    dataSource,
    roomType,
    compId,
  ]);

  const heatMapOptions = useMemo(() => {
    return options;
  }, [options]);

  return {
    heatMapOptions,
    loading: {
      'RM Data': rmDataLoading,
      'Best Rev Data': bestRevDataLoading,
      'Rooms Data': roomDataLoading,
      'Rate Shop Data': rateShopDataLoading,
      'Live Data': liveDataLoading,
    },
  };
};
