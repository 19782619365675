import { DailyGroupData } from '../../../graphql/types';
import Table from '../../../components/Table';
import { displayDate } from '../../../helpers/dateHelpers';
import { useMemo } from 'react';

export type GroupTableProps = {
  data?: DailyGroupData[];
};

const processGroupData = (dailyGroups: DailyGroupData[]) => {
  const rows: (string | number)[][] = [];
  dailyGroups.forEach((day) => {
    day.details
      ?.filter((group) => group?.is_past === false)
      .forEach((group) => {
        const row = [
          displayDate(day.stay_date as string),
          String(group?.description),
          Number(group?.group_rooms_sold),
          Number(group?.group_rooms_remaining),
          Number(group?.group_rooms_committed),
        ];
        rows.push(row);
      });
  });
  return rows;
};

const tableData = (dailyGroups?: DailyGroupData[]) => {
  const headers = ['Date', 'Name', 'Sold', 'Remaining', 'Committed'];

  const rows = dailyGroups ? processGroupData(dailyGroups) : [];

  return {
    headers,
    rows,
    perPage: 5,
  };
};

const GroupsTable = ({ data }: GroupTableProps) => {
  const groupData = useMemo(() => {
    if (!data) {
      return;
    }
    return tableData(data);
  }, [data]);

  if (!groupData) {
    return null;
  }

  return <Table {...groupData} />;
};

export { GroupsTable };
