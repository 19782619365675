import { DATE_SEGMENT_FIELDS } from './fragments';
import { gql } from '@apollo/client';

export const CREATE_FORECAST = gql`
  mutation CreateForecast($forecast: CreateForecastInput!) {
    createForecast(forecast: $forecast) {
      created_at
      date_ym
      date_yq
      date_yw
      date_year
      fcstType
      forecast_sold_1
      forecast_adr_1
      forecast_revenue_1
      forecast_sold_2
      forecast_adr_2
      forecast_revenue_2
      hotel_id
      id
      stay_date
    }
  }
`;

export const COMBINED_HOTEL_FORECAST = gql`
  query CombinedHotelForecast(
    $hotelId: Int
    $startDate: String
    $endDate: String
    $snapshotDate: String
  ) {
    combinedHotelForecast(
      hotelId: $hotelId
      startDate: $startDate
      endDate: $endDate
      snapshotDate: $snapshotDate
    ) {
      hotel_id
      forecast_sold_1
      forecast_adr_1
      forecast_revenue_1
      forecast_sold_2
      forecast_adr_2
      forecast_revenue_2
      id
      ${DATE_SEGMENT_FIELDS}
      stay_date
      fcstType
      calcForecasts {
        bestrev {
          fcstType
          hotel_id
          forecast_sold_1
          forecast_adr_1
          forecast_revenue_1
          stay_date
        }
      }
    }
  }
`;

export const LIST_FORECASTS = gql`
  query ListForecasts($lookup: ForecastLookup!) {
    listForecasts(lookup: $lookup) {
      ${DATE_SEGMENT_FIELDS}
      stay_date
      id
      forecast_sold_1
      forecast_sold_2
      forecast_adr_1
      forecast_adr_2
      forecast_revenue_1
      forecast_revenue_2
    }
  }
`;

export const UPDATE_FORECAST = gql`
  mutation UpdateForecast(
    $updateForecastId: String!
    $forecast: UpdateForecastInput!
  ) {
    updateForecast(id: $updateForecastId, forecast: $forecast) {
      id
      fcstType
      forecast_sold_1
      forecast_adr_1
      forecast_revenue_1
      forecast_sold_2
      forecast_adr_2
      forecast_revenue_2
      hotel_id
      stay_date
      date_year
      date_ym
      date_yq
      date_yw
      updated_at
    }
  }
`;
