import { GetRateLevelStatusQuery } from '../../../gql/_gen_/rate-levels.gql';
import { TableWidget } from '../../TableWidget';
import clsx from 'clsx';
import { getCellColor } from '../../../helpers/heatMapHelper';

type Props = {
  rateLevelData: GetRateLevelStatusQuery['rateLevelStatus'];
  table: string;
  loading: boolean;
  scrollDates: Function;
};

export type RowData = {
  sold: number;
  remaining: number;
  occupancy: number;
  status: string;
  stay_date: string;
};

function RateLevelTable({ rateLevelData, table, loading, scrollDates }: Props) {
  const tableName = getTableName();
  const rateLevels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  function cellColor(cellData: RowData) {
    const { sold, remaining, occupancy, status } = cellData;
    switch (table) {
      case 'sold':
        return getCellColor(sold, status);
      case 'remaining':
        return getCellColor(remaining, status);
      case 'occupancy':
        return getCellColor(occupancy, status);
    }
  }

  //#region Format Rows
  function getTableName() {
    switch (table) {
      case 'sold':
        return 'Sold';
      case 'remaining':
        return 'Remaining';
      case 'occupancy':
        return 'Occupancy %';
      default:
        return '';
    }
  }

  function getRow(rateLevel: string, levelData: RowData[]) {
    const row = [rateLevel, ...levelData];
    return row;
  }

  let rateLevelRows = [];

  if (rateLevelData && Array.isArray(rateLevelData)) {
    const rateLevelMap = new Map();
    rateLevels.forEach((level: string) => {
      rateLevelMap.set(level, []);
    });

    rateLevelData?.forEach((dateObj: any) => {
      rateLevels.forEach((level: string) => {
        const lowerCaseLevel = level.toLowerCase();
        const levelData = {
          sold: dateObj[`lvl_${lowerCaseLevel}_sold`],
          remaining: dateObj[`lvl_${lowerCaseLevel}_remaining`],
          occupancy: Math.round(dateObj[`lvl_${lowerCaseLevel}_occ`] * 100),
          status: dateObj[`lvl_${lowerCaseLevel}_status`],
          stay_date: dateObj.stay_date,
        };

        const mapValue = rateLevelMap.get(level);
        const updatedValue = [...mapValue, levelData];
        rateLevelMap.set(level, updatedValue);
      });
    });

    for (const rateLevel of Array.from(rateLevelMap)) {
      const [level, data] = rateLevel;
      const row = getRow(level, data);
      rateLevelRows.push(row);
    }
  }
  //#endregion Format Rows

  return (
    <div
      className={`flex flex-col overflow-auto ${clsx(
        table === 'flex' ? null : 'mb-5'
      )}`}
    >
      <TableWidget
        tableName={tableName}
        cellColor={cellColor}
        data={rateLevelData}
        rowData={rateLevelRows}
        scrollDates={scrollDates}
        tableKey={table}
        loading={loading}
        key={tableName}
        page='Rate Level'
      />
    </div>
  );
}

export default RateLevelTable;
