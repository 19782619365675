import { DayFilters } from '../../reducers/useDataTableOptions';
import { useEditable } from '../../hooks/useEditable';
import { useRef } from 'react';

type Props = {
  initialValue: string | number;
  label: string;
  onChange: (value: string | number | DayFilters) => void;
  spanClass?: string;
};

const FilterText = ({
  initialValue,
  label,
  onChange,
  spanClass = '',
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { edit, handleOnBlur, handleChange, handleKeyDown, setEdit, value } =
    useEditable({ initialValue, inputRef, onChange });

  const hideInput = edit ? '' : 'hidden';

  return (
    <>
      {edit ? (
        <input
          className={`${hideInput} text-xs text-left`}
          ref={inputRef}
          type='text'
          value={value as string}
          onBlur={handleOnBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <span className={spanClass} onClick={() => setEdit(true)}>
          {label}
        </span>
      )}
    </>
  );
};

export default FilterText;
