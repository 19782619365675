import { useCallback, useEffect, useRef, useState } from 'react';

import { CellObject } from '../features/overview-table/hooks/use-table';
import { Forecast } from '../graphql/types';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { calculateForecast } from '../helpers/forecastHelpers';
import { debounce } from 'lodash';
import { isValidRate } from '../helpers/rateShopHelpers';
import { sameTimeYearsAgo } from '../helpers/dateHelpers';
import { useDashboard } from '../context/dashboardContext';
import { useGridCell } from '../hooks/useGridCell';
import { useUser } from '../context/userContext';

type Props = {
  cell: CellObject;
  columnIndex: number;
  isActiveCell: boolean;
  rowIndex: number;
};

const MAX_LENGTH = 10;

const RenderEditable = ({
  cell,
  columnIndex,
  isActiveCell,
  rowIndex,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(cell.value || '');
  const { hotel, hotelSettings, setActiveCell } = useDashboard();
  const { user } = useUser();
  const callback = useCallback(
    () =>
      debounce(
        () => {
          ReactTooltip.rebuild();
          console.count('rebuild');
        },
        500,
        {
          leading: false,
          trailing: true,
        }
      ),
    []
  );
  const { forecasts, notes, rmRecs } = useGridCell({ callback });

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.type === 'text' && inputRef.current.select();
    }
    if (edit === true) {
      setActiveCell({ row: rowIndex, col: columnIndex });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  useEffect(() => {
    setValue(cell.value || '');
  }, [cell.value]);

  useEffect(() => {
    isActiveCell && setEdit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveCell]);

  const cleanValue = (value: string | number) => {
    return typeof value === 'string' ? value.replace('$', '') : value;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    switch (key) {
      case 'ArrowDown':
        if (validateValue(value)) {
          setActiveCell({
            row: rowIndex + 1,
            col: columnIndex,
          });
          handleSave();
        }
        break;
      case 'ArrowLeft':
        if (validateValue(value)) {
          if (inputRef.current?.selectionStart === 0) {
            setActiveCell({
              row: rowIndex,
              col: columnIndex - 1,
            });
            handleSave();
          }
        }
        break;
      case 'ArrowUp':
        if (validateValue(value)) {
          setActiveCell({
            row: rowIndex - 1,
            col: columnIndex,
          });
          handleSave();
        }
        break;
      case 'ArrowRight':
        if (validateValue(value)) {
          // We only want to move cells to the right if the user
          // is at the end of the value. This prevents it from moving cells
          // if they are only using the arrow keys to navigate within the input.
          if (
            inputRef.current?.selectionEnd === inputRef.current?.value.length
          ) {
            setActiveCell({
              row: rowIndex,
              col: columnIndex + 1,
            });
            handleSave();
          }
        }
        break;
      case 'Delete':
        setValue('');
        break;
      case 'Enter':
        if (validateValue(value)) {
          setActiveCell({
            row: rowIndex + 1,
            col: columnIndex,
          });
          handleSave();
        }
        break;
      case 'Escape':
        setEdit(false);
        setValue(cell.value as string);
        break;
      case 'Tab':
        if (validateValue(value)) {
          setActiveCell({
            row: rowIndex,
            col: columnIndex + 1,
          });
          handleSave();
        }
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    setEdit(false);
    if (valuesAreNew()) {
      console.log('saving new value', cleanValue(value));
      switch (true) {
        case cell.dataKey.includes('rm_recs.'):
          if (cell.meta?.id) {
            // update existing rm_recs
            rmRecs.update[0]({
              variables: {
                updateRmRecId: cell.meta.id,
                rmRec: {
                  [cell.dataKey.split('.')[1]]: cell.dataKey.includes('rate')
                    ? Number(value)
                    : value,
                },
              },
            });
          } else {
            // create new rm_recs
            rmRecs.create[0]({
              variables: {
                rmRec: {
                  created_by_id: user?.id,
                  hotel_id: hotel?.hotel_id || hotel?.brand_code,
                  stay_date: cell.stayDate,
                  [cell.dataKey.split('.')[1]]: cell.dataKey.includes('rate')
                    ? Number(value)
                    : value,
                },
              },
            });
          }
          break;
        case cell.dataKey.includes('forecast'):
          if (cell.meta?.id) {
            const existingForecast = cell.meta?.data as Forecast;

            const forecastValues = calculateForecast({
              ...existingForecast,
              [cell.dataKey]: Number(value),
            });

            // update existing forecast
            forecasts.update[0]({
              variables: {
                updateForecastId: cell.meta.id,
                forecast: {
                  ..._.pick(forecastValues, [
                    'forecast_sold_1',
                    'forecast_sold_2',
                    'forecast_adr_1',
                    'forecast_adr_2',
                  ]),
                },
              },
              optimisticResponse: {
                updateForecast: {
                  __typename: 'Forecast',
                  ...forecastValues,
                },
              },
            });
          } else {
            // create new forecast
            const existingForecast = cell.meta?.data as Forecast;

            const forecastValues = calculateForecast({
              ...existingForecast,
              [cell.dataKey]: Number(value),
            });

            forecasts.create[0]({
              variables: {
                forecast: {
                  created_by_id: user?.id,
                  hotel_id: hotel?.hotel_id || hotel?.brand_code,
                  stay_date: cell.stayDate,
                  ..._.pick(forecastValues, [
                    'forecast_sold_1',
                    'forecast_sold_2',
                    'forecast_adr_1',
                    'forecast_adr_2',
                  ]),
                  [cell.dataKey]: Number(value),
                },
              },
              optimisticResponse: {
                createForecast: {
                  __typename: 'Forecast',
                  created_by_id: user?.id,
                  hotel_id: hotel?.hotel_id || hotel?.brand_code,
                  stay_date: cell.stayDate,
                  ...forecastValues,
                  [cell.dataKey]: Number(value),
                },
              },
            });
          }
          break;
        case cell.dataKey.includes('note_'):
          if (cell.meta?.id) {
            // update existing notes
            notes.update[0]({
              variables: {
                updateNoteId: cell.meta.id,
                note: {
                  content: value,
                },
              },
            });
          } else {
            // create new note
            notes.create[0]({
              variables: {
                note: {
                  created_by_id: user?.id,
                  hotel_id: hotel?.hotel_id || hotel?.brand_code,
                  stay_date: cell.dataKey.includes('note_ly')
                    ? sameTimeYearsAgo(cell.stayDate)
                    : cell.stayDate,
                  content: value,
                },
              },
            });
          }
          break;
        default:
          break;
      }
    } else {
      setValue(cell.value || '');
    }
  };

  /**
   * Should the value be truncated?
   * @param cellValue The attribute 'value' from the cell object. Can be string, number, or null.
   * @returns True if the value is not a number and is not null or an empty string.
   */
  const shouldTruncate = (cellValue: typeof cell.value) => {
    return isNaN(Number(cellValue));
  };

  const validateValue = (value: string | number) => {
    if (cell.dataKey && cell.dataKey.includes('rate') && value !== '') {
      if (isValidRate(Number(cleanValue(value)))) {
        return true;
      } else {
        return window.confirm(
          `${value} does not appear to be a valid rate.\nAre you sure you want to save this value? \nRate can be saved but will not be uploaded.`
        );
      }
    } else {
      return true;
    }
  };

  const valuesAreNew = (): boolean => {
    if (cell.value === null && value === '') {
      return false;
    }
    return String(value) !== String(cell.value);
  };

  const DisplayCell = () => {
    if (shouldTruncate(cell.value)) {
      const displayValue = valuesAreNew() ? value : cell.displayValue || '...';
      return (
        <span data-tip={displayValue} onClick={() => setEdit(true)}>
          {String(displayValue).length > MAX_LENGTH
            ? String(displayValue).substring(0, MAX_LENGTH)
            : displayValue}
        </span>
      );
    } else if (cell.dataKey.includes('forecast')) {
      const bestrev = cell.meta?.data?.calcForecasts?.bestrev;
      const calcValue = bestrev ? Math.round(bestrev[cell.dataKey]) : undefined;
      const showSuperscript = hotelSettings?.forecasts?.showSuperscript;
      return (
        <span onClick={() => setEdit(true)}>
          {valuesAreNew() ? value : cell.displayValue || '...'}
          <sup>
            {showSuperscript && calcValue && !isNaN(calcValue) ? calcValue : ''}
          </sup>
        </span>
      );
    } else {
      return (
        <span onClick={() => setEdit(true)}>
          {valuesAreNew() ? value : cell.displayValue || '...'}
        </span>
      );
    }
  };

  return (
    <>
      {edit ? (
        <input
          className='text-center w-full mb-1'
          ref={inputRef}
          onBlur={handleSave}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          type='text'
          value={value}
        />
      ) : (
        <DisplayCell />
      )}
    </>
  );
};

export default RenderEditable;
