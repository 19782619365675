import { Actions, FormField } from '../../types/DataTable';
import { useEffect, useState } from 'react';

import Toggle from '../Toggle';
import { clsx } from 'clsx';
import { toast } from 'react-toastify';

type Props = {
  actions: Actions;
  fields: FormField[];
};

const DTCoreButtons = ({ actions, fields }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clear, setClear] = useState<string | undefined>();
  const [selOptions, setSelOptions] = useState<
    { name: string; value: string }[] | undefined
  >();
  const { bulkUpsert, deleteAll } = actions;

  useEffect(() => {
    if (fields) {
      const newOptions = fields
        .filter((f) => f.bulk)
        .map((field) => {
          return { name: field.label, value: field.name };
        });
      setSelOptions(newOptions);
      setClear(newOptions[0]?.value);
    }
  }, [fields]);

  const handleClearColumn = () => {
    if (clear) {
      if (
        window.confirm(`Are you sure you want to clear the ${clear} for all?`)
      ) {
        toast.promise(bulkUpsert(['all'], { [clear]: null }), {
          pending: 'Clearing all...',
          success: 'All cleared!',
          error: 'Error!',
        });
      }
    }
  };

  const handleDeleteAll = () => {
    if (window.confirm('Are you sure you want to delete all?')) {
      toast.promise(deleteAll(), {
        pending: 'Deleting all...',
        success: 'All deleted!',
        error: 'Error!',
      });
    }
  };

  return (
    <div className='w-full shadow-sm p-1'>
      <div className='w-full rounded-2xl bg-white'>
        <div className='text-gray-500'>
          <Toggle checked={isOpen} label={'Actions'} onChange={setIsOpen} />
          <div className={clsx(!isOpen && 'hidden', 'flex flex-col')}>
            <button
              className='items-center mt-2 text-xs shadow-sm p-2 text-white bg-red-500 hover:bg-red-600 rounded'
              onClick={handleDeleteAll}
            >
              <span>Delete All</span>
            </button>

            <span
              className={clsx(
                !selOptions && 'hidden',
                selOptions && selOptions.length === 0 && 'hidden',
                'relative inline-flex items-center mt-2 p-2 text-xs hover:bg-blue-600 text-white rounded bg-blue-500'
              )}
            >
              <span
                className='relative inline-flex items-center rounded-l-md'
                data-tip='Clear a single column for all records'
              >
                <select
                  id={'reset-all'}
                  className={clsx(
                    'shadow-sm block w-full text-gray-900 text-xs border-gray-300 rounded-sm'
                  )}
                  onChange={(e) => setClear(e.target.value)}
                  name={'reset-all'}
                  placeholder={'Select'}
                  value={clear}
                >
                  {selOptions &&
                    selOptions.map(({ name, value }) => (
                      <option key={`${name}-${value}`} value={value}>
                        {name}
                      </option>
                    ))}
                </select>
              </span>
              <button className='ml-2' onClick={handleClearColumn}>
                <span>Clear</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DTCoreButtons;
