import { RateQueryParams } from '../types/__generated__/globalTypes';

interface ICheckBoxGroup {
  changeDays: RateQueryParams['changeDays'];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxGroup = ({ handleChange, changeDays }: ICheckBoxGroup) => {
  return (
    <fieldset className='space-y-1'>
      <legend className='sr-only'>Notifications</legend>
      <div className='inline-flex items-start'>
        <div className='inline-flex h-5 items-center'>
          <input
            id='1'
            checked={changeDays?.includes(1) || false}
            name='1'
            onChange={handleChange}
            type='checkbox'
            className='h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
          />
        </div>
        <div className='ml-1 text-sm'>
          <label htmlFor='1' className='font-medium text-gray-700'>
            1
          </label>
        </div>
      </div>
      <div className='inline-flex items-start'>
        <div className='inline-flex h-5 items-center'>
          <input
            id='3'
            checked={changeDays?.includes(3) || false}
            name='3'
            onChange={handleChange}
            type='checkbox'
            className='ml-2 h-3 w-3 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500'
          />
        </div>
        <div className='ml-1 text-sm'>
          <label htmlFor='2' className='font-medium text-gray-700'>
            3
          </label>
        </div>
      </div>
      <div className='inline-flex items-start'>
        <div className='inline-flex h-5 items-center'>
          <input
            id='7'
            checked={changeDays?.includes(7) || false}
            name='7'
            onChange={handleChange}
            type='checkbox'
            className='ml-2 h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500'
          />
        </div>
        <div className='ml-1 text-sm'>
          <label htmlFor='7' className='font-medium text-gray-700'>
            7
          </label>
        </div>
      </div>
    </fieldset>
  );
};

export default CheckboxGroup;
