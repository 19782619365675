import {
  endOfYear,
  firstOfYear,
  getYear,
  getYearMonths,
} from '@/helpers/dateHelpers';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCreateMonthlyBudgetMutation,
  useDeleteMonthlyBudgetMutation,
  useHotelMonthlyBudgetsQuery,
  useUpdateMonthlyBudgetMutation,
} from '@/features/budget/_gen_/budget.gql';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CurrencyCell } from '@/components/TableCells';
import DataTableContainer from '../DataTableContainer';
import { Input } from '@/components/ui/input';
import { MdDeleteForever } from 'react-icons/md';
import { MonthlyBudget } from '@/graphql/types';
import ReactTooltip from 'react-tooltip';
import { constructStayDate } from '@/lib/utils';
import { useHotel } from '@/context/hotelContext';
import { useUser } from '@/context/userContext';

type MonthRowProps = {
  data?: MonthlyBudget | null;
  isLoading: boolean;
  month: { display: string; value: number };
  userId: string;
  hotelId: number;
  selected: boolean;
  addToQueue: (item: QueueItem) => void;
  onSelect: (id: string) => void;
  onDelete: (id: string) => void;
};

type QueueItem = {
  type: 'create' | 'update' | 'delete';
  data: Partial<MonthlyBudget>;
};

function useQueue() {
  const [queue, setQueue] = useState<QueueItem[]>([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const storedQueue = localStorage.getItem('budgetQueue');
    if (storedQueue) {
      setQueue(JSON.parse(storedQueue));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('budgetQueue', JSON.stringify(queue));
  }, [queue]);

  const addToQueue = (item: QueueItem) => {
    setQueue((prev) => [...prev, item]);
  };

  return { queue, setQueue, processing, setProcessing, addToQueue };
}

const MonthRow = memo(function MonthRow({
  data,
  month,
  hotelId,
  userId,
  selected,
  addToQueue,
  onSelect,
  onDelete,
}: MonthRowProps) {
  const [sold, setSold] = useState(data?.budget_sold || 0);
  const [adr, setAdr] = useState(data?.budget_adr || 0);

  useEffect(() => {
    setSold(data?.budget_sold || 0);
    setAdr(data?.budget_adr || 0);
  }, [data]);

  const revenue = useMemo(() => {
    if (adr && sold) {
      return adr * sold;
    }
    return data?.budget_revenue || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sold, adr]);

  const { id } = data || {};

  const year = getYear();

  const handleChange = useCallback(
    (field: 'budget_sold' | 'budget_adr') =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value) || 0;
        const originalValue = data?.[field] || 0;

        if (value === originalValue) return;

        addToQueue({
          type: id ? 'update' : 'create',
          data: {
            id: id,
            hotel_id: hotelId,
            created_by_id: userId,
            stay_date: constructStayDate(Number(year), month.value),
            [field]: value,
          },
        });
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, hotelId, userId, year, month.value, addToQueue]
  );

  return (
    <tr className='border-b border-gray-200 bg-gray-50 h-10'>
      <td className='w-24'>
        <div className='flex items-center ml-10'>
          {data && (
            <input
              type='checkbox'
              checked={selected}
              onChange={() => onSelect(data.id)}
              aria-label={`Select ${month.display}`}
            />
          )}
          {selected && (
            <button
              className='ml-2 text-xl text-red-700'
              onClick={() => data?.id && onDelete(data?.id)}
              aria-label={`Delete ${month.display}`}
            >
              <MdDeleteForever />
            </button>
          )}
        </div>
      </td>
      <td className='text-center py-2 font-medium text-gray-600'>
        {month.display}
      </td>
      <td className='text-center py-2 w-28'>
        <Input
          type='number'
          value={sold || ''}
          onChange={(e) => setSold(parseFloat(e.target.value) || 0)}
          onBlur={handleChange('budget_sold')}
          className='text-md'
          aria-label={`Sold for ${month.display}`}
        />
      </td>
      <td className='text-center py-2 w-28'>
        <Input
          type='number'
          value={adr || ''}
          onChange={(e) => setAdr(parseFloat(e.target.value) || 0)}
          onBlur={handleChange('budget_adr')}
          className='text-md'
          step='0.01'
          aria-label={`ADR for ${month.display}`}
        />
      </td>
      <CurrencyCell
        value={revenue}
        metric='revenue'
        style={['text-center py-2 text-blue-900 font-semibold']}
      />
    </tr>
  );
});

function Budget() {
  const { queue, setQueue, processing, setProcessing, addToQueue } = useQueue();

  const processQueue = async () => {
    if (processing || queue.length === 0) return;

    setProcessing(true);
    const item = queue[0];

    try {
      switch (item.type) {
        case 'create':
          if (
            !item.data ||
            !item.data.created_by_id ||
            !item.data.hotel_id ||
            !item.data.stay_date
          ) {
            console.log(`Budget Create: No IDs found for item:`, item);
            break;
          }

          await createMonthlyBudget({
            variables: {
              monthlyBudget: {
                budget_sold: item.data.budget_sold,
                budget_adr: item.data.budget_adr,
                created_by_id: item.data.created_by_id,
                hotel_id: item.data.hotel_id,
                stay_date: item.data.stay_date,
              },
            },
          });
          break;
        case 'update':
          if (!item.data.id) {
            console.log(`Budget Update: No ID found for item:`, item);
            break;
          }

          await updateMonthlyBudget({
            variables: {
              updateMonthlyBudgetId: item.data.id,
              monthlyBudget: {
                budget_adr: item.data.budget_adr,
                budget_sold: item.data.budget_sold,
              },
            },
          });
          break;
        case 'delete':
          if (!item.data.id) {
            console.log(`Budget Delete: No ID found for item:`, item);
            break;
          }

          await deleteMonthlyBudget({
            variables: { deleteMonthlyBudgetId: item.data.id },
          });
          break;
      }

      setQueue((prev) => prev.slice(1));
    } catch (error) {
      console.error('Error processing queue item:', error);
    }

    setProcessing(false);
  };

  const { hotel } = useHotel();
  const { user } = useUser();
  const months = useMemo(() => getYearMonths(), []);
  const [selected, setSelected] = useState<string[]>([]);

  const {
    data: budgetData,
    loading: loadingBudget,
    refetch,
  } = useHotelMonthlyBudgetsQuery({
    skip: !hotel?.hotel_id,
    variables: {
      filters: {
        hotelId: Number(hotel?.hotel_id),
        startDate: firstOfYear(months[0].display),
        endDate: endOfYear(months[months.length - 1].display),
      },
    },
  });

  useEffect(() => {
    if (!processing && queue.length > 0) {
      processQueue();
    } else if (!processing && queue.length === 0) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, processing]);

  const [createMonthlyBudget, { loading }] = useCreateMonthlyBudgetMutation();
  const [updateMonthlyBudget, { loading: updating }] =
    useUpdateMonthlyBudgetMutation();
  const [deleteMonthlyBudget, { loading: deleting }] =
    useDeleteMonthlyBudgetMutation();

  const isLoading = loading || updating || deleting;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selected]);

  const handleRowSelect = useCallback((id: string) => {
    setSelected((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
    );
  }, []);

  const handleSelectAllRows = useCallback(() => {
    setSelected((prev) =>
      prev.length === budgetData?.hotelMonthlyBudgets?.length
        ? []
        : budgetData?.hotelMonthlyBudgets?.map((b) => b?.id || '') || []
    );
  }, [budgetData?.hotelMonthlyBudgets]);

  const handleDelete = useCallback(
    (id: string) => {
      addToQueue({ type: 'delete', data: { id } });
      setSelected((prev) => prev.filter((i) => i !== id));
    },
    [addToQueue]
  );

  const monthlyBudgets = useMemo(() => {
    return months.map((month) => {
      const existingBudget = budgetData?.hotelMonthlyBudgets?.find((b) => {
        const modMonth = month.value > 12 ? month.value - 12 : month.value;
        return b?.year === month.year && b.month === modMonth;
      });
      return {
        ...existingBudget,
        month,
      };
    });
  }, [months, budgetData?.hotelMonthlyBudgets]);

  if (loadingBudget || !budgetData) {
    return (
      <div className='flex justify-center items-center h-64'>
        <AiOutlineLoading3Quarters className='animate-spin ml-2 text-blue-900 w-10 h-10' />
        <h2 className='ml-4 text-2xl'>Loading Budget...</h2>
      </div>
    );
  }

  return (
    <div className='border-t border-gray-200 mt-6'>
      {queue.length > 0 ? (
        <div className='mt-4 text-lg text-red-700 flex items-center justify-center'>
          {processing
            ? `Do not navigate away. Processing changes... ${queue.length} remaining`
            : `${queue.length} changes pending`}
        </div>
      ) : (
        <div className='mt-4 text-lg flex items-center justify-center'>
          Monthly Budgets
        </div>
      )}
      <DataTableContainer>
        <table className='w-[70%] mx-auto shadow rounded mt-4'>
          <thead>
            <tr className='border-b border-gray-200'>
              <th className='w-24'>
                <div className='flex items-center ml-10'>
                  <input
                    type='checkbox'
                    onChange={handleSelectAllRows}
                    checked={
                      selected.length ===
                        budgetData?.hotelMonthlyBudgets?.length &&
                      selected.length > 0
                    }
                    aria-label='Select all months'
                  />
                  {selected.length > 1 && (
                    <button
                      className='ml-2 text-xl text-red-700'
                      onClick={() => selected.forEach(handleDelete)}
                      data-tip='Delete Selected'
                      aria-label='Delete selected months'
                    >
                      <MdDeleteForever />
                    </button>
                  )}
                </div>
              </th>
              <th className='py-2 text-gray-800'>Month</th>
              <th className='py-2 text-gray-800'>Sold</th>
              <th className='py-2 text-gray-800'>ADR</th>
              <th className='py-2 text-gray-800'>Revenue</th>
            </tr>
          </thead>
          <tbody>
            {monthlyBudgets.map((budget) => (
              <MonthRow
                key={budget.month.value}
                isLoading={isLoading}
                month={budget.month}
                // @ts-ignore
                data={budget}
                userId={user?.id || ''}
                // @ts-ignore
                hotelId={hotel?.hotel_id}
                selected={selected.includes(budget.id || '')}
                addToQueue={addToQueue}
                onSelect={handleRowSelect}
                onDelete={handleDelete}
              />
            ))}
          </tbody>
        </table>
      </DataTableContainer>
      <ReactTooltip />
    </div>
  );
}

export default Budget;
