type Props = {
  children: React.ReactNode;
};

const DataTableContainer = ({ children }: Props) => {
  return (
    <section className='bg-gray-100'>
      <div className='mx-auto'>
        <div className='bg-white rounded'>
          <div className='flex flex-wrap items-center py-2 px-3'>
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataTableContainer;
