import { Event } from '../graphql/types';
import { baseFormat } from '../renderers/baseFormat';
import { masterColumns } from '../renderers/masterColumns';

const dataRow = (metrics: string[], event?: Event) => {
  if (!event) {
    return [];
  }

  return metrics.map((metric) => {
    const eventMetric = metric.replace('event.', '');
    return baseFormat(metric, event[eventMetric as keyof Event], 'day');
  });
};

export const tableData = (events?: Event[]) => {
  const metrics = [
    'event.name',
    'event.start_date',
    'event.end_date',
    'event.details',
  ];

  const headers = metrics.map((metric) => masterColumns[metric].header);
  const rows = events ? events.map((event) => dataRow(metrics, event)) : [];

  return {
    headers,
    rows,
    perPage: 5,
  };
};
