import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function constructStayDate(year: number, month: number) {
  if (month > 12) {
    year = year + 1;
    month = month - 12;
    return `${year}-${month.toString().padStart(2, '0')}-01`;
  } else {
    return `${year}-${month.toString().padStart(2, '0')}-01`;
  }
}

export function formatCurrency(value: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
}
