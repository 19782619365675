/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { ActionItemFieldsFragmentDoc } from './action-item.fragment.gql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHotelActionItemsQueryVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
}>;


export type GetHotelActionItemsQuery = { __typename?: 'Query', getHotelActionItems?: Array<{ __typename?: 'ActionItem', assignedToId?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, dueDate?: string | null, id?: string | null, name?: string | null, status?: Types.ActionItemStatus | null, statusUpdated?: string | null } | null> | null };

export type AddActionItemMutationVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  createdById: Types.Scalars['String'];
  name: Types.Scalars['String'];
  assignedToId?: Types.InputMaybe<Types.Scalars['String']>;
  dueDate?: Types.InputMaybe<Types.Scalars['String']>;
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  status?: Types.InputMaybe<Types.ActionItemStatus>;
}>;


export type AddActionItemMutation = { __typename?: 'Mutation', addActionItem?: { __typename?: 'ActionItem', assignedToId?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, dueDate?: string | null, id?: string | null, name?: string | null, status?: Types.ActionItemStatus | null, statusUpdated?: string | null } | null };

export type UpdateActionItemMutationVariables = Types.Exact<{
  updateActionItemId: Types.Scalars['String'];
  assignedToId?: Types.InputMaybe<Types.Scalars['String']>;
  dueDate?: Types.InputMaybe<Types.Scalars['String']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  notes?: Types.InputMaybe<Types.Scalars['String']>;
  status?: Types.InputMaybe<Types.ActionItemStatus>;
}>;


export type UpdateActionItemMutation = { __typename?: 'Mutation', updateActionItem?: { __typename?: 'ActionItem', assignedToId?: string | null, brandCode?: string | null, createdAt?: string | null, createdById?: string | null, dueDate?: string | null, id?: string | null, name?: string | null, status?: Types.ActionItemStatus | null, statusUpdated?: string | null } | null };


export const GetHotelActionItemsDocument = gql`
    query GetHotelActionItems($brandCode: String!) {
  getHotelActionItems(brandCode: $brandCode) {
    ...ActionItemFields
  }
}
    ${ActionItemFieldsFragmentDoc}`;

/**
 * __useGetHotelActionItemsQuery__
 *
 * To run a query within a React component, call `useGetHotelActionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelActionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelActionItemsQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useGetHotelActionItemsQuery(baseOptions: Apollo.QueryHookOptions<GetHotelActionItemsQuery, GetHotelActionItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHotelActionItemsQuery, GetHotelActionItemsQueryVariables>(GetHotelActionItemsDocument, options);
      }
export function useGetHotelActionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHotelActionItemsQuery, GetHotelActionItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHotelActionItemsQuery, GetHotelActionItemsQueryVariables>(GetHotelActionItemsDocument, options);
        }
export type GetHotelActionItemsQueryHookResult = ReturnType<typeof useGetHotelActionItemsQuery>;
export type GetHotelActionItemsLazyQueryHookResult = ReturnType<typeof useGetHotelActionItemsLazyQuery>;
export type GetHotelActionItemsQueryResult = Apollo.QueryResult<GetHotelActionItemsQuery, GetHotelActionItemsQueryVariables>;
export const AddActionItemDocument = gql`
    mutation AddActionItem($brandCode: String!, $createdById: String!, $name: String!, $assignedToId: String, $dueDate: String, $notes: String, $status: ActionItemStatus) {
  addActionItem(
    brandCode: $brandCode
    createdById: $createdById
    name: $name
    assignedToId: $assignedToId
    dueDate: $dueDate
    notes: $notes
    status: $status
  ) {
    ...ActionItemFields
  }
}
    ${ActionItemFieldsFragmentDoc}`;
export type AddActionItemMutationFn = Apollo.MutationFunction<AddActionItemMutation, AddActionItemMutationVariables>;

/**
 * __useAddActionItemMutation__
 *
 * To run a mutation, you first call `useAddActionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActionItemMutation, { data, loading, error }] = useAddActionItemMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      createdById: // value for 'createdById'
 *      name: // value for 'name'
 *      assignedToId: // value for 'assignedToId'
 *      dueDate: // value for 'dueDate'
 *      notes: // value for 'notes'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAddActionItemMutation(baseOptions?: Apollo.MutationHookOptions<AddActionItemMutation, AddActionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActionItemMutation, AddActionItemMutationVariables>(AddActionItemDocument, options);
      }
export type AddActionItemMutationHookResult = ReturnType<typeof useAddActionItemMutation>;
export type AddActionItemMutationResult = Apollo.MutationResult<AddActionItemMutation>;
export type AddActionItemMutationOptions = Apollo.BaseMutationOptions<AddActionItemMutation, AddActionItemMutationVariables>;
export const UpdateActionItemDocument = gql`
    mutation UpdateActionItem($updateActionItemId: String!, $assignedToId: String, $dueDate: String, $name: String, $notes: String, $status: ActionItemStatus) {
  updateActionItem(
    id: $updateActionItemId
    assignedToId: $assignedToId
    dueDate: $dueDate
    name: $name
    notes: $notes
    status: $status
  ) {
    ...ActionItemFields
  }
}
    ${ActionItemFieldsFragmentDoc}`;
export type UpdateActionItemMutationFn = Apollo.MutationFunction<UpdateActionItemMutation, UpdateActionItemMutationVariables>;

/**
 * __useUpdateActionItemMutation__
 *
 * To run a mutation, you first call `useUpdateActionItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionItemMutation, { data, loading, error }] = useUpdateActionItemMutation({
 *   variables: {
 *      updateActionItemId: // value for 'updateActionItemId'
 *      assignedToId: // value for 'assignedToId'
 *      dueDate: // value for 'dueDate'
 *      name: // value for 'name'
 *      notes: // value for 'notes'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateActionItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActionItemMutation, UpdateActionItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActionItemMutation, UpdateActionItemMutationVariables>(UpdateActionItemDocument, options);
      }
export type UpdateActionItemMutationHookResult = ReturnType<typeof useUpdateActionItemMutation>;
export type UpdateActionItemMutationResult = Apollo.MutationResult<UpdateActionItemMutation>;
export type UpdateActionItemMutationOptions = Apollo.BaseMutationOptions<UpdateActionItemMutation, UpdateActionItemMutationVariables>;
export const namedOperations = {
  Query: {
    GetHotelActionItems: 'GetHotelActionItems'
  },
  Mutation: {
    AddActionItem: 'AddActionItem',
    UpdateActionItem: 'UpdateActionItem'
  }
}