import { gql } from '@apollo/client';

export const CREATE_NOTE = gql`
  mutation CreateNote($note: CreateNoteInput!) {
    createNote(note: $note) {
      content
      hotel_id
      id
      stay_date
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($updateNoteId: String!, $note: UpdateNoteInput!) {
    updateNote(id: $updateNoteId, note: $note) {
      content
      hotel_id
      id
      stay_date
    }
  }
`;
