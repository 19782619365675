import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

import { Button } from '@/components/ui/button';
import { DatePickerFormField } from './date-picker-form-field';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { UseFormReturn } from 'react-hook-form';
import { useRef } from 'react';

interface AddEventFormProps {
  form: UseFormReturn<any>;
  onSubmit: (data: any) => void;
}

export const AddEventForm = ({ form, onSubmit }: AddEventFormProps) => {
  const startDateRef = useRef<string | null>(null);
  const endDateRef = useRef<string | null>(null);

  const handleError = (error: any) => {
    console.error(error);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, handleError)}
        className='space-y-4 mb-8'
      >
        <FormField
          control={form.control}
          name='name'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input
                  placeholder='Event name'
                  autoComplete='off'
                  {...field}
                  value={field.value ?? ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex space-x-4'>
          <DatePickerFormField
            name='start_date'
            label='Start Date'
            dateRef={startDateRef}
            form={form}
          />
          <DatePickerFormField
            name='end_date'
            label='End Date'
            minDate={form.watch('start_date')}
            dateRef={endDateRef}
            form={form}
          />
        </div>
        <FormField
          control={form.control}
          name='details'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Details</FormLabel>
              <FormControl>
                <Textarea
                  placeholder='Event details'
                  autoComplete='off'
                  {...field}
                  value={field.value ?? ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type='submit'>Create Event</Button>
      </form>
    </Form>
  );
};
