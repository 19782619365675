import { CORE_EVENT_FIELDS, DATE_SEGMENT_FIELDS } from '../../../gql/fragments';

import { gql } from '@apollo/client';

export const DAILY_HOTEL_EVENTS = gql`
  ${CORE_EVENT_FIELDS}
  query DailyEvents($filters: FindEventArgs!) {
    dailyEvents(filters: $filters) {
      stay_date
      ${DATE_SEGMENT_FIELDS}
      events_ty {
        count
        events {
          ...CoreEventFields
        }
      }
      events_ly {
        count
        events {
          ...CoreEventFields
        }
      }
    }
  }
`;
