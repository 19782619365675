import { Meeting } from '../../../graphql/types';
import { useTracking } from 'react-tracking';
import { useUser } from '../../../context/userContext';

interface UseTrackMeetingEventProps {
  meeting?: Meeting;
  page: string;
}

export const useTrackMeetingEvent = ({
  meeting,
  page,
}: UseTrackMeetingEventProps) => {
  const { user, oktaUser, userIp } = useUser();
  const { trackEvent } = useTracking();

  return (action: string, event: string) => {
    trackEvent({
      action,
      agendaItems:
        meeting?.agendaItems?.length === 0 ? undefined : meeting?.agendaItems,
      attendees:
        meeting?.attendees?.length === 0 ? undefined : meeting?.attendees,
      brandCode: meeting?.brandCode,
      event,
      ipAddr: userIp,
      meetingCategory: meeting?.category,
      meetingDate: meeting?.meetingDate,
      meetingId: meeting?.id,
      page,
      userEmail: user?.email || 'unknown',
      userName: user?.name,
      userRole: oktaUser?.userRole,
    });
  };
};
