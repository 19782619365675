import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import React, { useEffect, useRef, useState } from 'react';
import { addMonths, format, isAfter, isBefore, isValid, parse } from 'date-fns';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Input } from '@/components/ui/input';
import { UseFormReturn } from 'react-hook-form';

interface DatePickerFormFieldProps {
  name: 'start_date' | 'end_date';
  label: string;
  minDate?: string;
  maxDate?: string;
  dateRef: React.MutableRefObject<string | null | undefined>;
  form: UseFormReturn<any>;
  onComplete?: () => void;
  endDateRef?: React.RefObject<HTMLInputElement>;
}

export const DatePickerFormField = ({
  name,
  label,
  minDate,
  maxDate,
  dateRef,
  form,
  onComplete,
  endDateRef,
}: DatePickerFormFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateInput, setDateInput] = useState('');
  const [localMinDate, setLocalMinDate] = useState(minDate);
  const [localMaxDate, setLocalMaxDate] = useState(maxDate);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (name === 'start_date') {
      const startDate = form.getValues('start_date');
      const endDate = form.getValues('end_date');

      if (startDate) {
        const startDateObj = parse(startDate, 'yyyy-MM-dd', new Date());
        const oneMonthLater = addMonths(startDateObj, 1);

        let newEndDate = endDate;

        if (
          !endDate ||
          isBefore(parse(endDate, 'yyyy-MM-dd', new Date()), startDateObj)
        ) {
          // If end date is empty or less than start date, set it to start date
          newEndDate = startDate;
        } else if (
          isAfter(parse(endDate, 'yyyy-MM-dd', new Date()), oneMonthLater)
        ) {
          // If end date is more than one month from start date, set it to one month from start date
          newEndDate = format(oneMonthLater, 'yyyy-MM-dd');
        }

        if (newEndDate !== endDate) {
          form.setValue('end_date', newEndDate);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch('start_date'), name]);

  useEffect(() => {
    if (isOpen && name === 'start_date') {
      inputRef.current?.focus();
    }
  }, [isOpen, name]);

  useEffect(() => {
    if (name === 'end_date') {
      setLocalMinDate(minDate);
      if (minDate) {
        const oneMonthLater = format(
          addMonths(parse(minDate, 'yyyy-MM-dd', new Date()), 1),
          'yyyy-MM-dd'
        );
        setLocalMaxDate(oneMonthLater);

        // Check if the current end date is still valid
        const currentEndDate = form.getValues('end_date');
        if (
          currentEndDate &&
          (isBefore(
            parse(currentEndDate, 'yyyy-MM-dd', new Date()),
            parse(minDate, 'yyyy-MM-dd', new Date())
          ) ||
            isAfter(
              parse(currentEndDate, 'yyyy-MM-dd', new Date()),
              parse(oneMonthLater, 'yyyy-MM-dd', new Date())
            ))
        ) {
          form.setValue('end_date', '');
          setDateInput('');
        }
      }
    }
  }, [minDate, name, form]);

  // Add this effect to reset the dateInput when the form is reset
  useEffect(() => {
    const formValue = form.watch(name);
    if (!formValue) {
      setDateInput('');
      dateRef.current = null;
    }
  }, [form, name, dateRef]);

  const handleDateInput = (
    dateString: string,
    onChange: (date: string | null) => void
  ) => {
    setDateInput(dateString);
    if (dateString === '') {
      onChange(null);
      dateRef.current = null;
    } else if (isValid(parse(dateString, 'yyyy-MM-dd', new Date()))) {
      if (name === 'end_date' && localMinDate) {
        const oneMonthLater = format(
          addMonths(parse(localMinDate, 'yyyy-MM-dd', new Date()), 1),
          'yyyy-MM-dd'
        );
        if (
          isBefore(
            parse(dateString, 'yyyy-MM-dd', new Date()),
            parse(localMinDate, 'yyyy-MM-dd', new Date())
          ) ||
          isAfter(
            parse(dateString, 'yyyy-MM-dd', new Date()),
            parse(oneMonthLater, 'yyyy-MM-dd', new Date())
          )
        ) {
          return;
        }
      }
      onChange(dateString);
      dateRef.current = dateString;

      // Trigger form validation
      form.trigger(name);
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      if (isValid(parse(dateInput, 'yyyy-MM-dd', new Date()))) {
        setIsOpen(false);
        if (name === 'start_date' && onComplete) {
          onComplete();
        }
      }
    }
  };

  const handleButtonKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className='flex flex-col'>
          <FormLabel>{label}</FormLabel>
          <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  ref={buttonRef}
                  variant='outline'
                  role='combobox'
                  aria-expanded={isOpen}
                  aria-haspopup='dialog'
                  aria-label={`Select ${label}`}
                  className={`w-full justify-start text-left font-normal ${
                    !field.value && 'text-muted-foreground'
                  }`}
                  onClick={() => {
                    setIsOpen(true);
                    if (dateRef.current) {
                      field.onChange(dateRef.current);
                    }
                  }}
                  onKeyDown={handleButtonKeyDown}
                >
                  {field.value ? (
                    format(parse(field.value, 'yyyy-MM-dd', new Date()), 'PPP')
                  ) : (
                    <span>Pick a date</span>
                  )}
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0' align='start'>
              <div className='p-2'>
                <Input
                  type='date'
                  ref={name === 'start_date' ? inputRef : endDateRef}
                  id={name}
                  value={dateInput}
                  onChange={(e) =>
                    handleDateInput(e.target.value, field.onChange)
                  }
                  onKeyDown={handleInputKeyDown}
                  className='mb-2'
                  aria-label={`Enter ${label}`}
                  min={localMinDate}
                  max={localMaxDate}
                />
              </div>
              <Calendar
                mode='single'
                selected={
                  field.value
                    ? parse(field.value, 'yyyy-MM-dd', new Date())
                    : undefined
                }
                onSelect={(date) => {
                  if (date) {
                    const dateString = format(date, 'yyyy-MM-dd');
                    field.onChange(dateString);
                    dateRef.current = dateString;
                    setIsOpen(false);
                    setDateInput(dateString);
                    if (name === 'start_date' && onComplete) {
                      onComplete();
                    } else {
                      buttonRef.current?.focus();
                    }
                  }
                }}
                disabled={(date) => {
                  if (name === 'start_date') return false;
                  if (name === 'end_date' && localMinDate) {
                    const oneMonthLater = addMonths(
                      parse(localMinDate, 'yyyy-MM-dd', new Date()),
                      1
                    );
                    return (
                      isBefore(
                        date,
                        parse(localMinDate, 'yyyy-MM-dd', new Date())
                      ) || isAfter(date, oneMonthLater)
                    );
                  }
                  return localMinDate
                    ? date < parse(localMinDate, 'yyyy-MM-dd', new Date())
                    : date < new Date();
                }}
                initialFocus
                defaultMonth={
                  dateRef.current
                    ? parse(dateRef.current, 'yyyy-MM-dd', new Date())
                    : undefined
                }
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
