import { BiNotepad } from 'react-icons/bi';
import { Button } from '../../../components/ui/button';

interface BtnInsertNotepadProps {
  handleInsert: () => void;
}

export const BtnInsertNotepad = ({ handleInsert }: BtnInsertNotepadProps) => {
  return (
    <Button
      variant='outline'
      size='icon-sm'
      onClick={handleInsert}
      data-tip={'Insert from Notepad'}
    >
      <BiNotepad className='w-3 h-3' />
    </Button>
  );
};
