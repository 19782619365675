import { IoTodaySharp } from 'react-icons/io5';
import { TargetDate } from '../../context/dashboardContext';
import { today } from '../../helpers/dateHelpers';

type BtnProps = {
  btnClass: string;
  btnIconClass?: string;
  setTargetDate: (targetDate: TargetDate) => void;
};

export default function BtnToday({
  btnClass,
  btnIconClass = 'h-5 w-5',
  setTargetDate,
}: BtnProps) {
  const handleClick = () => {
    setTargetDate({ value: today() });
  };

  return (
    <span
      onClick={handleClick}
      className={`${btnClass} cursor-pointer`}
      data-tip='Go To Today'
    >
      <IoTodaySharp className={btnIconClass} aria-hidden='true' />
    </span>
  );
}
