import { DailyRoomRate, RmData, RmRec } from '../../../graphql/types';
import { cloneDeep, orderBy } from 'lodash';

import Table from '../../../components/Table';
import { tableData } from '../../../reports/dataRmRecTable';
import { useMemo } from 'react';

export type RmRecsTableProps = {
  data?: RmRec[];
  reportData?: RmData[];
  currentRateData?: DailyRoomRate[];
};

const RmRecsTable = ({
  data,
  currentRateData,
  reportData,
}: RmRecsTableProps) => {
  const rmRecData = useMemo(() => {
    if (!data) {
      return;
    }

    const filterNotEmpty = (rec: RmRec | null) => {
      return rec?.rate !== null || rec?.notes !== null;
    };

    const sortedData = orderBy(
      cloneDeep(data?.filter(filterNotEmpty)),
      'stay_date',
      'asc'
    );

    const combinedData = sortedData.map((rec) => {
      const rmData = reportData?.find((x) => x.stay_date === rec.stay_date);
      const currentRate = currentRateData?.find(
        (r) => r.stay_date === rec.stay_date
      );
      if (!rmData) {
        return rec;
      }
      return {
        ...rec,
        sold: rmData.sold,
        adr: rmData.adr,
        current_rate: currentRate?.current_rate,
      };
    });
    return tableData(combinedData as RmRec[]);
  }, [currentRateData, data, reportData]);

  if (!rmRecData) {
    return null;
  }

  return <Table {...rmRecData} />;
};

export { RmRecsTable };
