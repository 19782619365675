import { gql } from '@apollo/client';

export const GET_USER_SETTINGS = gql`
  query UserSetting($userId: String!) {
    userSetting(userId: $userId) {
      created_at
      id
      updated_at
      user_id
      dashboards {
        detail_table {
          hotels {
            brandCode
            columns {
              columnSize
              header
              key
            }
          }
        }
      }
    }
  }
`;
