import {
  RateChangeTable,
  useRateChangeLogs,
} from '../../../features/rate-change-log';

import { RateChangeLog } from '../../../graphql/types';

export const Main = () => {
  const { data, loading } = useRateChangeLogs();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data || !data.getHotelRateChangeLogs) {
    return <div>No data found</div>;
  }

  return (
    <div>
      <h1>Rate Change Log</h1>
      <RateChangeTable data={data?.getHotelRateChangeLogs as RateChangeLog[]} />
    </div>
  );
};
