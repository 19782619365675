import { REPORT_PATH, SHARED_PATH } from '../../../AppRoutes';
import { useLocation, useParams } from 'react-router-dom';

export const useIsShared = () => {
  const location = useLocation();
  const isShared = location.pathname.includes(SHARED_PATH);
  return { isShared, sharedPath: SHARED_PATH };
};

export const useIsReport = () => {
  const location = useLocation();
  const isReport = location.pathname.includes(REPORT_PATH);
  return { isReport, reportPath: REPORT_PATH };
};

export const useIsMeeting = () => {
  const { meetingId } = useParams<{ meetingId?: string }>();
  const isMeeting = !!meetingId;
  return { isMeeting };
};
