import {
  BulkDates,
  DayFilters,
  FormField,
  HandleCreateFunction,
  ValidationFn,
} from '../../types/DataTable';
import {
  ActionTypes as DTCreateActions,
  useDTCreateForm,
} from '../../reducers/useDTCreateForm';
import { ToastContentProps, toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';

import DTFormDays from './DTFormDays';
import { Event } from '../../types/Event';
import { Popover } from '@headlessui/react';
import { RiErrorWarningFill } from 'react-icons/ri';
import Toggle from '../Toggle';
import { ValidationError } from 'joi';
import _ from 'lodash';
import dayjs from 'dayjs';
import pluralize from 'pluralize';
import { usePrevious } from '../../hooks/usePrevious';

const Joi = require('joi').extend(require('@joi/date'));

type Props = {
  fields: FormField[];
  handleCreate?: HandleCreateFunction;
  modelName: string;
  validation?: ValidationFn;
  events: Event[];
};

type CreateAnotherRef = {
  form: FormField[];
  bulkDates: BulkDates;
  bulkDays: DayFilters;
};

const BULK_ALLOWED = ['Rm Rec'];

const DTForm = ({
  fields,
  handleCreate,
  modelName,
  validation,
  events,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [existingEvents, setExistingEvents] = useState(new Map());
  const [dateWarning, setDateWarning] = useState<Array<object> | undefined>(
    undefined
  );

  const [
    { bulkDates, bulkDays, createAnother, enableBulk, form },
    dispatchCreateForm,
  ] = useDTCreateForm({
    form: fields.filter((f) => f.name !== 'id' && !f.readOnly),
  });

  const formRef = useRef<CreateAnotherRef | undefined>();
  const [errors, setErrors] = useState<ValidationError | undefined>();

  useEffect(() => {
    if (formRef.current) {
      dispatchCreateForm({
        type: DTCreateActions.UPDATE,
        payload: {
          bulkDates: formRef.current.bulkDates,
          bulkDays: formRef.current.bulkDays,
          form: formRef.current.form,
        },
      });
    } else if (fields && !_.isEqual(fields, form)) {
      dispatchCreateForm({
        type: DTCreateActions.DEFAULTS,
        payload: { form: fields.filter((f) => f.name !== 'id' && !f.readOnly) },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {
    if (createAnother === true) {
      formRef.current = { form, bulkDates, bulkDays };
    } else if (createAnother === false) {
      formRef.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAnother, form, bulkDates, bulkDays]);

  const prevEvents = usePrevious(events);
  const eventsChanged = !_.isEqual(prevEvents, events);

  const prevForm = usePrevious(form);
  const formChanged = !_.isEqual(prevForm, form);

  useEffect(() => {
    if (eventsChanged) {
      const eventMap = getExistingDates(events);
      setExistingEvents(eventMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, eventsChanged]);

  useEffect(() => {
    if (formChanged) {
      setDateWarning(undefined);
      checkSameDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formChanged]);

  const handleBulkDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newBulkDates = { ...bulkDates, [e.target.name]: e.target.value };

    dispatchCreateForm({
      type: DTCreateActions.UPDATE,
      payload: { bulkDates: newBulkDates },
    });
  };

  const handleBulkDaysChange = (dayFilters: DayFilters) => {
    dispatchCreateForm({
      type: DTCreateActions.UPDATE,
      payload: { bulkDays: dayFilters },
    });
  };

  const checkSameDates = () => {
    const dates = form.slice(1, 3).map((field) => field.value);
    const allDates = [];

    const startDate: any = dayjs(dates[0]);
    const endDate: any = dayjs(dates[1]);
    let currentDate: any = startDate;

    while (currentDate <= endDate) {
      allDates.push(currentDate.format('MM/DD/YYYY'));
      currentDate = dayjs(currentDate).add(1, 'day');
    }

    const duplicateDates: Array<object> = [];

    allDates.forEach((date: string) => {
      if (existingEvents.has(date)) {
        const eventName = existingEvents.get(date);
        duplicateDates.push({
          [date]: eventName,
        });
      }
    });

    if (duplicateDates.length) {
      setDateWarning(duplicateDates);
    }

    return new Promise((resolve) => {
      resolve(!!duplicateDates.length);
    });
  };

  const getExistingDates = (events: Event[]): Map<string, string[]> => {
    const dateMap = new Map();
    events.forEach((event) => {
      const startDate = dayjs(event.start_date);
      const endDate = dayjs(event.end_date);
      let currentDate = startDate;

      while (currentDate <= endDate) {
        if (dateMap.has(currentDate.format('MM/DD/YYYY'))) {
          const updatedNames = [
            event.name,
            ...dateMap.get(currentDate.format('MM/DD/YYYY')),
          ];
          dateMap.set(currentDate.format('MM/DD/YYYY'), updatedNames);
        } else {
          dateMap.set(currentDate.format('MM/DD/YYYY'), [event.name]);
        }
        currentDate = dayjs(currentDate).add(1, 'day');
      }
    });
    return dateMap;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    let formValues: FormField[] = [];

    if (name === 'start_date') {
      formValues = form.map((field) => {
        if (field.name === name) {
          return {
            ...field,
            value: field.type === 'number' ? Number(value) : value,
          };
        } else if (field.name === 'end_date') {
          if (dayjs(value).isAfter(field.value)) {
            return {
              ...field,
              value: field.type === 'number' ? Number(value) : value,
            };
          } else {
            return field;
          }
        }
        return field;
      });
    } else if (name === 'end_date') {
      formValues = form.map((field) => {
        if (field.name === name) {
          return {
            ...field,
            value: field.type === 'number' ? Number(value) : value,
          };
        } else if (field.name === 'start_date') {
          if (dayjs(value).isBefore(field.value)) {
            return {
              ...field,
              value: field.type === 'number' ? Number(value) : value,
            };
          } else {
            return field;
          }
        }
        return field;
      });
    } else {
      formValues = form.map((field) => {
        if (field.name === name) {
          return {
            ...field,
            value: field.type === 'number' ? Number(value) : value,
          };
        }
        return field;
      });
    }

    const validateValues = {} as any;
    formValues.forEach((field) => {
      validateValues[field.name] = field.value;
    });

    if (validation) {
      const { error } = validation(validateValues, 'standard');
      setErrors(error);
    }

    dispatchCreateForm({
      type: DTCreateActions.UPDATE,
      payload: { form: formValues },
    });
  };

  const handleConfirm = () => {
    setDateWarning(undefined);
    handleSave(true);
  };

  const handleCancel = () => {
    setDateWarning(undefined);
    dispatchCreateForm({
      type: DTCreateActions.DEFAULTS,
      payload: { form: fields.filter((f) => f.name !== 'id' && !f.readOnly) },
    });
  };

  const handleSave = async (confirmed?: any) => {
    const hasDateWarning = confirmed === true ? false : await checkSameDates();

    if (hasDateWarning) {
      return;
    }

    if (enableBulk) {
      const bulkValidation = Joi.object({
        bulkStart: Joi.date().format('YYYY-MM-DD').required(),
        bulkEnd: Joi.date()
          .format('YYYY-MM-DD')
          .min(Joi.ref('bulkStart'))
          .required(),
      });
      const { error: bulkError } = bulkValidation.validate(bulkDates);

      const validateValues = {} as any;
      form.forEach((field) => {
        validateValues[field.name] = field.value;
      });

      if (validation) {
        const { error } = validation(validateValues, 'bulk');
        if (error) {
          console.log(error);
          toast(error.details[0].message, { type: 'error' });
        } else if (bulkError) {
          console.log(bulkError);
          toast(bulkError.details[0].message, { type: 'error' });
        } else {
          if (handleCreate) {
            toast.promise(handleCreate(form, { ...bulkDates, bulkDays }), {
              pending: 'Creating...',
              success: 'Created!',
              error: 'Error!',
            });
          }
        }
      }
    } else {
      const validateValues = {} as any;
      form.forEach((field) => {
        validateValues[field.name] = field.value;
      });
      if (validation) {
        const { error } = validation(validateValues, 'standard');
        if (error) {
          console.log(error);
          toast(error.details[0].message, { type: 'error' });
        } else {
          if (handleCreate) {
            toast.promise(handleCreate(form), {
              pending: 'Creating...',
              success: 'Created!',
              error: {
                render({ data }: ToastContentProps<{ message: string }>) {
                  return `${saneErrorMessage(data?.message)}`;
                },
              },
            });
          }
        }
      }
    }
  };

  const saneErrorMessage = (message?: string) => {
    if (
      message &&
      message.includes('duplicate key value violates unique constraint')
    ) {
      return 'An active record already exists for that date.';
    } else {
      return message;
    }
  };

  return (
    <div className='w-full shadow-sm p-1'>
      <div className='w-full rounded-2xl bg-white'>
        <div className=' text-gray-500'>
          <Toggle
            checked={isOpen}
            label={`Create ${modelName}`}
            onChange={setIsOpen}
          />
          <div className={`${isOpen ? '' : 'hidden'}`}>
            <div className={`grid grid-cols-5 gap-x-8`}>
              {form.map(({ label, name, id, value, type, options }, index) => {
                const isInvalid =
                  errors &&
                  errors.details &&
                  errors.details[0].path[0] === name;
                return (
                  <div key={`form-input-${name}-${index}`}>
                    <label
                      htmlFor={name}
                      className='block text-sm font-medium text-gray-700'
                    >
                      {label}
                    </label>
                    <div className='mt-1'>
                      {type === 'select' ? (
                        <select
                          id={id}
                          className={`${
                            isInvalid ? 'border-2 border-red-500' : ''
                          } shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs border-gray-300 rounded-md`}
                          onChange={handleChange}
                          name={name}
                          value={value}
                        >
                          {options &&
                            options.map(({ name, value }) => (
                              <option key={`${name}-${value}`} value={value}>
                                {name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <input
                          className={`${
                            isInvalid ? 'border-2 border-red-500' : ''
                          } shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:border-red-800 disabled:bg-red-200 disabled:rounded-sm block w-full text-xs pl-1.5 border-gray-300 rounded-md`}
                          id={id}
                          name={name}
                          onChange={handleChange}
                          placeholder={label}
                          disabled={type === 'date' && enableBulk}
                          type={type}
                          value={value}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className={`${
                enableBulk ? '' : 'hidden'
              } mt-3 text-xs text-gray-500`}
            >
              <div className={`grid grid-cols-4 gap-x-1 gap-y-2`}>
                {/* Bulk Start Date */}
                <div key={`form-input-bulk_start_date`}>
                  <label
                    htmlFor='bulk_start_date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Bulk Start Date
                  </label>
                  <div className='mt-1'>
                    <input
                      className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md`}
                      id='bulkStart'
                      name='bulkStart'
                      onChange={handleBulkDateChange}
                      type='date'
                      value={bulkDates.bulkStart}
                    />
                  </div>
                </div>

                {/* Bulk End Date */}
                <div key={`form-input-bulk_end_date`}>
                  <label
                    htmlFor='bulk_end_date'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Bulk End Date
                  </label>
                  <div className='mt-1'>
                    <input
                      className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md`}
                      id='bulkEnd'
                      name='bulkEnd'
                      onChange={handleBulkDateChange}
                      type='date'
                      value={bulkDates.bulkEnd}
                    />
                  </div>
                </div>

                {/* Day of Week */}
                <div className='text-center mt-3 ml-2 pt-2 text-xs text-gray-600 bg-white'>
                  <DTFormDays
                    onChange={handleBulkDaysChange}
                    value={bulkDays}
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-row align-middle mt-2'>
              <span className='relative inline-flex items-center p-2 text-xs hover:bg-blue-600 text-white rounded bg-blue-500'>
                <span
                  className='relative inline-flex items-center rounded-l-md'
                  data-tip='Remember Form Values'
                >
                  <label htmlFor='select-all' className='sr-only'>
                    Create Another
                  </label>
                  <input
                    id='create-another'
                    type='checkbox'
                    name='create-another'
                    checked={createAnother}
                    onChange={() =>
                      dispatchCreateForm({
                        type: DTCreateActions.UPDATE,
                        payload: { createAnother: !createAnother },
                      })
                    }
                    className='h-4 w-4 rounded'
                  />
                </span>
                <button className='ml-2' onClick={handleSave}>
                  <span>
                    Create{' '}
                    {enableBulk ? `${pluralize(modelName, 2)}` : `${modelName}`}
                  </span>
                </button>
              </span>
              {dateWarning && form[0].value !== '' && (
                <div>
                  <div className='flex flex-row items-center'>
                    <span className='text-sm text-red-600 ml-2'>
                      <RiErrorWarningFill className='inline mr-1 mb-0.5' />
                      Existing event in this date range
                    </span>
                    <Popover>
                      <Popover.Button className='text-sm text-blue-500 ml-2 hover:text-blue-600'>
                        See Events
                      </Popover.Button>
                      <Popover.Panel className='absolute z-10 p-2 rounded border border-blue-500 bg-white min-h-[75px] min-w-[150px] max-h-48 overflow-auto'>
                        <div className='flex flex-row justify-around'>
                          <ul className='text-xs m-1'>
                            <li className='font-semibold text-black'>Date</li>
                            {dateWarning.map((object) =>
                              Object.keys(object).map((date) => {
                                return <li>{date}</li>;
                              })
                            )}
                          </ul>
                          <ul className='text-xs m-1'>
                            <li className='font-semibold text-black'>Event</li>
                            {dateWarning.map((warning) => {
                              return (
                                <li>
                                  {String(
                                    _.flattenDeep(Object.values(warning))
                                  ).replace(/,/g, ' | ')}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Popover.Panel>
                    </Popover>
                  </div>
                  <div className='text-sm ml-2'>
                    Add New Event:
                    <button
                      className='relative inline-flex items-center p-0.5 mx-0.5 text-xs hover:bg-green-600 text-white rounded bg-green-500'
                      onClick={handleConfirm}
                    >
                      Confirm
                    </button>
                    <button
                      className='relative inline-flex items-center p-0.5 mx-0.5 text-xs hover:bg-red-600 text-white rounded bg-red-500'
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              <div
                className={`${
                  BULK_ALLOWED.includes(modelName) ? '' : 'hidden'
                } flex items-center mt-2 ml-2 text-xs`}
              >
                <Toggle
                  checked={enableBulk}
                  label={enableBulk ? 'Disable Bulk Form' : 'Enable Bulk Form'}
                  onChange={() =>
                    dispatchCreateForm({
                      type: DTCreateActions.UPDATE,
                      payload: { enableBulk: !enableBulk },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DTForm;
