import { DAILY_HOTEL_NOTES } from '../gql/gql-daily-notes';
import { FindNotesArgs } from '../../../types/__generated__/globalTypes';
import { useQuery } from '@apollo/client';

export function useDailyHotelNotes(args: { filters: FindNotesArgs }) {
  return useQuery(DAILY_HOTEL_NOTES, {
    variables: {
      ...args,
    },
    skip: !args.filters.hotelId,
  });
}
