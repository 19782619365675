import { DATE_SEGMENT_FIELDS } from '../../../gql/fragments';
import { gql } from '@apollo/client';

export const CURRENT_RATE = gql`
  query CurrentRateTable($args: CurrentRateTableArgs!) {
    currentRateTable(args: $args) {
      brand_code
      stay_date
      ${DATE_SEGMENT_FIELDS}
      snapshot_date
      current_rate
      current_rate_ly
      current_rate_ly_final
      current_rate_ly_final_n
      current_rate_ly_n
    }
  }
`;
