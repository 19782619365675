import { CSVLink } from 'react-csv';
import { FaFileCsv } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { today } from '../../../helpers/dateHelpers';
import { useEffect } from 'react';

type DownloadCSVButtonProps = {
  data?: any[];
  headers?: Header[];
};

type Header = {
  title: string;
};

type Cell = {
  displayValue: string;
};

const DownloadCSVButton = ({ data, headers }: DownloadCSVButtonProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  const formattedHeaders = Array.isArray(headers)
    ? headers?.map((header: Header) => header.title)
    : [];

  const formattedData = Array.isArray(data)
    ? data.map((row: Cell[]) => row.map((cell: Cell) => cell.displayValue))
    : [];

  const headersAndData = [formattedHeaders, ...formattedData];

  return (
    <span data-tip='Download CSV'>
      <CSVLink data={headersAndData} filename={`Detail Table ${today()}`}>
        <FaFileCsv className='text-white' />
      </CSVLink>
    </span>
  );
};

export default DownloadCSVButton;
