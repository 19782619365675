import * as DateHelpers from '../../../helpers/dateHelpers';

import { useEffect, useState } from 'react';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Chart from '../../../components/Chart';
import { HeatRoomDataQuery } from '../gql/_gen_/heat-map.gql';
import { LoadingTooltip } from '../../reports/components/btn-share-meeting';
import { OtaiLowestRatesQuery } from '../../overview-table/gql/_gen_/rate-shop.gql';
import _ from 'lodash';
import { staticOptions } from '../heat-map-options';
import { useHeatMap } from '../hooks/use-heat-map';

type Option = {
  dataKey: string;
  label: string;
  source: string;
  roomCode?: string;
  compId?: string;
};

type OptionSections = {
  deltas: Option[];
  metrics: Option[];
  roomTypes?: Option[];
  rateShops?: Option[];
};

type HeatMapProps = {
  brandCode: string;
};

export default function HeatMap({ brandCode }: HeatMapProps) {
  const [options, setOptions] = useState<OptionSections>(staticOptions);
  const [selectedOption, setSelectedOption] = useState<Option>(
    staticOptions.deltas[0]
  );

  useEffect(() => {
    if (DateHelpers.dow() === 'Mon') {
      setSelectedOption(staticOptions.deltas[1]);
    } else {
      setSelectedOption(staticOptions.deltas[0]);
    }
  }, [brandCode]);

  const handleRoomOptions = (data?: HeatRoomDataQuery) => {
    if (data) {
      const rooms = _.uniqBy(data?.roomData, 'rt_description');
      const allocationOptions = rooms.map((room) => ({
        dataKey: 'rt_room_allocation',
        label: `Allocation ${room?.rt_description}`,
        source: 'roomData',
        roomCode: room?.room_code,
      }));

      const flexRateOptions = rooms.map((room) => ({
        dataKey: 'rt_flex_rate',
        label: `Flex Rate ${room?.rt_description}`,
        source: 'roomData',
        roomCode: room?.room_code,
      }));

      const occOptions = rooms.map((room) => ({
        dataKey: 'rt_occ',
        label: `Occupancy ${room?.rt_description}`,
        source: 'roomData',
        roomCode: room?.room_code,
      }));

      const remOptions = rooms.map((room) => ({
        dataKey: 'rt_remaining',
        label: `Remaining ${room?.rt_description}`,
        source: 'roomData',
        roomCode: room?.room_code,
      }));

      const soldOptions = rooms.map((room) => ({
        dataKey: 'rt_sold',
        label: `Sold ${room?.rt_description}`,
        source: 'roomData',
        roomCode: room?.room_code,
      }));

      const updatedOptions = {
        ...options,
        roomTypes: [
          ...allocationOptions,
          ...flexRateOptions,
          ...occOptions,
          ...remOptions,
          ...soldOptions,
        ],
      };

      setOptions(updatedOptions as OptionSections);
    }
  };

  const handleRateShopOptions = (data?: OtaiLowestRatesQuery) => {
    const competitors = data?.compSet?.filter(
      (c) => c?.competitor_id !== 'price_index'
    );
    if (competitors) {
      const compOptions = competitors.map((c) => [
        {
          dataKey: 'rate',
          label: `${c?.competitor_name}`,
          source: 'rate_shop',
          compId: c?.competitor_id,
        },
        {
          dataKey: 'rate_change_1_day',
          label: `${c?.competitor_name} Rate Δ 1 Day`,
          source: 'rate_shop',
          compId: c?.competitor_id,
        },
        {
          dataKey: 'rate_change_3_day',
          label: `${c?.competitor_name} Rate Δ 3 Day`,
          source: 'rate_shop',
          compId: c?.competitor_id,
        },
        {
          dataKey: 'rate_change_7_day',
          label: `${c?.competitor_name} Rate Δ 7 Day`,
          source: 'rate_shop',
          compId: c?.competitor_id,
        },
      ]);

      const updatedOptions = {
        ...options,
        rateShops: compOptions.flat(),
      };

      setOptions(updatedOptions as OptionSections);
    }
  };

  const { heatMapOptions, loading } = useHeatMap({
    brandCode,
    dataKey: selectedOption.dataKey,
    snapshotDate: DateHelpers.today(),
    startDate: DateHelpers.firstOfMonth({}),
    endDate: DateHelpers.monthsOut({ months: 11, firstOrLast: 'last' }),
    dataSource: selectedOption.source,
    roomType: selectedOption.roomCode,
    compId: selectedOption.compId,
    handleRoomOptions,
    handleRateShopOptions,
  });

  const handleSelect = (label: string) => {
    if (options) {
      const option = Object.values(options)
        .flat()
        .find((o) => o.label === label);
      if (option) {
        setSelectedOption(option);
      }
    }
  };

  const loadingList = Object.entries(loading)
    .filter(([, value]) => value === true)
    .map(([key]) => key);

  return (
    <div className='flex flex-col'>
      <div className='flex items-center'>
        <select
          value={selectedOption.label}
          onChange={(e) => handleSelect(e.target.value)}
          className='w-[40%] text-sm p-0.5 mt-1'
        >
          <optgroup label='Deltas'>
            {options?.deltas.map((option) => (
              <option key={option.dataKey} value={option.label}>
                {option.label}
              </option>
            ))}
          </optgroup>
          <optgroup label='Metrics'>
            {options?.metrics.map((option) => (
              <option key={option.dataKey} value={option.label}>
                {option.label}
              </option>
            ))}
          </optgroup>
          <optgroup label='Rate Shops'>
            {options?.rateShops?.map((option) => (
              <option
                key={`${option.label}-${option.dataKey}`}
                value={option.label}
              >
                {option.label}
              </option>
            ))}
          </optgroup>
          <optgroup label='Room Types'>
            {options?.roomTypes?.map((option) => (
              <option
                key={`${option.label}-${option.dataKey}`}
                value={option.label}
              >
                {option.label}
              </option>
            ))}
          </optgroup>
        </select>
        {loadingList.length ? (
          <div data-tip data-for='loading-data' className='ml-2'>
            <AiOutlineLoading3Quarters className='animate-spin text-blue-900' />
            <LoadingTooltip loading={loadingList} />
          </div>
        ) : null}
      </div>
      {heatMapOptions ? <Chart chartOptions={heatMapOptions} /> : null}
    </div>
  );
}
