import { MdTrendingDown, MdTrendingUp } from 'react-icons/md';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { Hotel } from '../../../graphql/types';
import { MdDeleteForever } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { useEffect } from 'react';
import { usePortfolioWidgetQuery } from '.././gql/_gen_/portfolio.gql';
import { useRemoveHotelFromPortfolioMutation } from '../gql/_gen_/portfolio.gql';

type HotelWidgetProps = {
  data?: Hotel | null;
  selected?: boolean;
  portfolioId?: string;
  setSelected: (hotel?: Hotel | null) => void;
};

function HotelWidget({
  data,
  selected,
  portfolioId,
  setSelected,
}: HotelWidgetProps) {
  const { brand_code: brandCode } = data || {};
  const startDate = DateHelpers.firstOfMonth({});
  const endDate = DateHelpers.monthsOut({ months: 11, firstOrLast: 'last' });

  const upArrow = <MdTrendingUp className='text-green-500 text-xl' />;
  const downArrow = <MdTrendingDown className='text-red-500 text-xl' />;

  const { data: widgetData, loading: widgetLoading } = usePortfolioWidgetQuery({
    skip: !data?.brand_code,
    variables: {
      brandCode,
      startDate,
      endDate,
      args: {
        brandCode,
        startDate,
        endDate,
        snapshotDate: DateHelpers.today(),
      },
    },
  });

  const [removeHotel] = useRemoveHotelFromPortfolioMutation({
    variables: {
      brandCode,
      portfolioId: portfolioId || '',
    },
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data, widgetLoading]);

  //#region Widget Data
  const liveData = widgetData?.liveData || [];
  const rmData = widgetData?.rmData || [];

  const liveSum = _.sumBy(liveData, 'transient_delta_intraday');
  const liveMin = _.minBy(
    liveData,
    'transient_delta_intraday'
  )?.transient_delta_intraday;
  const liveMax = _.maxBy(
    liveData,
    'transient_delta_intraday'
  )?.transient_delta_intraday;

  const liveRow = [liveSum, liveMin, liveMax];

  const isMonday = DateHelpers.dow() === 'Mon';

  const deltaSum = isMonday
    ? _.sumBy(rmData, 'delta_3day')
    : _.sumBy(rmData, 'delta_1day');
  const deltaMin = isMonday
    ? _.minBy(rmData, 'delta_3day')?.delta_3day
    : _.minBy(rmData, 'delta_1day')?.delta_1day;
  const deltaMax = isMonday
    ? _.maxBy(rmData, 'delta_3day')?.delta_3day
    : _.maxBy(rmData, 'delta_1day')?.delta_1day;

  const deltaRow = [deltaSum, deltaMin, deltaMax];

  const getData = (value: number | null | undefined, index: number) => {
    const sumTooltip = 'Sum for Snapshot';
    const minTooltip = 'Min for Snapshot';
    const maxTooltip = 'Max for Snapshot';

    const toolTip =
      index === 0 ? sumTooltip : index === 1 ? minTooltip : maxTooltip;

    if (value === 0) {
      return (
        <div
          key={index}
          className='flex items-center justify-center w-[45px]'
          data-tip={toolTip}
        >
          <span className='w-[20px]'> </span>
          <span className='m-1 text-sm text-gray-600'>{value}</span>
        </div>
      );
    }
    return (
      <div
        key={index}
        className='flex items-center justify-center m-0.5 w-[45px]'
        data-tip={toolTip}
      >
        {value && value > 0 ? upArrow : downArrow}{' '}
        <span className='text-sm text-gray-600 m-1'>{value}</span>
      </div>
    );
  };
  //#endregion

  return (
    <div
      className={`group h-28 w-[230px] shadow rounded m-2 p-2.5 ${
        selected && 'border-2 border-blue-700'
      }`}
      onClick={() => setSelected(data)}
    >
      <div className='h-full flex flex-col group'>
        <div className='flex justify-between items-center'>
          <h3
            className={`font-medium text-gray-700 ${
              selected ? 'blur-none' : 'blur-sm'
            } group-hover:blur-none`}
          >
            {brandCode}
          </h3>
          <button onClick={() => removeHotel()}>
            <MdDeleteForever className='invisible group-hover:visible text-red-500' />
          </button>
        </div>
        {widgetLoading ? (
          <div className='flex justify-center items-center h-full'>
            <AiOutlineLoading3Quarters className='animate-spin ml-2 text-blue-900 text-lg w-8 h-8' />
          </div>
        ) : (
          <div>
            <div className='flex justify-between items-center'>
              <div className='flex justify-around'>
                {deltaRow.map((value, index) => getData(value, index))}
              </div>
              <div className='text-sm font-semibold text-gray-700 w-[60px]'>
                {isMonday ? 'Δ 3D' : 'Δ 1D'}
              </div>
            </div>
            <div className='flex justify-between items-center'>
              <div className='flex justify-around'>
                {liveRow.map((value, index) => getData(value, index))}
              </div>
              <div className='text-sm font-semibold text-gray-700 w-[60px]'>
                Δ LV Trn
              </div>
            </div>
          </div>
        )}
      </div>
      <ReactTooltip />
    </div>
  );
}

export default HotelWidget;
