/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserOptionMutationVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  userId: Types.Scalars['String'];
  appNotes?: Types.InputMaybe<Types.Scalars['String']>;
  headers?: Types.InputMaybe<Types.Scalars['String']>;
  overviewSettings?: Types.InputMaybe<Types.Scalars['String']>;
  shopParams?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateUserOptionMutation = { __typename?: 'Mutation', updateUserOption?: { __typename?: 'UserOption', id?: string | null, appNotes?: string | null, brandCode?: string | null, headers?: string | null, overviewSettings?: string | null, shopParams?: string | null, userId?: string | null, createdAt?: string | null, updatedAt?: string | null } | null };

export type GetUserOptionQueryVariables = Types.Exact<{
  brandCode: Types.Scalars['String'];
  userId: Types.Scalars['String'];
}>;


export type GetUserOptionQuery = { __typename?: 'Query', getUserOption?: { __typename?: 'UserOption', id?: string | null, appNotes?: string | null, brandCode?: string | null, headers?: string | null, overviewSettings?: string | null, shopParams?: string | null, userId?: string | null, createdAt?: string | null, updatedAt?: string | null } | null };


export const UpdateUserOptionDocument = gql`
    mutation UpdateUserOption($brandCode: String!, $userId: String!, $appNotes: String, $headers: String, $overviewSettings: String, $shopParams: String) {
  updateUserOption(
    brandCode: $brandCode
    userId: $userId
    appNotes: $appNotes
    headers: $headers
    overviewSettings: $overviewSettings
    shopParams: $shopParams
  ) {
    id
    appNotes
    brandCode
    headers
    overviewSettings
    shopParams
    userId
    createdAt
    updatedAt
  }
}
    `;
export type UpdateUserOptionMutationFn = Apollo.MutationFunction<UpdateUserOptionMutation, UpdateUserOptionMutationVariables>;

/**
 * __useUpdateUserOptionMutation__
 *
 * To run a mutation, you first call `useUpdateUserOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOptionMutation, { data, loading, error }] = useUpdateUserOptionMutation({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      userId: // value for 'userId'
 *      appNotes: // value for 'appNotes'
 *      headers: // value for 'headers'
 *      overviewSettings: // value for 'overviewSettings'
 *      shopParams: // value for 'shopParams'
 *   },
 * });
 */
export function useUpdateUserOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserOptionMutation, UpdateUserOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserOptionMutation, UpdateUserOptionMutationVariables>(UpdateUserOptionDocument, options);
      }
export type UpdateUserOptionMutationHookResult = ReturnType<typeof useUpdateUserOptionMutation>;
export type UpdateUserOptionMutationResult = Apollo.MutationResult<UpdateUserOptionMutation>;
export type UpdateUserOptionMutationOptions = Apollo.BaseMutationOptions<UpdateUserOptionMutation, UpdateUserOptionMutationVariables>;
export const GetUserOptionDocument = gql`
    query GetUserOption($brandCode: String!, $userId: String!) {
  getUserOption(brandCode: $brandCode, userId: $userId) {
    id
    appNotes
    brandCode
    headers
    overviewSettings
    shopParams
    userId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetUserOptionQuery__
 *
 * To run a query within a React component, call `useGetUserOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOptionQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserOptionQuery(baseOptions: Apollo.QueryHookOptions<GetUserOptionQuery, GetUserOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOptionQuery, GetUserOptionQueryVariables>(GetUserOptionDocument, options);
      }
export function useGetUserOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOptionQuery, GetUserOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOptionQuery, GetUserOptionQueryVariables>(GetUserOptionDocument, options);
        }
export type GetUserOptionQueryHookResult = ReturnType<typeof useGetUserOptionQuery>;
export type GetUserOptionLazyQueryHookResult = ReturnType<typeof useGetUserOptionLazyQuery>;
export type GetUserOptionQueryResult = Apollo.QueryResult<GetUserOptionQuery, GetUserOptionQueryVariables>;
export const namedOperations = {
  Query: {
    GetUserOption: 'GetUserOption'
  },
  Mutation: {
    UpdateUserOption: 'UpdateUserOption'
  }
}