import { Hotel } from '../graphql/types';
import { useHotel } from '../context/hotelContext';
import { useMemo } from 'react';
import { useSetUseForecastMutation } from '../features/forecasts/gql/_gen_/use-forecast.gql';

export const useShowForecast = (hotelOveride?: Hotel) => {
  const { hotel } = useHotel();

  const showForecast = useMemo(() => {
    if (hotelOveride) {
      return hotelOveride.use_forecast !== false;
    }

    if (hotel) {
      return hotel.use_forecast !== false;
    } else {
      return true;
    }
  }, [hotel, hotelOveride]);

  const [changeUseForecast, { loading }] = useSetUseForecastMutation({
    refetchQueries: ['GetByBrandCode'],
  });

  const toggleUseForecast = () => {
    if (!hotel || !hotel.brand_code) return;

    changeUseForecast({
      variables: {
        hotel: {
          brand_code: hotel.brand_code,
          use_forecast: !showForecast,
        },
      },
    });
  };

  return { showForecast, toggleUseForecast, loading };
};
