import { Hotel } from '../graphql/types';
import _ from 'lodash';
import { logoMap } from '../img/logos';

const city = (hotel?: Hotel) => {
  return hotel?.city || '';
};

/**
 * Hotel City, State
 * @param hotel Hotel object
 * @returns An address string formatted as City, State
 */
export const cityState = (hotel?: Hotel) => {
  return hotel ? `${city(hotel)}, ${state(hotel)}` : '';
};

/**
 * Hotel Brand Logo
 * @param hotel Hotel object
 * @returns The brand logo for the hotel, or the default BW logo if not found
 */
export const logo = (hotel?: Hotel) => {
  return hotel?.descriptor
    ? logoMap[hotel.descriptor as keyof typeof logoMap]
    : logoMap.default;
};

/**
 * Hotel State formatted in Start Case.
 * From the database the state is all capitalized. This function converts it to
 * lowercase first, then to start case. This converts OREGON to Oregon.
 * @param hotel Hotel object
 * @returns A string formatted in Start Case for the state
 */
const state = (hotel?: Hotel) => {
  return hotel ? _.startCase(_.lowerCase(String(hotel.state))) : '';
};
