import DataTableContainer from '../DataTableContainer';
import RmRecsTable from '../tables/rm_recs/RmRecsTable';

function RmRecs() {
  return (
    <DataTableContainer>
      <RmRecsTable />
    </DataTableContainer>
  );
}

export default RmRecs;
