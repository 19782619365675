import { Switch } from '@headlessui/react';
import { classNames } from '../helpers/tailwindHelper';

type Props = {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
};

const Toggle = ({ checked, label, onChange }: Props) => {
  return (
    <Switch.Group as='div' className={`flex items-center my-2 text-xs`}>
      <Switch
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-4 w-6 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'
        )}
      >
        <span
          aria-hidden='true'
          className={classNames(
            checked ? 'translate-x-2' : 'translate-x-0',
            'pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as='span' className='ml-2'>
        <span className='text-gray-500'>{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
};

export default Toggle;
