export type FieldSelectProps = {
  customClass?: string;
  customLabel?: string;
  disabled?: boolean;
  field: string;
  loading?: boolean;
  onChange: (value: string) => void;
  options: FieldSelectOption[];
  showLabel?: boolean;
  value: FieldSelectOption['value'];
};

export type FieldSelectOption = {
  value: string | number | undefined;
  label: string;
};

export default function FieldSelect(props: FieldSelectProps) {
  const {
    customClass,
    customLabel,
    disabled = false,
    field,
    onChange,
    options,
    showLabel,
    value,
  } = props;

  const defaultClass =
    'ml-2 inline-block py-1 px-2 border rounded text-xs text-gray-500';

  const selectClass = customClass || defaultClass;
  const label = customLabel || field;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={selectClass}>
      {showLabel && (
        <label className='inline-block text-xs text-gray-500 mx-2'>
          {label}
        </label>
      )}
      <select
        className={`pr-1 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        id={field}
        name={field}
        onChange={handleChange}
        value={value}
        disabled={disabled}
      >
        {options.map((option) => {
          return (
            <option key={`option-key-${option.value}`} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
