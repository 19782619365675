import { Hotel, Meeting, User, UserLookupType } from '../../../graphql/types';
import { useEffect, useState } from 'react';
import {
  useGetMeetingByDateQuery,
  useGetMeetingByIdQuery,
} from '../gql/_gen_/meeting.gql';

import { today } from '../../../helpers/dateHelpers';
import { useGetByBrandCodeQuery } from '../gql/_gen_/hotel.gql';
import { useGetUserQuery } from '../../user/gql/_gen_/user.gql';
import { useIsShared } from '../../reports/hooks/use-report-location';

interface IUseMeeting {
  brandCode?: string;
  meetingId?: string;
}

export const useMeeting = ({ brandCode, meetingId }: IUseMeeting) => {
  const { isShared } = useIsShared();
  const [meeting, setMeeting] = useState<Meeting | undefined>();
  const [meetingHotel, setMeetingHotel] = useState<Hotel | undefined>();
  const [meetingUser, setMeetingUser] = useState<User | undefined>();

  const { data, loading } = useGetMeetingByDateQuery({
    skip: !brandCode || isShared,
    variables: {
      brandCode,
      meetingDate: today(),
    },
  });

  useEffect(() => {
    if (data && data?.getMeetingByDate) {
      setMeeting(data.getMeetingByDate);
    } else {
      setMeeting(undefined);
    }
  }, [data, loading]);

  const { data: dataId, loading: loadingId } = useGetMeetingByIdQuery({
    skip: !meetingId,
    variables: {
      meetingId,
    },
  });

  useEffect(() => {
    if (dataId && dataId?.getMeetingById) {
      setMeeting(dataId?.getMeetingById);
    } else {
      setMeeting(undefined);
    }
  }, [dataId, loadingId]);

  useGetByBrandCodeQuery({
    skip: !meeting?.brandCode || isShared,
    variables: {
      brandCode: meeting?.brandCode,
    },
    onCompleted: (data) => {
      if (data.getByBrandCode) setMeetingHotel(data.getByBrandCode);
    },
  });

  useGetUserQuery({
    skip: !meeting?.createdById || isShared,
    variables: {
      lookup: {
        lookupColumn: UserLookupType.Id,
        lookupValue: meeting?.createdById as string,
      },
    },
    onCompleted: (data) => {
      if (data.getUser) setMeetingUser(data.getUser);
    },
  });

  return {
    meeting,
    meetingHotel,
    meetingUser,
    loading: loading || loadingId,
    loadingId,
  };
};
