import { useCreateForecast, useUpdateForecast } from './useForecasts';
import { useCreateNote, useUpdateNote } from './useNotes';
import { useCreateRmRec, useUpdateRmRec } from './useRmRecs';
import { useDeleteEvent, useUpdateEvent } from './useEvents';

type UseGridCellParams = {
  callback: () => void;
};

export function useGridCell(params: UseGridCellParams) {
  const { callback } = params;

  const hookFuncs = {
    events: {
      delete: useDeleteEvent(),
      update: useUpdateEvent(),
    },
    forecasts: {
      create: useCreateForecast(),
      update: useUpdateForecast(),
    },
    notes: {
      create: useCreateNote(),
      update: useUpdateNote(),
    },
    rmRecs: {
      create: useCreateRmRec({ callback }),
      update: useUpdateRmRec({ callback }),
    },
  };

  return hookFuncs;
}
