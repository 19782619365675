import { HeaderObject } from '../../../features/overview-table/hooks/use-table';
import { useDashboard } from '../../../context/dashboardContext';
import { useFlags } from '@/context/feature-flags';

type Props = {
  columnCount: number;
  columnIndex: number;
  header: HeaderObject;
  isLocked: boolean;
  style: React.CSSProperties;
};

const rndCorners = (count: number, index: number) => {
  if (index === 0) {
    return 'rounded-tl-lg';
  } else if (index === count - 1) {
    return 'rounded-tr-lg';
  } else {
    return '';
  }
};

const Header = ({
  columnCount,
  columnIndex,
  header,
  isLocked,
  style,
}: Props) => {
  const { setSortDir, setSortKey, sortKey, sortDir } = useDashboard();
  const { flags } = useFlags();

  const flagShowSuperscript = flags?.['rate-change-superscript'];

  const isRateShop =
    header.meta.renderer === 'prm.rate_shop' && flagShowSuperscript;

  const baseClass = `sticky top-0 bg-blue-200 tracking-tighter font-semibold`;

  const sortDirColor =
    sortDir === 'asc' ? 'border-blue-600' : 'border-pink-600';

  const sortBorder =
    sortKey === header.dataKey
      ? `border-b-8 ${sortDirColor} border-opacity-50`
      : '';

  const textSize = header?.dataKey?.includes('rates.')
    ? 'text-xs mt-4'
    : 'text-xs pt-4';

  const zIdx = isLocked ? 'z-20' : 'z-10';

  const handleSortClicks = () => {
    if (header?.dataKey === sortKey) {
      toggleSortDir();
    } else {
      setSortKey(header.dataKey as string);
      setSortDir('desc');
    }
  };

  const toggleSortDir = () => {
    if (sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
  };

  return (
    <div
      className={`${baseClass} ${rndCorners(
        columnCount,
        columnIndex
      )} sort-border-div ${sortBorder} ${zIdx}`}
      style={style}
    >
      <div
        className={`flex flex-row ${
          isRateShop ? 'text-left pl-1' : 'justify-center'
        } ${textSize}`}
        data-tip={header.tooltip}
      >
        <span onClick={handleSortClicks}>{header.title}</span>
      </div>
    </div>
  );
};

export default Header;
