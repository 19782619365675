import { AiFillDashboard, AiFillFolderOpen } from 'react-icons/ai';
import { TabItems, tabItems } from '../../dashboards/tabItems';

import { HiDocumentReport } from 'react-icons/hi';
import { IconType } from 'react-icons/lib';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';

export type MenuItem = {
  adminOnly?: boolean;
  href: string;
  icon: IconType;
  key: string;
  tabItems?: TabItems[];
  title: string;
};

export const menuItems: MenuItem[] = [
  {
    href: '/dashboard/overview',
    icon: AiFillDashboard,
    key: 'dashboard',
    tabItems: tabItems,
    title: 'Dashboards',
  },
  {
    href: '/reports',
    icon: HiDocumentReport,
    key: 'reports',
    title: 'Reports',
  },
  {
    href: '/portfolio',
    icon: AiFillFolderOpen,
    key: 'portfolio',
    title: 'Portfolio',
  },
  {
    adminOnly: true,
    href: '/admin',
    icon: MdOutlineAdminPanelSettings,
    key: 'admin',
    title: 'Admin',
  },
];
