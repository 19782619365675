import {
  State as Checked,
  Action as CheckedActions,
} from '../../reducers/useBulkCheckboxes';
import { MdClear, MdDeleteForever } from 'react-icons/md';

import { Actions } from '../../types/DataTable';
import { TableFilters } from '../../reducers/useDataTableOptions';
import _ from 'lodash';
import { defaultDays } from '../../components/data_table/FilterDays';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';

type Props = {
  actions?: Actions;
  checked: Checked;
  dataRange?: string[];
  setChecked: React.Dispatch<CheckedActions>;
  setTableFilters: (tableFilters: TableFilters) => void;
  tableFilters: TableFilters;
  tableName: string;
};

const DTHeaderBulk = ({
  actions,
  checked,
  dataRange,
  setChecked,
  setTableFilters,
  tableFilters,
  tableName,
}: Props) => {
  const isDefaultDays = _.every(_.values(tableFilters), defaultDays);
  const hideBulkReset = _.isEmpty(tableFilters) || isDefaultDays;

  const handleResetFilters = () => {
    if (!dataRange || dataRange.length < 2) {
      setTableFilters({});
    } else {
      setTableFilters({
        startDate: dataRange[0],
        endDate: dataRange[1],
      });
    }
  };

  return (
    <div className='flex flex-row items-center mx-auto pl-6'>
      <input
        className='mr-3'
        checked={checked.bulk || false}
        readOnly={true}
        type='checkbox'
        name='bulk_all'
        id='bulk_all'
        onClick={() =>
          setChecked({
            key: 'bulk',
            type: checked.bulk ? 'UNCHECK_ALL' : 'CHECK_ALL',
          })
        }
      />
      {actions ? (
        <MdDeleteForever
          data-tip='Delete Selected'
          className={`cursor-pointer text-red-700 h-5 w-5 ${
            checked.bulk === false && 'hidden'
          } ${actions?.deleteStatus && 'animate-spin'}`}
          onClick={() => {
            const targetIds: string[] = [];
            _.forEach(_.omit(checked, ['bulk']), (value, key) => {
              if (value) {
                targetIds.push(key);
              }
            });
            toast.promise(actions.delete(targetIds), {
              pending: 'Delete is pending...',
              success: `Success! Deleted ${pluralize(
                tableName,
                targetIds.length,
                true
              )}`,
              error: 'There was an issue, sorry.',
            });
            setChecked({
              key: 'bulk',
              type: 'UNCHECK_ALL',
            });
          }}
        />
      ) : null}
      <button
        type='button'
        onClick={handleResetFilters}
        className={`${
          hideBulkReset ? 'hidden' : ''
        } inline-flex items-center ml-1 px-1 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500`}
      >
        <MdClear className='h-2 w-2' />
      </button>
    </div>
  );
};

export default DTHeaderBulk;
