import { CellObject, HeaderObject } from '../../overview-table/hooks/use-table';

import DataGrid from '../../../components/DataGrid';
import { DateHelpers } from '../../../helpers/dateHelpers';
import { OtaiLowestRatesQuery } from '../../overview-table/gql/_gen_/rate-shop.gql';
import RateDataTable from './rate-data-table';
import clsx from 'clsx';

type DetailTableProps = {
  brandCode: string;
  handleTableChange: (tableVersion: string) => void;
  headers: HeaderObject[];
  rateData?: OtaiLowestRatesQuery;
  rows: CellObject[][];
  tableVersion: string;
  todaysDate?: string;
};

export const DetailTable = ({
  brandCode,
  handleTableChange,
  headers,
  rateData,
  rows,
  tableVersion,
  todaysDate = DateHelpers.today(),
}: DetailTableProps) => {
  return (
    <>
      <nav className='mb-2'>
        <button
          className={clsx(
            'text-sm font-semibold px-2',
            tableVersion === 'detail' ? 'text-blue-900' : 'text-gray-400'
          )}
          onClick={() => handleTableChange('detail')}
          disabled={tableVersion === 'detail'}
        >
          Detail Table
        </button>
        <button
          className={clsx(
            'text-sm font-semibold px-2',
            tableVersion === 'rates' ? 'text-blue-900' : 'text-gray-400'
          )}
          onClick={() => handleTableChange('rates')}
          disabled={tableVersion === 'rates'}
        >
          Rate Data
        </button>
      </nav>
      {tableVersion === 'detail' ? (
        <DataGrid
          headers={headers}
          rows={rows}
          brandCode={brandCode}
          colLock={1}
          colToggles={{}}
          dateSegment='day'
          monthOffset={0}
          snapshotDate={todaysDate}
          sortDir='asc'
          sortKey='stay_date'
          targetDate={{
            value: todaysDate,
          }}
          startDate={DateHelpers.subtract(todaysDate, 'month', 1)}
          setSortDir={() => {}}
          setSortKey={() => {}}
        />
      ) : (
        <RateDataTable
          rateData={rateData || { dailyRates: [], compSet: [] }}
          brandCode={brandCode}
        />
      )}
    </>
  );
};
