import { Meeting, MeetingService } from '../../../graphql/types';
import { useEffect, useMemo, useRef, useState } from 'react';

import { HandleSaveMeetingOptions } from '../context/meeting-context';
import { Hotel } from '../../../graphql/types';
import { ReportDataType } from '../../reports/context/report-data-context';
import { User } from '../../../graphql/types';
import axios from 'axios';
import { isEqual } from 'lodash';
import useTimeout from '../../../hooks/useTimeout';
import { useUploadUrlLazyQuery } from '../gql/_gen_/meeting.gql';

export interface SharedData extends HandleSaveMeetingOptions {
  hotel: Hotel;
  user: User;
}

export interface UseUploadDataArgs {
  data?: ReportDataType;
  dataLoading?: boolean;
  hotel?: Hotel;
  meeting?: Meeting;
  meetingTools?: MeetingService;
  user?: User | null;
}

export const useUploadData = ({
  data,
  dataLoading,
  hotel,
  meeting,
  meetingTools,
  user,
}: UseUploadDataArgs) => {
  const [changed, setChanged] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [fetching, setFetching] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>();
  const [uploading, setUploading] = useState<boolean>(false);

  const reportData = useMemo(() => {
    return { data, hotel, meetingTools, user };
  }, [data, hotel, meetingTools, user]);

  const dataRef = useRef(data);

  const { reset } = useTimeout({
    callback: () => {
      setSuccess(undefined);
      setError(undefined);
    },
    delay: 2500,
  });

  useEffect(() => {
    if (dataRef.current && !isEqual(dataRef.current, data)) {
      dataRef.current = data;
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [data]);

  const [getUploadUrl] = useUploadUrlLazyQuery();

  const storeMeetingData = async () => {
    // Verify Meeting is valid
    if (
      meeting &&
      meeting.id &&
      meeting.bucket &&
      meeting.bucketKey &&
      !meeting.bucketKey.includes('undefined')
    ) {
      if (
        data &&
        hotel &&
        meetingTools &&
        user &&
        meeting.brandCode === hotel.brand_code &&
        meeting.bucketKey.includes(meeting.brandCode as string) &&
        !dataLoading
      ) {
        const { bucket, bucketKey: key } = meeting;
        setSuccess(undefined);
        setFetching(true);
        // This gives us a presigned url to upload the data to S3 directly from the browser
        const { data: signedUrlResponse, error: uploadError } =
          await getUploadUrl({
            variables: {
              bucket,
              key,
            },
          });

        if (uploadError) {
          setSuccess(false);
          setError(uploadError.message);
          setFetching(false);
          return;
        }
        setFetching(false);

        if (
          signedUrlResponse?.uploadUrl &&
          signedUrlResponse?.uploadUrl.includes(key)
        ) {
          setUploading(true);

          const response = await axios.put(
            signedUrlResponse.uploadUrl,
            reportData
          );

          if (response.status === 200) {
            setSuccess(true);
            setError(undefined);
            setUploading(false);
            setChanged(false);
            reset();
            return;
          } else {
            setSuccess(false);
            setError(response.statusText);
            setUploading(false);
            reset();
            return;
          }
        }
      }
    }
  };

  return { changed, error, fetching, storeMeetingData, success, uploading };
};
