import { fromToday, today } from '../../../helpers/dateHelpers';
import {
  namedOperations,
  useAddMeetingMutation,
  useGetMeetingsQuery,
  useUpdateMeetingMutation,
} from '../../meetings/gql/_gen_/meeting.gql';
import { useEffect, useState } from 'react';

import { Meeting } from '../../../graphql/types';
import { Status } from '../types/status';
import { delay } from 'lodash';
import { useGetUserOptionQuery } from '../../user-options/gql/_gen_/user-options.gql';
import { useIsShared } from '../../reports/hooks/use-report-location';

export interface UseMeetingNotesProps {
  brandCode?: string;
  meeting?: Meeting;
  onSuccess?: () => void;
  setMeeting?: (meeting: Meeting) => void;
  userId?: string;
}

export const useMeetingNotes = ({
  brandCode,
  meeting,
  onSuccess,
  setMeeting,
  userId,
}: UseMeetingNotesProps) => {
  const { isShared } = useIsShared();
  const [notes, setNotes] = useState(meeting?.notes || '');
  const [prevNotes, setPrevNotes] = useState('');
  const [notepad, setNotepad] = useState('');
  const [status, setStatus] = useState<Status>();

  const filterMtg = (mtg: Meeting | null) => {
    return mtg && mtg.id !== meeting?.id && mtg.notes && mtg.notes !== '';
  };

  const skip =
    !brandCode ||
    brandCode === undefined ||
    brandCode === null ||
    brandCode === 'undefined' ||
    !userId ||
    userId === undefined ||
    userId === null ||
    userId === 'undefined' ||
    isShared;

  useGetUserOptionQuery({
    skip,
    variables: {
      brandCode: String(brandCode),
      userId: String(userId),
    },
    onCompleted: (data) => {
      if (data && data.getUserOption && data.getUserOption.appNotes) {
        setNotepad(data.getUserOption.appNotes);
      }
    },
  });

  useGetMeetingsQuery({
    skip: !brandCode || isShared,
    variables: {
      brandCode,
      meetingDate: fromToday(-7),
    },
    onCompleted: (data) => {
      if (data && data.getMeetings) {
        data.getMeetings.filter(filterMtg).every((prevMeeting) => {
          if (prevMeeting) {
            setPrevNotes(prevMeeting.notes!);
            return false;
          }
          return true;
        });
      }
    },
  });

  const [saveMeeting] = useAddMeetingMutation({
    refetchQueries: [namedOperations.Query.GetMeetingByDate],
  });
  const [updateMeeting, { loading }] = useUpdateMeetingMutation();

  useEffect(() => {
    if (!meeting || meeting.brandCode !== brandCode) setNotes('');
    if (meeting?.notes) {
      setNotes(meeting.notes);
    } else {
      setNotes('');
    }
  }, [brandCode, meeting]);

  useEffect(() => {
    if (loading) setStatus(Status.SAVING);
    if (notes === meeting?.notes) {
      setStatus(Status.SAVED);
      delay(() => setStatus(undefined), 2000);
    } else if (meeting && meeting.notes !== notes) {
      setStatus(Status.UNSAVED);
    } else if (!meeting && notes !== '') {
      setStatus(Status.UNSAVED);
    } else {
      setStatus(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, meeting?.notes, notes]);

  const handleSave = async (content: string) => {
    if (meeting && meeting.id) {
      await updateMeeting({
        variables: {
          updateMeetingId: meeting.id,
          notes: content,
        },
        onCompleted: (data) => {
          if (data?.updateMeeting?.notes) {
            setNotes(data.updateMeeting.notes);
            setMeeting && setMeeting(data.updateMeeting);
            onSuccess && onSuccess();
          }
        },
      });
    } else if (!meeting?.id && userId && brandCode && content) {
      await saveMeeting({
        variables: {
          brandCode,
          createdById: userId,
          meetingDate: today(),
          notes: content,
        },
        onCompleted: (data) => {
          if (data?.addMeeting?.notes) {
            setNotes(data.addMeeting.notes);
            setMeeting && setMeeting(data.addMeeting);
            onSuccess && onSuccess();
          }
        },
      });
    }
  };

  return {
    handleSave,
    prevNotes,
    notepad,
    notes,
    setNotes,
    status,
  };
};
