import { useTrackingByHotelQuery } from '../gql/_gen_/tracking.gql';

export const useHotelTracking = (
  brandCode?: string,
  limit?: number,
  cursor?: string
) => {
  return useTrackingByHotelQuery({
    skip: !brandCode,
    variables: {
      brandCode: brandCode,
      limit,
      cursor,
    },
  });
};
