import {
  UserSetting_userSetting_dashboards_detail_table_hotels_columns as CustomColumnSettings,
  UserSetting,
} from '../../../types/__generated__/UserSetting';
import {
  useGetUserOptionQuery,
  useUpdateUserOptionMutation,
} from '../../user-options/gql/_gen_/user-options.gql';

import { GetUserOptionVariables } from '../../../types/__generated__/GetUserOption';
import { pick } from 'lodash';
import { useGetOldSettings } from './old-settings';

const ALLOWED_COLUMN_KEYS = ['key', 'header'];
const OLD_PREFIX = [
  'bestrev.',
  'budgets.',
  'forecasts.',
  'live_data.',
  'rm_forecast_data.',
  'roomtypes.',
];

export const useGetCustomHeaders = ({
  brandCode,
  userId,
}: {
  brandCode: string;
  userId: string;
}): {
  headers: CustomColumnSettings[] | null;
  loading: boolean;
} => {
  const skip =
    !brandCode ||
    brandCode === undefined ||
    brandCode === null ||
    brandCode === 'undefined' ||
    !userId ||
    userId === undefined ||
    userId === null ||
    userId === 'undefined';

  const { data, loading } = useGetUserOptionQuery({
    skip,
    variables: { brandCode, userId },
  });
  const strHeaders = data?.getUserOption?.headers;
  const headers = strHeaders ? JSON.parse(strHeaders) : null;

  return {
    headers,
    loading,
  };
};

export const useImportOldSettings = (userId?: string) => {
  const { data }: { data: UserSetting } = useGetOldSettings(userId);
  const [saveHeaders] = useUpdateUserOptionMutation();

  const handleSave = async (brandCode: string, headers: string) => {
    if (headers && userId && brandCode) {
      await saveHeaders({
        variables: {
          brandCode,
          userId,
          headers,
        },
      });
    }
  };

  const importOldSettings = async () => {
    data?.userSetting?.dashboards?.detail_table?.hotels?.forEach(
      async (hotelSetting) => {
        if (hotelSetting) {
          const { brandCode, columns } = hotelSetting;
          if (columns) {
            const adjColumns = columns.map((column) => {
              const { key } = column as CustomColumnSettings;
              let adjKey = keyReplace(key as string);

              return { ...pick(column, ALLOWED_COLUMN_KEYS), key: adjKey };
            });
            await handleSave(brandCode, JSON.stringify(adjColumns));
          }
        }
      }
    );
  };

  const keyReplace = (key: string): string => {
    if (key.includes('rate_shops.')) {
      return rateReplace(key);
    } else if (key.includes('price_index')) {
      return 'rates.price_index';
    } else if (key.includes('roomtypes.')) {
      return roomReplace(key);
    } else {
      return defaultReplace(key);
    }
  };

  const defaultReplace = (key: string): string => {
    OLD_PREFIX.forEach((preFix) => {
      key = key.replace(preFix, '');
    });
    return key;
  };

  const rateReplace = (key: string): string => {
    return key
      .replace('rate_shops.rate_shop_', 'rates.')
      .replace('.amount', '');
  };

  const roomReplace = (key: string): string => {
    return key.replace('roomtypes.roomtype_detail_', 'room_types.');
  };

  return { importOldSettings };
};

export const useSaveCustomHeaders = ({
  brandCode,
  userId,
}: GetUserOptionVariables) => {
  const [saveHeaders, { loading }] = useUpdateUserOptionMutation();
  const handleSaveCustomHeaders = async (headers: string) => {
    if (headers && userId && brandCode) {
      await saveHeaders({
        variables: {
          brandCode,
          userId,
          headers,
        },
      });
    }
  };
  return { handleSaveCustomHeaders, loading };
};
