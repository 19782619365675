import 'react-quill/dist/quill.snow.css';

import { useRef, useState } from 'react';

import { GiNotebook } from 'react-icons/gi';
import { NotesToolbar } from '../../notes/components/notes-toolbar';
import { Popover } from '@headlessui/react';
import ReactQuill from 'react-quill';
import { Status } from '../../notes/types/status';
import { useNotepad } from '../hooks/use-notepad';
import { usePopper } from 'react-popper';

interface NotepadButtonProps {
  brandCode?: string;
  userId?: string | null;
}

const NotepadButton = ({ brandCode, userId }: NotepadButtonProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const editorRef = useRef<ReactQuill>(null);
  const { notepad, setNotepad, loadingSave, saveNotes, savedValue } =
    useNotepad({
      brandCode,
      editorRef,
      userId,
    });

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'left-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [20, 5],
        },
      },
    ],
  });

  const handleSave = async () => {
    if (notepad !== savedValue) {
      await saveNotes();
    }
  };

  const savedStatus = loadingSave
    ? Status.SAVING
    : notepad === savedValue
    ? Status.SAVED
    : notepad.length
    ? Status.UNSAVED
    : undefined;

  return (
    <Popover>
      {userId && (
        <Popover.Button
          type='button'
          ref={setReferenceElement}
          data-tip='Notepad'
          className='relative inline-flex items-center mx-2 bg-blue-900 text-white hover:text-gray-300 rounded-r-md focus:outline-none'
        >
          <GiNotebook />
        </Popover.Button>
      )}

      {/* Notepad Editor */}
      <Popover.Panel
        className='bg-white shadow-md max-w-[50%] min-w-[410px] rounded-lg p-2'
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: 9999 }}
        {...attributes.popper}
      >
        <h1 className='font-medium ml-2'>Notepad for {brandCode}</h1>
        <div className='p-2 text-left border rounded-md border-dashed border-gray-400'>
          <NotesToolbar status={savedStatus} handleSave={handleSave} />
          <ReactQuill
            readOnly={loadingSave}
            ref={editorRef}
            theme='snow'
            value={notepad}
            onChange={setNotepad}
            onBlur={handleSave}
            modules={{
              toolbar: '#toolbar',
            }}
          />
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export { NotepadButton };
