/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionItemStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export enum BrandDescriptor {
  ADEN = 'ADEN',
  BEST = 'BEST',
  BWSC = 'BWSC',
  EXRE = 'EXRE',
  GLO = 'GLO',
  PLUS = 'PLUS',
  PMCL = 'PMCL',
  PRMR = 'PRMR',
  SADI = 'SADI',
  SSES = 'SSES',
  SSH = 'SSH',
  SSPL = 'SSPL',
  SSSC = 'SSSC',
  SUES = 'SUES',
  SUH = 'SUH',
  SUPL = 'SUPL',
  SUSC = 'SUSC',
  VIB = 'VIB',
  WHCC = 'WHCC',
  WHDI = 'WHDI',
  WHEL = 'WHEL',
  WHLX = 'WHLX',
}

export enum Country {
  ALBANIA = 'ALBANIA',
  ALGERIA = 'ALGERIA',
  ANTIGUA_AND_BARBUDA = 'ANTIGUA_AND_BARBUDA',
  ARGENTINA = 'ARGENTINA',
  ARMENIA = 'ARMENIA',
  ARUBA = 'ARUBA',
  AUSTRALIA = 'AUSTRALIA',
  AUSTRIA = 'AUSTRIA',
  BAHAMAS = 'BAHAMAS',
  BAHRAIN = 'BAHRAIN',
  BANGLADESH = 'BANGLADESH',
  BELGIUM = 'BELGIUM',
  BELIZE = 'BELIZE',
  BENIN = 'BENIN',
  BOLIVIA = 'BOLIVIA',
  BOTSWANA = 'BOTSWANA',
  BRAZIL = 'BRAZIL',
  BULGARIA = 'BULGARIA',
  CAMBODIA = 'CAMBODIA',
  CAMEROON = 'CAMEROON',
  CANADA = 'CANADA',
  CAYMAN_ISLANDS = 'CAYMAN_ISLANDS',
  CHILE = 'CHILE',
  CHINA = 'CHINA',
  COLOMBIA = 'COLOMBIA',
  COOK_ISLANDS = 'COOK_ISLANDS',
  COSTA_RICA = 'COSTA_RICA',
  CROATIA = 'CROATIA',
  CURACAO = 'CURACAO',
  CYPRUS = 'CYPRUS',
  CZECH_REPUBLIC = 'CZECH_REPUBLIC',
  DENMARK = 'DENMARK',
  DOMINICAN_REPUBLIC = 'DOMINICAN_REPUBLIC',
  ECUADOR = 'ECUADOR',
  EGYPT = 'EGYPT',
  EL_SALVADOR = 'EL_SALVADOR',
  ESTONIA = 'ESTONIA',
  ETHIOPIA = 'ETHIOPIA',
  FIJI = 'FIJI',
  FIJI_ISLANDS = 'FIJI_ISLANDS',
  FINLAND = 'FINLAND',
  FRANCE = 'FRANCE',
  FRENCH_GUIANA = 'FRENCH_GUIANA',
  GEORGIA = 'GEORGIA',
  GERMANY = 'GERMANY',
  GHANA = 'GHANA',
  GREAT_BRITAIN = 'GREAT_BRITAIN',
  GREAT_BRITAIN_UK = 'GREAT_BRITAIN_UK',
  GREECE = 'GREECE',
  GREENLAND = 'GREENLAND',
  GRENADA = 'GRENADA',
  GUADELOUPE = 'GUADELOUPE',
  GUAM = 'GUAM',
  GUATEMALA = 'GUATEMALA',
  HAITI = 'HAITI',
  HONDURAS = 'HONDURAS',
  HUNGARY = 'HUNGARY',
  ICELAND = 'ICELAND',
  INDIA = 'INDIA',
  INDONESIA = 'INDONESIA',
  IRAQ = 'IRAQ',
  IRELAND = 'IRELAND',
  ISRAEL = 'ISRAEL',
  ITALY = 'ITALY',
  JAPAN = 'JAPAN',
  JORDAN = 'JORDAN',
  KAZAKHSTAN = 'KAZAKHSTAN',
  KENYA = 'KENYA',
  KOSOVO = 'KOSOVO',
  KUWAIT = 'KUWAIT',
  LAOS = 'LAOS',
  LATVIA = 'LATVIA',
  LEBANON = 'LEBANON',
  LITHUANIA = 'LITHUANIA',
  LUXEMBOURG = 'LUXEMBOURG',
  MACEDONIA = 'MACEDONIA',
  MALAYSIA = 'MALAYSIA',
  MALDIVES = 'MALDIVES',
  MALTA = 'MALTA',
  MARTINIQUE = 'MARTINIQUE',
  MAURITIUS = 'MAURITIUS',
  MEXICO = 'MEXICO',
  MOLDOVA = 'MOLDOVA',
  MONGOLIA = 'MONGOLIA',
  MOROCCO = 'MOROCCO',
  MYANMAR = 'MYANMAR',
  NEPAL = 'NEPAL',
  NETHERLANDS = 'NETHERLANDS',
  NETHERLANDS_ANTILLES = 'NETHERLANDS_ANTILLES',
  NEW_CALEDONIA = 'NEW_CALEDONIA',
  NEW_ZEALAND = 'NEW_ZEALAND',
  NICARAGUA = 'NICARAGUA',
  NIGERIA = 'NIGERIA',
  NORTHERN_MARIANA_ISLANDS = 'NORTHERN_MARIANA_ISLANDS',
  NORWAY = 'NORWAY',
  OMAN = 'OMAN',
  PAKISTAN = 'PAKISTAN',
  PANAMA = 'PANAMA',
  PARAGUAY = 'PARAGUAY',
  PERU = 'PERU',
  PHILIPPINES = 'PHILIPPINES',
  POLAND = 'POLAND',
  PORTUGAL = 'PORTUGAL',
  PUERTO_RICO = 'PUERTO_RICO',
  QATAR = 'QATAR',
  REPUBLIC_OF_KOREA = 'REPUBLIC_OF_KOREA',
  REPUBLIC_OF_MONTENEGRO = 'REPUBLIC_OF_MONTENEGRO',
  REUNION_FRENCH = 'REUNION_FRENCH',
  ROMANIA = 'ROMANIA',
  RUSSIA = 'RUSSIA',
  SAINT_LUCIA = 'SAINT_LUCIA',
  SAN_MARINO_REPUBLIC = 'SAN_MARINO_REPUBLIC',
  SAUDI_ARABIA = 'SAUDI_ARABIA',
  SERBIA = 'SERBIA',
  SEYCHELLES_ISL = 'SEYCHELLES_ISL',
  SINGAPORE = 'SINGAPORE',
  SLOVAKIA = 'SLOVAKIA',
  SLOVENIA = 'SLOVENIA',
  SOUTH_AFRICA = 'SOUTH_AFRICA',
  SPAIN = 'SPAIN',
  SRI_LANKA = 'SRI_LANKA',
  SURINAME = 'SURINAME',
  SWEDEN = 'SWEDEN',
  SWITZERLAND = 'SWITZERLAND',
  TAIWAN = 'TAIWAN',
  TANZANIA = 'TANZANIA',
  THAILAND = 'THAILAND',
  TONGA = 'TONGA',
  TRINIDAD_AND_TOBAGO = 'TRINIDAD_AND_TOBAGO',
  TUNISIA = 'TUNISIA',
  TURKEY = 'TURKEY',
  TURKS_AND_CAICOS_ISLANDS = 'TURKS_AND_CAICOS_ISLANDS',
  UGANDA = 'UGANDA',
  UKRAINE = 'UKRAINE',
  UNITED_ARAB_EMIRATES = 'UNITED_ARAB_EMIRATES',
  UNITED_STATES = 'UNITED_STATES',
  URUGUAY = 'URUGUAY',
  VENEZUELA = 'VENEZUELA',
  VIETNAM = 'VIETNAM',
  VIET_NAM = 'VIET_NAM',
  VIRGIN_ISLANDS = 'VIRGIN_ISLANDS',
  VIRGIN_ISLANDS_US = 'VIRGIN_ISLANDS_US',
  YEMEN = 'YEMEN',
  ZAMBIA = 'ZAMBIA',
  ZIMBABWE = 'ZIMBABWE',
}

export enum ForecastLookupType {
  CREATED_BY_ID = 'CREATED_BY_ID',
  HOTEL_ID = 'HOTEL_ID',
  ID = 'ID',
}

export enum MealType {
  ALL_INCLUSIVE = 'ALL_INCLUSIVE',
  BREAKFAST = 'BREAKFAST',
  FULL_BOARD = 'FULL_BOARD',
  HALF_BOARD = 'HALF_BOARD',
  NO_MEAL = 'NO_MEAL',
  ROOM_ONLY = 'ROOM_ONLY',
}

export enum MemberStatus {
  ATTENDING = 'ATTENDING',
  NOT_ATTENDING = 'NOT_ATTENDING',
}

export enum OtaiSubLookupType {
  BRAND_CODE = 'BRAND_CODE',
  ID = 'ID',
  SUB_ID = 'SUB_ID',
}

export enum RmRecStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  ERROR = 'ERROR',
  OPEN = 'OPEN',
  PROCESSING = 'PROCESSING',
  UPLOADED = 'UPLOADED',
}

export enum State {
  ABW = 'ABW',
  AE = 'AE',
  AIN = 'AIN',
  ALABAMA = 'ALABAMA',
  ALASKA = 'ALASKA',
  ALBANIA = 'ALBANIA',
  ALBERTA = 'ALBERTA',
  ALGERIA = 'ALGERIA',
  ALPES_MARITIMES = 'ALPES_MARITIMES',
  ANTIGUA_AND_BARBUDA = 'ANTIGUA_AND_BARBUDA',
  ARGENTINA = 'ARGENTINA',
  ARIZONA = 'ARIZONA',
  ARKANSAS = 'ARKANSAS',
  ARMENIA = 'ARMENIA',
  AUSTALIA = 'AUSTALIA',
  AUSTRALIA = 'AUSTRALIA',
  AUSTRALIAN_CAPITAL = 'AUSTRALIAN_CAPITAL',
  AUSTRALIAN_CAPITAL_TERRITORY = 'AUSTRALIAN_CAPITAL_TERRITORY',
  AUSTRIA = 'AUSTRIA',
  AV = 'AV',
  AZ = 'AZ',
  BAHRAIN = 'BAHRAIN',
  BALI = 'BALI',
  BANGKOK = 'BANGKOK',
  BANGLADESH = 'BANGLADESH',
  BELGIUM = 'BELGIUM',
  BELIZE = 'BELIZE',
  BENIN = 'BENIN',
  BH = 'BH',
  BOLIVIA = 'BOLIVIA',
  BOTSWANA = 'BOTSWANA',
  BRAZIL = 'BRAZIL',
  BRITISH_COLUMBIA = 'BRITISH_COLUMBIA',
  BULGARIA = 'BULGARIA',
  CA = 'CA',
  CALIFORNIA = 'CALIFORNIA',
  CAMBODIA = 'CAMBODIA',
  CAMEROON = 'CAMEROON',
  CANARY_ISLANDS = 'CANARY_ISLANDS',
  CARIBBEAN = 'CARIBBEAN',
  CARRIBEAN = 'CARRIBEAN',
  CHANNEL_ISLANDS = 'CHANNEL_ISLANDS',
  CHILE = 'CHILE',
  CHINA = 'CHINA',
  CN = 'CN',
  CO = 'CO',
  COLOMBIA = 'COLOMBIA',
  COLORADO = 'COLORADO',
  COMBODIA = 'COMBODIA',
  CONNECTICUT = 'CONNECTICUT',
  COOK_ISLAND = 'COOK_ISLAND',
  COSTA_RICA = 'COSTA_RICA',
  CRETE = 'CRETE',
  CROATIA = 'CROATIA',
  CUR = 'CUR',
  CURACAO_COUNTRY = 'CURACAO_COUNTRY',
  CYPRUS = 'CYPRUS',
  CZECH_REPUBLIC = 'CZECH_REPUBLIC',
  DE = 'DE',
  DELAWARE = 'DELAWARE',
  DENMARK = 'DENMARK',
  DISTRICT_OF_COLUMBIA = 'DISTRICT_OF_COLUMBIA',
  DO = 'DO',
  DO_12 = 'DO_12',
  ECUADOR = 'ECUADOR',
  EGPYT = 'EGPYT',
  EGYPT = 'EGYPT',
  EL_SALVADOR = 'EL_SALVADOR',
  EN = 'EN',
  ENGLAND = 'ENGLAND',
  ESSONNE = 'ESSONNE',
  ESTONIA = 'ESTONIA',
  ETHIOPIA = 'ETHIOPIA',
  FAROE_ISLANDS = 'FAROE_ISLANDS',
  FIJI = 'FIJI',
  FIJI_ISLANDS = 'FIJI_ISLANDS',
  FINLAND = 'FINLAND',
  FLORIDA = 'FLORIDA',
  FR = 'FR',
  FRANCE = 'FRANCE',
  FRENCH_GUIANA = 'FRENCH_GUIANA',
  FRENCH_GUYANA = 'FRENCH_GUYANA',
  FUJIAN = 'FUJIAN',
  GB = 'GB',
  GEORGIA = 'GEORGIA',
  GER = 'GER',
  GERMANY = 'GERMANY',
  GHANA = 'GHANA',
  GN = 'GN',
  GREAT_BRITAIN = 'GREAT_BRITAIN',
  GREECE = 'GREECE',
  GREENLAND = 'GREENLAND',
  GUADELOUPE = 'GUADELOUPE',
  GUAM = 'GUAM',
  GUATEMALA = 'GUATEMALA',
  HAITI = 'HAITI',
  HAUTS_DE_SEINE = 'HAUTS_DE_SEINE',
  HAWAII = 'HAWAII',
  HIMACHAL_PRADESH = 'HIMACHAL_PRADESH',
  HONDURAS = 'HONDURAS',
  HONG_KONG = 'HONG_KONG',
  HUNGARY = 'HUNGARY',
  ICELAND = 'ICELAND',
  IDAHO = 'IDAHO',
  IL = 'IL',
  ILLINOIS = 'ILLINOIS',
  INDIA = 'INDIA',
  INDIANA = 'INDIANA',
  INDONESIA = 'INDONESIA',
  IOWA = 'IOWA',
  IRAQ = 'IRAQ',
  IRELAND = 'IRELAND',
  ISLE_OF_MAN = 'ISLE_OF_MAN',
  ISRAEL = 'ISRAEL',
  IT = 'IT',
  ITALY = 'ITALY',
  ITALY_ = 'ITALY_',
  JALISCO = 'JALISCO',
  JAPAN = 'JAPAN',
  JEOLLABUK_DO = 'JEOLLABUK_DO',
  JORDAN = 'JORDAN',
  KANSAS = 'KANSAS',
  KARNATAKA = 'KARNATAKA',
  KAZAKHSTAN = 'KAZAKHSTAN',
  KENTUCKY = 'KENTUCKY',
  KENYA = 'KENYA',
  KOSOVO = 'KOSOVO',
  KUWAIT = 'KUWAIT',
  LAOS = 'LAOS',
  LATVIA = 'LATVIA',
  LEBANON = 'LEBANON',
  LITHUANIA = 'LITHUANIA',
  LOUISIANA = 'LOUISIANA',
  LOZERE = 'LOZERE',
  LUXEMBOURG = 'LUXEMBOURG',
  MA = 'MA',
  MACEDONIA = 'MACEDONIA',
  MAINE = 'MAINE',
  MALAYSIA = 'MALAYSIA',
  MALDIVES = 'MALDIVES',
  MALTA = 'MALTA',
  MANITOBA = 'MANITOBA',
  MARYLAND = 'MARYLAND',
  MASSACHUSETTS = 'MASSACHUSETTS',
  MAURITIUS = 'MAURITIUS',
  MEXICO = 'MEXICO',
  MICHIGAN = 'MICHIGAN',
  MINNESOTA = 'MINNESOTA',
  MISSISSIPPI = 'MISSISSIPPI',
  MISSOURI = 'MISSOURI',
  MOLDOVA = 'MOLDOVA',
  MONGOLIA = 'MONGOLIA',
  MONTANA = 'MONTANA',
  MOROCCO = 'MOROCCO',
  MX = 'MX',
  MYANMAR = 'MYANMAR',
  NEBRASKA = 'NEBRASKA',
  NEPAL = 'NEPAL',
  NETHERLANDS = 'NETHERLANDS',
  NEVADA = 'NEVADA',
  NEWFOUNDLAND = 'NEWFOUNDLAND',
  NEW_BRUNSWICK = 'NEW_BRUNSWICK',
  NEW_CALEDONIA = 'NEW_CALEDONIA',
  NEW_HAMPSHIRE = 'NEW_HAMPSHIRE',
  NEW_JERSEY = 'NEW_JERSEY',
  NEW_MEXICO = 'NEW_MEXICO',
  NEW_SOUTH_WALES = 'NEW_SOUTH_WALES',
  NEW_YORK = 'NEW_YORK',
  NEW_ZEALAND = 'NEW_ZEALAND',
  NICARAGUA = 'NICARAGUA',
  NIGERIA = 'NIGERIA',
  NL = 'NL',
  NOORD_HOLLAND = 'NOORD_HOLLAND',
  NORTHERN_IRELAND = 'NORTHERN_IRELAND',
  NORTHERN_MARIANA_ISLANDS = 'NORTHERN_MARIANA_ISLANDS',
  NORTHERN_TERRITORY = 'NORTHERN_TERRITORY',
  NORTH_CAROLINA = 'NORTH_CAROLINA',
  NORTH_DAKOTA = 'NORTH_DAKOTA',
  NORTH_ISLAND = 'NORTH_ISLAND',
  NORTH_RHINE_WESTPHALIA = 'NORTH_RHINE_WESTPHALIA',
  NORWAY = 'NORWAY',
  NOVA_SCOTIA = 'NOVA_SCOTIA',
  NY = 'NY',
  NYANZA = 'NYANZA',
  OHIO = 'OHIO',
  OKLAHOMA = 'OKLAHOMA',
  OMAN = 'OMAN',
  ONTARIO = 'ONTARIO',
  OREGON = 'OREGON',
  PAKISTAN = 'PAKISTAN',
  PANAMA = 'PANAMA',
  PARAGUAY = 'PARAGUAY',
  PENNSYLVANIA = 'PENNSYLVANIA',
  PERU = 'PERU',
  PHILIPPINES = 'PHILIPPINES',
  POLAND = 'POLAND',
  PORTUGAL = 'PORTUGAL',
  PRINCE_EDWARD_ISLAND = 'PRINCE_EDWARD_ISLAND',
  PUERTO_RICO = 'PUERTO_RICO',
  PUNJAB = 'PUNJAB',
  PYRENEES_ATLANTIQUES = 'PYRENEES_ATLANTIQUES',
  QATAR = 'QATAR',
  QLD = 'QLD',
  QUEBEC = 'QUEBEC',
  QUEENSLAND = 'QUEENSLAND',
  REPUBLIC_OF_KOREA = 'REPUBLIC_OF_KOREA',
  REPUBLIC_OF_MONTENEGRO = 'REPUBLIC_OF_MONTENEGRO',
  REPUBLIC_OF_SERBIA = 'REPUBLIC_OF_SERBIA',
  REPULIC_OF_KOREA = 'REPULIC_OF_KOREA',
  REUNION_FRENCH = 'REUNION_FRENCH',
  RHODE_ISLAND = 'RHODE_ISLAND',
  RHONE = 'RHONE',
  ROMANIA = 'ROMANIA',
  RUSSIA = 'RUSSIA',
  SAINT_LUCIA = 'SAINT_LUCIA',
  SAN_MARINO_REPUBLIC = 'SAN_MARINO_REPUBLIC',
  SASKATCHEWAN = 'SASKATCHEWAN',
  SAUDI_ARABIA = 'SAUDI_ARABIA',
  SCOTLAND = 'SCOTLAND',
  SELANGOR = 'SELANGOR',
  SERBIA = 'SERBIA',
  SEYCHELLES = 'SEYCHELLES',
  SEYCHELLES_ISL = 'SEYCHELLES_ISL',
  SG = 'SG',
  SHANDONG = 'SHANDONG',
  SINGAPORE = 'SINGAPORE',
  SLOVAKIA = 'SLOVAKIA',
  SLOVENIA = 'SLOVENIA',
  SOUTH_AFRICA = 'SOUTH_AFRICA',
  SOUTH_AUSTRALIA = 'SOUTH_AUSTRALIA',
  SOUTH_CAROLINA = 'SOUTH_CAROLINA',
  SOUTH_DAKOTA = 'SOUTH_DAKOTA',
  SOUTH_ISLAND = 'SOUTH_ISLAND',
  SPAIN = 'SPAIN',
  SRI_LANKA = 'SRI_LANKA',
  STATE_OF_BAHRAIN = 'STATE_OF_BAHRAIN',
  SURINAME = 'SURINAME',
  SWEDEN = 'SWEDEN',
  SWEDEN_ = 'SWEDEN_',
  SWITZERLAND = 'SWITZERLAND',
  TAIWAN = 'TAIWAN',
  TAMAULIPAS = 'TAMAULIPAS',
  TANZANIA = 'TANZANIA',
  TASMANIA = 'TASMANIA',
  TENNESSEE = 'TENNESSEE',
  TEST_STATE = 'TEST_STATE',
  TEXAS = 'TEXAS',
  TH = 'TH',
  THAILAND = 'THAILAND',
  THE_NETHERLANDS = 'THE_NETHERLANDS',
  TLAXCALA = 'TLAXCALA',
  TONGA = 'TONGA',
  TRINIDAD_AND_TOBAGO = 'TRINIDAD_AND_TOBAGO',
  TT = 'TT',
  TUNISIA = 'TUNISIA',
  TURKEY = 'TURKEY',
  TURKS_AND_CAICOS_ISLANDS = 'TURKS_AND_CAICOS_ISLANDS',
  TYNE_AND_WEAR = 'TYNE_AND_WEAR',
  UGANDA = 'UGANDA',
  UKRAINE = 'UKRAINE',
  UNITED_ARAB_EMIRATES = 'UNITED_ARAB_EMIRATES',
  UNKNOWN = 'UNKNOWN',
  URUGUAY = 'URUGUAY',
  UTAH = 'UTAH',
  VA = 'VA',
  VENEZUELA = 'VENEZUELA',
  VERMONT = 'VERMONT',
  VIC = 'VIC',
  VICTORIA = 'VICTORIA',
  VIETNAM = 'VIETNAM',
  VIET_NAM = 'VIET_NAM',
  VIRGINIA = 'VIRGINIA',
  VIRGIN_ISLANDS = 'VIRGIN_ISLANDS',
  WA = 'WA',
  WALES = 'WALES',
  WASHINGTON = 'WASHINGTON',
  WESTERN_AUSTRALIA = 'WESTERN_AUSTRALIA',
  WEST_VIRGINIA = 'WEST_VIRGINIA',
  WISCONSIN = 'WISCONSIN',
  WYOMING = 'WYOMING',
  XX = 'XX',
  YEMEN = 'YEMEN',
  YUKON_TERRITORY = 'YUKON_TERRITORY',
  YVELINES = 'YVELINES',
  ZAMBIA = 'ZAMBIA',
  ZHEJIANG = 'ZHEJIANG',
  ZIMBABWE = 'ZIMBABWE',
}

export enum UserLookupType {
  EMAIL = 'EMAIL',
  ID = 'ID',
  OKTA_UID = 'OKTA_UID',
  UID = 'UID',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  RM = 'RM',
}

export interface ActionItemInput {
  id?: string | null;
  name?: string | null;
  assignedTo?: string | null;
  dueDate?: any | null;
  status?: ActionItemStatus | null;
  createdBy?: string | null;
  statusUpdated?: any | null;
}

export interface AgendaItemInput {
  brandCode?: string | null;
  completed?: boolean | null;
  created_by_id?: string | null;
  created_by?: UserInput | null;
  datesReviewed?: (string | null)[] | null;
  id?: string | null;
  name?: string | null;
  notes?: string | null;
}

export interface CreateEventInput {
  name: string;
  start_date: any;
  end_date?: any | null;
  details?: string | null;
  hotel_id: number;
  created_by_id: string;
}

export interface CreateForecastInput {
  forecast_sold_1?: number | null;
  forecast_sold_2?: number | null;
  forecast_adr_1?: number | null;
  forecast_adr_2?: number | null;
  stay_date: any;
  hotel_id: number;
  created_by_id: string;
}

export interface CreateNoteInput {
  content?: any | null;
  created_by_id: string;
  hotel_id: number;
  stay_date?: any | null;
}

export interface CreateRmRecInput {
  stay_date: any;
  rate?: number | null;
  notes?: string | null;
  status?: RmRecStatus | null;
  hotel_id: number;
  created_by_id: string;
}

export interface CurrentRateTableArgs {
  brandCode: any;
  endDate?: any | null;
  snapshotDate: any;
  startDate?: any | null;
  useCache?: boolean | null;
  yearsCompare?: number | null;
}

export interface FindEventArgs {
  hotelId: number;
  startDate?: any | null;
  endDate?: any | null;
  createdById?: string | null;
  limit?: number | null;
  offset?: number | null;
}

export interface FindNotesArgs {
  createdById?: string | null;
  hotelId: number;
  startDate?: any | null;
  endDate?: any | null;
}

export interface FindRmRecArgs {
  hotelId: number;
  hidden?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
  createdById?: string | null;
  status?: RmRecStatus | null;
  limit?: number | null;
  offset?: number | null;
  ids?: (string | null)[] | null;
}

export interface ForecastLookup {
  lookupColumn: ForecastLookupType;
  lookupValue: string;
}

export interface OtaiSubLookup {
  lookupColumn: OtaiSubLookupType;
  lookupValue: string;
}

/**
 * The paramaeters for the OTAI Lowest Rates API
 * The latest documenation can be found at https: // api.otainsight.com/
 */
export interface RateQueryParams {
  bar?: boolean | null;
  changeDays?: (number | null)[] | null;
  compsetIds?: (number | null)[] | null;
  currency?: string | null;
  format?: string | null;
  fromDate?: string | null;
  los?: number | null;
  mealType?: MealType | null;
  ota?: string | null;
  persons?: number | null;
  roomType?: string | null;
  shopLength?: number | null;
  subscriptionId: string;
}

export interface RmDataArgs {
  brandCode?: any | null;
  hotelId?: string | null;
  endDate?: any | null;
  snapshotDate?: any | null;
  startDate?: any | null;
  useCache?: boolean | null;
  yearsCompare?: number | null;
}

export interface SaveMeetingInput {
  actionItems?: (ActionItemInput | null)[] | null;
  attendees?: (TeamMemberInput | null)[] | null;
  brandCode: string;
  createdById: string;
  date: string;
  itemsReviewed?: (AgendaItemInput | null)[] | null;
  notes?: string | null;
  reportDataFile?: string | null;
  widgetData?: string | null;
}

export interface TeamMemberInput {
  id?: string | null;
  brandCode?: string | null;
  name?: string | null;
  email?: string | null;
  title?: string | null;
  phone?: string | null;
  status?: MemberStatus | null;
  datesAttended?: (any | null)[] | null;
  active?: boolean | null;
  inactiveDate?: any | null;
}

export interface TrackingInput {
  action?: string | null;
  app: string;
  brandCode: any;
  colLock?: number | null;
  dateSegment?: string | null;
  endDate?: any | null;
  event?: string | null;
  month?: number | null;
  page?: string | null;
  snapshotDate?: any | null;
  startDate?: any | null;
  userEmail: string;
  userName?: string | null;
  userRole?: string | null;
  yearsCompare?: number | null;
}

export interface UpdateEventInput {
  name?: string | null;
  start_date?: any | null;
  end_date?: any | null;
  details?: string | null;
  hotel_id?: string | null;
  created_by_id?: string | null;
}

export interface UpdateForecastInput {
  forecast_sold_1?: number | null;
  forecast_sold_2?: number | null;
  forecast_adr_1?: number | null;
  forecast_adr_2?: number | null;
}

export interface UpdateNoteInput {
  content?: any | null;
  stay_date?: any | null;
}

export interface UpdateRmRecInput {
  stay_date?: any | null;
  rate?: number | null;
  notes?: string | null;
  status?: RmRecStatus | null;
}

export interface UploadRatesInput {
  id: string;
  current_rate?: number | null;
}

export interface UpsertRmRecInput {
  id?: string | null;
  stay_date: any;
  rate?: number | null;
  notes?: string | null;
  status?: RmRecStatus | null;
  hotel_id?: string | null;
  created_by_id?: string | null;
}

export interface UserInput {
  email?: string | null;
  id?: string | null;
  name?: string | null;
  role?: UserRole | null;
}

export interface UserLookup {
  lookupColumn: UserLookupType;
  lookupValue: string;
}

export interface UserOptionInput {
  appNotes?: string | null;
  brandCode: any;
  headers?: string | null;
  overviewSettings?: string | null;
  shopParams?: string | null;
  userId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
