export type TabItems = {
  key: string;
  title: string;
  to: string;
};

export const tabItems = [
  {
    key: 'tab-overview',
    to: '/admin/overview',
    title: 'Overview',
  },
  {
    key: 'tab-tracking',
    to: '/admin/tracking',
    title: 'Tracking',
  },
  {
    key: 'tab-agendas',
    to: '/admin/agendas',
    title: 'Agendas',
  },
];
