import { FaArrowCircleUp } from 'react-icons/fa';

interface BtnScrollToTopProps {
  callback?: (id?: string) => void;
}

export const BtnScrollToTop = ({ callback }: BtnScrollToTopProps) => {
  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    let topButton = document.getElementById('btn-back-to-top');

    if (!topButton) {
      return;
    }

    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      topButton.style.display = 'block';
    } else {
      topButton.style.display = 'none';
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    callback && callback();
  };

  return (
    <button
      type='button'
      data-mdb-ripple='true'
      data-mdb-ripple-color='light'
      data-tip='Back To Top'
      className='fixed inline-block p-3 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-xl hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5 z-50'
      onClick={() => scrollToTop()}
      id='btn-back-to-top'
      style={{ display: 'none' }}
    >
      <FaArrowCircleUp className='h-10 w-10' />
    </button>
  );
};
