/* eslint-disable */
import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetByBrandCodeQueryVariables = Types.Exact<{
  brandCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetByBrandCodeQuery = { __typename?: 'Query', getByBrandCode?: { __typename?: 'Hotel', brand_code: any, city?: string | null, country?: Types.Country | null, descriptor?: Types.BrandDescriptor | null, hotel_id?: number | null, is_prm?: boolean | null, name?: string | null, rm_serv_code?: string | null, state?: Types.State | null, use_forecast?: boolean | null } | null };


export const GetByBrandCodeDocument = gql`
    query GetByBrandCode($brandCode: String) {
  getByBrandCode(brandCode: $brandCode) {
    brand_code
    city
    country
    descriptor
    hotel_id
    is_prm
    name
    rm_serv_code
    state
    use_forecast
  }
}
    `;

/**
 * __useGetByBrandCodeQuery__
 *
 * To run a query within a React component, call `useGetByBrandCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetByBrandCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetByBrandCodeQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useGetByBrandCodeQuery(baseOptions?: Apollo.QueryHookOptions<GetByBrandCodeQuery, GetByBrandCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetByBrandCodeQuery, GetByBrandCodeQueryVariables>(GetByBrandCodeDocument, options);
      }
export function useGetByBrandCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetByBrandCodeQuery, GetByBrandCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetByBrandCodeQuery, GetByBrandCodeQueryVariables>(GetByBrandCodeDocument, options);
        }
export type GetByBrandCodeQueryHookResult = ReturnType<typeof useGetByBrandCodeQuery>;
export type GetByBrandCodeLazyQueryHookResult = ReturnType<typeof useGetByBrandCodeLazyQuery>;
export type GetByBrandCodeQueryResult = Apollo.QueryResult<GetByBrandCodeQuery, GetByBrandCodeQueryVariables>;
export const namedOperations = {
  Query: {
    GetByBrandCode: 'GetByBrandCode'
  }
}