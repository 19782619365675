import { Route, Switch } from 'react-router-dom';

import Budget from './sections/Budget';
import Compare from './sections/Compare';
import Competitors from './sections/Competitors';
import Events from './sections/Events';
import Meetings from './sections/meetings';
import Overview from './sections/Overview';
import RateChangeLogs from './sections/RateChangeLogs';
import RateLevels from './sections/RateLevels';
import RmRecs from './sections/RmRecs';
import Rooms from './sections/Rooms';

const Routes = () => {
  return (
    <Switch>
      <Route path='/dashboard/budget' component={Budget} exact />
      <Route path='/dashboard/compare' component={Compare} exact />
      <Route path='/dashboard/competitors' component={Competitors} exact />
      <Route path='/dashboard/events' component={Events} exact />
      <Route path='/dashboard/meetings' component={Meetings} exact />
      <Route path='/dashboard/overview' component={Overview} exact />
      <Route path='/dashboard/rate_changes' component={RateChangeLogs} exact />
      <Route path='/dashboard/rate_levels' component={RateLevels} exact />
      <Route path='/dashboard/rm_recs' component={RmRecs} exact />
      <Route path='/dashboard/rooms' component={Rooms} exact />
    </Switch>
  );
};

export default Routes;
