import { Link, useLocation } from 'react-router-dom';

import { tabItems } from './tabItems';

type TabItem = {
  key: string;
  to: string;
  title: string;
};

const renderTabItems = (tabItems: TabItem[], location: any) => {
  return tabItems.map((tabItem) => {
    const current = tabItem.to === location.pathname;
    const linkClass =
      'inline-block w-full md:w-1/2 lg:w-auto px-4 pb-2 border-b-2 border-transparent';
    return (
      <Link
        {...tabItem}
        className={
          current
            ? `${linkClass} font-semibold text-blue-900`
            : `${linkClass} hover:border-blue-900 text-gray-700`
        }
      >
        {tabItem.title}
      </Link>
    );
  });
};

const Tabs = () => {
  const location = useLocation();

  return (
    <div className='flex flex-wrap text-sm text-center'>
      {renderTabItems(tabItems, location)}
    </div>
  );
};

export default Tabs;
