import { initTableDates } from '../helpers/dateHelpers';
import { omit } from 'lodash';
import { useReducer } from 'react';

export enum ActionTypes {
  DOW,
  FILTERS,
  SORT,
}

export type Action = {
  type: ActionTypes;
  payload: {
    dow?: {
      mon?: boolean;
      tue?: boolean;
      wed?: boolean;
      thu?: boolean;
      fri?: boolean;
      sat?: boolean;
      sun?: boolean;
    };
    endDate?: string;
    sort?: {
      key: string;
      dir?: SortDir;
    };
    startDate?: string;
    textFilter?: string;
  };
};

export type DayFilters = {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
};

type OptionOverrides = {
  dow?: DayFilters;
  endDate?: string;
  sort?: { key: string; dir: SortDir };
  startDate?: string;
  textFilter?: string;
};

export enum SortDir {
  ASC = 'asc',
  DESC = 'desc',
}

export type Sort = { key: string; dir: SortDir };

export type State = {
  startDate: string;
  endDate: string;
  dow: DayFilters;
  sort: Sort;
  textFilter: string;
};

export type NumberRange = [number, number];

export type TableFilters = {
  [fieldName: string]: DayFilters | string | number | boolean | NumberRange;
};

const initialState: State = {
  startDate: initTableDates().startDate,
  endDate: initTableDates().endDate,
  dow: {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: true,
    sun: true,
  },
  sort: {
    key: 'stay_date',
    dir: SortDir.ASC,
  },
  textFilter: '',
};

const togSort = (dir: SortDir) => {
  return dir === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.DOW:
      return { ...state, dow: { ...state.dow, ...payload.dow } };

    case ActionTypes.FILTERS:
      return { ...state, ...omit(payload, ['sort', 'dow']) };

    case ActionTypes.SORT:
      const sort = payload.sort as Sort;
      const isKey = state.sort.key === sort.key;
      return {
        ...state,
        sort: {
          key: sort.key,
          dir: isKey ? togSort(state.sort.dir) : SortDir.DESC,
        },
      };

    default:
      return state;
  }
};

export const useDataTableOptions = (overrides?: OptionOverrides) => {
  return useReducer(reducer, { ...initialState, ...overrides });
};
