import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../components/ui/form';
import React, { useMemo } from 'react';

import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Plus } from 'lucide-react';
import { Switch } from '../../../components/ui/switch';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useManageFlags } from '../hooks/use-manage-flags';
import { useToast } from '../../../components/ui/use-toast';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

// Define the schema for feature flag creation
const createInsertFeatureFlagSchema = (existingKeys: string[]) =>
  z.object({
    key: z
      .string()
      .min(2, 'Key must be at least 2 characters')
      .transform((v) => _.kebabCase(v))
      .refine(
        (key) => !existingKeys.includes(key),
        (key) => ({ message: `The key "${key}" already exists` })
      ),
    description: z.string().min(1, 'Description is required'),
    defaultValue: z.boolean(),
    active: z.boolean(),
  });

type CreateFlagFormProps = {
  onSuccess?: () => void;
};

export const CreateFlagForm: React.FC<CreateFlagFormProps> = ({
  onSuccess,
}) => {
  const { toast } = useToast();
  const { createFlag, creating, flags, updating } = useManageFlags();

  const existingFlagKeys = useMemo(() => {
    return flags.filter((f) => f !== null).map((f) => f.key!);
  }, [flags]);

  const insertFeatureFlagSchema = useMemo(() => {
    return createInsertFeatureFlagSchema(existingFlagKeys);
  }, [existingFlagKeys]);

  const form = useForm<z.infer<typeof insertFeatureFlagSchema>>({
    resolver: zodResolver(insertFeatureFlagSchema),
    mode: 'onBlur',
    defaultValues: {
      key: '',
      description: '',
      defaultValue: false,
      active: false,
    },
  });

  const onSubmit = async (values: z.infer<typeof insertFeatureFlagSchema>) => {
    const result = await createFlag(values);

    if (result.success) {
      form.reset();
      toast({
        title: result.title,
        description: result.description,
      });
      onSuccess?.();
    } else {
      toast({
        title: result.title,
        description: result.description,
        variant: 'destructive',
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
        <FormField
          control={form.control}
          name='key'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Key</FormLabel>
              <FormControl>
                <Input placeholder='new-feature' {...field} />
              </FormControl>
              <FormDescription>
                The unique identifier for this feature flag
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='description'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Input placeholder='Describe the feature flag' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='defaultValue'
          render={({ field }) => (
            <FormItem className='flex flex-row items-center justify-between rounded-lg border p-4'>
              <div className='space-y-0.5'>
                <FormLabel className='text-base'>Default Value</FormLabel>
                <FormDescription>
                  Set the default state of the feature flag
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='active'
          render={({ field }) => (
            <FormItem className='flex flex-row items-center justify-between rounded-lg border p-4'>
              <div className='space-y-0.5'>
                <FormLabel className='text-base'>Active</FormLabel>
                <FormDescription>
                  Set the initial active state of the feature flag
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type='submit' disabled={creating || updating}>
          <Plus className='mr-2 h-4 w-4' /> {creating ? 'Creating' : 'Create'}{' '}
          Feature Flag
        </Button>
      </form>
    </Form>
  );
};
